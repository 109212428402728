import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import PageHeader from '../../app/components/PageHeader';
import useAuth from '../../auth/hooks/useAuth';
import Parameters from '../../features/ParametersPage/Parameters';

const SettingsPage = () => {
  const { signout } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div>
      <PageHeader
        title={t('parametersPage.title')}
        description={t('parametersPage.description')}
      />
      <Parameters onAccountDeleted={() => signout(() => navigate('/'))} />
    </div>
  );
};

export default SettingsPage;
