import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer } from 'react-bootstrap';

import { useGetParametersQuery, GetParametersQueryResult } from '../../../app/generated/graphql';
import * as tokens from '../../../app/DesignSystem/tokens';
import Card from '../../../app/components/Card';
import QueryIndicator from '../../../app/components/QueryIndicator';
import CardTitle from '../../../app/components/CardTitle';
import Modal from '../../../app/components/Modal';
import ParametersEmailForm from '../ParametersEmailForm';
import ParametersPasswordForm from '../ParametersPasswordForm';
import ParametersProfileForm from '../ParametersProfileForm';
import ParametersDeleteAccountForm from '../ParametersDeleteAccountForm';
import Button from '../../../app/components/Button';
import Toast from '../../../app/components/Toast';

type EditButtonProps = { id: string, onEdit: VoidFunction, eventName: string };
const EditButton = ({ onEdit, id, eventName }: EditButtonProps) => (
  <button
    aria-labelledby="Edit button"
    data-tracking-event={eventName}
    onClick={onEdit}
    type="button"
    data-testid={`${id}-edit-icon`}
    style={{
      background: 'transparent',
      border: 0,
    }}
  >
    <FontAwesomeIcon
      icon={faEdit}
      role="img"
      fontSize={18}
      color={tokens.colorBlueOcean}
    />
  </button>
);

type FieldProps = {
  eventName: string
  label: string
  id: string
  value: string
  onEdit?: undefined | VoidFunction
  type?: React.HTMLInputTypeAttribute
};
const Field = ({
  label, id, value, onEdit, type, eventName,
}: FieldProps) => (
  <label
    htmlFor={id}
    className="d-flex mb-4"
    style={{
      // display: 'flex', width: '100%', marginBottom: tokens.spaceExtraSmall, alignItems: 'center',
    }}
  >
    <span style={{ flex: 1 }}>{label}</span>
    <input
      id={id}
      disabled
      value={value}
      type={type}
      style={{
        ...tokens.textFieldDisabled,
        flex: 2,
      }}
    />
    <div style={{ display: 'flex', flex: 1, justifyContent: 'flex-end' }}>
      {onEdit !== undefined && <EditButton id={id} onEdit={onEdit} eventName={eventName} />}
    </div>
  </label>
);
Field.defaultProps = { onEdit: undefined, type: 'text' };

type Props = { onAccountDeleted: VoidFunction };
const Parameters = ({ onAccountDeleted }: Props) => {
  const [emailFormVisible, setEmailFormVisible] = useState<boolean>(false);
  const [emailToastVisible, setEmailToastVisible] = useState<boolean>(false);

  const [passwordFormVisible, setPasswordFormVisible] = useState<boolean>(false);
  const [passwordToastVisible, setPasswordToastVisible] = useState<boolean>(false);

  const [profileFormVisible, setProfileFormVisible] = useState<boolean>(false);
  const [profileToastVisible, setProfileToastVisible] = useState<boolean>(false);

  const [deleteAccountFormVisible, setDeleteAccountFormVisible] = useState<boolean>(false);
  const { t } = useTranslation();
  const {
    data,
    error,
    loading,
  } = useGetParametersQuery({ notifyOnNetworkStatusChange: true });

  if (loading) {
    return <QueryIndicator />;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  const userData = (data as GetParametersQueryResult['data'])!.me!;
  const { profile } = userData;

  return (
    <div className="d-flex justify-content-center">
      <div className="w-100" style={{ maxWidth: 576 }}>
        <Card marginBottom={tokens.spaceMedium}>
          <CardTitle>{t('parametersPage.titlePersonalInfo')}</CardTitle>
          <div
            style={{
              position: 'absolute',
              top: tokens.spaceSmall,
              right: tokens.spaceSmall,
            }}
          >
            <EditButton
              id="profile"
              onEdit={() => setProfileFormVisible(true)}
              eventName="m.settings.personal_info.edit.click"
            />
          </div>
          <Field
            id="gender"
            label={t('parametersPage.labelGender')}
            value={t(`gender.${profile.gender}`)}
            eventName=""
          />
          <Field
            id="lastname"
            label={t('parametersPage.labelLastname')}
            value={profile.lastName}
            eventName=""
          />
          <Field
            id="firstname"
            label={t('parametersPage.labelFirstname')}
            value={profile.firstName || ''}
            eventName=""
          />
          <Field
            id="phone_number"
            label={t('parametersPage.labelPhone')}
            value={profile.phone.number || ''}
            eventName=""
          />
        </Card>
        <Card marginBottom={tokens.spaceMedium}>
          <CardTitle>{t('parametersPage.titleCredentials')}</CardTitle>
          <Field
            id="email"
            label={t('parametersPage.labelEmail')}
            value={profile.email}
            onEdit={() => setEmailFormVisible(true)}
            eventName="m.settings.email.edit.click"
          />
          <Field
            id="password"
            label={t('parametersPage.labelPassword')}
            value="******"
            onEdit={() => setPasswordFormVisible(true)}
            type="password"
            eventName="m.settings.password.edit.click"
          />
        </Card>
        <Button
          title={t('parametersPage.deleteAccount')}
          onClick={() => setDeleteAccountFormVisible(true)}
          variant="negative"
        />
      </div>
      <Modal
        onClose={() => setEmailFormVisible(false)}
        visible={emailFormVisible}
      >
        <ParametersEmailForm
          userProfile={userData}
          onCancel={() => setEmailFormVisible(false)}
          onMutationSuccess={() => {
            setEmailFormVisible(false);
            setEmailToastVisible(true);
          }}
        />
      </Modal>
      <Modal
        onClose={() => setPasswordFormVisible(false)}
        visible={passwordFormVisible}
      >
        <ParametersPasswordForm
          onCancel={() => setPasswordFormVisible(false)}
          onMutationSuccess={() => {
            setPasswordFormVisible(false);
            setPasswordToastVisible(true);
          }}
        />
      </Modal>
      <Modal
        onClose={() => setProfileFormVisible(false)}
        visible={profileFormVisible}
      >
        <ParametersProfileForm
          recruiterParameters={userData}
          onCancel={() => setProfileFormVisible(false)}
          onMutationSuccess={() => {
            setProfileFormVisible(false);
            setProfileToastVisible(true);
          }}
        />
      </Modal>
      <Modal
        onClose={() => setDeleteAccountFormVisible(false)}
        visible={deleteAccountFormVisible}
      >
        <ParametersDeleteAccountForm
          onCancel={() => setDeleteAccountFormVisible(false)}
          onMutationSuccess={onAccountDeleted}
        />
      </Modal>
      <ToastContainer position="top-end">
        <Toast
          show={emailToastVisible}
          onClose={() => setEmailToastVisible(false)}
          message={t('parametersEmailForm.successMessage')}
          variant="success"
        />
        <Toast
          show={passwordToastVisible}
          onClose={() => setPasswordToastVisible(false)}
          message={t('parametersPasswordForm.successMessage')}
          variant="success"
        />
        <Toast
          show={profileToastVisible}
          onClose={() => setProfileToastVisible(false)}
          message={t('parametersProfileForm.successMessage')}
          variant="success"
        />
      </ToastContainer>
    </div>
  );
};

export default Parameters;
