/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'react-bootstrap';
import { faEllipsis } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

export type Action = {
  label: string
  action: VoidFunction
  labelIcon?: IconProp
};

type ButtonProps = { onClick: (e: React.MouseEvent<HTMLButtonElement>) => void };

type Props = {
  actions: Array<Action>
  id: string
  icon?: IconProp
  headerText?: string | null
};

const ActionDropdown = ({
  actions, id, icon, headerText,
}: Props) => {
  const ToggleButton = React.forwardRef(({ onClick }: ButtonProps, ref: any) => (
    <button
      aria-labelledby="Toggle dropdown button"
      className="border-0 bg-transparent text-grey-blue"
      data-testid={`action-dropdown-${id}`}
      type="button"
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <FontAwesomeIcon icon={icon!} role="img" fontSize={20} />
    </button>
  ));
  return (
    <Dropdown style={{ textAlign: 'center' }}>
      <Dropdown.Toggle as={ToggleButton} id={id}>
        Bonjour
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {headerText && (
        <Dropdown.Header>
          {headerText}
        </Dropdown.Header>
        )}
        {actions.map(({ label, action, labelIcon }) => (
          <Dropdown.Item
            key={`dropdown-${id}-item-${label}`}
            as="button"
            onClick={action}
          >
            <div>
              {labelIcon && <FontAwesomeIcon fontSize={20} icon={labelIcon} /> }
              &nbsp;
              {label}
            </div>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

ActionDropdown.defaultProps = {
  icon: faEllipsis,
  headerText: null,
};

export default ActionDropdown;
