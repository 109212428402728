import React from 'react';
import { useGroupOffice } from '../../data/hooks';
import useTracking from '../../hooks/useTracking';
import Select from '../Select';

type Props = { eventNamePrefix: string };

const GroupOfficeSelect = ({ eventNamePrefix }: Props) => {
  const { logEvent } = useTracking();
  const {
    allGroupOffices,
    selectedGroupOfficeUuid,
    selectGroupOffice,
  } = useGroupOffice();

  const handleChange = (goUuid: string) => {
    logEvent(`${eventNamePrefix}.select_etab.update`);
    selectGroupOffice(goUuid);
  };

  return (
    <div>
      <Select
        title="Tous les établissements"
        onChange={handleChange}
        options={allGroupOffices}
        variant="white"
        eventName={`${eventNamePrefix}.select_etab.click`}
        value={selectedGroupOfficeUuid}
        hideTitle={allGroupOffices.length === 1}
      />
    </div>
  );
};

export default GroupOfficeSelect;
