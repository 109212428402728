/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { ColumnDef, ColumnSort } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { ARRAY_ITEMS_PER_PAGE } from '../../constants';
import {
  DateRangeInput,
  Mission,
  useUpcomingMissionsExportLazyQuery,
  useUpcomingMissionsQuery,
} from '../../generated/graphql';
import AlertMessage from '../AlertMessage';
import QueryIndicator from '../QueryIndicator';
import Table from '../Table';
import utils from '../../domain/utils';
import TableCellService from '../TableCellService';
import TableCellSpecialty from '../TableCellSpecialty';
import { useExportOptions } from '../../hooks/useExportOptions';
import TableCellUserWithPopover from '../TableCellUserWithPopover';
import useAuth from '../../../auth/hooks/useAuth';
import useTracking from '../../hooks/useTracking';
import ActionDropdown, { Action } from '../ActionDropdown/ActionDropdown';
import RequestMissionCancellation from '../../../features/Mission/RequestMissionCancellation';

type Props = {
  groupOfficesUuid: Array<string>
  dates: DateRangeInput
  title: string
  hint: string
  eventNamePrefix: string
};
const UpcomingMissions = ({
  eventNamePrefix,
  groupOfficesUuid,
  dates,
  title,
  hint,
}: Props) => {
  const [missionToCancel, setMissionToCancel] = React.useState<Mission | null>(null);
  const {
    data,
    error,
    loading,
  } = useUpcomingMissionsQuery({ variables: { groupOfficesUuid, dates } });
  const { logEvent } = useTracking();
  const { t } = useTranslation();
  const { getAccessLevel } = useAuth();

  const { csvOptions, xlsxOptions } = useExportOptions({
    query: useUpcomingMissionsExportLazyQuery,
    dates,
    groupOfficesUuid,
    name: t('missionsPage.exportUpcomingTitle'),
    eventName: eventNamePrefix,
    role: getAccessLevel(),
  });

  if (loading) {
    return <QueryIndicator />;
  }

  if (error) {
    return <AlertMessage message={error.message} />;
  }

  const columns: ColumnDef<Mission>[] = [
    {
      id: 'date',
      header: () => t('column.date'),
      accessorFn: (row) => row.dates.startDate,
      cell: ({ row }) => utils.formatDate(row.original.dates.startDate),
    },
    {
      id: 'candidate_name',
      header: () => t('column.candidate'),
      accessorFn: (row) => `${row.candidate?.profile.lastName} ${row.candidate?.profile.firstName}`,
      cell: ({ row }) => (
        <TableCellUserWithPopover
          specialties={row.original.candidate.specialties}
          profile={row.original.candidate.profile}
        />
      ),
    },
    {
      id: 'specialty',
      header: () => t('column.specialties'),
      accessorFn: (row) => row.specialty.name,
      cell: ({ row }) => {
        const { name } = row.original.specialty;

        return <TableCellSpecialty name={name} uuid={row.original.uuid} />;
      },
    },
    {
      id: 'service',
      header: () => t('column.service'),
      accessorFn: (row) => row.service.name,
      cell: ({ row }) => {
        const { specificServices, service } = row.original;

        return <TableCellService service={service} specificServices={specificServices} />;
      },
    },
    {
      id: 'sector',
      header: t('column.sector'),
      accessorKey: 'sector',
    },
    {
      id: 'amount',
      enableGlobalFilter: false,
      header: () => t('column.salary'),
      accessorFn: (row) => `${utils.formatFloat(row.salary!)} €`,
    },
    {
      id: 'hours',
      header: () => t('column.hours'),
      cell: ({ row }) => {
        const missionDates = row.original.dates;

        const getHours = (dateTime: string) => utils.formatDateTime(dateTime, 'HH:mm');
        return `${getHours(missionDates.startDate)} - ${getHours(missionDates.endDate)}`;
      },
    },
    {
      id: 'actions',
      header: () => t('column.actions'),
      cell: ({ row }) => {
        const actions: Array<Action> = [{
          label: t('column.cancelRequest'),
          action: () => {
            logEvent('m.mission.button.cancel.open');
            setMissionToCancel(row.original);
          },
        }];

        return <ActionDropdown actions={actions} id={`table-action-${row.original.uuid}`} />;
      },
    },
  ];

  const missions = data!.me!.missions!.edges!.map((item) => (item!.node));

  const defaultColumnSort: ColumnSort = { id: 'date', desc: false };

  const closeModal = () => setMissionToCancel(null);

  return (
    <>
      <Table
        eventNamePrefix={eventNamePrefix}
        title={title}
        hint={hint}
        columns={utils.filterTableColumnsByRole(columns, getAccessLevel())}
        data={(missions as Array<Mission>)}
        defaultColumnSort={defaultColumnSort}
        canBeFiltered
        pageSize={ARRAY_ITEMS_PER_PAGE}
        exportOptions={[csvOptions, xlsxOptions]}
      />
      {missionToCancel ? (
        <RequestMissionCancellation
          missionUuid={missionToCancel.uuid}
          missionDate={missionToCancel.dates.startDate}
          missionCandidate={missionToCancel.candidate.profile}
          missionGroupOffice={missionToCancel.groupOffice}
          onClose={closeModal}
        />
      ) : null}
    </>
  );
};

export default UpcomingMissions;
