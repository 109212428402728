/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';

type PageProps = { title: string };

const Page = ({ title, children }: React.PropsWithChildren<PageProps>) => {
  React.useEffect(() => {
    document.title = `Espace Etablissement - ${title}`;
  }, [title]);

  if (children) {
    return <>{children}</>;
  }
  return null;
};

export default Page;
