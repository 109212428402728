export default {
  nav: {
    dashboard: 'Tableau de bord',
    missions: 'Missions',
    contracts: 'Contrats',
    invoices: 'Factures',
    feesInvoices: 'Factures Medelse',
    help: 'Aide',
    contact: 'Contact',
    settings: 'Paramètres',
    FAQ: 'FAQ',
  },
  emptyResult: 'Pas de données à afficher pour le moment',
  app: {
    global: {
      cancel: 'Annuler',
    },
  },
  general: {
    message: 'Message',
  },
  month: {
    '01': 'jan',
    '02': 'fev',
    '03': 'mar',
    '04': 'avr',
    '05': 'mai',
    '06': 'jui',
    '07': 'jul',
    '08': 'aou',
    '09': 'sep',
    10: 'oct',
    11: 'nov',
    12: 'dec',
  },
  gender: {
    MALE: 'M.',
    FEMALE: 'Mme.',
    DOCTOR: 'Dr.',
    NONE: '-',
  },
  components: {
    table: {
      filterPlaceholder: 'Rechercher …',
    },
  },
  actions: {
    confirm: 'Confirmer',
    refuse: 'Refuser',
    cancel: 'Annuler',
    sign: 'Signer',
    report: 'Signaler',
  },
  errors: {
    requiredField: 'Champ obligatoire',
  },
  auth: {
    register: {
      link: 'Créer compte',
    },
    recoverPassword: {
      link: 'Mot de passe oublié ?',
      headline: 'Mot de passe oublié ?',
      instructions: 'Renseignez votre adresse email pour recevoir le lien de récupération',
      emailInputLabel: 'Adresse email',
      submitButton: 'Envoyer le lien',
      successMessage: 'Nous vous avons envoyé le lien de récupération par email.',
    },
    login: {
      button: 'Se connecter',
      pageTitle: 'Espace établissement de santé',
      title: 'Connexion',
      username: 'Adresse email',
      password: 'Mot de passe',
      rememberMe: 'Se souvenir de moi',
      youAreSearchingMission: 'Vous êtes professionnel de santé ?',
      loginHere: 'Connectez-vous ici',
      noAccount: 'Pas de compte ?',
      register: 'S\'inscrire',
    },
    logout: 'Quitter',
    unauthorized: 'Vous ne pouvez pas accéder à la page de l\'espace établissement. Veuillez vous connecter à <1>l\'espace professionnel</1> pour continuer.',
  },
  dashboardPage: {
    hint: 'Retrouvez ici la synthèse de l’activité Medelse sur vos établissements.',
    greetings: 'Bonjour',
  },
  dashboard: {
    announcementsCountSingular: 'Annonce en ligne',
    announcementsCountPlural: 'Annonces en ligne',
    contractualizedMissionsCountSingular: 'Mission contractualisée',
    contractualizedMissionsCountPlural: 'Missions contractualisées',
    lateInvoicesCountSingular: 'Facture en retard de paiement',
    lateInvoicesCountPlural: 'Factures en retard de paiement',
    totalActionToDo: 'Actions à réaliser',
    contractsToSign: 'Contrats à signer',
    datesToConfirm: 'Dates à confirmer',
    missionsCountInNextInvoiceSingular: 'Mission dans la prochaine facturation',
    missionsCountInNextInvoicePlural: 'Missions dans la prochaine facturation',
    upcomingMissions: 'Arrivées de la semaine',
    upcomingMissionsHint: 'Missions contractualisées des 7 prochains jours',
    selectOfficePlaceholder: 'Sélectionnez un établissement pour voir plus de données',
    topTen: {
      title: 'Top 10 Professionnels de santé',
      hint: 'Des 6 derniers mois',
    },
  },
  missionsPage: {
    title: 'Missions',
    hint: 'Retrouvez ici l’ensemble des missions de vos établissements.',
    tabUpcoming: 'À venir',
    tabArchived: 'Archivées',
    exportUpcomingTitle: 'Mission-à-venir',
    exportArchivedTitle: 'Mission-archivées',
    exportToConfirmTitle: 'Mission-à-confirmer',
    exportAnnouncementsTitle: 'Mission-en-ligne',
    exportCancelledTitle: 'Mission-annulées',
  },
  createAnnouncement: {
    openForm: 'Publier des besoins',
    chooseAnOffice: 'Sélectionner un établissement',
    title: 'Publication de besoins',
    description: 'En cliquant sur confirmer, vous approuvez la publication des besoins sur la plateforme Medelse, et leur diffusion auprès des professionnels de santé utilisateurs de Medelse',
    content: `Bonjour Equipe Planning,

L'établissement : {{ officeName }} fait part des besoins suivants :

• Service : [Insérer le service requis]
• Date : [Insérer la date souhaitée]
• Horaires : [Insérer les horaires nécessaires]
• Spécialités : [Insérer les spécialités requises]


Merci de publier les annonces`,
    attachment: 'Pièce-jointe (facultatif)',
  },
  cancelAnnouncement: {
    content: `Bonjour Equipe Planning,

L'établissement {{ officeName }} demande l'annulation de l'annonce suivante :
• Annonce du {{ date }}`,
  },
  cancelRequest: {
    chooseAnOffice: 'Sélectionner un établissement',
    title: 'Annulation de la mission du {{ date }} {{ candidate }}',
    description: "Une annulation à moins de 48h du début de la mission sera facturée 25% du montant de la mission, et 100% si l'annulation est émise à moins de 24h du début de la mission.",
    content: `Bonjour Equipe Planning,

L'établissement {{ officeName }} demande l'annulation de la mission suivante :
• {{ candidate }} pour la mission du {{ date }}`,
  },
  missions: {
    onlineAnnouncements: 'Missions à pourvoir',
    onlineAnnouncementsHint: 'Missions pour lesquelles vous recherchez un Professionnel de Santé ',
    missionsToConfirm: 'Missions à confirmer',
    missionsToConfirmHint: 'Missions acceptées par un Professionnel de Santé en attente de validation',
    finishedMissions: 'Missions réalisées',
    finishedMissionsHint: 'Missions où des Professionnels de Santé sont venus exercer',
    cancelledMissions: 'Missions annulées',
    cancelledMissionsHint: "Missions annulées par l'Établissement ou le Professionnel de santé",
    confirmedAt: 'Confirmée le {{date}}, en attente de contrat',
    refusedAt: 'Refusée le {{date}}, en attente d\'annulation',
    reportedAt: 'Signalée le',
    upcomingMissions: 'Missions à venir',
    upcomingMissionsHint: 'Missions contractualisées',
  },
  confirmMissionForm: {
    alert: 'En confirmant la date, vous approuvez la création du contrat entre le professionnel et l’établissement concernés par la mission',
    title: 'Confirmation de la mission du',
    content: `Bonjour Equipe Planning,

L'établissement {{officeName}} confirme l'acceptation du professionnel de santé :
- {{candidateName}} pour la mission du {{date}} - Service : {{service}} {{specificServices}}

C’est bon pour générer le contrat !

L'Etablissement {{officeName}}`,
  },
  refuseMissionForm: {
    alert: 'En refusant la date, vous impactez la réputation de l’établissement auprès des Professionnels de santé, pensez à mettre à jour vos besoins en amont.',
    title: 'Refus de la mission du',
    content: `Bonjour Equipe Planning,

L'établissement {{officeName}} refuse l'acceptation du professionnel de santé :
- {{candidateName}} pour la mission du {{date}} - Service : {{service}} {{specificServices}}

L'Etablissement {{officeName}}`,
  },
  reportMissionForm: {
    alert: 'En faisant un signalement, vous bloquez la facturation de la mission le temps que nos équipes fassent le point avec vous et le Professionnel de santé.',
    title: 'Signalement de la mission du',
    reason: {
      BAD_TIME: 'Temps travaillé non conforme au contrat',
      NOT_DONE: 'Journée non réalisée',
      OTHER: 'Autre',
    },
    break: 'Temps de pause pris (en minutes)',
    duration: 'Heures travaillées',
    startTime: 'Heure d’arrivée',
    endTime: 'Heure de départ',
    notDone: 'Merci de nous donner plus d’informations …',
    other: 'Autre écart par rapport aux termes contractuels …',
  },
  column: {
    actions: 'Action',
    candidate: 'Pro. de santé',
    specialties: 'Spécialités',
    numberOfMissions: 'Nb. Missions',
    contractNumber: 'N° de Contrat',
    contractDates: 'Dates du contrat',
    paymentDates: 'Date du Paiement',
    paymentLimit: 'Échéance',
    invoiceNumber: 'N° de Facture',
    status: 'État',
    sign: 'Signer en ligne',
    date: 'Date',
    sector: 'Secteur',
    hours: 'Horaires',
    salary: 'Montant',
    service: 'Service',
    confirm: 'Confirmer',
    refuse: 'Refuser',
    report: 'Signaler',
    cancelRequest: "Demander l'annulation",
    cancelledBy: 'Annulé par',
  },
  cancelledBy: {
    recruiter: 'Etablissement',
    candidate: 'Professionnel',
  },
  generatePassword: {
    title: 'Création du Mot de Passe',
    password: 'Nouveau mot de passe',
    passwordConfirm: 'Confirmation mot de passe',
    submitButton: 'Confimer',
    rulesLabel: 'Le mot de passe doit respecter les conditions suivantes :',
    rules: {
      length: 'Faire au moins 8 caractères',
      uppercase: 'Une majuscule',
      lowercase: 'Une minucsule',
      numberOrSpecialChar: 'Un chiffre ou un caractère spécial',
      sameValue: 'Les champs ne correspondent pas',
    },
  },
  invoices: {
    paymentStatusDone: 'Paiement effectué',
    paymentStatusPending: 'En attente de paiement',
  },
  contractsPage: {
    title: 'Contrats',
    hint: 'Retrouvez ici l’ensemble des contrats de vos établissements.',
    tabToSign: 'À signer',
    tabSigned: 'Autres',
    exportToSignTitle: 'Contrats-à-signer',
    exportSignedTitle: 'Contrats-autres',
  },
  contracts: {
    signed: 'Contrats',
    signedHint: 'Contrats signés, annulés, ou révoqués',
    toSign: 'Contrats',
    toSignHint: 'Contrats en attente de signature sur vos établissements',
    canSign: 'est le signataire',
  },
  invoicesPage: {
    title: 'Factures',
    hint: 'Retrouvez ici l’ensemble des notes d’honoraires liées à vos établissements.',
    tabToPay: 'À payer',
    tabPayed: 'Payées',
    exportPaidTitle: 'Factures-payées',
    exportToPayTitle: 'Factures-à-payer',
    zipLoading: {
      title: 'Chargement de vos factures',
      subTitle: 'Votre téléchargement débutera dans quelques instants.',
    },
  },
  feesInvoicesPage: {
    title: 'Factures Medelse',
    hint: 'Sélectionnez un établissement pour retrouver l\'ensemble des Factures de frais Medelse liées à cet établissement.',
  },
  parametersPage: {
    title: 'Paramètres',
    description: 'Retrouvez ici vos informations et identifiants de compte.',
    titlePersonalInfo: 'Informations Personnelles',
    titleCredentials: 'Identifiants',
    labelGender: 'Civilité',
    labelLastname: 'Nom',
    labelFirstname: 'Prénom',
    labelPhone: 'Téléphone',
    labelEmail: 'Email',
    labelPassword: 'Mot de passe',
    deleteAccount: 'Demander la suppression du compte',
  },
  parametersEmailForm: {
    title: 'Modification Adresse email',
    currentEmail: 'Adresse email actuelle',
    email: 'Nouvelle Adresse Email',
    emailConfirm: 'Confirmer la nouvelle adresse email',
    successMessage: 'Modification enregistrée',
  },
  parametersPasswordForm: {
    title: 'Modification Mot de Passe',
    oldPassword: 'Mot de passe actuel',
    newPassword: 'Nouveau Mot de passe',
    passwordConfirmation: 'Confirmer le nouveau mot de passe',
    successMessage: 'Modification enregistrée',
  },
  parametersProfileForm: {
    title: 'Modification Informations Personnelles',
    gender: 'Civilité',
    lastname: 'Nom',
    firstname: 'Prénom',
    phone: 'Téléphone',
    successMessage: 'Modification enregistrée',
  },
  parametersDeleteAccountForm: {
    title: 'Suppression de compte',
    description: 'En cliquant sur confirmer, vous confirmez votre demande de suppression du compte ainsi que ses accès.',
    legalNotice: 'Dans un souci d\'amélioration de notre produit, nos conserverons les données liées à votre utilisation de nos services.',
  },
  healthProfessionalDetails: {
    title: 'Professionnel de santé :',
    email: 'Email :',
    specialty: 'Spécialité(s) :',
    phoneNumber: 'Tél. :',
  },
  contactPage: {
    title: 'Contacts',
    hint: 'Retrouvez ici vos contacts importants chez Medelse.',
  },
  contact: {
    email: 'Email',
    phone: 'Téléphone',
    calendarLink: 'Prise de rendez-vous',
    administrator: {
      title: 'Votre conseiller Medelse',
      links: 'Coordonnées',
      description: 'Des besoins spécifiques ? Des questions sur notre partenariat ? Ecrivez ou prenez rendez-vous avec votre chargé de compte.',
    },
    planning: {
      title: 'Planning et Contrats',
      description: 'Une question sur un contrat ou besoin d’aide sur la gestion de votre planning ?',
      phone: '07.57.95.74.74',
      phone_demo: '0101010101',
      email: 'planning@medelse.com',
      email_demo: 'exemple1@medelse.com',
    },
    invoicing: {
      title: 'Facturation',
      description: 'Une question liée à la facturation ?',
      email: 'facturation@medelse.com',
      email_demo: 'exemple2@medelse.com',
    },
    support: {
      title: 'Support',
      description: 'Un souci sur la plateforme ou un incident technique ? Disponible de 8h à 20h (8h-18h le Week-End).',
      phone: '07.57.95.74.74',
      phone_demo: '0202020202',
    },
    product: {
      title: 'Produit',
      description: 'Un retour sur la plateforme ou une recommandation d’évolution à faire ?',
      email: 'product@medelse.com',
      email_demo: 'exemple3@medelse.com',
    },
  },
  upflowSummary: {
    placeholder: 'Veuillez sélectionner un établissement …',
    openSummaryInNewTab: 'Voir la liste des factures dans Upflow',
    errorBlocMessage: 'Vous ne pouvez pas encore accéder à vos factures car cet établissement ne dispose pas de compte chez notre partenaire Upflow, merci de contacter facturation@medelse.com pour obtenir votre accès.',
  },
  error: {
    wrongCredentials: 'Mauvaise combinaison Identifiant / Mot de passe',
  },
};
