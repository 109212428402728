import React from 'react';
import { useTranslation } from 'react-i18next';

import { useDeleteRecruiterAccountMutation } from '../../../app/generated/graphql';
import CardTitle from '../../../app/components/CardTitle';
import FormActions from '../../../app/components/FormActions';
import QueryIndicator from '../../../app/components/QueryIndicator';
import AlertMessage from '../../../app/components/AlertMessage';

type Props = {
  onCancel: VoidFunction
  onMutationSuccess: VoidFunction
};

const ParametersDeleteAccountForm = ({ onCancel, onMutationSuccess }: Props) => {
  const { t } = useTranslation();
  const [deleteRecruiterAccount, { loading, error }] = useDeleteRecruiterAccountMutation({
    onCompleted() { onMutationSuccess(); },
    onError() {},
  });

  return (
    <div>
      <CardTitle>{t('parametersDeleteAccountForm.title')}</CardTitle>
      {loading ? (
        <QueryIndicator />
      ) : (
        <div>
          {error && <AlertMessage message={error.message} />}
          <div className="mb-5">
            {t('parametersDeleteAccountForm.description')}
          </div>
          <div className="fst-italic">
            {t('parametersDeleteAccountForm.legalNotice')}
          </div>
          <FormActions
            onCancel={onCancel}
            onConfirm={deleteRecruiterAccount}
            variant="negative"
            eventNamePrefix="m.settings.account.deletes"
          />
        </div>
      )}
    </div>
  );
};

export default ParametersDeleteAccountForm;
