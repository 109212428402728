import React from 'react';
import { BrowserRouter, Routes as RouterElements, Route } from 'react-router-dom';

import RequireAnonymous from '../auth/RequireAnonymous';
import RequireAuth from '../auth/RequireAuth';
import NotFound from './NotFound';
import Login from '../auth/LoginRoute';
import RecoverPasswordRoute from '../auth/RecoverPassewordRoute';
import GeneratePasswordRoute from '../features/generate-password/GeneratePasswordRoute';
import DashboardPage from './pages/DashboardPage';
import MissionsPage from './pages/MissionsPage';
import ContactPage from './pages/ContactPage';
import ContractsPage from './pages/ContractsPage';
import InvoicesPage from './pages/InvoicesPage';
import FeesInvoicesPage from './pages/FeesInvoicesPage';
import SettingsPage from './pages/SettingsPage';
import AutoLogFromRefreshTokenRoute from '../auth/AutoLogFromRefreshRoute';
import CDDMetabasePage from './pages/CDDMetabasePage';
import Page from './Page';

export const Routes = () => (
  <RouterElements>
    <Route
      path="/"
      element={(
        <Page title="Dashboard">
          <RequireAuth>
            <DashboardPage />
          </RequireAuth>
        </Page>
      )}
    />
    <Route
      path="/missions"
      element={(
        <Page title="Missions">
          <RequireAuth>
            <MissionsPage />
          </RequireAuth>
        </Page>
      )}
    />
    <Route
      path="/contracts"
      element={(
        <Page title="Contrats">
          <RequireAuth>
            <ContractsPage />
          </RequireAuth>
        </Page>
      )}
    />
    <Route
      path="/invoices"
      element={(
        <Page title="Factures">
          <RequireAuth>
            <InvoicesPage />
          </RequireAuth>
        </Page>
      )}
    />
    <Route
      path="/fees-invoices"
      element={(
        <Page title="Factures Medelse">
          <RequireAuth>
            <FeesInvoicesPage />
          </RequireAuth>
        </Page>
      )}
    />
    <Route
      path="/settings"
      element={(
        <Page title="Paramètres">
          <RequireAuth>
            <SettingsPage />
          </RequireAuth>
        </Page>
      )}
    />
    <Route
      path="/contact"
      element={(
        <Page title="Contact">
          <RequireAuth>
            <ContactPage />
          </RequireAuth>
        </Page>
      )}
    />
    <Route
      path="/login"
      element={(
        <Page title="Connexion">
          <RequireAnonymous>
            <Login />
          </RequireAnonymous>
        </Page>
      )}
    />
    <Route
      path="/login/recover"
      element={(
        <Page title="Mot de passe oublié">
          <RequireAnonymous>
            <RecoverPasswordRoute />
          </RequireAnonymous>
        </Page>
      )}
    />
    <Route
      path="/login/generate-password"
      element={(
        <Page title="Génération de mot de passe">
          <RequireAnonymous>
            <GeneratePasswordRoute />
          </RequireAnonymous>
        </Page>
      )}
    />
    <Route
      path="/arsarot/:refreshToken"
      element={(
        <RequireAnonymous>
          <AutoLogFromRefreshTokenRoute />
        </RequireAnonymous>
      )}
    />
    <Route
      path="/cdd/:groupOfficeUuid"
      element={<CDDMetabasePage />}
    />
    <Route path="*" element={<NotFound />} />
  </RouterElements>
);

const Router = () => (
  <BrowserRouter>
    <Routes />
  </BrowserRouter>
);

export default Router;
