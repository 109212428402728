import {
  type IconDefinition,
  faHouse,
  faCalendarDays,
  faFileSignature,
  faFileInvoiceDollar,
  faGears,
} from '@fortawesome/free-solid-svg-icons';

export type Menu = {
  label: string
  to: string
  icon: IconDefinition
  eventName: string
}[];

export const mainNavOnce: Menu = [
  {
    label: 'nav.dashboard',
    to: '/',
    icon: faHouse,
    eventName: 'm.navigation.menu.dashboard.click',
  },
  {
    label: 'nav.missions',
    to: '/missions',
    icon: faCalendarDays,
    eventName: 'm.navigation.menu.mission.click',
  },
  {
    label: 'nav.contracts',
    to: '/contracts',
    icon: faFileSignature,
    eventName: 'm.navigation.menu.contract.click',
  },
  {
    label: 'nav.invoices',
    to: '/invoices',
    icon: faFileInvoiceDollar,
    eventName: 'm.navigation.menu.invoice.click',
  },
  {
    label: 'nav.feesInvoices',
    to: '/fees-invoices',
    icon: faFileInvoiceDollar,
    eventName: 'm.navigation.menu.fees-invoice.click',
  },
];

export const mainNavTwo: Menu = [
  {
    label: 'nav.settings',
    to: '/settings',
    icon: faGears,
    eventName: 'm.navigation.menu.settings.click',
  },
];
