import React from 'react';
import { useTranslation } from 'react-i18next';
import AlertMessage from '../../../app/components/AlertMessage';

import CardTitle from '../../../app/components/CardTitle';
import FormActions from '../../../app/components/FormActions';
import QueryIndicator from '../../../app/components/QueryIndicator';
import TextField from '../../../app/components/TextField';
import utils from '../../../app/domain/utils';
import { useConfirmMissionMutation } from '../../../app/generated/graphql';
import { MissionData } from '../MissionsTypes';

type Props = {
  onCancel: VoidFunction
  onMutationSuccess: VoidFunction
  mission: MissionData
};

const ConfirmMissionForm = ({ mission, onMutationSuccess, onCancel }: Props) => {
  const { t } = useTranslation();
  const { profile } = mission.candidate;
  const gender = t(`gender.${profile.gender}`);
  let specificServicesValue: string = '';
  if (mission.specificServices.length) {
    specificServicesValue = `(${mission.specificServices.map((i) => i?.name).join(', ')})`;
  }
  const defaultMessage = t('confirmMissionForm.content', {
    date: utils.formatDateTime(mission.dates.startDate, 'dd/MM'),
    officeName: mission.groupOffice.name,
    candidateName: `${gender} ${profile.lastName}`,
    service: t(`database_fields.${mission.service.name}`),
    specificServices: specificServicesValue,
  });
  const [message, setMessage] = React.useState<string>(defaultMessage);
  const [confirmMission, { loading, error }] = useConfirmMissionMutation();

  const handleOnSubmit = () => {
    confirmMission({
      variables: { input: { missionUuid: mission.uuid, content: message } },
      onCompleted() { onMutationSuccess(); },
      onError() {},
    });
  };

  const title = `${t('confirmMissionForm.title')} ${utils.formatDateTime(mission.dates.startDate, 'dd/MM/yyyy')} - ${gender} ${profile.firstName} ${profile.lastName}`;

  return (
    <div>
      <CardTitle>{title}</CardTitle>
      <AlertMessage variant="success" message={t('confirmMissionForm.alert')} />
      {loading ? (
        <QueryIndicator />
      ) : (
        <>
          <form>
            {error && <AlertMessage message={error.message} />}
            <TextField
              id="confirm-mission-input"
              label={t('general.message')}
              onChange={setMessage}
              value={message}
              multiline
            />
          </form>
          <FormActions
            onCancel={onCancel}
            onConfirm={handleOnSubmit}
            eventNamePrefix="m.mission.button.confirm"
          />
        </>
      )}
    </div>
  );
};

export default ConfirmMissionForm;
