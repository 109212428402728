/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faQuestion,
  faInfo,
  faPlus,
  type IconDefinition,
} from '@fortawesome/free-solid-svg-icons';

import * as tokens from '../../DesignSystem/tokens';
import { TOOLTIP_ICON_FONT_SIZE, TOOLTIP_ICON_SIZE } from '../../constants';

const iconContainerStyles: React.CSSProperties = {
  width: TOOLTIP_ICON_SIZE,
  height: TOOLTIP_ICON_SIZE,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: 0,
  borderRadius: TOOLTIP_ICON_SIZE / 2,
  color: tokens.colorWhite,
};

export type TooltipVariant = 'info' | 'help' | 'more';

export type TooltipType = 'tooltip' | 'popover';

type Props = {
  children: React.ReactNode,
  id: string
  variant?: TooltipVariant | undefined,
  type?: TooltipType | undefined,
};

const TooltipIcon = ({
  children, id, variant, type,
}: Props) => {
  let icon: IconDefinition = faInfo;
  let iconColor: string = tokens.colorWhite;
  let iconBgColor: string = tokens.colorBlueNight;

  if (type === 'popover') {
    iconColor = tokens.colorBlueNight;
    iconBgColor = 'transparent';
  }

  // default is 'info'
  switch (variant) {
    case 'help':
      icon = faQuestion;
      iconBgColor = tokens.colorSilverDark;
      break;
    case 'more':
      icon = faPlus;
      iconColor = tokens.colorWhite;
      iconBgColor = tokens.colorBlueNight;
      break;

    default:
      break;
  }

  const renderTooltip = (props: any) => (
    <Tooltip id={`tooltip-${id}`} {...props}>
      {children}
    </Tooltip>
  );

  const renderPopover = (
    <Popover id="popover-basic" style={{ maxWidth: '100%' }}>
      <Popover.Body>
        {children}
      </Popover.Body>
    </Popover>
  );

  const Button = (
    <button
      aria-labelledby="tooltip"
      style={{
        ...iconContainerStyles,
        color: iconColor,
        backgroundColor: iconBgColor,
      }}
      type="button"
    >
      <FontAwesomeIcon icon={icon} role="img" fontSize={TOOLTIP_ICON_FONT_SIZE} />
    </button>
  );

  return (
    <OverlayTrigger
      rootClose
      placement="right"
      trigger={type === 'popover' ? 'click' : undefined}
      delay={{ show: 200, hide: 200 }}
      overlay={type === 'popover' ? renderPopover : renderTooltip}
    >
      {Button}
    </OverlayTrigger>
  );
};

TooltipIcon.defaultProps = {
  variant: undefined,
  type: undefined,
};

export default TooltipIcon;
