import React from 'react';
import { useTranslation } from 'react-i18next';

import { UserProfile } from '../../generated/graphql';

export type CellUserType = Pick<UserProfile, 'gender' | 'firstName' | 'lastName'>;

type Props = { profile: CellUserType | undefined };

const TableCellUser = ({ profile }: Props) => {
  const { t } = useTranslation();

  if (!profile) {
    return <span>-</span>;
  }

  const { gender, lastName, firstName } = profile;

  return <span>{`${t(`gender.${gender}`)} ${lastName} ${firstName}`}</span>;
};

export default TableCellUser;
