import axios from 'axios';
import { authAPIRoutes } from '../../constants';
import { NavigationLogInput } from '../../domain/types';

const logNavigation = async (pathname: string, token: string) => new Promise<void>((resolve) => {
  const input: NavigationLogInput = {
    name: pathname,
    client: { userAgent: window.navigator.userAgent },
    origin: 'RECRUITER',
  };

  axios.post(
    process.env.REACT_APP_API_URL + authAPIRoutes.logNavigation,
    input,
    { headers: { Authorization: `Bearer ${token}` } },
  )
    .then(() => {})
    .catch(() => {})
    .finally(() => { resolve(); });
});

export default {
  logNavigation,
};
