import React from 'react';
import { useTranslation } from 'react-i18next';
import CardTitle from '../../../app/components/CardTitle';
import AlertMessage from '../../../app/components/AlertMessage';
import QueryIndicator from '../../../app/components/QueryIndicator';
import TextField from '../../../app/components/TextField';
import FormActions from '../../../app/components/FormActions';
import { GroupOffice, UserProfile, useCancelMissionRequestMutation } from '../../../app/generated/graphql';
import utils from '../../../app/domain/utils';

type Props = {
  missionUuid: string
  missionDate: string
  missionCandidate: Partial<UserProfile>
  missionGroupOffice: Partial<GroupOffice>
  onCancel: VoidFunction
  onMutationSuccess: VoidFunction
};

const RequestMissionCancellationForm = ({
  missionUuid, missionDate, missionCandidate, missionGroupOffice, onMutationSuccess, onCancel,
}: Props) => {
  const { t } = useTranslation();
  const candidateGender = t(`gender.${missionCandidate.gender}`);
  const candidate = `${candidateGender} ${missionCandidate.lastName} ${missionCandidate.firstName}`;
  const date = utils.formatDate(missionDate);
  const officeName = missionGroupOffice.name;

  const [content, setContent] = React.useState<string>(t('cancelRequest.content', {
    officeName, candidate, date,
  }));

  const [cancelRequest, { loading, error }] = useCancelMissionRequestMutation();

  const handleOnSubmit = () => {
    cancelRequest({
      variables: { input: { content, missionUuid } },
      onCompleted() { onMutationSuccess(); },
      onError() {},
    });
  };

  return (
    <div>
      <CardTitle>{t('cancelRequest.title', { date, candidate })}</CardTitle>
      <AlertMessage variant="danger" message={t('cancelRequest.description')} />
      {loading ? (
        <QueryIndicator />
      ) : (
        <>
          <form>
            {error && <AlertMessage message={error.message} />}
            <TextField
              id="cancel-request-input"
              label={t('general.message')}
              onChange={setContent}
              value={content}
              multiline
            />
          </form>
          <FormActions
            onCancel={onCancel}
            onConfirm={handleOnSubmit}
            eventNamePrefix="m.mission.button.cancel"
            variant="warning"
          />
        </>
      )}
    </div>
  );
};

export default RequestMissionCancellationForm;
