import React from 'react';

import { TooltipType, TooltipVariant } from '../TooltipIcon/TooltipIcon';
import TooltipIcon from '../TooltipIcon';

type TextWithTooltipProps = {
  text: string,
  className?: string | undefined
  tooltipContent: React.ReactNode
  tooltipId: string
  tooltipVariant?: TooltipVariant | undefined
  tooltipType?: TooltipType | undefined
};

const TextWithTooltip = ({
  text,
  className,
  tooltipContent,
  tooltipId,
  tooltipVariant,
  tooltipType,
}: TextWithTooltipProps) => (
  <div className="d-flex flex-row align-items-center">
    <span className={`me-1 ${className || ''}`}>{text}</span>
    <TooltipIcon
      id={tooltipId}
      variant={tooltipVariant || 'info'}
      type={tooltipType || 'tooltip'}
    >
      {tooltipContent}
    </TooltipIcon>
  </div>
);

TextWithTooltip.defaultProps = {
  className: undefined,
  tooltipVariant: undefined,
  tooltipType: undefined,
};

export default TextWithTooltip;
