import React from 'react';

import RequestMissionCancellationForm from './RequestMissionCancellationForm';
import Modal from '../../../app/components/Modal';
import { GroupOffice, UserProfile } from '../../../app/generated/graphql';

type Props = {
  missionDate: string
  missionUuid: string
  missionCandidate: Partial<UserProfile>
  missionGroupOffice: Partial<GroupOffice>
  onClose: () => void
};

const RequestMissionCancellation = ({
  missionUuid, missionDate, missionCandidate, missionGroupOffice, onClose,
}: Props) => (
  <Modal
    visible
    onClose={onClose}
  >
    <RequestMissionCancellationForm
      missionUuid={missionUuid}
      missionDate={missionDate}
      missionCandidate={missionCandidate}
      missionGroupOffice={missionGroupOffice}
      onCancel={onClose}
      onMutationSuccess={onClose}
    />
  </Modal>
);

export default RequestMissionCancellation;
