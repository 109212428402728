/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnDef, ColumnSort } from '@tanstack/react-table';

import {
  DateRangeInput,
  Mission,
  useMissionsToConfirmExportLazyQuery,
  useMissionsToConfirmQuery,
  ValidationStatusEnum,
} from '../../../app/generated/graphql';
import AlertMessage from '../../../app/components/AlertMessage';
import { ARRAY_ITEMS_PER_PAGE } from '../../../app/constants';
import QueryIndicator from '../../../app/components/QueryIndicator';
import Table from '../../../app/components/Table';
import utils from '../../../app/domain/utils';
import TableCellService from '../../../app/components/TableCellService';
import Button from '../../../app/components/Button';
import TableCellIcon from '../../../app/components/TableCellIcon';
import TableCellUser from '../../../app/components/TableCellUser';
import TableCellSpecialty from '../../../app/components/TableCellSpecialty';
import Modal from '../../../app/components/Modal';
import ConfirmMissionForm from '../ConfirmMissionForm';
import RefuseMissionForm from '../RefuseMissionForm';
import { MissionData } from '../MissionsTypes';
import { useExportOptions } from '../../../app/hooks/useExportOptions';
import useAuth from '../../../auth/hooks/useAuth';

type Props = {
  groupOfficesUuid: Array<string>
  dates: DateRangeInput | null
  eventNamePrefix: string
};
const MissionsToConfirmTable = ({ groupOfficesUuid, dates, eventNamePrefix }: Props) => {
  const { getAccessLevel } = useAuth();
  const { data, error, loading } = useMissionsToConfirmQuery(
    { variables: { groupOfficesUuid, dates } },
  );
  const { t } = useTranslation();

  const [missionToConfirm, setMissionToConfirm] = React.useState<MissionData | null>(null);
  const [missionToRefuse, setMissionToRefuse] = React.useState<MissionData | null>(null);

  const CONFIRM = t('column.confirm');
  const REFUSE = t('column.refuse');

  const { csvOptions, xlsxOptions } = useExportOptions({
    query: useMissionsToConfirmExportLazyQuery,
    dates,
    groupOfficesUuid,
    name: t('missionsPage.exportToConfirmTitle'),
    eventName: eventNamePrefix,
    role: getAccessLevel(),
  });

  if (loading) {
    return <QueryIndicator />;
  }

  if (error) {
    return <AlertMessage message={error.message} />;
  }

  const columns: ColumnDef<Mission>[] = [
    {
      id: 'date',
      header: t('column.date'),
      accessorFn: (row) => row.dates.startDate,
      cell: ({ row }) => utils.formatDate(row.original.dates.startDate),
    },
    {
      id: 'candidate_name',
      header: t('column.candidate'),
      accessorFn: (row) => `${row.candidate.profile.lastName} ${row.candidate.profile.firstName}`,
      cell: ({ row }) => <TableCellUser profile={row.original.candidate.profile} />,
    },
    {
      id: 'specialty',
      header: t('column.specialties'),
      accessorKey: 'specialty',
      cell: ({ row }) => {
        const { name } = row.original.specialty;

        return <TableCellSpecialty name={name} uuid={row.original.uuid} />;
      },
    },
    {
      id: 'service',
      header: t('column.service'),
      accessorFn: (row) => row.service.name,
      cell: ({ row }) => {
        const { specificServices, service } = row.original;

        return <TableCellService service={service} specificServices={specificServices} />;
      },
    },
    {
      id: 'sector',
      header: t('column.sector'),
      accessorKey: 'sector',
    },
    {
      id: 'amount',
      enableGlobalFilter: false,
      header: t('column.salary'),
      accessorFn: (row) => `${utils.formatFloat(row.salary!)} €`,
    },
    {
      id: 'hours',
      header: t('column.hours'),
      cell: ({ row }) => {
        const missionDates = row.original.dates;

        const getHours = (dateTime: string) => utils.formatDateTime(dateTime, 'HH:mm');
        return `${getHours(missionDates.startDate)} - ${getHours(missionDates.endDate)}`;
      },
    },
    {
      id: 'confirm',
      header: t('column.confirm'),
      cell: ({ row }) => {
        const { validationDate, validationStatus } = row.original.recruiterValidation!;

        if (validationStatus) {
          return validationStatus === ValidationStatusEnum.Valid ? (
            <TableCellIcon
              variant="success"
              placement="top"
              text={t('missions.confirmedAt', { date: utils.formatDateTime(validationDate, 'dd/MM/yyyy') })}
            />
          ) : null;
        }

        return (
          <Button
            trackingEvent="m.mission.button.confirm.click"
            variant="positive"
            onClick={() => setMissionToConfirm(row.original)}
            title={CONFIRM}
          />
        );
      },
    },
    {
      id: 'refuse',
      header: t('column.refuse'),
      cell: ({ row }) => {
        const { validationDate, validationStatus } = row.original.recruiterValidation!;

        if (validationStatus) {
          return validationStatus === ValidationStatusEnum.Invalid ? (
            <TableCellIcon
              variant="danger"
              placement="top"
              text={t('missions.refusedAt', { date: utils.formatDateTime(validationDate, 'dd/MM/yyyy') })}
            />
          ) : null;
        }

        return (
          <Button
            trackingEvent="m.mission.button.refuse.click"
            variant="negative"
            onClick={() => setMissionToRefuse(row.original)}
            title={REFUSE}
          />
        );
      },
    },
  ];

  const missions = data?.me?.missions?.edges?.map((item) => item?.node);

  const defaultColumnSort: ColumnSort = { id: 'date', desc: false };

  const closeConfirmMissionForm = () => setMissionToConfirm(null);
  const closeRefuseForm = () => setMissionToRefuse(null);

  return (
    <>
      <Table
        eventNamePrefix={eventNamePrefix}
        pageSize={ARRAY_ITEMS_PER_PAGE}
        columns={utils.filterTableColumnsByRole(columns, getAccessLevel())}
        data={(missions as Array<Mission>)}
        defaultColumnSort={defaultColumnSort}
        canBeFiltered
        title={t('missions.missionsToConfirm')}
        hint={t('missions.missionsToConfirmHint')}
        exportOptions={[csvOptions, xlsxOptions]}
      />
      <Modal
        visible={missionToConfirm !== null}
        onClose={closeConfirmMissionForm}
      >
        {missionToConfirm && (
          <ConfirmMissionForm
            mission={missionToConfirm}
            onCancel={closeConfirmMissionForm}
            onMutationSuccess={closeConfirmMissionForm}
          />
        )}
      </Modal>
      <Modal
        visible={missionToRefuse !== null}
        onClose={closeRefuseForm}
      >
        {missionToRefuse && (
          <RefuseMissionForm
            mission={missionToRefuse}
            onCancel={closeRefuseForm}
            onMutationSuccess={closeRefuseForm}
          />
        )}
      </Modal>
    </>
  );
};

export default MissionsToConfirmTable;
