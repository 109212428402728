import React from 'react';
import { useTranslation } from 'react-i18next';

import { useGetAdministratorQuery } from '../../../app/generated/graphql';
import Card from '../../../app/components/Card';
import QueryIndicator from '../../../app/components/QueryIndicator';
import Avatar from '../../../app/components/Avatar';
import ContactMethods from '../../../app/components/ContactMethods';
import AlertMessage from '../../../app/components/AlertMessage';

const JOB_TITLE_PREFIX = 'enum.medelse_core_bundle_entity_enum_administrator_job_title_';

const Administrator = () => {
  const { t } = useTranslation();
  const { loading, data, error } = useGetAdministratorQuery();

  const admin = data?.me?.administrator;

  return (
    <Card>
      <div className="row">
        <div className="col-xl-6">
          <h2 className="h5 fw-bold border-bottom border-primary pb-3 mb-4 w-75">{t('contact.administrator.title')}</h2>
          {admin ? (
            <>
              <div className="row">
                <div className="col d-flex flex-column text-center justify-content-center">
                  <div className="h5 fw-bold">{`${admin.firstName} ${admin.lastName}`}</div>
                  {admin.jobTitle ? (
                    <div className="fw-ligter fst-italic">{t(JOB_TITLE_PREFIX + admin.jobTitle.toLowerCase())}</div>
                  ) : null}
                </div>
                {admin.imageFile && admin.imageFile.square.square273 ? (
                  <div className="col">
                    <Avatar source={admin.imageFile.square.square273} />
                  </div>
                ) : null}
              </div>
              <div className="pt-2">
                {t('contact.administrator.description')}
              </div>
            </>
          ) : null}
        </div>
        {admin ? (
          <div className="col-xl-6">
            <h2 className="h5 fw-bold border-bottom border-primary pt-4 pt-xl-0 pb-3 mb-4 w-75">{t('contact.administrator.links')}</h2>
            <div className="pt-4">
              <ContactMethods
                calendarLink={admin.agendaLink || undefined}
                email={admin.email || undefined}
                phone={admin.phoneNumber || undefined}
              />
            </div>
          </div>
        ) : null}
        {error ? <AlertMessage message={error.message} /> : null}
        {loading ? <QueryIndicator /> : null}
      </div>
    </Card>
  );
};

export default Administrator;
