import React from 'react';
import { useTranslation } from 'react-i18next';

import { useActionBlockQuery } from '../../../app/generated/graphql';
import AlertMessage from '../../../app/components/AlertMessage';
import Card from '../../../app/components/Card';
import QueryIndicator from '../../../app/components/QueryIndicator';
import Button from '../../../app/components/Button';

import img1 from '../../../assets/action_block_bg1.jpg';
import img2 from '../../../assets/action_block_bg2.jpg';
import img3 from '../../../assets/action_block_bg3.jpg';

type Props = {
  groupOfficesUuid: Array<string>
  onContractButtonClicked: VoidFunction
  onMissionButtonClicked: VoidFunction
  showConfirmation: boolean
};

const ActionBlock = ({
  groupOfficesUuid,
  onContractButtonClicked,
  onMissionButtonClicked,
  showConfirmation,
}: Props) => {
  const { t } = useTranslation();
  const { data, loading, error } = useActionBlockQuery({ variables: { groupOfficesUuid } });

  const render = (children: React.ReactNode) => (
    <Card>{children}</Card>
  );

  if (loading) {
    return render(<QueryIndicator />);
  }

  if (error) {
    return render(<AlertMessage message={error.message} />);
  }

  const contractToSignCount: number = data?.me?.contracts?.totalCount || 0;
  const missionsToConfirmCount: number = data?.me?.missions?.totalCount || 0;
  let sumNumber = contractToSignCount;

  if (showConfirmation) {
    sumNumber += missionsToConfirmCount;
  }

  return render(
    <>
      <div className="action-block-animation">
        <img src={img1} alt="" />
        <img src={img2} alt="" />
        <img src={img3} alt="" />
      </div>
      <div
        className="d-flex flew-row align-items-center position-relative"
        style={{ zIndex: 2 }}
      >
        <h1 className="text-white flex-fill align-items-center">
          <p>{sumNumber}</p>
          {t('dashboard.totalActionToDo')}
        </h1>
        <div className="d-flex flex-column gap-4">
          <Button
            trackingEvent="m.dash.header.contract_to_sign.click"
            onClick={onContractButtonClicked}
            title={`${contractToSignCount} ${t('dashboard.contractsToSign')}`}
          />
          {showConfirmation ? (
            <Button
              trackingEvent="m.dash.header.dates_to_confirm.click"
              onClick={onMissionButtonClicked}
              title={`${missionsToConfirmCount} ${t('dashboard.datesToConfirm')}`}
            />
          ) : null}
        </div>
      </div>
    </>,
  );
};

export default ActionBlock;
