import React from 'react';
import { useTranslation } from 'react-i18next';
import Avatar from '../../app/components/Avatar';
import Card from '../../app/components/Card';
import ContactMethods from '../../app/components/ContactMethods';

import PageHeader from '../../app/components/PageHeader';
import Administrator from '../../features/Contact/Administrator';
import avatarPlanning from '../../assets/avatar_planning.png';
import avatarInvoicing from '../../assets/avatar_facturation.png';
import avatarSupport from '../../assets/avatar_support.png';
import avatarProduct from '../../assets/avatar_product.png';

const ContactPage = () => {
  const { t } = useTranslation();

  let SUFFIX: string = '';
  if (process.env.REACT_APP_IS_DEMO === 'true') {
    SUFFIX = '_demo';
  }

  return (
    <div>
      <PageHeader
        title={t('contactPage.title')}
        description={t('contactPage.hint')}
      />
      <div className="px-0 px-lg-5">
        <Administrator />
        <div className="grid mt-4">
          <div className="g-col-12 g-col-xl-6">
            <Card h100>
              <h2 className="h5 fw-bold text-center border-bottom border-primary pb-3 mb-4">
                {t('contact.planning.title')}
              </h2>
              <div className="text-center">
                <Avatar source={avatarPlanning} />
              </div>
              <div className="fst-italic text-center p-3 min-h-2">{t('contact.planning.description')}</div>
              <ContactMethods
                email={t(`contact.planning.email${SUFFIX}`)}
                phone={t(`contact.planning.phone${SUFFIX}`)}
              />
            </Card>
          </div>
          <div className="g-col-12 g-col-xl-6">
            <Card h100>
              <h2 className="h5 fw-bold text-center border-bottom border-primary pb-3 mb-4">
                {t('contact.invoicing.title')}
              </h2>
              <div className="text-center">
                <Avatar source={avatarInvoicing} />
              </div>
              <div className="fst-italic text-center p-3 min-h-2">{t('contact.invoicing.description')}</div>
              <ContactMethods
                email={t(`contact.invoicing.email${SUFFIX}`)}
              />
            </Card>
          </div>
          <div className="g-col-12 g-col-xl-6">
            <Card h100>
              <h2 className="h5 fw-bold text-center border-bottom border-primary pb-3 mb-4">
                {t('contact.support.title')}
              </h2>
              <div className="text-center">
                <Avatar source={avatarSupport} />
              </div>
              <div className="fst-italic text-center p-3 min-h-2">{t('contact.support.description')}</div>
              <ContactMethods
                phone={t(`contact.support.phone${SUFFIX}`)}
              />
            </Card>
          </div>
          <div className="g-col-12 g-col-xl-6">
            <Card h100>
              <h2 className="h5 fw-bold text-center border-bottom border-primary pb-3 mb-4">
                {t('contact.product.title')}
              </h2>
              <div className="text-center">
                <Avatar source={avatarProduct} />
              </div>
              <div className="fst-italic text-center p-3 min-h-2">{t('contact.product.description')}</div>
              <ContactMethods
                email={t(`contact.product.email${SUFFIX}`)}
              />
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
