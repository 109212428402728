import React from 'react';
import { ApolloConsumer } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import LayoutSplit from '../app/components/LayoutSplit/LayoutSplit';
import RecoverPassword from './RecoverPassword';
import useTracking from '../app/hooks/useTracking';

const RecoverPasswordRoute = () => {
  const { t } = useTranslation();
  const { logEvent } = useTracking();
  const navigate = useNavigate();

  return (
    <ApolloConsumer>
      {(client) => {
        client.clearStore();

        return (
          <LayoutSplit coverImage="https://blog.medelse.com/wp-content/uploads/2021/05/infirmier-liberal-demarches.jpg">
            <>
              <div className="h2">{t('auth.recoverPassword.headline')}</div>
              <div>{t('auth.recoverPassword.instructions')}</div>
              <RecoverPassword
                onCancel={() => {
                  logEvent('m.password.button.cancel.click');
                  navigate('/');
                }}
              />
            </>
          </LayoutSplit>
        );
      }}
    </ApolloConsumer>
  );
};

export default RecoverPasswordRoute;
