import React from 'react';

import logo from '../../../assets/logo.svg';

const coverImageBaseStyles: React.CSSProperties = {
  backgroundSize: 'cover',
  height: '100%',
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
};

type Props = {
  belowCard?: React.ReactChild,
  coverImage: string,
  children: React.ReactChild,
  withLogo?: boolean,
  title?: string
};

const LayoutSplit = ({
  belowCard,
  coverImage,
  children,
  withLogo,
  title,
}: Props) => {
  const coverImageStyles = {
    ...coverImageBaseStyles,
    backgroundImage: `url(${coverImage})`,
  };

  return (
    <div className="container-fluid">
      <div className="min-vh-100 row">
        <div className="d-none d-lg-block position-relative col-6">
          <div className="bg-holder" style={coverImageStyles} />
        </div>
        <div className="align-self-center mx-auto py-5 col-md-6 col-sm-10">
          <div className="justify-content-center row">
            <div className="col-xxl-8 col-xl-8 col-lg-9">
              <div className="title-container">
                {title && (
                <h1 className="text-dark text-uppercase mb-5"><strong>{title}</strong></h1>
                )}
              </div>
              <div className="card overflow-hidden">
                {withLogo && (
                  <div className="text-center p-3 bg-primary">
                    <img src={logo} className="card-img-top" alt="Medelse logo" style={{ width: 200 }} />
                  </div>
                )}
                <div className="card-body">
                  {children}
                </div>
              </div>
              {belowCard !== undefined ? (
                <div className="text-center my-4">
                  {belowCard}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

LayoutSplit.defaultProps = {
  belowCard: undefined,
  withLogo: false,
  title: undefined,
};

export default LayoutSplit;
