/* eslint-disable react/no-children-prop */
import React from 'react';
import {
  Column,
  ColumnDef,
  ColumnSort,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSort,
  faSortUp,
  faSortDown,
  faFileDownload,
} from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import TextWithTooltip from '../TextWithTooltip';
import TablePagination from '../TablePagination/TablePagination';
import ActionDropdown from '../ActionDropdown';
import useTracking from '../../hooks/useTracking';
import EmptyDataComponent from '../EmptyDataComponent';

type Props<T> = {
  canBeFiltered?: boolean
  data: T[]
  columns: ColumnDef<T>[]
  pageSize?: number
  defaultColumnSort?: ColumnSort | undefined
  setRowStyles?: (row: any) => React.CSSProperties
  title?: string | undefined
  hint?: string | undefined
  exportOptions?: { label: string, action: () => void, labelIcon: IconProp }[] | null
  eventNamePrefix?: string
};

const Table = <T extends object>({
  canBeFiltered,
  columns,
  data,
  defaultColumnSort,
  pageSize,
  setRowStyles,
  title,
  hint,
  exportOptions,
  eventNamePrefix,
}: Props<T>) => {
  const { t } = useTranslation();
  const { logTableOrderChange, logTableFilterChange } = useTracking();
  const [globalFilter, setGlobalFilter] = React.useState<string>('');
  const [pageIndex, setPageIndex] = React.useState<number>(0);
  const [sorting, setSorting] = React.useState<SortingState>([]);

  React.useEffect(() => {
    if (defaultColumnSort !== undefined) {
      setSorting([defaultColumnSort]);
    }
  }, [defaultColumnSort]);

  React.useEffect(() => {
    const debounceId = setTimeout(() => {
      if (eventNamePrefix && globalFilter.length) {
        logTableFilterChange(eventNamePrefix, { table_filter_value: globalFilter });
      }
    }, 2000);
    return () => clearTimeout(debounceId);
  }, [globalFilter, logTableFilterChange, eventNamePrefix]);

  let paginationState: PaginationState | undefined;
  if (pageSize !== undefined) {
    paginationState = {
      pageIndex,
      pageSize,
    };
  }

  const table = useReactTable({
    data,
    columns,
    state: {
      globalFilter,
      sorting,
      pagination: paginationState,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    sortDescFirst: false,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: pageSize ? getPaginationRowModel() : undefined,
  });

  const startItem = () => {
    if (pageIndex === 0) {
      return pageIndex + 1;
    }

    return ((pageSize || 0) * pageIndex) + 1;
  };

  const endItem = () => {
    const maxResult = table.getFilteredRowModel().rows.length;
    const maxItems = (pageSize || 0) * (pageIndex + 1);

    if (maxItems > maxResult) {
      return maxResult;
    }

    return maxItems;
  };

  const isEmpty = data.length === 0;

  const onColumnHeaderClick = (column: Column<T, unknown>) => {
    if (column.getCanSort() === true) {
      if (eventNamePrefix) {
        let tableOrder: 'ASC' | 'DESC' | 'NONE' = 'ASC';
        switch (column.getIsSorted()) {
          case false:
            tableOrder = 'ASC';
            break;
          case 'asc':
            tableOrder = 'DESC';
            break;
          case 'desc':
            tableOrder = 'NONE';
            break;
          default:
            tableOrder = 'NONE';
            break;
        }
        logTableOrderChange(
          eventNamePrefix,
          {
            table_order_field: column.id,
            table_order_order: tableOrder,
          },
        );
      }

      column.toggleSorting();
    }
  };

  return (
    <>
      <div className="d-flex align-items-center mb-4">
        <div className="flex-fill">
          { title && hint === undefined && (
            <h3 className="mb-0">{title}</h3>
          )}
          { title && hint && (
            <TextWithTooltip
              text={title}
              tooltipId={`${title}-table-title`}
              tooltipContent={hint}
              tooltipVariant="help"
              className="h3 mb-0"
            />
          )}
        </div>
        {!isEmpty && canBeFiltered && (
          <div style={{ width: 200 }}>
            <input
              value={globalFilter}
              onChange={(e) => {
                setGlobalFilter(String(e.target.value));
                setPageIndex(0);
              }}
              className="p-2 form-control w-100"
              placeholder={t('components.table.filterPlaceholder')}
            />
          </div>
        )}
      </div>
      <div>
        <table className="table striped table-bordered text-center">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder ? null : (
                      <div
                        role="presentation"
                        className={header.column.getCanSort() ? 'cursor-pointer select-none pr-3' : ''}
                        onClick={() => onColumnHeaderClick(header.column)}
                      >
                        <span />
                        <span className="th-label text-center">
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </span>
                        <span className="text-end">
                          {{
                            asc: <FontAwesomeIcon icon={faSortUp} role="img" />,
                            desc: <FontAwesomeIcon icon={faSortDown} role="img" />,
                          }[header.column.getIsSorted() as string] ?? (
                            header.column.getCanSort() ? (
                              <FontAwesomeIcon icon={faSort} role="img" />
                            ) : null
                          )}
                        </span>
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} style={setRowStyles ? setRowStyles(row) : {}}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div>
        { !isEmpty && pageSize && (
          <div className="d-flex flex-direction-row btn-toolbar">
            <div className="col-form-label text-grey-blue" data-testid="pagination-details">
              {`${startItem()} à ${endItem()} sur ${table.getFilteredRowModel().rows.length}`}
            </div>
            <div className="mx-auto">
              <TablePagination
                pageCount={table.getPageCount()}
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                getNextPage={table.getCanNextPage()}
                getPreviousPage={table.getCanPreviousPage()}
                eventNamePrefix={eventNamePrefix}
              />
            </div>
            { exportOptions && (
            <ActionDropdown headerText="Extraire les données" icon={faFileDownload} id="table-export" actions={exportOptions} />
            )}
          </div>
        )}
        { isEmpty ? <EmptyDataComponent /> : null}
      </div>
    </>
  );
};

Table.defaultProps = {
  canBeFiltered: false,
  defaultColumnSort: undefined,
  pageSize: undefined,
  setRowStyles: () => ({}),
  title: undefined,
  hint: undefined,
  exportOptions: null,
  eventNamePrefix: undefined,
};

export default Table;
