import { t } from 'i18next';
import React from 'react';

import GroupOfficeSelect from '../../app/components/GroupOfficeSelect';
import PageHeader from '../../app/components/PageHeader';
import RangeDatePicker from '../../app/components/RangeDatePicker';
import Tabs from '../../app/components/Tabs';
import { useGroupOffice } from '../../app/data/hooks';
import { DateRangeInput } from '../../app/generated/graphql';
import useTracking from '../../app/hooks/useTracking';
import ContractsAlert from '../../features/ContractsPage/ContractsAlert';
import ContractsSignedTable from '../../features/ContractsPage/ContractsSignedTable/ContractsSignedTable';
import ContractsToSignTable from '../../features/ContractsPage/ContractsToSignTable';

const ContractsPage = () => {
  const { logEvent } = useTracking();
  const { getGroupOfficeQueryFilter } = useGroupOffice();

  const [filterDates, setFilterDates] = React.useState<DateRangeInput | null>(null);
  const selectedGroupOffices = getGroupOfficeQueryFilter();

  const onFilterDatesChange = (value: DateRangeInput | null) => {
    logEvent('m.contract.header.select_date.update');
    setFilterDates(value);
  };

  return (
    <div>
      <PageHeader
        title={t('contractsPage.title')}
        description={t('contractsPage.hint')}
        leftSlot={<GroupOfficeSelect eventNamePrefix="m.contract.header" />}
        rightSlot={(
          <>
            <div className="mb-4">
              <ContractsAlert groupOfficesUuid={selectedGroupOffices} />
            </div>
            <RangeDatePicker onChange={onFilterDatesChange} />
          </>
        )}
      />
      <Tabs
        items={[
          {
            title: t('contractsPage.tabToSign'),
            content: <ContractsToSignTable
              dates={filterDates}
              groupOfficesUuid={selectedGroupOffices}
              eventNamePrefix="m.contract.to_sign"
            />,
            eventName: 'm.contract.switch.to_sign.click',
          },
          {
            title: t('contractsPage.tabSigned'),
            content: <ContractsSignedTable
              dates={filterDates}
              groupOfficesUuid={selectedGroupOffices}
              eventNamePrefix="m.contract.others"
            />,
            eventName: 'm.contract.switch.others.click',
          },
        ]}
      />
    </div>
  );
};

export default ContractsPage;
