/* eslint-disable @typescript-eslint/default-param-last */
import { AnyAction, Reducer } from 'redux';

import { GroupOfficeState } from '../../domain/models';
import { ActionTypes } from './actions';

const groupOfficesInitialState: GroupOfficeState = {
  allGroupOffices: [],
  selectedGroupOffice: '',
};

export const groupOfficesReducer: Reducer<GroupOfficeState> = (
  state: GroupOfficeState = groupOfficesInitialState,
  action: AnyAction,
): GroupOfficeState => {
  switch (action.type) {
    case ActionTypes.STORE_GROUP_OFFICES:
      return {
        ...state,
        allGroupOffices: action.groupOfficeOptions,
      };
    case ActionTypes.SELECT_GROUP_OFFICE:
      return {
        ...state,
        selectedGroupOffice: action.groupOfficeUuid,
      };
    default:
      return state;
  }
};

export default {};
