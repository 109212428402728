import React from 'react';

import * as tokens from '../../DesignSystem/tokens';

type Props = {
  description: string
  title: string
  leftSlot?: React.ReactNode
  rightSlot?: React.ReactNode
};

const PageHeader = ({
  description, leftSlot, rightSlot, title,
}: Props) => (
  <div className="row" style={{ font: tokens.fontBase }}>
    <div className="col-lg-6 d-flex flex-column" style={{ marginBottom: tokens.spaceMedium }}>
      <h1 className="h2 mb-4">{title}</h1>
      <div className="mb-4">{description}</div>
      {leftSlot && (
        <div className="mt-auto">{leftSlot}</div>
      )}
    </div>
    {rightSlot && (
      <div className="col-lg-6 d-flex flex-column" style={{ marginBottom: tokens.spaceMedium }}>
        <div className="mt-auto">
          {rightSlot}
        </div>
      </div>
    )}
  </div>
);

PageHeader.defaultProps = {
  leftSlot: undefined,
  rightSlot: undefined,
};

export default PageHeader;
