import React from 'react';
import { useTranslation } from 'react-i18next';

import { GetParametersQuery, useUpdateRecruiterEmailMutation } from '../../../app/generated/graphql';
import CardTitle from '../../../app/components/CardTitle';
import TextField from '../../../app/components/TextField';
import QueryIndicator from '../../../app/components/QueryIndicator';
import FormActions from '../../../app/components/FormActions';
import AlertMessage from '../../../app/components/AlertMessage';

type Props = {
  userProfile: GetParametersQuery['me']
  onCancel: VoidFunction
  onMutationSuccess: VoidFunction
};

const ParametersEmailForm = ({ userProfile, onCancel, onMutationSuccess }: Props) => {
  const { t } = useTranslation();
  const [updateRecruiterEmailMutation, { loading, error }] = useUpdateRecruiterEmailMutation();
  const [email, setEmail] = React.useState<string>('');
  const [emailConfirmation, setEmailConfirmation] = React.useState<string>('');

  const handleOnSubmit = () => {
    updateRecruiterEmailMutation({
      variables: { input: { email, emailConfirmation } },
      onCompleted() { onMutationSuccess(); },
      onError() {},
    });
  };

  return (
    <div>
      <CardTitle>{t('parametersEmailForm.title')}</CardTitle>
      {loading ? (
        <QueryIndicator />
      ) : (
        <>
          <form>
            {error && <AlertMessage message={error.message} />}
            <div className="mb-4">
              <TextField
                label={t('parametersEmailForm.currentEmail')}
                id="currentEmmail"
                value={userProfile?.profile.email}
                onChange={() => {}}
                disabled
              />
            </div>
            <div className="mb-4">
              <TextField
                id="email"
                label={t('parametersEmailForm.email')}
                onChange={setEmail}
                value={email}
              />
            </div>
            <TextField
              id="emailConfirm"
              label={t('parametersEmailForm.emailConfirm')}
              onChange={setEmailConfirmation}
              value={emailConfirmation}
            />
          </form>
          <FormActions
            onCancel={onCancel}
            onConfirm={handleOnSubmit}
            eventNamePrefix="m.settings.email.edit"
          />
        </>
      )}
    </div>
  );
};

export default ParametersEmailForm;
