/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import './LoginForm.css';

import useAuth from './hooks/useAuth';
import LoaderFullPage from '../app/LoaderFullPage';
import useTracking from '../app/hooks/useTracking';
import PasswordInput from '../app/components/PasswordInput';

const LoginForm = () => {
  const navigate = useNavigate();
  const auth = useAuth();
  const { logEvent } = useTracking();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [isUsernameValid, setIsUsernameValid] = useState<boolean>(true);
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(true);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError(null);
    let userNameValid = false;
    let passwordValid = false;

    if (username && username.trim().length > 0) {
      userNameValid = true;
    }

    if (password && password.trim().length > 0) {
      passwordValid = true;
    }

    if (userNameValid && passwordValid) {
      const credentials = { username, password };
      setLoading(true);
      auth.signin(credentials, rememberMe)
        .then(() => navigate('/', { replace: true }))
        .catch((err) => {
          logEvent('m.login.form.id.error');
          if (err.message === 'Request failed with status code 401') {
            setError('error.wrongCredentials');
          } else {
            setError(err.message);
          }
          setLoading(false);
        });
    } else {
      setIsUsernameValid(userNameValid);
      setIsPasswordValid(passwordValid);
    }
  };

  if (loading) {
    return (
      <div className="fixed-top vh-100">
        <LoaderFullPage />
      </div>
    );
  }

  return (
    <div>
      {error && (
        <div className="alert alert-danger">
          {error === 'auth.unauthorized' ? (
            <Trans i18nKey="auth.unauthorized">
              <span>
                {t(error)}
              </span>
              <a className="text-danger" href={process.env.REACT_APP_PRO_URL} target="_blank" rel="noreferrer">
                {process.env.REACT_APP_PRO_URL}
              </a>
            </Trans>
          )
            : (t(error))}
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="input-group has-validation">
          <label htmlFor="usernameField" className="form-label w-100">
            {t('auth.login.username')}
            <input
              data-testid="usernameField"
              className={`form-control${!isUsernameValid ? ' is-invalid' : ''}`}
              id="usernameField"
              name="username"
              type="text"
              value={username}
              onChange={({ target }) => setUsername(target.value)}
              required
            />
            {!isUsernameValid && <div className="invalid-feedback">{t('errors.requiredField')}</div>}
          </label>
        </div>
        <div className="input-group has-validation">
          <label htmlFor="passwordField" className="form-label w-100">
            {t('auth.login.password')}
            <PasswordInput
              testID="passwordField"
              valid={isPasswordValid}
              id="passwordField"
              value={password}
              onChange={setPassword}
              required
            />
            {!isPasswordValid && <div className="invalid-feedback">{t('errors.requiredField')}</div>}
          </label>
        </div>
        <div className="row mb-2">
          <div className="col d-flex align-items-center">
            <div className="form-check">
              <label className="form-check-label" htmlFor="rememberMeField">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="rememberMeField"
                  data-tracking-event="m.login.buttons.remember.click"
                  checked={rememberMe}
                  onChange={() => setRememberMe(!rememberMe)}
                />
                {t('auth.login.rememberMe')}
              </label>
            </div>
          </div>
          <div className="col text-end">
            <button
              className="btn btn-link link-ternary"
              type="button"
              data-tracking-event="m.login.buttons.forgot.click"
              onClick={() => navigate('/login/recover')}
            >
              {t('auth.recoverPassword.link')}
            </button>
          </div>
        </div>
        <div className="d-grid gap-2">
          <button
            data-testid="loginSubmitBtn"
            type="submit"
            className="btn btn-primary"
          >
            {t('auth.login.button')}
          </button>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
