import React from 'react';
import { useTranslation } from 'react-i18next';

import { useMissionsCountInNextInvoiceQuery } from '../../../app/generated/graphql';
import AlertMessage from '../../../app/components/AlertMessage';
import QueryIndicator from '../../../app/components/QueryIndicator';

type Props = { groupOfficesUuid: Array<string> };
const MissionsCountInNextInvoice = ({ groupOfficesUuid }: Props) => {
  const {
    data,
    error,
    loading,
  } = useMissionsCountInNextInvoiceQuery({ variables: { groupOfficesUuid } });
  const { t } = useTranslation();

  if (loading) {
    return <QueryIndicator />;
  }

  if (error) {
    return <AlertMessage message={error.message} />;
  }

  let translationKey = 'dashboard.missionsCountInNextInvoiceSingular';

  if (
    data
    && data.me
    && data.me.missions
    && data.me.missions.totalCount
    && data?.me?.missions?.totalCount > 1
  ) {
    translationKey = 'dashboard.missionsCountInNextInvoicePlural';
  }

  return (
    <>
      <div className="h2 mb-3">
        {data?.me?.missions?.totalCount}
      </div>
      <h3>{t(translationKey)}</h3>
    </>
  );
};

export default MissionsCountInNextInvoice;
