/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import * as tokens from '../../DesignSystem/tokens';

type Props = {
  disabled?: boolean
  id: string
  label?: string | undefined
  onChange: (val: string) => void
  multiline?: boolean
  type?: React.HTMLInputTypeAttribute
  placeholder?: string | undefined
  value?: string | undefined
  lines?: number
};

const TextField = ({
  disabled, id, label, onChange, multiline, type, placeholder, value, lines,
}: Props) => {
  const sharedProps = {
    style: disabled ? tokens.textFieldDisabled : tokens.textFieldBase,
    disabled,
    id: `field-${id}`,
    onChange: (
      evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => onChange(evt.target.value),
    value,
  };

  const renderField = () => {
    if (multiline) {
      return <textarea {...sharedProps} rows={lines} placeholder={placeholder} />;
    }

    return <input {...sharedProps} type={type} />;
  };

  if (label) {
    return (
      <label htmlFor={`field-${id}`} style={tokens.textFieldLabel}>
        {label}
        {renderField()}
      </label>
    );
  }

  return renderField();
};

TextField.defaultProps = {
  disabled: false,
  type: 'text',
  label: undefined,
  multiline: false,
  placeholder: undefined,
  value: undefined,
  lines: 10,
};

export default TextField;
