import { useState } from 'react';
import { faFileCsv, faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { EnumType } from 'typescript';
import {
  DateRangeInput,
  ExportToSpreadsheetInput,
  InputMaybe,
  PaymentStatus,
  ReplacementTypeEnum,
  Scalars,
  SpreadsheetExportableFields,
} from '../generated/graphql';
import useTracking, { TableExportEvent } from './useTracking';
import utils from '../domain/utils';

export type ExportType = 'SPREADSHEET' | 'ZIP';

export interface QuerySpreadSheetOption {
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>> | InputMaybe<Scalars['Uuid']>>
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>
  dates?: InputMaybe<DateRangeInput>
  replacementType: ReplacementTypeEnum
}

export interface QueryZipOption {
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>> | InputMaybe<Scalars['Uuid']>>
  dates?: InputMaybe<DateRangeInput>
  paymentStatus: PaymentStatus[]
  replacementType: ReplacementTypeEnum
}

export type QueryOption<T extends ExportType> = T extends 'SPREADSHEET' ? QuerySpreadSheetOption : QueryZipOption;

export interface QueryOptions {
  csvOptions: QueryOption<'SPREADSHEET'>,
  xlsxOptions: QueryOption<'SPREADSHEET'>,
  zipOptions: QueryOption<'ZIP'>,
}

export interface QuerySpreadsheet {
  name: string,
  format: string
  fields: EnumType[]
}

interface Query { (arg0: Object): any[] }

interface ExportOption {
  label: string,
  action: () => any,
  labelIcon: any
}
interface ExportOptions {
  loading: boolean,
  csvOptions: ExportOption,
  xlsxOptions: ExportOption,
  zipOptions: ExportOption
}
export interface GetQueryOptions {
  (
    dates: DateRangeInput | null,
    groupOfficesUuid: string[],
    name: string,
    role: number
  ): QueryOptions
}

export type UseExportParams = {
  dates: DateRangeInput | null,
  groupOfficesUuid: string[],
  name: string,
  eventName?: string | undefined,
  query: Query;
  role?: number
};

let fields: SpreadsheetExportableFields[];
let paymentStatus: PaymentStatus[];

const getQueryOptions: GetQueryOptions = (
  dates: DateRangeInput | null,
  groupOfficesUuid: string[],
  name: string,
  role: number,
) => {
  switch (name) {
    case 'Contrats-autres':
      fields = [
        SpreadsheetExportableFields.ContractNumber,
        SpreadsheetExportableFields.FullName,
        SpreadsheetExportableFields.Speciality,
        SpreadsheetExportableFields.ContractDates,
        SpreadsheetExportableFields.Status,
        SpreadsheetExportableFields.SignedAt,
      ];
      break;
    case 'Contrats-à-signer':
      fields = [
        SpreadsheetExportableFields.ContractNumber,
        SpreadsheetExportableFields.FullName,
        SpreadsheetExportableFields.Speciality,
        SpreadsheetExportableFields.ContractDates,
        SpreadsheetExportableFields.Status,
      ];
      break;
    case 'Mission-à-confirmer':
      fields = [
        SpreadsheetExportableFields.Dates,
        SpreadsheetExportableFields.FullName,
        SpreadsheetExportableFields.Speciality,
        SpreadsheetExportableFields.Service,
        SpreadsheetExportableFields.SpecificService,
        SpreadsheetExportableFields.Sector,
        SpreadsheetExportableFields.Salary,
        SpreadsheetExportableFields.Hours,
        SpreadsheetExportableFields.Validated,
      ];
      break;
    case 'Mission-en-ligne':
      fields = [
        SpreadsheetExportableFields.Dates,
        SpreadsheetExportableFields.Speciality,
        SpreadsheetExportableFields.Service,
        SpreadsheetExportableFields.SpecificService,
        SpreadsheetExportableFields.Sector,
        SpreadsheetExportableFields.Salary,
        SpreadsheetExportableFields.Hours,
        SpreadsheetExportableFields.NbrNeeds,
      ];
      break;
    case 'Mission-à-venir':
      fields = [
        SpreadsheetExportableFields.Dates,
        SpreadsheetExportableFields.FullName,
        SpreadsheetExportableFields.Speciality,
        SpreadsheetExportableFields.Service,
        SpreadsheetExportableFields.SpecificService,
        SpreadsheetExportableFields.Sector,
        SpreadsheetExportableFields.Salary,
        SpreadsheetExportableFields.Hours,
      ];
      break;
    case 'Mission-archivées':
      fields = [
        SpreadsheetExportableFields.Dates,
        SpreadsheetExportableFields.FullName,
        SpreadsheetExportableFields.Speciality,
        SpreadsheetExportableFields.Service,
        SpreadsheetExportableFields.SpecificService,
        SpreadsheetExportableFields.Sector,
        SpreadsheetExportableFields.Salary,
        SpreadsheetExportableFields.Hours,
        SpreadsheetExportableFields.Reported,
      ];
      break;
    case 'Mission-annulées':
      fields = [
        SpreadsheetExportableFields.Dates,
        SpreadsheetExportableFields.FullName,
        SpreadsheetExportableFields.Speciality,
        SpreadsheetExportableFields.Service,
        SpreadsheetExportableFields.SpecificService,
        SpreadsheetExportableFields.Sector,
        SpreadsheetExportableFields.Salary,
        SpreadsheetExportableFields.Hours,
        SpreadsheetExportableFields.Cancelled,
      ];
      break;
    case 'Factures-payées':
      fields = [
        SpreadsheetExportableFields.InvoiceNumber,
        SpreadsheetExportableFields.FullName,
        SpreadsheetExportableFields.Speciality,
        SpreadsheetExportableFields.Salary,
        SpreadsheetExportableFields.PaymentDate,
        SpreadsheetExportableFields.Dates,
      ];
      paymentStatus = [PaymentStatus.Paid];
      break;
    case 'Factures-à-payer':
      fields = [
        SpreadsheetExportableFields.InvoiceNumber,
        SpreadsheetExportableFields.FullName,
        SpreadsheetExportableFields.Speciality,
        SpreadsheetExportableFields.Salary,
        SpreadsheetExportableFields.PendingDate,
        SpreadsheetExportableFields.Status,
        SpreadsheetExportableFields.Dates,
      ];
      paymentStatus = [PaymentStatus.Late, PaymentStatus.Pending, PaymentStatus.Processing];
      break;
    default:
      break;
  }

  const replacementType: ReplacementTypeEnum = ReplacementTypeEnum.Session;

  const queryOptions: QueryOptions = {
    csvOptions: {
      groupOfficesUuid,
      dates,
      replacementType,
      spreadSheet: {
        name,
        format: 'Csv',
        fields: utils.filterExportOptionsByRole(fields, role),
      },
    },
    xlsxOptions: {
      groupOfficesUuid,
      dates,
      replacementType,
      spreadSheet: {
        name,
        format: 'Xlsx',
        fields: utils.filterExportOptionsByRole(fields, role),
      },
    },
    zipOptions: {
      groupOfficesUuid,
      dates,
      paymentStatus,
      replacementType,
    },
  };

  return queryOptions;
};

export const useExportOptions = (
  {
    query,
    dates,
    groupOfficesUuid,
    name,
    eventName: eventNamePrefix,
    role,
  }: UseExportParams,
): ExportOptions => {
  const { t } = useTranslation();
  const { logTableFilterExport } = useTracking();

  const [loading, setLoading] = useState(false);
  const [getExportLink] = query({ fetchPolicy: 'network-only' });

  let type: string;

  switch (name) {
    case t('contractsPage.exportToSignTitle'):
    case t('contractsPage.exportSignedTitle'):
      type = 'contracts';
      break;
    case t('missionsPage.exportAnnouncementsTitle'):
      type = 'Announcements';
      break;
    case t('missionsPage.exportUpcomingTitle'):
    case t('missionsPage.exportArchivedTitle'):
    case t('missionsPage.exportCancelledTitle'):
    case t('missionsPage.exportToConfirmTitle'):
      type = 'missions';
      break;
    case t('invoicesPage.exportPaidTitle'):
    case t('invoicesPage.exportToPayTitle'):
      type = 'invoices';
      break;
    default:
      break;
  }

  const logExport = (table_export_format: TableExportEvent['table_export_format']) => {
    if (eventNamePrefix) {
      logTableFilterExport(eventNamePrefix, { table_export_format });
    }
  };

  const exportOptions: ExportOptions = {
    loading,
    csvOptions: {
      label: 'Format .csv',
      action: () => getExportLink({
        variables: getQueryOptions(dates, groupOfficesUuid, name, role ?? 3).csvOptions,
      }).then(({ data: csvData }: Awaited<Promise<any>>) => {
        if (csvData && csvData.me[type].spreadSheet.url) {
          logExport('csv');
          window.location.href = csvData.me[type].spreadSheet.url;
        }
      }),
      labelIcon: faFileCsv,
    },
    xlsxOptions: {
      label: 'Format .xlsx',
      action: () => getExportLink({
        variables: getQueryOptions(dates, groupOfficesUuid, name, role ?? 3).xlsxOptions,
      }).then(({ data: xlsxData }: Awaited<Promise<any>>) => {
        if (xlsxData && xlsxData.me[type].spreadSheet.url) {
          logExport('xlsx');
          window.location.href = xlsxData.me[type].spreadSheet.url;
        }
      }),
      labelIcon: faFileExcel,
    },
    zipOptions: {
      label: 'Format .pdf',
      action: async () => {
        try {
          setLoading(true);

          const { data: zipData } = await getExportLink({
            variables: getQueryOptions(dates, groupOfficesUuid, name, role ?? 3).zipOptions,
          });

          if (zipData && zipData.downloadInvoices.link) {
            logExport('zip');
            window.location.href = zipData.downloadInvoices.link;
          }
        } finally {
          setLoading(false);
        }
      },
      labelIcon: faFilePdf,
    },
  };
  return { ...exportOptions };
};
