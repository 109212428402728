import React from 'react';

import LoaderFullPage from '../app/LoaderFullPage';
import { useUserNameQuery } from '../app/generated/graphql';
import UserNameContext from './UserNameContext';

const UserNameProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    loading,
    data,
    error,
  } = useUserNameQuery({ fetchPolicy: 'no-cache' });

  if (loading) {
    return <LoaderFullPage />;
  }

  if (error) {
    return <div>{children}</div>;
  }

  if (!data) {
    return null;
  }

  return <UserNameContext.Provider value={data.me}>{children}</UserNameContext.Provider>;
};

export default UserNameProvider;
