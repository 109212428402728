/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClose,
  faCheck,
  faWarning,
  type IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { Placement } from 'react-bootstrap/esm/types';

import * as tokens from '../../DesignSystem/tokens';

const ICON_SIZE = 25;

const iconContainerStyles: React.CSSProperties = {
  width: ICON_SIZE,
  height: ICON_SIZE,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: 0,
  borderRadius: ICON_SIZE / 2,
  color: tokens.colorWhite,
};

type Variant = 'success' | 'danger' | 'warning';

type Props = {
  variant: Variant,
  text: string,
  placement?: Placement
};

const TableCellIcon = ({
  text, variant, placement,
}: Props) => {
  let iconRatio: number = 0.7;
  let icon: IconDefinition = faClose;
  let iconColor: string = tokens.colorWhite;
  let iconBgColor: string = tokens.colorGreenDark;

  switch (variant) {
    case 'danger':
      icon = faClose;
      iconBgColor = tokens.colorRedDark;
      break;
    case 'warning':
      icon = faWarning;
      iconColor = tokens.colorOrangeDark;
      iconBgColor = 'transparent';
      iconRatio = 1;
      break;
    case 'success':
      icon = faCheck;
      break;
    default:
      break;
  }

  const renderTooltip = (props: any) => (
    <Tooltip id={`tooltip-cell-${Math.random()}`} {...props}>
      {text}
    </Tooltip>
  );

  const Button = (
    <button
      aria-labelledby="tooltip"
      style={{
        ...iconContainerStyles,
        color: iconColor,
        backgroundColor: iconBgColor,
      }}
      type="button"
    >
      <FontAwesomeIcon
        icon={icon}
        role="img"
        fontSize={ICON_SIZE * iconRatio}
      />
    </button>
  );

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <OverlayTrigger
        rootClose
        placement={placement || 'right'}
        delay={{ show: 200, hide: 200 }}
        overlay={renderTooltip}
      >
        {Button}
      </OverlayTrigger>
    </div>
  );
};

TableCellIcon.defaultProps = {
  placement: undefined,
};

export default TableCellIcon;
