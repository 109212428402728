import React from 'react';

import QueryIndicator from '../../../app/components/QueryIndicator';
import { useContractsAlertQuery, ContractsAlertQueryResult } from '../../../app/generated/graphql';
import Toast from '../../../app/components/Toast';

type Props = {
  groupOfficesUuid: string[]
};

const ContractsAlert = ({ groupOfficesUuid }: Props) => {
  const {
    data,
    error,
    loading,
  } = useContractsAlertQuery({ variables: { groupOfficesUuid } });

  if (loading) {
    return <QueryIndicator />;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  const count = (data as ContractsAlertQueryResult['data'])!.me!.contracts!.totalCount;

  if (count! > 5) {
    return <Toast variant="danger" message={`Attention - ${count} contrats à signer`} />;
  }
  if (count! > 0) {
    return <Toast variant="warning" message={`Attention - ${count} contrats à signer`} />;
  }

  return <Toast variant="success" message="Super - Aucun contrat à signer" />;
};

export default ContractsAlert;
