import React from 'react';

import * as tokens from './DesignSystem/tokens';

export const dots = '...';

export const authAPIRoutes = {
  signIn: '/api/login_check',
  recoverPassword: '/api/password/recover',
  refreshToken: '/api/token/refresh',
  logNavigation: '/log',
  CDDMetabase: '/api/cdd/metabase?uuid={uuid}',
};

export const ROUTE_TRANSLATIONS = 'https://www.medelse.com/api/translations';

export const EXTERNAL_URL_HELP = 'https://www.medelse.com/contact/';
export const EXTERNAL_URL_DEMO = 'https://www.medelse.com/demande-de-demo/';

export const ARRAY_ITEMS_PER_PAGE = 10;

export const TOOLTIP_ICON_SIZE: number = 16;
export const TOOLTIP_ICON_FONT_SIZE: number = 10;

export const STYLE_ARRAY_RED_BACKGROUND: React.CSSProperties = {
  backgroundColor: tokens.colorRedLight,
};

export const availableCountryCodes = [{
  flag: '🇫🇷', code: 33,
}, {
  flag: '🇬🇵', code: 590,
}, {
  flag: '🇬🇫', code: 594,
}, {
  flag: '🇲🇶', code: 596,
}, {
  flag: '🇾🇹', code: 269,
}, {
  flag: '🇳🇨', code: 687,
}, {
  flag: '🇵🇫', code: 689,
}, {
  flag: '🇷🇪', code: 262,
}, {
  flag: '🇵🇲', code: 508,
}, {
  flag: '🇼🇫', code: 681,
}];
