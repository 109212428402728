/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

import './styles.scss';
import utils from '../../domain/utils';
import { Specialty, UserProfile } from '../../generated/graphql';
import TextWithTooltip from '../TextWithTooltip';

export type CellUserType = Pick<UserProfile, 'gender' | 'firstName' | 'lastName' | 'email' | 'phone'>;
export type SpecialtyUserType = Pick<Specialty, 'name'>;

type Props = {
  profile: CellUserType | undefined
  specialties?: SpecialtyUserType[] | undefined
};

const TableCellUser = ({ profile, specialties }: Props) => {
  const [toast, setToast] = React.useState(false);
  const [visible, makeVisible] = React.useState(false);
  const { t } = useTranslation();

  if (!profile) {
    return <span>-</span>;
  }

  const {
    gender, lastName, firstName, email, phone,
  } = profile;

  const fullName = `${t(`gender.${gender}`)} ${lastName} ${firstName}`;

  const handleCopy = (string: string) => {
    utils.copyToClipboard(string);
    setToast(true);
    makeVisible(false);
    setTimeout(() => {
      setToast(false);
      makeVisible(true);
    }, 1000);
    setTimeout(() => makeVisible(false), 2000);
  };

  return (
    <div className="d-flex justify-content-center">
      <TextWithTooltip
        text={fullName}
        tooltipId="tableCellUserTooltip"
        tooltipType="popover"
        tooltipVariant="more"
        tooltipContent={(
          <>
            <div>
              <span className="fw-bold">{t('healthProfessionalDetails.title')}</span>
              {` ${fullName}`}
            </div>
            <>
              <span className="fw-bold">{t('healthProfessionalDetails.email')}</span>
              {` ${email}`}
              <span className={`${toast && 'show'} ${visible ? 'hide' : 'default'}`} data-tooltip="Copié !" />
              <FontAwesomeIcon
                className="copy-icon text-primary ms-3"
                role="button"
                tabIndex={0}
                onClick={() => handleCopy(email)}
                onKeyDown={() => utils.copyToClipboard(email)}
                icon={faCopy}
                fontSize={18}
              />
            </>
            <div>
              <span className="fw-bold">{`${t('healthProfessionalDetails.specialty')}`}</span>
              <ul style={{ margin: 0 }}>
                {specialties && specialties.map((specialty: any) => (
                  <li key={`${specialty.name}`}>{` ${t(`database_fields.${specialty.name}`)}`}</li>
                ))}

              </ul>
            </div>
            {phone && phone.number && (
            <div>
              <span className="fw-bold">{t('healthProfessionalDetails.phoneNumber')}</span>
              {` ${utils.formatPhoneNumber(phone)}`}
            </div>
            )}
          </>
        )}
      />
    </div>
  );
};

TableCellUser.defaultProps = {
  specialties: undefined,
};

export default TableCellUser;
