/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnDef, ColumnSort } from '@tanstack/react-table';

import {
  AnnouncementShort,
  DateRangeInput,
  useOnlineAnnouncementsExportLazyQuery,
  useOnlineAnnouncementsQuery,
} from '../../../app/generated/graphql';
import { ARRAY_ITEMS_PER_PAGE } from '../../../app/constants';
import QueryIndicator from '../../../app/components/QueryIndicator';
import Table from '../../../app/components/Table';
import utils from '../../../app/domain/utils';
import TableCellService from '../../../app/components/TableCellService';
import TableCellSpecialty from '../../../app/components/TableCellSpecialty';
import { useExportOptions } from '../../../app/hooks/useExportOptions';
import useAuth from '../../../auth/hooks/useAuth';
import useTracking from '../../../app/hooks/useTracking';
import ActionDropdown from '../../../app/components/ActionDropdown';
import { Action } from '../../../app/components/ActionDropdown/ActionDropdown';
import CancelAnnouncement from '../../announcement/CancelAnnouncement';
import Modal from '../../../app/components/Modal';
import { AnnouncementToCancel } from '../../announcement/CancelAnnouncement/CancelAnnouncement';

type Props = {
  groupOfficesUuid: Array<string>
  eventNamePrefix: string
  dates: DateRangeInput | null
};

const OnlineAnnouncementsTable = ({ groupOfficesUuid, dates, eventNamePrefix }: Props) => {
  const { logEvent } = useTracking();
  const { getAccessLevel } = useAuth();
  const { t } = useTranslation();
  const {
    data,
    error,
    loading,
  } = useOnlineAnnouncementsQuery({ variables: { groupOfficesUuid, dates } });
  const [
    announcementToCancel,
    setAnnouncementToCancel,
  ] = React.useState<AnnouncementShort | null>(null);

  const { csvOptions, xlsxOptions } = useExportOptions({
    query: useOnlineAnnouncementsExportLazyQuery,
    dates,
    groupOfficesUuid,
    name: t('missionsPage.exportAnnouncementsTitle'),
    eventName: eventNamePrefix,
    role: getAccessLevel(),
  });

  if (loading) {
    return <QueryIndicator />;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  const columns: ColumnDef<AnnouncementShort>[] = [
    {
      id: 'date',
      header: t('column.date'),
      accessorFn: (row) => row.dates.startDate,
      cell: ({ row }) => utils.formatDate(row.original.dates.startDate),
    },
    {
      id: 'specialty',
      header: t('column.specialties'),
      accessorKey: 'specialty',
      cell: ({ row }) => {
        const { name } = row.original.specialty;

        return <TableCellSpecialty name={name} uuid={row.original.uuid} />;
      },
    },
    {
      id: 'service',
      header: t('column.service'),
      accessorFn: (row) => {
        if (row.service) {
          return row.service.name;
        }

        return '';
      },
      cell: ({ row }) => {
        const { specificServices, service } = row.original;

        return <TableCellService service={service} specificServices={specificServices} />;
      },
    },
    {
      id: 'sector',
      header: t('column.sector'),
      accessorKey: 'sector',
    },
    {
      id: 'amount',
      enableGlobalFilter: false,
      header: t('column.salary'),
      accessorFn: (row) => `${utils.formatFloat(row.salary!)} €`,
    },
    {
      id: 'hours',
      header: t('column.hours'),
      cell: ({ row }) => {
        const missionDates = row.original.dates;

        const getHours = (dateTime: string) => utils.formatDateTime(dateTime, 'HH:mm');
        return `${getHours(missionDates.startDate)} - ${getHours(missionDates.endDate)}`;
      },
    },
    {
      header: 'Nb. Besoins',
      enableGlobalFilter: false,
      accessorKey: 'nbrNeeds',
    },
    {
      id: 'actions',
      header: () => t('column.actions'),
      cell: ({ row }) => {
        const actions: Array<Action> = [
          {
            label: t('column.cancelRequest'),
            action: () => {
              logEvent('m.announcement.button.cancel.open');
              setAnnouncementToCancel(row.original);
            },
          },
        ];

        return <ActionDropdown actions={actions} id={`table-action-${row.original.uuid}`} />;
      },
    },
  ];

  let announcements: Array<AnnouncementShort> = [];

  if (data!.me!.Announcements!.edges) {
    announcements = data!.me!.Announcements!.edges.map((edge) => (edge?.node as AnnouncementShort));
  }

  const defaultColumnSort: ColumnSort = { id: 'date', desc: false };

  return (
    <>
      <Table
        eventNamePrefix={eventNamePrefix}
        pageSize={ARRAY_ITEMS_PER_PAGE}
        columns={utils.filterTableColumnsByRole(columns, getAccessLevel())}
        data={announcements}
        defaultColumnSort={defaultColumnSort}
        canBeFiltered
        title={t('missions.onlineAnnouncements')}
        hint={t('missions.onlineAnnouncementsHint')}
        exportOptions={[csvOptions, xlsxOptions]}
      />
      <Modal
        visible={announcementToCancel !== null}
        onClose={() => setAnnouncementToCancel(null)}
      >
        <CancelAnnouncement
          announcement={(announcementToCancel as AnnouncementToCancel)}
          onCancel={() => setAnnouncementToCancel(null)}
        />
      </Modal>
    </>
  );
};

export default OnlineAnnouncementsTable;
