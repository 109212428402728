import React from 'react';

import animatedLogo from '../assets/logo_no_border_animated.gif';

const IMG_SIZE = 150;

const LoaderFullPage = () => (
  <div className="vh-100 p-3 align-items-center justify-content-center d-flex bg-light">
    <img
      src={animatedLogo}
      width={IMG_SIZE}
      height={IMG_SIZE}
      alt="Medelse logo animation"
      role="status"
    />
  </div>
);

export default LoaderFullPage;
