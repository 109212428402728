import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import LayoutApp from '../app/components/LayoutApp';
import useAuth from './hooks/useAuth';
import UserGroupOfficesProvider from './UserGroupOfficesProvider';
import LoggerService from '../app/services/LoggerService';
import UserNameProvider from './UserNameProvider';

type Props = { children: JSX.Element };

const RequireAuth = ({ children }: Props) => {
  const auth = useAuth();
  const location = useLocation();

  const token = auth.getToken();

  React.useEffect(() => {
    if (token) {
      LoggerService.logNavigation(location.pathname, token);
    }
  }, [location, token]);

  if (!token) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  document.body.dataset.logged = 'true';

  return (
    <UserNameProvider>
      <UserGroupOfficesProvider>
        <LayoutApp>
          {children}
        </LayoutApp>
      </UserGroupOfficesProvider>
    </UserNameProvider>
  );
};

export default RequireAuth;
