import { configureStore } from '@reduxjs/toolkit';

import { groupOfficesReducer } from './reducers';

const store = configureStore({
  reducer: {
    groupOffices: groupOfficesReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
