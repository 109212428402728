import React from 'react';
import { useTranslation } from 'react-i18next';

import { useUpdateRecruiterPasswordMutation } from '../../../app/generated/graphql';
import CardTitle from '../../../app/components/CardTitle';
import QueryIndicator from '../../../app/components/QueryIndicator';
import TextField from '../../../app/components/TextField';
import FormActions from '../../../app/components/FormActions';
import AlertMessage from '../../../app/components/AlertMessage';

type Props = {
  onCancel: VoidFunction
  onMutationSuccess: VoidFunction
};

const ParametersPasswordForm = ({ onCancel, onMutationSuccess }: Props) => {
  const { t } = useTranslation();
  const [updateRecruiterPassword, { loading, error }] = useUpdateRecruiterPasswordMutation();

  const [oldPassword, setOldPassword] = React.useState<string>('');
  const [newPassword, setNewPassword] = React.useState<string>('');
  const [passwordConfirmation, setPasswordConfirmation] = React.useState<string>('');

  const handleOnSubmit = () => {
    updateRecruiterPassword({
      variables: { input: { oldPassword, newPassword, passwordConfirmation } },
      onCompleted() { onMutationSuccess(); },
      onError() {},
    });
  };

  return (
    <div>
      <CardTitle>{t('parametersPasswordForm.title')}</CardTitle>
      {loading ? (
        <QueryIndicator />
      ) : (
        <>
          <form>
            {error && <AlertMessage message={error.message} />}
            <div className="mb-4">
              <TextField
                type="password"
                label={t('parametersPasswordForm.oldPassword')}
                id="oldPassword"
                value={oldPassword}
                onChange={setOldPassword}
              />
            </div>
            <div className="mb-4">
              <TextField
                type="password"
                id="newPassword"
                label={t('parametersPasswordForm.newPassword')}
                onChange={setNewPassword}
                value={newPassword}
              />
            </div>
            <TextField
              type="password"
              id="passwordConfirmation"
              label={t('parametersPasswordForm.passwordConfirmation')}
              onChange={setPasswordConfirmation}
              value={passwordConfirmation}
            />
          </form>
          <FormActions
            onCancel={onCancel}
            onConfirm={handleOnSubmit}
            eventNamePrefix="m.settings.password.edit"
          />
        </>
      )}
    </div>
  );
};

export default ParametersPasswordForm;
