import { GroupOfficeOption } from '../../domain/models';

export enum ActionTypes {
  SELECT_GROUP_OFFICE = 'select_group_office',
  STORE_GROUP_OFFICES = 'store_group_office',
}

export const selectGroupOffice = (groupOfficeUuid: string) => ({
  type: ActionTypes.SELECT_GROUP_OFFICE,
  groupOfficeUuid,
});

export const storeGroupOffices = (groupOfficeOptions: GroupOfficeOption[]) => ({
  type: ActionTypes.STORE_GROUP_OFFICES,
  groupOfficeOptions,
});
