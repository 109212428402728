import React, { AriaRole, HTMLInputTypeAttribute, useState } from 'react';
import { useTranslation } from 'react-i18next';

type TextFieldProps = {
  autoComplete?: string
  label: string
  error?: string
  name: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  required?: boolean
  role?: AriaRole,
  type?: HTMLInputTypeAttribute | undefined,
  value?: string
  valid?: boolean
};

type Rule = 'uppercase' | 'length' | 'lowercase' | 'numberOrSpecial';

const TextField = ({
  autoComplete, label, error, name, onChange, required, role, type, value, valid,
} : TextFieldProps) => {
  const classeNames = ['form-control'];

  if (valid !== undefined) {
    classeNames.push(valid ? 'is-valid' : 'is-invalid');
  }
  return (
    <div className="input-group has-validation">
      <label htmlFor="name" className="form-label w-100">
        {label}
        <input
          className={classeNames.join(' ')}
          autoComplete={autoComplete}
          id={name}
          name={name}
          aria-label={name}
          type={type}
          value={value}
          onChange={onChange}
          required={required}
          role={role}
        />
        <div className="invalid-feedback">{error}</div>
      </label>
    </div>
  );
};

TextField.defaultProps = {
  autoComplete: undefined,
  error: undefined,
  required: false,
  role: 'textbox',
  type: 'text',
  value: undefined,
  valid: undefined,
};

const GeneratePasswordForm = () => {
  const [hasValidation, setHasValidation] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [passwordConfirm, setPasswordConfirm] = useState<string>('');
  const [validatedRules, setValidatedRules] = useState<Rule[]>([]);
  const { t } = useTranslation();

  const passwordValid = validatedRules.length === 4;
  const confirmValid = passwordConfirm.length > 0 && passwordConfirm === password;

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const trimmedValue = event.target.value.trim();

    const updatedRules: Rule[] = [];

    if (/(?=.*[A-Z])/.test(trimmedValue)) {
      updatedRules.push('uppercase');
    }

    if (/(?=.*[a-z])/.test(trimmedValue)) {
      updatedRules.push('lowercase');
    }

    if (/(?=.*\d)/.test(trimmedValue) || /(?=.*[-+_!@#$%^&*.,?])/.test(trimmedValue)) {
      updatedRules.push('numberOrSpecial');
    }

    if (trimmedValue.length >= 8) {
      updatedRules.push('length');
    }

    setValidatedRules(updatedRules);
    setPassword(trimmedValue);
    if (!hasValidation) {
      setHasValidation(true);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  return (
    <form
      onSubmit={handleSubmit}
      aria-label="generatePassword"
      noValidate
    >
      <TextField
        autoComplete="off"
        label={t('generatePassword.password')}
        name="password"
        onChange={handleOnChange}
        type="password"
        required
        value={password}
        valid={hasValidation ? passwordValid : undefined}
      />
      <div>Le mot de passe doit respecter les conditions suivantes :</div>
      <ul>
        <li className={validatedRules.indexOf('length') === -1 ? 'text-danger' : 'text-success'}>{t('generatePassword.rules.length')}</li>
        <li className={validatedRules.indexOf('uppercase') === -1 ? 'text-danger' : 'text-success'}>{t('generatePassword.rules.uppercase')}</li>
        <li className={validatedRules.indexOf('lowercase') === -1 ? 'text-danger' : 'text-success'}>{t('generatePassword.rules.lowercase')}</li>
        <li className={validatedRules.indexOf('numberOrSpecial') === -1 ? 'text-danger' : 'text-success'}>{t('generatePassword.rules.numberOrSpecialChar')}</li>
      </ul>
      <TextField
        autoComplete="off"
        label={t('generatePassword.passwordConfirm')}
        name="passwordConfirm"
        onChange={(e) => setPasswordConfirm(e.target.value.trim())}
        type="password"
        valid={hasValidation ? confirmValid : undefined}
        value={passwordConfirm}
        error={hasValidation && !confirmValid ? t('generatePassword.rules.sameValue') : undefined}
        required
      />
      <div className="d-grid gap-2 mt-2">
        <button
          type="submit"
          disabled={!passwordValid || !confirmValid}
          className="btn btn-primary"
        >
          {t('generatePassword.submitButton')}
        </button>
      </div>
    </form>
  );
};

export default GeneratePasswordForm;
