/* eslint-disable react/jsx-props-no-spreading */
import React, { Dispatch, SetStateAction } from 'react';
import { dots } from '../../constants';
import { shadowMedium } from '../../DesignSystem/tokens';

const range = (start: number, end: number) => {
  const length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

type UsePaginationProps = {
  pageCount: number,
  pageIndex: number,
  siblingCount: number
};

const usePagination = ({
  pageCount,
  pageIndex,
  siblingCount = 1,
}: UsePaginationProps) => {
  const paginationRange: Array<string | number> = React.useMemo(() => {
    const totalPageNumbers = siblingCount + 2;

    if (totalPageNumbers >= pageCount) {
      return range(1, pageCount);
    }

    const leftSiblingIndex = Math.max(pageIndex - siblingCount, 1);
    const rightSiblingIndex = Math.min(pageIndex + siblingCount, pageCount);

    const shouldShowLeftDots = leftSiblingIndex > 1;
    const shouldShowRightDots = rightSiblingIndex < pageCount;

    const firstPageIndex = 1;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 5 * siblingCount;
      const leftRange = range(1, leftItemCount - 2);

      return [...leftRange, dots, pageCount];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 5 * siblingCount;
      const rightRange = range(
        pageCount - rightItemCount + 3,
        pageCount,
      );

      return [firstPageIndex, dots, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex);

      return [firstPageIndex, dots, ...middleRange, dots, pageCount];
    }
    return paginationRange;
  }, [pageCount, siblingCount, pageIndex]);
  return paginationRange;
};

export type TablePaginationProps = {
  pageCount: number
  setPageIndex: Dispatch<SetStateAction<number>>;
  pageIndex: number
  getNextPage: boolean
  getPreviousPage: boolean
  eventNamePrefix?: string
};

const TablePagination = (
  {
    eventNamePrefix,
    pageCount,
    setPageIndex,
    pageIndex,
    getNextPage,
    getPreviousPage,
  }: TablePaginationProps,
) => {
  const paginationRange = usePagination({ pageCount, pageIndex: pageIndex + 1, siblingCount: 1 });
  if (paginationRange.length < 2) {
    return null;
  }

  const conditionnalProps = eventNamePrefix ? (
    { 'data-tracking-event': `${eventNamePrefix}.table_p` }
  ) : {};

  return (
    <div style={{ boxShadow: shadowMedium }} className="btn-group">
      <button
        type="button"
        style={{ minWidth: 35 }}
        className="btn btn-outline-grey"
        onClick={() => setPageIndex(pageIndex - 1)}
        disabled={!getPreviousPage}
        {...conditionnalProps}
      >
        {'<'}
      </button>
      {pageCount >= 2 && paginationRange.map((page, index) => {
        const isCurrentPage = page === pageIndex + 1;
        const classes: string[] = ['btn', 'btn-outline-grey'];
        classes.push(isCurrentPage ? ('fw-bold active') : 'fw-normal');
        if (page === dots) {
          return (
            <button
              type="button"
              className={classes.join(' ')}
              style={{
                minWidth: 35, cursor: 'default', pointerEvents: 'none',
              }}
              key={`page-${index + 1}`}
              {...conditionnalProps}
            >
              &#8230;
            </button>
          );
        }
        return (
          <button
            type="button"
            key={`page-${index + 1}`}
            style={{ minWidth: 35 }}
            className={classes.join(' ')}
            onClick={() => { setPageIndex(Number(page) - 1); }}
            {...conditionnalProps}
          >
            {page}
          </button>
        );
      })}
      <button
        type="button"
        style={{ minWidth: 35 }}
        className="btn btn-outline-grey"
        onClick={() => setPageIndex(pageIndex + 1)}
        disabled={!getNextPage}
        {...conditionnalProps}
      >
        {'>'}
      </button>
    </div>
  );
};

TablePagination.defaultProps = {
  eventNamePrefix: undefined,
};

export default TablePagination;
