import { createContext } from 'react';

import { Credentials, Token } from './types';

export type AuthContextType = {
  autologFromRefresh: (refreshToken: string) => Promise<void>;
  getToken: () => Token;
  getAccessLevel: () => number;
  signin: (credentials: Credentials, rememberMe: boolean) => Promise<void>;
  signout: (callback: VoidFunction) => void;
  refreshToken: (callback: VoidFunction) => Promise<void>;
};

export default createContext<AuthContextType>(null!);
