import React from 'react';
import { useTranslation } from 'react-i18next';
import { addDays, addYears, format } from 'date-fns';

import Card from '../../app/components/Card';
import CardAnnouncements from './CardAnnouncements';
import ContractualizedMissions from './ContractualizedMissions';
import LateInvoices from './LateInvoice';
import TopTenUsers from './TopTenUsers';
import UpcomingMissions from '../../app/components/UpcomingMissions';
import { DateRangeInput } from '../../app/generated/graphql';
import MissionsCountInNextInvoice from './MissionsCountInNextInvoice';

type Props = {
  groupOfficesUuid: Array<string>
  onNavigate: (path: string) => void
};

type ClickableCardProps = {
  children: React.ReactNode
  onClick: VoidFunction
  trackingEvent: string
};

const ClickableCard = ({ children, onClick, trackingEvent }: ClickableCardProps) => (
  <button
    type="button"
    onClick={onClick}
    className="h-100 w-100 border-0 bg-transparent text-start p0"
    data-tracking-event={trackingEvent}
  >
    <Card h100>
      { children }
    </Card>
  </button>
);

const Dashboard = ({ groupOfficesUuid, onNavigate }: Props) => {
  const { t } = useTranslation();

  const today = new Date();
  const upcomingDates: DateRangeInput = {
    startDate: format(today, 'yyyy-MM-dd'),
    endDate: format(addDays(today, 6), 'yyyy-MM-dd'),
  };

  const contractualizedDates: DateRangeInput = {
    startDate: format(today, 'yyyy-MM-dd'),
    endDate: format(addYears(today, 5), 'yyyy-MM-dd'),
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-6 col-lg-3 mb-medium">
          <ClickableCard
            onClick={() => onNavigate('/missions')}
            trackingEvent="m.dash.cards.announcement.click"
          >
            <CardAnnouncements groupOfficesUuid={groupOfficesUuid} />
          </ClickableCard>
        </div>
        <div className="col-md-6 col-lg-3 mb-medium">
          <ClickableCard
            onClick={() => onNavigate('/missions')}
            trackingEvent="m.dash.cards.contractualized.click"
          >
            <ContractualizedMissions
              groupOfficesUuid={groupOfficesUuid}
              dates={contractualizedDates}
            />
          </ClickableCard>
        </div>
        <div className="col-md-6 col-lg-3 mb-medium">
          <ClickableCard
            onClick={() => onNavigate('/invoices')}
            trackingEvent="m.dash.cards.to_invoice.click"
          >
            <MissionsCountInNextInvoice groupOfficesUuid={groupOfficesUuid} />
          </ClickableCard>
        </div>
        <div className="col-md-6 col-lg-3 mb-medium">
          <ClickableCard
            onClick={() => onNavigate('/invoices')}
            trackingEvent="m.dash.cards.late_invoice.click"
          >
            <LateInvoices groupOfficesUuid={groupOfficesUuid} />
          </ClickableCard>
        </div>
      </div>
      <div className="row mb-medium">
        <div className="col">
          <Card>
            <UpcomingMissions
              eventNamePrefix="m.dash.upcoming_missions"
              groupOfficesUuid={groupOfficesUuid}
              dates={upcomingDates}
              title={t('dashboard.upcomingMissions')}
              hint={t('dashboard.upcomingMissionsHint')}
            />
          </Card>
        </div>
      </div>
      <div className="row mb-medium">
        {groupOfficesUuid.length !== 1 ? (
          <div>{t('dashboard.selectOfficePlaceholder')}</div>
        ) : (
          <div className="col-sm-6">
            <Card h100>
              <TopTenUsers groupOfficesUuid={groupOfficesUuid} />
            </Card>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
