import React, { CSSProperties } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import * as tokens from '../../DesignSystem/tokens';

type Option = {
  value: string,
  label: string,
};

type Props = {
  title: string,
  options: Option[],
  variant: 'white' | 'transparent' | 'silver',
  onChange: (val: string) => void
  value?: string | undefined,
  eventName?: string | undefined
  hideTitle?: boolean
};

const Select = ({
  title, options, variant, onChange, value, eventName, hideTitle,
}: Props) => {
  let bgClr: string;

  switch (variant) {
    case 'white':
      bgClr = tokens.colorWhite;
      break;
    case 'transparent':
      bgClr = 'transparent';
      break;
    case 'silver':
      bgClr = tokens.colorSilverDark;
      break;
    default:
      bgClr = tokens.colorWhite;
      break;
  }

  const SelectStyles: CSSProperties = {
    ...tokens.textFieldBase,
    backgroundColor: bgClr,
    border: 'none',
    borderRadius: '5px',
    fontStyle: 'italic',
    width: '100%',
    zIndex: 1,
    appearance: 'none',
    MozAppearance: 'none',
    WebkitAppearance: 'none',
    padding: '0.5rem 1rem',
    boxShadow: '0 7px 14px 0 rgb(65 69 88 / 10%), 0 3px 6px 0 rgb(0 0 0 / 7%)',
  };

  const IconStyles: CSSProperties = {
    position: 'absolute',
    bottom: 0,
    top: '25%',
    right: '10px',
    zIndex: 2,
    pointerEvents: 'none',
  };

  const SelectContainer: CSSProperties = {
    width: '100%',
    position: 'relative',
  };

  const trackAttributes = {
    'data-tracking-event': eventName,
  };

  return (
    <div
      role="button"
      style={SelectContainer}
    >
      <select
        value={value}
        style={SelectStyles}
        data-testid="select-component"
        onChange={(e) => onChange(e.target.value)}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...eventName ? trackAttributes : {}}
      >
        {hideTitle === false ? (
          <option value="">{title}</option>
        ) : null}
        {options.map((props:any) => (<option key={`option-${props.value}`} value={props.value}>{props.label}</option>))}
      </select>
      <FontAwesomeIcon style={IconStyles} icon={faChevronDown} />
    </div>
  );
};

Select.defaultProps = {
  value: undefined,
  eventName: undefined,
  hideTitle: false,
};

export default Select;
