import React from 'react';
import { useTranslation } from 'react-i18next';

import CardTitle from '../../../app/components/CardTitle';
import FormActions from '../../../app/components/FormActions';
import PhoneNumberInput from '../../../app/components/PhoneNumberInput';
import { PhoneObject } from '../../../app/components/PhoneNumberInput/PhoneNumberInput';
import QueryIndicator from '../../../app/components/QueryIndicator';
import TextField from '../../../app/components/TextField';
import * as tokens from '../../../app/DesignSystem/tokens';
import { GenderEnum, GetParametersQuery, useUpdateRecruiterProfileMutation } from '../../../app/generated/graphql';
import AlertMessage from '../../../app/components/AlertMessage/AlertMessage';

type Props = {
  recruiterParameters: GetParametersQuery['me']
  onCancel: VoidFunction
  onMutationSuccess: VoidFunction
};

const ParametersProfileForm = ({
  recruiterParameters,
  onCancel,
  onMutationSuccess,
}: Props) => {
  const { t } = useTranslation();
  const options = Object.values(GenderEnum).map((e) => ({ label: t(`gender.${e}`), value: e }));
  const [updateRecruiterProfile, { loading, error }] = useUpdateRecruiterProfileMutation();
  const [gender, setGender] = React.useState<GenderEnum>();
  const [lastname, setLastname] = React.useState<string>('');
  const [firstname, setFirstname] = React.useState<string>('');
  const [phone, setPhone] = React.useState<PhoneObject>({ code: 33, number: '' });

  React.useEffect(() => {
    setGender(recruiterParameters!.profile.gender);
    setLastname(recruiterParameters?.profile.lastName || '');
    setFirstname(recruiterParameters?.profile.firstName || '');
    const currentPhone: PhoneObject = {
      code: recruiterParameters?.profile.phone.code
        ? recruiterParameters.profile.phone.code
        : 33,
      number: recruiterParameters?.profile.phone.number
        ? recruiterParameters.profile.phone.number
        : '',
    };
    setPhone(currentPhone);
  }, [recruiterParameters]);

  const handleOnSubmit = () => {
    if (gender) {
      updateRecruiterProfile({
        variables: {
          input: {
            gender,
            lastName: lastname,
            firstName: firstname,
            phone: {
              code: phone.code,
              number: phone.number,
            },
          },
        },
        onCompleted() { onMutationSuccess(); },
        onError() {},
      });
    }
  };

  return (
    <div>
      <CardTitle>{t('parametersProfileForm.title')}</CardTitle>
      {loading ? (
        <QueryIndicator />
      ) : (
        <>
          <form>
            {error && (
              <AlertMessage message={error.message} />
            )}
            <div className="mb-4">
              <label htmlFor="field-gender" style={tokens.textFieldLabel}>
                {t('parametersProfileForm.gender')}
                {/* TODO:s Fix Select component */}
                <select
                  id="field-gender"
                  className="bg-white"
                  style={{ ...tokens.textFieldBase }}
                  value={gender}
                  onChange={(e) => setGender((e.target.value as GenderEnum))}
                >
                  {options.map((option) => (<option key={`option-${option.value}`} value={option.value}>{option.label}</option>))}
                </select>
              </label>
            </div>
            <div className="mb-4">
              <TextField
                id="lastname"
                value={lastname}
                onChange={setLastname}
                label={t('parametersProfileForm.lastname')}
              />
            </div>
            <div className="mb-4">
              <TextField
                id="firstname"
                value={firstname}
                onChange={setFirstname}
                label={t('parametersProfileForm.firstname')}
              />
            </div>
            <PhoneNumberInput label={t('parametersProfileForm.phone')} phoneValue={phone} onChange={setPhone} />
          </form>
          <FormActions
            onCancel={onCancel}
            onConfirm={handleOnSubmit}
            eventNamePrefix="m.settings.personal_info.edit"
          />
        </>
      )}
    </div>
  );
};

export default ParametersProfileForm;
