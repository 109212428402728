import React from 'react';
import { useTranslation } from 'react-i18next';

import TextWithTooltip from '../TextWithTooltip';

type Props = { name: string, uuid: string };

const TableCellSpecialty = ({ name, uuid }: Props) => {
  const { t } = useTranslation();
  const translatedName = t(`database_fields.${name}`);
  const translatedNameShort = t(`database_fields.acronym.${name}`);

  return (
    <div className="d-flex justify-content-center">
      <TextWithTooltip
        text={translatedNameShort}
        tooltipContent={translatedName}
        tooltipId={`${uuid}-specialy-tooltip`}
      />
    </div>
  );
};

export default TableCellSpecialty;
