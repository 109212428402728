/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { ColumnDef, ColumnSort } from '@tanstack/react-table';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import jwtDecode from 'jwt-decode';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { Contract, useGetContractSigningLinkLazyQuery } from '../../app/generated/graphql';
import { ARRAY_ITEMS_PER_PAGE } from '../../app/constants';
import { Action } from '../../app/components/ActionDropdown/ActionDropdown';
import utils from '../../app/domain/utils';
import Table from '../../app/components/Table';
import ActionDropdown from '../../app/components/ActionDropdown';
import Card from '../../app/components/Card';
import TableCellUser from '../../app/components/TableCellUser';
import useAuth from '../../auth/hooks/useAuth';
import Button from '../../app/components/Button';
import TableCellSpecialty from '../../app/components/TableCellSpecialty';
import useTracking from '../../app/hooks/useTracking';
import TableCellDatesPopover from '../../app/components/TableCellDatesPopover';

const SIGN_BTN_WIDTH = 120;

type Props = {
  eventNamePrefix: string
  contracts: Array<Contract>
  setRowStyles?: (row: any) => React.CSSProperties
  title: string
  hint: string
  exportOptions: { label:string, action: any, labelIcon: IconProp }[]
};

/**
 * This Table is used in both <ContractsSignedTable /> and <ContractsToSignTable />
 */

const ContractsTable = ({
  contracts, setRowStyles, title, hint, exportOptions, eventNamePrefix,
}: Props) => {
  const { getToken } = useAuth();
  const { logEvent } = useTracking();
  const token = getToken();
  const [getSigningLink] = useGetContractSigningLinkLazyQuery({ fetchPolicy: 'network-only' });
  const { t } = useTranslation();
  const auth = useAuth();
  const tokenData: any = jwtDecode(auth.getToken()!);

  const columns: ColumnDef<Contract>[] = [
    {
      accessorKey: 'contractNumber',
      header: () => t('column.contractNumber'),
    },
    {
      id: 'candidate_name',
      header: () => t('column.candidate'),
      accessorFn: (row) => `${row.candidate?.profile.lastName} ${row.candidate?.profile.firstName}`,
      cell: ({ row }) => <TableCellUser profile={row.original.candidate?.profile} />,
    },
    {
      id: 'specialty',
      accessorFn: (row) => row.specialty?.name,
      header: () => t('column.specialties'),
      cell: ({ row }) => {
        const { name } = row.original.specialty!;

        return <TableCellSpecialty name={name} uuid={row.original.uuid} />;
      },
    },
    {
      id: 'dates',
      header: () => t('column.contractDates'),
      accessorFn: (row) => row.contractDetails!.map(
        (cd) => cd?.dateTimes.startDate,
      ),
      cell: (props) => {
        const dates = props.getValue<Array<string>>();

        return (
          <TableCellDatesPopover
            dates={dates}
            rowUuid={props.row.original.uuid}
          />
        );
      },
    },
    {
      id: 'contract-status',
      header: () => t('column.status'),
      enableGlobalFilter: false,
      accessorFn: (row) => t(`enum.medelse_core_bundle_entity_enum_contract_status_${row.packageStatus?.toLowerCase()}`),
    },
    {
      id: 'signingLink',
      header: () => t('column.sign'),
      cell: (props) => {
        if (props.row.original.recruiterSignedAt) {
          return `Signé le ${utils.formatDate(props.row.original.recruiterSignedAt)}`;
        }

        if (tokenData.username && (
          (tokenData.username as string) === props.row.original.recruiterEmail)
        ) {
          return (
            <Button
              trackingEvent="m.contract.button.sign.click"
              variant="primaryDark"
              title={t('actions.sign')}
              minWidth={SIGN_BTN_WIDTH}
              onClick={
                () => getSigningLink({ variables: { contractUuid: props.row.original.uuid } })
                  .then(({ data }) => {
                    if (data && data.Contract?.recruiterSigningLink && token) {
                      utils.openStreamLink(data.Contract.recruiterSigningLink, token);
                    }
                  })
              }
            />
          );
        }

        const renderTooltip = (prps: any) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Tooltip id={`tooltip-recruiter-${props.row.original.uuid}`} {...prps}>
            {`${props.row.original.recruiterEmail} ${t('contracts.canSign')}`}
          </Tooltip>
        );

        return (
          <OverlayTrigger
            rootClose
            placement="left"
            delay={{ show: 200, hide: 200 }}
            overlay={renderTooltip}
          >
            <div>
              <Button
                disabled
                title={t('actions.sign')}
                onClick={() => {}}
                minWidth={SIGN_BTN_WIDTH}
              />
            </div>
          </OverlayTrigger>
        );
      },
    },
    {
      id: 'actions',
      header: () => t('column.actions'),
      cell: ({ row }) => {
        const actions: Array<Action> = [{
          label: 'Télécharger',
          action: () => {
            logEvent(`${eventNamePrefix}.download`);
            if (row.original.documentDownloadLink && token) {
              utils.openStreamLink(row.original.documentDownloadLink, token);
            }
          },
        }];

        return <ActionDropdown actions={actions} id={`table-action-${row.original.uuid}`} />;
      },
    },
  ];

  const defaultColumnSort: ColumnSort = { id: 'contractNumber', desc: true };

  return (
    <Card>
      <Table
        eventNamePrefix={eventNamePrefix}
        pageSize={ARRAY_ITEMS_PER_PAGE}
        columns={columns}
        data={contracts}
        defaultColumnSort={defaultColumnSort}
        canBeFiltered
        setRowStyles={setRowStyles}
        title={title}
        hint={hint}
        exportOptions={exportOptions}
      />
    </Card>
  );
};

ContractsTable.defaultProps = {
  setRowStyles: () => ({}),
};

export default ContractsTable;
