import React from 'react';

type Props = { children: string };

const CardTitle = ({ children }: Props) => (
  <h3 className="mb-4 pb-4 border-bottom border-primary">
    {children}
  </h3>
);

export default CardTitle;
