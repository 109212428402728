import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  Time: any;
  UploadType: any;
  Uuid: any;
};

/** An administrator entity */
export type Administrator = {
  __typename?: 'Administrator';
  /** The administrator agenda link. */
  agendaLink?: Maybe<Scalars['String']>;
  /** The administrator email. */
  email: Scalars['String'];
  /** The administrator first name. */
  firstName?: Maybe<Scalars['String']>;
  /** The administrator id. */
  id: Scalars['ID'];
  /** The image file. */
  imageFile?: Maybe<ImageFile>;
  /** The administrator job title */
  jobTitle?: Maybe<AdministratorJobTitleEnum>;
  /** The administrator last name. */
  lastName?: Maybe<Scalars['String']>;
  /** The administrator phone number and code. */
  phoneNumber?: Maybe<Scalars['String']>;
};

/** The administrator job title types enum. */
export enum AdministratorJobTitleEnum {
  /** Medelse counsellor */
  Adviser = 'ADVISER',
  /** Development manager */
  DevelopmentManager = 'DEVELOPMENT_MANAGER',
  /** Director of development */
  DirectorOfDevelopment = 'DIRECTOR_OF_DEVELOPMENT',
  /** Client Executive */
  ExecutiveCustomer = 'EXECUTIVE_CUSTOMER',
  /** Hiring Manager */
  HiringManager = 'HIRING_MANAGER',
  /** CTO - Associate */
  Job_9 = 'JOB_9',
  /** COO  - Cofounder */
  Job_10 = 'JOB_10',
  /** CEO - Cofounder */
  Job_11 = 'JOB_11',
  /** Business Developer */
  Job_12 = 'JOB_12',
  /** Conseillère en recrutement / Customer Success */
  Job_13 = 'JOB_13',
  /** Conseiller en recrutement / Customer Success */
  Job_14 = 'JOB_14',
  /** Product Owner */
  Job_15 = 'JOB_15',
  /** Job Dreamer */
  JobDreamer = 'JOB_DREAMER',
  /** Key Accounts Manager */
  KeyAccountManager = 'KEY_ACCOUNT_MANAGER',
  /** Medelse super hero */
  SuperHero = 'SUPER_HERO'
}

/** The advertisement status enum. */
export enum AdvertisementStatusEnum {
  /** The advertisement is deleted. */
  Deleted = 'DELETED',
  /** The advertisement is expired. */
  Expired = 'EXPIRED',
  /** The advertisement is pending : customer success must validate to be forecast. */
  Pending = 'PENDING',
  /** The advertisement is published. */
  Published = 'PUBLISHED',
  /** The advertisement is sub-accepted. */
  SubAccepted = 'SUB_ACCEPTED'
}

/** The amount object. */
export type Amount = {
  __typename?: 'Amount';
  /** The amount max. */
  max?: Maybe<Scalars['Int']>;
  /** The amount min. */
  min?: Maybe<Scalars['Int']>;
};

/** Announcement abstract entity */
export type Announcement = {
  /**
   * The working time AM end hour .
   * @deprecated Use dateTimeEnd instead
   */
  amEndHour?: Maybe<Scalars['String']>;
  /**
   * The working time AM start hour.
   * @deprecated Use dateTimeStart instead
   */
  amStartHour?: Maybe<Scalars['String']>;
  /**
   * The end date.
   * @deprecated Use dateTimeEnd instead
   */
  dateEnd?: Maybe<Scalars['Date']>;
  /**
   * The start date.
   * @deprecated Use dateTimeStart instead
   */
  dateStart?: Maybe<Scalars['Date']>;
  /** The announcement end date (with hours). */
  dateTimeEnd?: Maybe<Scalars['DateTime']>;
  /** The announcement start date (with hours). */
  dateTimeStart?: Maybe<Scalars['DateTime']>;
  /** The announcement datetimes. */
  dates: DateTimeRange;
  /** The announcement deletion date. */
  deletedAt?: Maybe<Scalars['Date']>;
  /** The announcement description. */
  description?: Maybe<Scalars['String']>;
  /** The estimated patient number. */
  estimatedPatient?: Maybe<Scalars['Int']>;
  /** The announcement expiration date. */
  expiredAt?: Maybe<Scalars['Date']>;
  /** The announcement group office. */
  groupOffice: GroupOffice;
  /** The announcement id. */
  id: Scalars['ID'];
  /** The medelse Fees. */
  medelseFees?: Maybe<Scalars['Float']>;
  /**
   * The working time PM end hour.
   * @deprecated Use dateTimeEnd instead
   */
  pmEndHour?: Maybe<Scalars['String']>;
  /**
   * The working time PM start hour.
   * @deprecated Use dateTimeStart instead
   */
  pmStartHour?: Maybe<Scalars['String']>;
  /** The announcement replacement type. */
  replacementType: ReplacementType;
  /** The announcement rhythm. */
  rhythm?: Maybe<RhythmEnum>;
  salaries: Array<Maybe<Salary>>;
  /** The announcement salary. */
  salary: Scalars['Float'];
  /** The announcement service. */
  service?: Maybe<Service>;
  /**
   * The announcement skills.
   * @deprecated Skills is deprecated
   */
  skills: Array<Maybe<Skill>>;
  /** The announcement specialty. */
  specialty: Specialty;
  /** The announcement specific services. */
  specificServices: Array<Maybe<SpecificService>>;
  /** The announcement status. */
  status: AdvertisementStatusEnum;
  /** The recruiter user. */
  user: Recruiter;
  /** The announcement uuid. */
  uuid: Scalars['Uuid'];
  /** The end date. */
  workingTime?: Maybe<WorkingTime>;
};

/** The announcement long representation. */
export type AnnouncementLong = Announcement & {
  __typename?: 'AnnouncementLong';
  /**
   * The working time AM end hour .
   * @deprecated Use dateTimeEnd instead
   */
  amEndHour?: Maybe<Scalars['String']>;
  /**
   * The working time AM start hour.
   * @deprecated Use dateTimeStart instead
   */
  amStartHour?: Maybe<Scalars['String']>;
  /**
   * The end date.
   * @deprecated Use dateTimeEnd instead
   */
  dateEnd?: Maybe<Scalars['Date']>;
  /**
   * The start date.
   * @deprecated Use dateTimeStart instead
   */
  dateStart?: Maybe<Scalars['Date']>;
  /** The announcement end date (with hours). */
  dateTimeEnd?: Maybe<Scalars['DateTime']>;
  /** The announcement start date (with hours). */
  dateTimeStart?: Maybe<Scalars['DateTime']>;
  /** The announcement datetimes. */
  dates: DateTimeRange;
  /** The announcement deletion date. */
  deletedAt?: Maybe<Scalars['Date']>;
  /** The announcement description. */
  description?: Maybe<Scalars['String']>;
  /** The estimated patient number. */
  estimatedPatient?: Maybe<Scalars['Int']>;
  /** The announcement expiration date. */
  expiredAt?: Maybe<Scalars['Date']>;
  /** The announcement group office. */
  groupOffice: GroupOffice;
  /** The announcement id. */
  id: Scalars['ID'];
  /** The medelse Fees. */
  medelseFees?: Maybe<Scalars['Float']>;
  /**
   * The working time PM end hour.
   * @deprecated Use dateTimeEnd instead
   */
  pmEndHour?: Maybe<Scalars['String']>;
  /**
   * The working time PM start hour.
   * @deprecated Use dateTimeStart instead
   */
  pmStartHour?: Maybe<Scalars['String']>;
  /** The announcement replacement type. */
  replacementType: ReplacementType;
  /** The announcement rhythm. */
  rhythm?: Maybe<RhythmEnum>;
  salaries: Array<Maybe<Salary>>;
  /** The announcement salary. */
  salary: Scalars['Float'];
  /** The announcement service. */
  service?: Maybe<Service>;
  /**
   * The announcement skills.
   * @deprecated Skills is deprecated
   */
  skills: Array<Maybe<Skill>>;
  /** The announcement specialty. */
  specialty: Specialty;
  /** The announcement specific services. */
  specificServices: Array<Maybe<SpecificService>>;
  /** The announcement status. */
  status: AdvertisementStatusEnum;
  /** The recruiter user. */
  user: Recruiter;
  /** The announcement uuid. */
  uuid: Scalars['Uuid'];
  /** The end date. */
  workingTime?: Maybe<WorkingTime>;
};

/** The announcement short representation. */
export type AnnouncementShort = Announcement & {
  __typename?: 'AnnouncementShort';
  /**
   * The working time AM end hour .
   * @deprecated Use dateTimeEnd instead
   */
  amEndHour?: Maybe<Scalars['String']>;
  /**
   * The working time AM start hour.
   * @deprecated Use dateTimeStart instead
   */
  amStartHour?: Maybe<Scalars['String']>;
  /** The mission break time. */
  break?: Maybe<Scalars['Int']>;
  /**
   * The end date.
   * @deprecated Use dateTimeEnd instead
   */
  dateEnd?: Maybe<Scalars['Date']>;
  /**
   * The start date.
   * @deprecated Use dateTimeStart instead
   */
  dateStart?: Maybe<Scalars['Date']>;
  /** The announcement end date (with hours). */
  dateTimeEnd?: Maybe<Scalars['DateTime']>;
  /** The announcement start date (with hours). */
  dateTimeStart?: Maybe<Scalars['DateTime']>;
  /** The announcement datetimes. */
  dates: DateTimeRange;
  /** The announcement deletion date. */
  deletedAt?: Maybe<Scalars['Date']>;
  /** The announcement description. */
  description?: Maybe<Scalars['String']>;
  /** The employee housing */
  employeeHousing?: Maybe<Scalars['String']>;
  /** Supports meals fee */
  employeeMeals?: Maybe<Scalars['String']>;
  /** Is the transport reimbursed ? */
  employeeTransport?: Maybe<Scalars['String']>;
  /** The estimated patient number. */
  estimatedPatient?: Maybe<Scalars['Int']>;
  /** The announcement expiration date. */
  expiredAt?: Maybe<Scalars['Date']>;
  /** The announcement group office. */
  groupOffice: GroupOffice;
  /** The announcement id. */
  id: Scalars['ID'];
  /** The medelse Fees. */
  medelseFees?: Maybe<Scalars['Float']>;
  /** Nbr needs */
  nbrNeeds?: Maybe<Scalars['Int']>;
  /**
   * The working time PM end hour.
   * @deprecated Use dateTimeEnd instead
   */
  pmEndHour?: Maybe<Scalars['String']>;
  /**
   * The working time PM start hour.
   * @deprecated Use dateTimeStart instead
   */
  pmStartHour?: Maybe<Scalars['String']>;
  /** The announcement replacement type. */
  replacementType: ReplacementType;
  /** The announcement rhythm. */
  rhythm?: Maybe<RhythmEnum>;
  salaries: Array<Maybe<Salary>>;
  /** The announcement salary. */
  salary: Scalars['Float'];
  /** The mission sector. */
  sector?: Maybe<Scalars['String']>;
  /** The announcement service. */
  service?: Maybe<Service>;
  /**
   * The announcement skills.
   * @deprecated Skills is deprecated
   */
  skills: Array<Maybe<Skill>>;
  /** The announcement specialty. */
  specialty: Specialty;
  /** The announcement specific services. */
  specificServices: Array<Maybe<SpecificService>>;
  /** The announcement status. */
  status: AdvertisementStatusEnum;
  /** The mission time slot. */
  timeSlot?: Maybe<Scalars['Int']>;
  /** The recruiter user. */
  user: Recruiter;
  /** The announcement uuid. */
  uuid: Scalars['Uuid'];
  /** The end date. */
  workingTime?: Maybe<WorkingTime>;
};

/** A connection to a list of items. */
export type AnnouncementShortConnection = {
  __typename?: 'AnnouncementShortConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<AnnouncementShortEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** How to export data. */
  spreadSheet?: Maybe<SpreadSheetFile>;
  /** The amount of announcements. */
  totalCount?: Maybe<Scalars['Int']>;
};


/** A connection to a list of items. */
export type AnnouncementShortConnectionSpreadSheetArgs = {
  dates?: InputMaybe<DateRangeInput>;
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  replacementType?: InputMaybe<ReplacementTypeEnum>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
  status?: InputMaybe<Array<AdvertisementStatusEnum>>;
};

/** An edge in a connection. */
export type AnnouncementShortEdge = {
  __typename?: 'AnnouncementShortEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<AnnouncementShort>;
};

/** The availability establishment types enum. */
export enum AvailabilityEstablishmentTypeEnum {
  /** Clinical / Private hospital */
  Clinical = 'CLINICAL',
  /** EHPAD / Nursing Home. */
  EhpadNursing = 'EHPAD_NURSING',
  /** Hospitalization at home. */
  Had = 'HAD',
  /** Health Center. */
  HealthCenter = 'HEALTH_CENTER',
  /** Heal House. */
  HealthHouse = 'HEALTH_HOUSE',
  /** Home consultation service */
  HomeConsultationService = 'HOME_CONSULTATION_SERVICE',
  /** Laboratory. */
  Labo = 'LABO',
  /** Group / Individual Liberal Cabinet. */
  LiberalOffice = 'LIBERAL_OFFICE',
  /** Other social medicine */
  OtherMs = 'OTHER_MS',
  /** Private company. */
  PrivComp = 'PRIV_COMP',
  /** Public hospital. */
  PublicHospital = 'PUBLIC_HOSPITAL',
  /** Aftercare and Rehabilitation Care. */
  Ssr = 'SSR',
  /** Teleconsultation service */
  Teleconsutlation = 'TELECONSUTLATION'
}

export type CancelAnnouncementInput = {
  /** The announcement uuid to cancel. */
  announcementUuid: Scalars['Uuid'];
  /** The mail content. */
  content: Scalars['String'];
};

/** The announcement to cancel mutation payload. */
export type CancelAnnouncementPayload = {
  __typename?: 'CancelAnnouncementPayload';
  /** The groupOffice. */
  groupOffice: GroupOffice;
};

/** A candidate. */
export type Candidate = NodeInterface & {
  __typename?: 'Candidate';
  /** The candidate account status. */
  accountStatus?: Maybe<CandidateAccountStatusEnum>;
  /** The user candidate agent */
  administrator?: Maybe<Administrator>;
  /** Link to Medelse advantage (CE) */
  advantageLink?: Maybe<Scalars['String']>;
  /** The paginated user contract. */
  contracts?: Maybe<ContractConnection>;
  /** GroupOffices that has been contractualized. */
  contractualizedGroupOffices?: Maybe<Array<GroupOffice>>;
  /** The user create date. */
  createAt: Scalars['DateTime'];
  /** Set when cv has been updated. */
  cvUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** Set when the user has delete his account. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Is the user disabled */
  disabled: Scalars['Boolean'];
  /** The user documents. */
  documents: Array<Maybe<UserDocument>>;
  /** The user establishmentTypes. */
  establishmentTypes: Array<Maybe<EstablishmentTypeEnum>>;
  /** Set when user has status. */
  files: UserFiles;
  /** The node id. A base 64 encode of 'User:$id'. */
  globalId: Scalars['ID'];
  /** True if user has all criteria filled, false otherwise. */
  hasAllCriteriaFilled: Scalars['Boolean'];
  /** True if user has future planning date active, false otherwise. */
  hasFuturePlanningDateActive: Scalars['Boolean'];
  /** True if user profile is filled, false otherwise. */
  hasProfileFilled: Scalars['Boolean'];
  /** Does the candidate need to rate the mobile application */
  hasToRateTheApp: Scalars['Boolean'];
  /** One obfuscated IBAN */
  iban?: Maybe<Scalars['String']>;
  /** Datetime when iban was updated */
  ibanUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** The user id. */
  id: Scalars['ID'];
  /** Set when image has been updated. */
  imageUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** GroupOffices that has been invoiced. */
  invoicedGroupOffices?: Maybe<Array<GroupOffice>>;
  /** The paginated user invoice. */
  invoices?: Maybe<InvoiceConnection>;
  /** Does the user handle CDD missions */
  isCDD: Scalars['Boolean'];
  /** The last visit date. */
  lastVisit?: Maybe<Scalars['DateTime']>;
  /** The user locations. */
  locations: Array<Maybe<CandidateLocation>>;
  /** GroupOffice that match missions filtered by macroStatus. */
  matchingGroupOffices?: Maybe<Array<GroupOffice>>;
  /** GroupOffices that have matching feesInvoices */
  medelseFeesInvoicedGroupOffices?: Maybe<Array<GroupOffice>>;
  /** The paginated user medelse invoice. */
  medelseFeesInvoices?: Maybe<MedelseFeesInvoiceConnection>;
  /** The paginated user mission. */
  missions?: Maybe<MissionConnection>;
  /**
   * The mission filters list.
   * @deprecated Use matchingGroupOffices instead
   */
  missionsFilters?: Maybe<MissionFilter>;
  /** NIC front & back */
  nic?: Maybe<Array<Maybe<File>>>;
  /** The user planning days for the given period. */
  planningDays: Array<Maybe<PlanningDay>>;
  /** The user candidate information. */
  proInformation: ProInformation;
  /** The user profile object. */
  profile: UserProfile;
  /** Candidate progression information */
  progression: CandidateProgression;
  /** The referral code. */
  referralCode?: Maybe<Scalars['String']>;
  /** The current rating of fulfilled missions compared to contractualized cancellations on a scale of 10 */
  reliabilityScore: Scalars['Float'];
  /** A valid rpps number. */
  rppsNumber?: Maybe<Scalars['String']>;
  /** The user salary expected. */
  salary?: Maybe<Scalars['Int']>;
  /** The user search criteria */
  searchCriterias: SearchCriteria;
  /** The user services. */
  services: Array<Maybe<Service>>;
  /** A valid siret number. */
  siret?: Maybe<Scalars['String']>;
  /** The user skills. */
  skills?: Maybe<Array<Skill>>;
  /** The user specialties. */
  specialties: Array<Specialty>;
  /** The user statistics */
  statistics?: Maybe<Array<Maybe<UserStatisticPeriod>>>;
  /** Set when user has status. */
  status?: Maybe<UserStatusEnum>;
  /** The candidate newsletter subscription. */
  subscription: Scalars['Boolean'];
  /** Candidate suspension information */
  suspension?: Maybe<Suspension>;
  /** The user tags */
  tags: Array<CandidateTag>;
  /** The user uuid. */
  uuid: Scalars['Uuid'];
  /** The user email validation date. */
  validatedAt?: Maybe<Scalars['DateTime']>;
};


/** A candidate. */
export type CandidateContractsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  candidateSigningStatus?: InputMaybe<SigningStatus>;
  createdAt?: InputMaybe<DateRangeInput>;
  dates?: InputMaybe<DateRangeInput>;
  first?: InputMaybe<Scalars['Int']>;
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ContractOrderFieldInput>>;
  packageStatus?: InputMaybe<Array<PackageStatus>>;
  recruiterSigningStatus?: InputMaybe<SigningStatus>;
  replacementType?: InputMaybe<ReplacementTypeEnum>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
};


/** A candidate. */
export type CandidateInvoicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateRangeInput>;
  dates?: InputMaybe<DateRangeInput>;
  first?: InputMaybe<Scalars['Int']>;
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InvoiceOrderFieldInput>>;
  paymentStatus?: InputMaybe<Array<InputMaybe<PaymentStatus>>>;
  replacementType?: InputMaybe<ReplacementTypeEnum>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
};


/** A candidate. */
export type CandidateMatchingGroupOfficesArgs = {
  macroStatus?: InputMaybe<Array<MissionMacroStatusEnum>>;
};


/** A candidate. */
export type CandidateMedelseFeesInvoicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateRangeInput>;
  dates?: InputMaybe<DateRangeInput>;
  first?: InputMaybe<Scalars['Int']>;
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<MedelseFeesInvoiceOrderFieldInput>>;
};


/** A candidate. */
export type CandidateMissionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  cancellationInitiator?: InputMaybe<Array<InputMaybe<MissionInitiatorEnum>>>;
  candidateSigningStatus?: InputMaybe<SigningStatus>;
  contractDetailCandidateValidationStatus?: InputMaybe<Array<InputMaybe<ValidationStatusEnum>>>;
  contractDetailInvoiceStatus?: InputMaybe<Array<InputMaybe<ContractDetailInvoiceStatusEnum>>>;
  contractDetailRecruiterValidationStatus?: InputMaybe<Array<InputMaybe<ValidationStatusEnum>>>;
  dates?: InputMaybe<DateRangeInput>;
  first?: InputMaybe<Scalars['Int']>;
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  last?: InputMaybe<Scalars['Int']>;
  macroStatus?: InputMaybe<Array<MissionMacroStatusEnum>>;
  orderBy?: InputMaybe<Array<MissionOrderFieldInput>>;
  recruiterSigningStatus?: InputMaybe<SigningStatus>;
  recruiterValidationStatus?: InputMaybe<Array<InputMaybe<ValidationStatusEnum>>>;
  replacementType?: InputMaybe<ReplacementTypeEnum>;
  servicesId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
  states?: InputMaybe<Array<InputMaybe<MissionStateEnum>>>;
  status?: InputMaybe<Array<MissionStatusEnum>>;
};


/** A candidate. */
export type CandidateMissionsFiltersArgs = {
  status?: InputMaybe<Array<MissionStatusEnum>>;
};


/** A candidate. */
export type CandidatePlanningDaysArgs = {
  dates: DateRangeInput;
  limit?: InputMaybe<Scalars['Int']>;
};


/** A candidate. */
export type CandidateStatisticsArgs = {
  dates: DateRangeInput;
  groupBy: UserStatisticGroupByEnum;
};

/** Account status for candidate */
export enum CandidateAccountStatusEnum {
  /** Candidate is OFF (and never been ON) */
  Off = 'OFF',
  /** Candidate is ON */
  On = 'ON',
  /** Candidate is OFF (and previously been ON) */
  TemporaryOff = 'TEMPORARY_OFF'
}

export type CandidateLocation = {
  __typename?: 'CandidateLocation';
  /** The city. */
  city?: Maybe<City>;
  /** The department. */
  department?: Maybe<Department>;
  /** The range associated to the city or department. */
  range: Scalars['Int'];
  /** The CandidateLocation uuid. */
  uuid: Scalars['Uuid'];
};

/** A candidate's missions stats. */
export type CandidateMissionsStats = {
  __typename?: 'CandidateMissionsStats';
  /** The candidate. */
  candidate: Candidate;
  /** The completed mission nb. */
  completedMissionNb: Scalars['Int'];
};

/** All the information concerning the Candidate's progression */
export type CandidateProgression = {
  __typename?: 'CandidateProgression';
  /** Number of missions the candidate has cancelled illegitimately last 30 days */
  cancelledMissions: Scalars['Int'];
  /** Number of missions the candidate has fulfilled last 30 days */
  completedMissions: Scalars['Int'];
  /** Total number of missions the user needs to complete in order to obtain the next tier of the progression badge */
  currentExperimentedBadgeInterval: TagRange;
  /** Number of missions the candidate has cancelled but justified last 30 days */
  legitimatelyCancelledMissions: Scalars['Int'];
  /** Number of missions the user needs to complete in order to obtain the next tier of the progression badge */
  missionsUntilNextExperimentedBadgeTier?: Maybe<Scalars['Int']>;
  /** Number of badges the candidate has obtained last 30 days */
  obtainedBadges: Scalars['Int'];
  /** Total number of missions the candidate has fulfilled */
  totalCompletedMissions: Scalars['Int'];
};

/** Candidate UserTag */
export type CandidateTag = {
  __typename?: 'CandidateTag';
  /** Percentage of users having unlocked the badge */
  stat?: Maybe<Scalars['Float']>;
  /** UserTagEnum */
  tag: UserTagEnum;
};

export type City = NodeInterface & {
  __typename?: 'City';
  /** The city coordinates. */
  coordinates?: Maybe<Coordinates>;
  /** The country where the city is located. */
  country: Country;
  /** The department where the city is located. */
  department?: Maybe<Department>;
  /** The node id. A base 64 encode of 'City:$id'. */
  globalId: Scalars['ID'];
  /** The city google place id. */
  googlePlaceId?: Maybe<Scalars['String']>;
  /** The City id. */
  id: Scalars['ID'];
  /** The city name. */
  name?: Maybe<Scalars['String']>;
  /** The city North Est coordinates. */
  northEastCoordinates?: Maybe<Coordinates>;
  /** The city slug used in url. */
  slug?: Maybe<Scalars['String']>;
  /** The city South West coordinates. */
  southWestCoordinates?: Maybe<Coordinates>;
  /** The city zip code */
  zipCode?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type CityConnection = {
  __typename?: 'CityConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<CityEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The amount of cities. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type CityEdge = {
  __typename?: 'CityEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<City>;
};

/** Contracts */
export type Contract = {
  __typename?: 'Contract';
  /** The contract candidate */
  candidate?: Maybe<Candidate>;
  /** The candidate signed date */
  candidateSignedAt?: Maybe<Scalars['DateTime']>;
  /** The candidate signing link */
  candidateSigningLink?: Maybe<Scalars['String']>;
  /** The candidate signing status */
  candidateSigningStatus?: Maybe<SigningStatus>;
  /** The candidate signed date */
  contractDetails?: Maybe<Array<Maybe<ContractDetail>>>;
  /** The contract number */
  contractNumber: Scalars['String'];
  /** The contract date */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The contract missions dates */
  dates?: Maybe<DateRange>;
  /** The contract document link */
  documentDownloadLink?: Maybe<Scalars['String']>;
  /** The contract group office */
  groupOffice?: Maybe<GroupOffice>;
  /** is a CDD contract */
  isCDD: Scalars['Boolean'];
  /** The package status */
  packageStatus?: Maybe<PackageStatus>;
  /** The contract parent (if the contract is an amendment). */
  parent?: Maybe<Contract>;
  /** The contract recruiter email */
  recruiterEmail?: Maybe<Scalars['String']>;
  /** The recruiter signed date */
  recruiterSignedAt?: Maybe<Scalars['DateTime']>;
  /** The recruiter signing link */
  recruiterSigningLink?: Maybe<Scalars['String']>;
  /** The recruiter signing status */
  recruiterSigningStatus?: Maybe<SigningStatus>;
  /**
   * The signed contract document link
   * @deprecated Use documentDownloadLink instead
   */
  signedDocumentDownloadLink?: Maybe<Scalars['String']>;
  /** The contract specialty */
  specialty?: Maybe<Specialty>;
  /** The contract uuid. */
  uuid: Scalars['Uuid'];
};

/** A connection to a list of items. */
export type ContractConnection = {
  __typename?: 'ContractConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<ContractEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** How to export data. */
  spreadSheet?: Maybe<SpreadSheetFile>;
  /** Contracts count. */
  totalCount?: Maybe<Scalars['Int']>;
};


/** A connection to a list of items. */
export type ContractConnectionSpreadSheetArgs = {
  dates?: InputMaybe<DateRangeInput>;
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  packageStatus?: InputMaybe<Array<PackageStatus>>;
  recruiterSigningStatus?: InputMaybe<SigningStatus>;
  replacementType?: InputMaybe<ReplacementTypeEnum>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
};

/** Contract detail */
export type ContractDetail = {
  __typename?: 'ContractDetail';
  /** Break duration in minutes */
  breakDuration?: Maybe<Scalars['Int']>;
  /** The candidate. */
  candidate: Candidate;
  /** Awaiting candidate validation */
  candidateNeedToBeValidated?: Maybe<Scalars['Boolean']>;
  /** The candidate validation date. */
  candidateValidationDate?: Maybe<Scalars['DateTime']>;
  /** The candidate validation status. */
  candidateValidationStatus?: Maybe<ValidationStatusEnum>;
  /** The child contract detail. */
  child?: Maybe<ContractDetail>;
  /** The contract. */
  contract: Contract;
  /** The contract detail dates. */
  dateTimes: DateTimeRange;
  /**
   * The contract detail dates.
   * @deprecated Use dateTimes instead
   */
  dates: DateRange;
  /** The employee housing */
  employeeHousing?: Maybe<Scalars['String']>;
  /** Supports meals fee */
  employeeMeals?: Maybe<Scalars['String']>;
  /** Is the transport reimbursed ? */
  employeeTransport?: Maybe<Scalars['String']>;
  /** The candidate. */
  groupOffice: GroupOffice;
  /** The invoiced. */
  invoice?: Maybe<Invoice>;
  /** The invoice status. */
  invoiceStatus?: Maybe<ContractDetailInvoiceStatusEnum>;
  /** The invoiced amount. */
  invoicedAmount?: Maybe<InvoicedAmount>;
  /** The parent contract detail. */
  parent?: Maybe<ContractDetail>;
  /** The recruiter validation date. */
  recruiterValidationDate?: Maybe<Scalars['DateTime']>;
  /** The recruiter validation status. */
  recruiterValidationStatus?: Maybe<ValidationStatusEnum>;
  /** The rhythm. */
  rhythm: RhythmEnum;
  /** The salary. */
  salary: Scalars['Float'];
  /** The service. */
  service: Service;
  /** The specialty. */
  specialty: Specialty;
  /** The contract detail status. */
  status: ContractDetailStatusEnum;
  /** Time slot */
  timeSlot?: Maybe<Scalars['Int']>;
  /** The contract detail uuid. */
  uuid: Scalars['Uuid'];
};

/** The contract detail invoice status. */
export enum ContractDetailInvoiceStatusEnum {
  /** Contract detail is not invoiceable. */
  Invoiceable = 'INVOICEABLE',
  /** Contract detail is invoiced. */
  Invoiced = 'INVOICED',
  /** Contract detail is invoiceable. */
  NotInvoiceable = 'NOT_INVOICEABLE'
}

/** The contract detail status. */
export enum ContractDetailStatusEnum {
  /** Contract detail is active. */
  Active = 'ACTIVE',
  /** Contract detail is amended. */
  Amendment = 'AMENDMENT',
  /** Contract detail is cancelled. */
  Cancel = 'CANCEL'
}

/** An edge in a connection. */
export type ContractEdge = {
  __typename?: 'ContractEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Contract>;
};

/** The contract field to order by. */
export enum ContractOrderFieldEnum {
  /** Order by contractNumber field */
  ContractNumber = 'CONTRACT_NUMBER',
  /** Order by createdAt field */
  CreatedAt = 'CREATED_AT',
  /** Order by first mission date field */
  MissionDate = 'MISSION_DATE'
}

/** Ordering options for contract connections */
export type ContractOrderFieldInput = {
  /** The ordering direction. */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** The field to order contract by. */
  field: ContractOrderFieldEnum;
  /** The field values to order by. */
  values?: InputMaybe<Array<Scalars['String']>>;
};

/** The coordinates representation. */
export type Coordinates = {
  __typename?: 'Coordinates';
  /** The latitude. */
  latitude?: Maybe<Scalars['Float']>;
  /** The longitude. */
  longitude?: Maybe<Scalars['Float']>;
};

export type Country = NodeInterface & {
  __typename?: 'Country';
  /** The country alpha code 2. */
  alpha2: Scalars['String'];
  /** The country alpha code 3. */
  alpha3: Scalars['String'];
  /** The country id. */
  id: Scalars['ID'];
  /** If true, the country is active. */
  isActive: Scalars['Boolean'];
  /** The country name. */
  name: Scalars['String'];
  /** The international country phone code. */
  phoneCode?: Maybe<Scalars['Int']>;
  /** The country slug. Used in url. */
  slug?: Maybe<Scalars['String']>;
};

/** A connection to a list of items. */
export type CountryConnection = {
  __typename?: 'CountryConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<CountryEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The amount of countries. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type CountryEdge = {
  __typename?: 'CountryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Country>;
};

export type DateRange = {
  __typename?: 'DateRange';
  /** The end date. */
  endDate?: Maybe<Scalars['Date']>;
  /** The start date. */
  startDate?: Maybe<Scalars['Date']>;
};

/** Date range input */
export type DateRangeInput = {
  /** The end date. */
  endDate: Scalars['Date'];
  /** The start date. */
  startDate: Scalars['Date'];
};

export type DateTimeRange = {
  __typename?: 'DateTimeRange';
  /** The end datetime. */
  endDate?: Maybe<Scalars['DateTime']>;
  /** The start datetime. */
  startDate?: Maybe<Scalars['DateTime']>;
};

export type Department = NodeInterface & {
  __typename?: 'Department';
  /** The country where the department is located. */
  country: Country;
  /** The node id. A base 64 encode of 'Department:$id'. */
  globalId: Scalars['ID'];
  /** The department id. */
  id: Scalars['ID'];
  /** The department name. */
  name?: Maybe<Scalars['String']>;
  /** The local department number. */
  number?: Maybe<Scalars['String']>;
};

/** A document entity. */
export type Document = {
  __typename?: 'Document';
  /** Set when the document is deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The document id. */
  id: Scalars['ID'];
  /** The document mime types accepted. */
  mimeTypes: Array<Scalars['String']>;
  /** The document name. */
  name: Scalars['String'];
  /** The document order. */
  order?: Maybe<Scalars['Int']>;
};

/** The result of download mutation payload. */
export type DownloadPayload = {
  __typename?: 'DownloadPayload';
  /** The link to download file. */
  link: Scalars['String'];
};

/** The availability establishment types enum. */
export enum EstablishmentTypeEnum {
  /** Clinical / Private hospital */
  Clinical = 'CLINICAL',
  /** EHPAD / Nursing Home. */
  EhpadNursing = 'EHPAD_NURSING',
  /** Hospitalization at home. */
  Had = 'HAD',
  /** Health Center. */
  HealthCenter = 'HEALTH_CENTER',
  /** Heal House. */
  HealthHouse = 'HEALTH_HOUSE',
  /** Home consultation service */
  HomeConsultationService = 'HOME_CONSULTATION_SERVICE',
  /** Laboratory. */
  Labo = 'LABO',
  /** Other social medicine */
  OtherMs = 'OTHER_MS',
  /** Private company. */
  PrivComp = 'PRIV_COMP',
  /** Public hospital. */
  PublicHospital = 'PUBLIC_HOSPITAL',
  /** Aftercare and Rehabilitation Care. */
  Ssr = 'SSR',
  /** Teleconsultation service */
  Teleconsultation = 'TELECONSULTATION'
}

/** Export to spreadsheet options. */
export type ExportToSpreadsheetInput = {
  fields?: InputMaybe<Array<InputMaybe<SpreadsheetExportableFields>>>;
  /** Expected file''s format: Csv or Xlsx */
  format?: InputMaybe<Scalars['String']>;
  /** Spreadsheet's file name. */
  name?: InputMaybe<Scalars['String']>;
};

/** The file representation. */
export type File = {
  __typename?: 'File';
  /** The file name. */
  name: Scalars['String'];
  /** The public file name without uniq id. */
  publicName: Scalars['String'];
  /** The file upload date. */
  uploadedAt: Scalars['DateTime'];
  /** The file url. */
  url: Scalars['String'];
};

/** Gender types. */
export enum GenderEnum {
  /** A doctor */
  Doctor = 'DOCTOR',
  /** Female gender. */
  Female = 'FEMALE',
  /** Male gender. */
  Male = 'MALE',
  /** No gender expected. */
  None = 'NONE'
}

/** Group. */
export type Group = {
  __typename?: 'Group';
  /** The group id. */
  id: Scalars['ID'];
  /** Is the group active ? */
  isActive: Scalars['Boolean'];
  /** The group name. */
  name: Scalars['String'];
  /** The group public information */
  publicInformation: PublicInformation;
};

/** Group office. */
export type GroupOffice = {
  __typename?: 'GroupOffice';
  /** The office address. */
  addr1?: Maybe<Scalars['String']>;
  /** The office address 2. */
  addr2?: Maybe<Scalars['String']>;
  /** The office city. */
  city: City;
  /** The group office coordinates. */
  coordinates?: Maybe<Coordinates>;
  /** The office country. */
  country: Country;
  /** The parent group. */
  group?: Maybe<Group>;
  /** The office introduce text. */
  groupOfficeIntroduceText?: Maybe<Scalars['String']>;
  /** Has the office an Upflow ID stored ? */
  hasUpflowId: Scalars['Boolean'];
  /** The office id. */
  id: Scalars['ID'];
  /** Does the group office have CDD missions */
  isCDD: Scalars['Boolean'];
  /** Has the group office the feature mission confirmation enabled */
  isFeatureConfirmationEnabled: Scalars['Boolean'];
  /** The group office Medelse fees */
  medelseFees?: Maybe<Array<MedelseFees>>;
  /** The group office metabases */
  metabases?: Maybe<GroupOfficeMetabase>;
  /** The office name. */
  name: Scalars['String'];
  /** The group office public information */
  publicInformation: PublicInformation;
  /** The group office tags */
  tags: Array<GroupOfficeTagEnum>;
  /** The office uuid. */
  uuid: Scalars['Uuid'];
  /** The office zipcode. */
  zipCode?: Maybe<Scalars['String']>;
};

/** The address object. */
export type GroupOfficeMetabase = {
  __typename?: 'GroupOfficeMetabase';
  /** The contract and invoice group office metabase. */
  contractInvoice?: Maybe<Scalars['String']>;
  /** The general group office metabase. */
  homepage?: Maybe<Scalars['String']>;
  /** The medelse rate group office metabase. */
  medelseRate?: Maybe<Scalars['String']>;
};

/** Group office tags */
export enum GroupOfficeTagEnum {
  /** Adapted equipment */
  Equipment = 'EQUIPMENT',
  /** new office on Medelse */
  New = 'NEW',
  /** Reception and orientation quality */
  Reception = 'RECEPTION',
  /** reliable and low cancellation rate */
  Reliable = 'RELIABLE',
  /** Welcoming and professional team */
  Team = 'TEAM',
  /** Efficient transmissions */
  Transmissions = 'TRANSMISSIONS',
  /** Accessibility and transport */
  Transport = 'TRANSPORT'
}

/** The image file crop object. */
export type ImageCrop = {
  __typename?: 'ImageCrop';
  /** The image url cropped has 35. */
  crop35: Scalars['String'];
  /** The image url cropped has 75. */
  crop75: Scalars['String'];
  /** The image url cropped has 90. */
  crop90: Scalars['String'];
  /** The image url cropped has 100. */
  crop100: Scalars['String'];
  /** The image url cropped has 130. */
  crop130: Scalars['String'];
  /** The image url cropped has 273. */
  crop273: Scalars['String'];
};

/** The image file representation. */
export type ImageFile = {
  __typename?: 'ImageFile';
  /** The image crop object. */
  crop: ImageCrop;
  /** The file name. */
  name: Scalars['String'];
  /** The public file name without uniq id. */
  publicName: Scalars['String'];
  /** The image square object. */
  square: ImageSquare;
  /** The file upload date. */
  uploadedAt: Scalars['DateTime'];
  /** The file url. */
  url: Scalars['String'];
};

/** The image mutation payload. */
export type ImagePayload = {
  __typename?: 'ImagePayload';
  /** The image object. */
  image: ImageFile;
  /** The user whose link image. */
  user: User;
};

/** The image deletion mutation payload. */
export type ImageRemovedPayload = {
  __typename?: 'ImageRemovedPayload';
  /** True if image has been deleted. */
  success: Scalars['Boolean'];
  /** The user whose image was deleted. */
  user: User;
};

/** The image file crop object. */
export type ImageSquare = {
  __typename?: 'ImageSquare';
  /** The image url squared has 75. */
  square75: Scalars['String'];
  /** The image url squared has 90. */
  square90: Scalars['String'];
  /** The image url squared has 130. */
  square130: Scalars['String'];
  /** The image url squared has 273. */
  square273: Scalars['String'];
  /** The image url squared has 350. */
  square350: Scalars['String'];
};

/** The validation data object */
export type Initiator = {
  __typename?: 'Initiator';
  /** The initiator first name */
  firstName?: Maybe<Scalars['String']>;
  /** The initiator gender */
  gender?: Maybe<Scalars['String']>;
  /** The initiator id */
  id: Scalars['ID'];
  /** The initiator last name */
  lastName?: Maybe<Scalars['String']>;
};

/** Invoices */
export type Invoice = {
  __typename?: 'Invoice';
  /** The invoice candidate */
  candidate?: Maybe<Candidate>;
  /** The invoice contract detail */
  contractDetails?: Maybe<Array<Maybe<ContractDetail>>>;
  /** The invoice created date */
  createdAt: Scalars['DateTime'];
  /** The invoice dates */
  dates?: Maybe<DateRange>;
  /** The invoice document link */
  documentDownloadLink?: Maybe<Scalars['String']>;
  /** The invoice group office */
  groupOffice?: Maybe<GroupOffice>;
  /** The invoice id */
  id: Scalars['ID'];
  /** The invoice payment data */
  payment?: Maybe<Payment>;
  /** The invoice total amount */
  totalAmount?: Maybe<Scalars['Float']>;
  /** The invoice uuid */
  uuid: Scalars['Uuid'];
};

/** A connection to a list of items. */
export type InvoiceConnection = {
  __typename?: 'InvoiceConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<InvoiceEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** How to export data. */
  spreadSheet?: Maybe<SpreadSheetFile>;
  /** The total of invoice. */
  totalCount?: Maybe<Scalars['Int']>;
};


/** A connection to a list of items. */
export type InvoiceConnectionSpreadSheetArgs = {
  dates?: InputMaybe<DateRangeInput>;
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  paymentStatus?: InputMaybe<Array<InputMaybe<PaymentStatus>>>;
  replacementType?: InputMaybe<ReplacementTypeEnum>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
};

/** An edge in a connection. */
export type InvoiceEdge = {
  __typename?: 'InvoiceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Invoice>;
};

/** The invoice field to order by. */
export enum InvoiceOrderFieldEnum {
  /** Order by createdAt field */
  CreatedAt = 'CREATED_AT',
  /** Order by invoiceNumber (id) field */
  InvoiceNumber = 'INVOICE_NUMBER'
}

/** Ordering options for invoice connections */
export type InvoiceOrderFieldInput = {
  /** The ordering direction. */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** The field to order invoice by. */
  field: InvoiceOrderFieldEnum;
  /** The field values to order by. */
  values?: InputMaybe<Array<Scalars['String']>>;
};

export type InvoicedAmount = {
  __typename?: 'InvoicedAmount';
  /** The medelse fee amount. */
  medelseAmount?: Maybe<Scalars['Float']>;
  /** The pro amount. */
  proAmount?: Maybe<Scalars['Float']>;
  /** The invoiced total amount. */
  totalAmount?: Maybe<Scalars['Float']>;
};

/** The available locale. */
export enum LocaleEnum {
  Fr = 'FR'
}

/** Set the user profile. */
export type LocalisationInput = {
  /** The address */
  address: Scalars['String'];
  /** The city address. */
  city: Scalars['ID'];
  /** The city zip code. */
  zipCode: Scalars['String'];
};

/** The location description. */
export type Location = {
  __typename?: 'Location';
  /** The location address. */
  address?: Maybe<Scalars['String']>;
  /** The location city. */
  city?: Maybe<City>;
  /** The location country. */
  country?: Maybe<Country>;
  /** The location zip code. */
  zipCode?: Maybe<Scalars['String']>;
};

export type MedelseFees = {
  __typename?: 'MedelseFees';
  /** The medelse fees date. */
  date: Scalars['Date'];
  /** The medelse fees percent. */
  percent: Scalars['Float'];
};

/** Medelse Invoices for User Fees */
export type MedelseFeesInvoice = {
  __typename?: 'MedelseFeesInvoice';
  /** The medelse invoice amount without tax */
  amountExclTax?: Maybe<Scalars['Float']>;
  /** The medelse invoice amount with tax included */
  amountInclTax?: Maybe<Scalars['Float']>;
  /** The medelse invoice vat amount */
  amountVat?: Maybe<Scalars['Float']>;
  /** The medelse invoice candidate */
  candidate?: Maybe<Candidate>;
  /** The invoice contract detail */
  contractDetails?: Maybe<Array<Maybe<ContractDetail>>>;
  /** The invoice created date */
  createdAt: Scalars['DateTime'];
  /** The medelse invoice document link */
  documentDownloadLink?: Maybe<Scalars['String']>;
  /** The medelse invoice group office */
  groupOffice?: Maybe<GroupOffice>;
  /** Invoice linked to this medelse invoice */
  invoice?: Maybe<Invoice>;
  /** The medelse invoice number */
  invoiceNumber?: Maybe<Scalars['String']>;
  /** The medelse invoice total amount. Same as amountInclTax */
  totalAmount?: Maybe<Scalars['Float']>;
  /** The medelse invoice uuid */
  uuid: Scalars['Uuid'];
};

/** A connection to a list of items. */
export type MedelseFeesInvoiceConnection = {
  __typename?: 'MedelseFeesInvoiceConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<MedelseFeesInvoiceEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The total of medelse invoice. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type MedelseFeesInvoiceEdge = {
  __typename?: 'MedelseFeesInvoiceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<MedelseFeesInvoice>;
};

/** The medelse invoice field to order by. */
export enum MedelseFeesInvoiceOrderFieldEnum {
  /** Order by createdAt field */
  CreatedAt = 'CREATED_AT',
  /** Order by invoiceNumber field */
  InvoiceNumber = 'INVOICE_NUMBER'
}

/** Ordering options for medelse invoice connections */
export type MedelseFeesInvoiceOrderFieldInput = {
  /** The ordering direction. */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** The field to order medese invoice by. */
  field: MedelseFeesInvoiceOrderFieldEnum;
  /** The field values to order by. */
  values?: InputMaybe<Array<Scalars['String']>>;
};

/** Missions */
export type Mission = {
  __typename?: 'Mission';
  /** The announcement additional info file stream url if exists */
  additionalInfoFile?: Maybe<Scalars['String']>;
  /** DEPRECATED - The mission break time. */
  break: Scalars['Int'];
  /** The mission break time. */
  breakDuration: Scalars['Int'];
  /** The mission candidate. */
  candidate: Candidate;
  /** The active contract detail */
  contractDetail?: Maybe<ContractDetail>;
  /** The mission datetimes. */
  dates: DateTimeRange;
  /** The mission description. */
  description?: Maybe<Scalars['String']>;
  /** The mission employee housing info. */
  employeeHousing?: Maybe<Scalars['String']>;
  /** The mission employee meals info. */
  employeeMeal?: Maybe<Scalars['String']>;
  /** The mission employee transport info. */
  employeeTransport?: Maybe<Scalars['String']>;
  /** The estimated mission invoice date. */
  estimatedInvoiceDate: Scalars['DateTime'];
  /** The mission estimated patient number. */
  estimatedPatient?: Maybe<Scalars['Int']>;
  /** The mission expired date. */
  expiredAt?: Maybe<Scalars['DateTime']>;
  /** For CDD - The mission gross salary. */
  grossSalary?: Maybe<Scalars['Float']>;
  /** The mission group office. */
  groupOffice: GroupOffice;
  /** The mission last object status. */
  lastStatusHistory: MissionStatus;
  /** The mission macro status. */
  macroStatus: MissionMacroStatusEnum;
  /** The mission medelse fees. */
  medelseFeesAmount: Scalars['Float'];
  /** For CDD - The mission paid holidays bonus. */
  paidHolidaysBonus?: Maybe<Scalars['Float']>;
  /** The mission practical information from office note field */
  practicalInformation?: Maybe<Scalars['String']>;
  /** The mission pro amount. */
  proAmount: Scalars['Float'];
  /** The mission read at date. */
  readAt?: Maybe<Scalars['DateTime']>;
  /** The recruiter validation datas. */
  recruiterValidation?: Maybe<Validation>;
  /** The mission's replacement type */
  replacementType?: Maybe<ReplacementType>;
  /** The mission rhythm. */
  rhythm: RhythmEnum;
  /** The mission salary. */
  salary: Scalars['Float'];
  /** The mission sector. */
  sector?: Maybe<Scalars['String']>;
  /** The mission service. */
  service: Service;
  /** For CDD - The mission contract bonus. */
  shortTermContractBonus?: Maybe<Scalars['Float']>;
  /** The mission specialty. */
  specialty: Specialty;
  /** The mission specific services. */
  specificServices: Array<Maybe<SpecificService>>;
  /** The mission state. */
  state: MissionStateEnum;
  /** The mission status. */
  status: MissionStatusEnum;
  /** The mission status object history. */
  statusHistory: Array<MissionStatus>;
  /** The mission time slot. */
  timeSlot: Scalars['Int'];
  /** The mission uuid. */
  uuid: Scalars['Uuid'];
};

/** A connection to a list of items. */
export type MissionConnection = {
  __typename?: 'MissionConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<MissionEdge>>>;
  /** The group office's missions. */
  groupOffices?: Maybe<Array<Maybe<GroupOffice>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** How to export data. */
  spreadSheet?: Maybe<SpreadSheetFile>;
  /** The missions total number. */
  totalCount?: Maybe<Scalars['Int']>;
};


/** A connection to a list of items. */
export type MissionConnectionSpreadSheetArgs = {
  dates?: InputMaybe<DateRangeInput>;
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  macroStatus?: InputMaybe<Array<MissionMacroStatusEnum>>;
  replacementType?: InputMaybe<ReplacementTypeEnum>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
  status?: InputMaybe<Array<MissionStatusEnum>>;
};

/** An edge in a connection. */
export type MissionEdge = {
  __typename?: 'MissionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Mission>;
};

/** The mission object. */
export type MissionFilter = {
  __typename?: 'MissionFilter';
  /** The group offices who's match with filters. */
  groupOffices?: Maybe<Array<GroupOffice>>;
};

/** Mission status */
export type MissionInitiator = {
  __typename?: 'MissionInitiator';
  /** The mission initiator first name. */
  firstName?: Maybe<Scalars['String']>;
  /** The mission initiator gender. */
  gender?: Maybe<Scalars['String']>;
  /** The mission initiator last name. */
  lastName?: Maybe<Scalars['String']>;
};

/** The initiator on mission actions. */
export enum MissionInitiatorEnum {
  /** The initiator is admin */
  InitiatorAdmin = 'INITIATOR_ADMIN',
  /** The initiator is auto */
  InitiatorAuto = 'INITIATOR_AUTO',
  /** The initiator is group office */
  InitiatorOffice = 'INITIATOR_OFFICE',
  /** The initiator is candidate */
  InitiatorPro = 'INITIATOR_PRO'
}

/** The mission macro status types enum. */
export enum MissionMacroStatusEnum {
  Accepted = 'ACCEPTED',
  CancellationRequested = 'CANCELLATION_REQUESTED',
  CancellationRequestedByCandidate = 'CANCELLATION_REQUESTED_BY_CANDIDATE',
  CancellationRequestedByRecruiter = 'CANCELLATION_REQUESTED_BY_RECRUITER',
  CancelledByAdmin = 'CANCELLED_BY_ADMIN',
  CancelledByCandidate = 'CANCELLED_BY_CANDIDATE',
  CancelledByRecruiter = 'CANCELLED_BY_RECRUITER',
  ContractualizedFullySignedInProgress = 'CONTRACTUALIZED_FULLY_SIGNED_IN_PROGRESS',
  ContractualizedFullySignedUpcoming = 'CONTRACTUALIZED_FULLY_SIGNED_UPCOMING',
  ContractualizedPending = 'CONTRACTUALIZED_PENDING',
  ContractualizedSignedByCandidate = 'CONTRACTUALIZED_SIGNED_BY_CANDIDATE',
  ContractualizedSignedByRecruiter = 'CONTRACTUALIZED_SIGNED_BY_RECRUITER',
  Deleted = 'DELETED',
  Expired = 'EXPIRED',
  Invoiceable = 'INVOICEABLE',
  Invoiced = 'INVOICED',
  NotInvoiceable = 'NOT_INVOICEABLE',
  Proposed = 'PROPOSED',
  Refused = 'REFUSED',
  ValidationInvalidatedByBoth = 'VALIDATION_INVALIDATED_BY_BOTH',
  ValidationInvalidatedByCandidate = 'VALIDATION_INVALIDATED_BY_CANDIDATE',
  ValidationInvalidatedByCandidateValidatedByRecruiter = 'VALIDATION_INVALIDATED_BY_CANDIDATE_VALIDATED_BY_RECRUITER',
  ValidationInvalidatedByRecruiter = 'VALIDATION_INVALIDATED_BY_RECRUITER',
  ValidationPending = 'VALIDATION_PENDING',
  ValidationValidatedByCandidate = 'VALIDATION_VALIDATED_BY_CANDIDATE',
  ValidationValidatedByCandidateInvalidatedByRecruiter = 'VALIDATION_VALIDATED_BY_CANDIDATE_INVALIDATED_BY_RECRUITER',
  ValidationValidatedByRecruiter = 'VALIDATION_VALIDATED_BY_RECRUITER',
  WaitingForContract = 'WAITING_FOR_CONTRACT'
}

/** The invoice field to order by. */
export enum MissionOrderFieldEnum {
  /** Order by group office name */
  GroupOfficeName = 'GROUP_OFFICE_NAME',
  /** Order by salary field */
  Salary = 'SALARY',
  /** Order by start date field */
  StartDate = 'START_DATE'
}

/** Ordering options for mission connections */
export type MissionOrderFieldInput = {
  /** The ordering direction. */
  direction?: InputMaybe<OrderDirectionEnum>;
  /** The field to order mission by. */
  field: MissionOrderFieldEnum;
  /** The field values to order by. */
  values?: InputMaybe<Array<Scalars['String']>>;
};

/** Mission refusal */
export type MissionRefusal = {
  __typename?: 'MissionRefusal';
  /** The mission cancelled user. */
  cancelledBy?: Maybe<Scalars['String']>;
  /** The mission refusal reason. */
  reason?: Maybe<Scalars['String']>;
  /** The mission refusal reason custom. */
  reasonCustom?: Maybe<Scalars['String']>;
};

/** The missions report reason enum. */
export enum MissionReportReasonEnum {
  /** Mission done with other time information (strat / end / break...) */
  BadTime = 'BAD_TIME',
  /** Mission not done */
  NotDone = 'NOT_DONE',
  /** Mission report for other reason */
  Other = 'OTHER'
}

/** Current mission state compared to the current Datetime. */
export enum MissionStateEnum {
  /** Mission is in progress. Start date less than now and end date greater then now */
  InProgress = 'IN_PROGRESS',
  /** Mission is passed. End date less then now. */
  Passed = 'PASSED',
  /** Mission is upcoming. Start date greater than now. */
  Upcoming = 'UPCOMING'
}

/** Mission status */
export type MissionStatus = {
  __typename?: 'MissionStatus';
  /** The mission status asked date. */
  askedAt?: Maybe<Scalars['DateTime']>;
  /** The mission status created date. */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** The mission initiator. */
  initiator?: Maybe<MissionInitiator>;
  /** The mission initiator. */
  initiatorType: MissionInitiatorEnum;
  /** The mission refusal. */
  refusal?: Maybe<MissionRefusal>;
  /** The mission status. */
  status: MissionStatusEnum;
};

/** The mission status types enum. */
export enum MissionStatusEnum {
  /** Mission accepted by candidate */
  Accepted = 'ACCEPTED',
  /** Mission is in request for cancellation */
  CancellationRequested = 'CANCELLATION_REQUESTED',
  /** Mission is cancelled */
  Cancelled = 'CANCELLED',
  /** Mission is contractualized */
  Contractualized = 'CONTRACTUALIZED',
  /** Mission is deleted */
  Deleted = 'DELETED',
  /** Mission is expired */
  Expired = 'EXPIRED',
  /** Mission is invoiced */
  Invoiced = 'INVOICED',
  /** Mission proposed to candidate */
  Proposed = 'PROPOSED',
  /** Mission is refused */
  Refused = 'REFUSED',
  /** Mission is pending contract */
  WaitingForContract = 'WAITING_FOR_CONTRACT'
}

/** Fetches an object given its ID */
export type NodeInterface = {
  /** The ID of an object */
  id: Scalars['ID'];
};

/** Possible directions in which to order a list of items when provided an orderBy argument. */
export enum OrderDirectionEnum {
  /** Specifies an ascending order for a given orderBy argument */
  Asc = 'ASC',
  /** Specifies a descending order for a given orderBy argument */
  Desc = 'DESC'
}

/** The package status. */
export enum PackageStatus {
  /** Signing process is canceled */
  Canceled = 'CANCELED',
  /** Fully signed */
  FullySigned = 'FULLY_SIGNED',
  /** Pending signing */
  Pending = 'PENDING',
  /** Pending signing approval */
  PendingApproval = 'PENDING_APPROVAL',
  /** Pending signature */
  PendingSignature = 'PENDING_SIGNATURE',
  /** Package rejected */
  Rejected = 'REJECTED',
  /** Package revoked */
  Revoked = 'REVOKED'
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** The payment details. */
export type Payment = {
  __typename?: 'Payment';
  /** The payment bill date. */
  billDate?: Maybe<Scalars['DateTime']>;
  /** The payment due date. */
  dueDate?: Maybe<Scalars['DateTime']>;
  /** The payment end date. */
  endDate?: Maybe<Scalars['DateTime']>;
  /** The payment pending date. */
  pendingDate?: Maybe<Scalars['DateTime']>;
  /** The payment status. */
  status?: Maybe<PaymentStatus>;
};

/** The package status. */
export enum PaymentStatus {
  /** Blocked payment */
  Blocked = 'BLOCKED',
  /** Canceled payment */
  Canceled = 'CANCELED',
  /** Failed payment */
  Failed = 'FAILED',
  /** Late payment */
  Late = 'LATE',
  /** Paid payment */
  Paid = 'PAID',
  /** Pending payment */
  Pending = 'PENDING',
  /** Processing payment */
  Processing = 'PROCESSING',
  /** Refused payment */
  Refused = 'REFUSED',
  /** Sended payment */
  Sent = 'SENT'
}

/** The phone object. */
export type Phone = {
  __typename?: 'Phone';
  /** The phone country code. */
  code?: Maybe<Scalars['Int']>;
  /** The phone number. */
  number?: Maybe<Scalars['String']>;
};

/** Set the user profile. */
export type PhoneInput = {
  /** The phone country code. */
  code?: InputMaybe<Scalars['Int']>;
  /** The phone number. */
  number?: InputMaybe<Scalars['String']>;
};

/** UserHasPlanning availability. */
export enum PlanningAvailabilityEnum {
  /** User is available */
  Available = 'AVAILABLE',
  /** User is unavailable because he is already committed with Medelse */
  MedelseMission = 'MEDELSE_MISSION',
  /** User is unavailable */
  Unavailable = 'UNAVAILABLE'
}

/** The planning day object. */
export type PlanningDay = {
  __typename?: 'PlanningDay';
  /**
   * The day availability.
   * @deprecated availabilityDay is now an enum: availabilityDayEnum.
   */
  availabilityDay: Scalars['Boolean'];
  /** The day availability enum. */
  availabilityDayEnum: PlanningAvailabilityEnum;
  /**
   * The night availability.
   * @deprecated availabilityNight is now an enum: availabilityNightEnum.
   */
  availabilityNight: Scalars['Boolean'];
  /** The night availability enum. */
  availabilityNightEnum: PlanningAvailabilityEnum;
  /** The date day. */
  date: Scalars['Date'];
};

/** A candidate user information. */
export type ProInformation = {
  __typename?: 'ProInformation';
  /** Is the user is beta short. */
  betaShort?: Maybe<Scalars['Boolean']>;
  /** The first replacement type. */
  canExercise?: Maybe<UserCanExerciseEnum>;
  /** The user council order number. */
  councilOrderNumber?: Maybe<Scalars['String']>;
  /** Is the user is subscribed on council order. */
  councilRegistration?: Maybe<Scalars['Boolean']>;
  /** Is the user is global unavailability. */
  globalUnavailability?: Maybe<Scalars['Boolean']>;
  /** Is the user liberal. */
  isLiberal?: Maybe<Scalars['Boolean']>;
  /** The french shared doctor repository index. */
  rppsNumber?: Maybe<Scalars['String']>;
  /** The user SIRET number. */
  siret?: Maybe<Scalars['String']>;
  /** The french urssaf code. */
  urssaf?: Maybe<Scalars['String']>;
};

/** The public information object */
export type PublicInformation = {
  __typename?: 'PublicInformation';
  /** Name */
  name?: Maybe<Scalars['String']>;
  /** Slug */
  slug?: Maybe<Scalars['String']>;
};

/** A recruiter. */
export type Recruiter = NodeInterface & {
  __typename?: 'Recruiter';
  /** A paginated list of announcements. */
  Announcements?: Maybe<AnnouncementShortConnection>;
  /** The user candidate agent */
  administrator?: Maybe<Administrator>;
  /** The paginated user contract. */
  contracts?: Maybe<ContractConnection>;
  /** The user create date. */
  createAt: Scalars['DateTime'];
  /** Set when cv has been updated. */
  cvUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** Set when the user has delete his account. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Is the user disabled */
  disabled: Scalars['Boolean'];
  /** The user documents. */
  documents: Array<Maybe<UserDocument>>;
  /** Set when user has status. */
  files: UserFiles;
  /** The node id. A base 64 encode of 'User:$id'. */
  globalId: Scalars['ID'];
  /** One obfuscated IBAN */
  iban?: Maybe<Scalars['String']>;
  /** The user id. */
  id: Scalars['ID'];
  /** Set when image has been updated. */
  imageUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** The paginated user invoice. */
  invoices?: Maybe<InvoiceConnection>;
  /** Does the user handle CDD missions */
  isCDD: Scalars['Boolean'];
  /** The last visit date. */
  lastVisit?: Maybe<Scalars['DateTime']>;
  /** The paginated user mission. */
  missions?: Maybe<MissionConnection>;
  /**
   * The mission filters list.
   * @deprecated Use matchingGroupOffices instead
   */
  missionsFilters?: Maybe<MissionFilter>;
  /** NIC front & back */
  nic?: Maybe<Array<Maybe<File>>>;
  /** The user planning days for the given period. */
  planningDays: Array<Maybe<PlanningDay>>;
  /** The user candidate information. */
  proInformation: ProInformation;
  /** The user profile object. */
  profile: UserProfile;
  /** The recruiter roles. */
  recruiterGroupOffices?: Maybe<Array<Maybe<RecruiterGroupOffice>>>;
  /** The referral code. */
  referralCode?: Maybe<Scalars['String']>;
  /** The user salary expected. */
  salary?: Maybe<Scalars['Int']>;
  /** The user search criteria */
  searchCriterias: SearchCriteria;
  /** The user skills. */
  skills?: Maybe<Array<Skill>>;
  /** The user specialties. */
  specialties: Array<Specialty>;
  /** The user statistics */
  statistics?: Maybe<Array<Maybe<UserStatisticPeriod>>>;
  /** Set when user has status. */
  status?: Maybe<UserStatusEnum>;
  /** The candidate newsletter subscription. */
  subscription: Scalars['Boolean'];
  /** The user uuid. */
  uuid: Scalars['Uuid'];
  /** The user email validation date. */
  validatedAt?: Maybe<Scalars['DateTime']>;
};


/** A recruiter. */
export type RecruiterAnnouncementsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  dates?: InputMaybe<DateRangeInput>;
  first?: InputMaybe<Scalars['Int']>;
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  last?: InputMaybe<Scalars['Int']>;
  replacementType?: InputMaybe<ReplacementTypeEnum>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
  status?: InputMaybe<Array<AdvertisementStatusEnum>>;
};


/** A recruiter. */
export type RecruiterContractsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  candidateSigningStatus?: InputMaybe<SigningStatus>;
  createdAt?: InputMaybe<DateRangeInput>;
  dates?: InputMaybe<DateRangeInput>;
  first?: InputMaybe<Scalars['Int']>;
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ContractOrderFieldInput>>;
  packageStatus?: InputMaybe<Array<PackageStatus>>;
  recruiterSigningStatus?: InputMaybe<SigningStatus>;
  replacementType?: InputMaybe<ReplacementTypeEnum>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
};


/** A recruiter. */
export type RecruiterInvoicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateRangeInput>;
  dates?: InputMaybe<DateRangeInput>;
  first?: InputMaybe<Scalars['Int']>;
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InvoiceOrderFieldInput>>;
  paymentStatus?: InputMaybe<Array<InputMaybe<PaymentStatus>>>;
  replacementType?: InputMaybe<ReplacementTypeEnum>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
};


/** A recruiter. */
export type RecruiterMissionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  cancellationInitiator?: InputMaybe<Array<InputMaybe<MissionInitiatorEnum>>>;
  candidateSigningStatus?: InputMaybe<SigningStatus>;
  contractDetailCandidateValidationStatus?: InputMaybe<Array<InputMaybe<ValidationStatusEnum>>>;
  contractDetailInvoiceStatus?: InputMaybe<Array<InputMaybe<ContractDetailInvoiceStatusEnum>>>;
  contractDetailRecruiterValidationStatus?: InputMaybe<Array<InputMaybe<ValidationStatusEnum>>>;
  dates?: InputMaybe<DateRangeInput>;
  first?: InputMaybe<Scalars['Int']>;
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  last?: InputMaybe<Scalars['Int']>;
  macroStatus?: InputMaybe<Array<MissionMacroStatusEnum>>;
  orderBy?: InputMaybe<Array<MissionOrderFieldInput>>;
  recruiterSigningStatus?: InputMaybe<SigningStatus>;
  recruiterValidationStatus?: InputMaybe<Array<InputMaybe<ValidationStatusEnum>>>;
  replacementType?: InputMaybe<ReplacementTypeEnum>;
  servicesId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
  states?: InputMaybe<Array<InputMaybe<MissionStateEnum>>>;
  status?: InputMaybe<Array<MissionStatusEnum>>;
};


/** A recruiter. */
export type RecruiterMissionsFiltersArgs = {
  status?: InputMaybe<Array<MissionStatusEnum>>;
};


/** A recruiter. */
export type RecruiterPlanningDaysArgs = {
  dates: DateRangeInput;
  limit?: InputMaybe<Scalars['Int']>;
};


/** A recruiter. */
export type RecruiterStatisticsArgs = {
  dates: DateRangeInput;
  groupBy: UserStatisticGroupByEnum;
};

/** Define the mission to cancel request. */
export type RecruiterCancelRequestMissionInput = {
  /** The content of the mail */
  content: Scalars['String'];
  /** The mission id to cancel request. */
  missionUuid: Scalars['String'];
};

/** The recruiter mission cancel request mutation payload */
export type RecruiterCancelRequestMissionPayload = {
  __typename?: 'RecruiterCancelRequestMissionPayload';
  /** The mission id to cancel request. */
  missionUuid: Scalars['Uuid'];
  /** True if cancellation request email sent. */
  success: Scalars['Boolean'];
  /** The recruiter that wants to cancel the mission. */
  user: Recruiter;
};

/** Define the mission to confirm. */
export type RecruiterConfirmMissionInput = {
  /** The mail content. */
  content: Scalars['String'];
  /** The mission id to confirm. */
  missionUuid: Scalars['Uuid'];
};

/** The confirm mission mutation payload. */
export type RecruiterConfirmMissionPayload = {
  __typename?: 'RecruiterConfirmMissionPayload';
  /** The mission object. */
  mission: Mission;
  /** The recruiter whose confirm mission. */
  user: Recruiter;
};

/** A recruiter group office association. */
export type RecruiterGroupOffice = {
  __typename?: 'RecruiterGroupOffice';
  /** The recruiter group office. */
  groupOffice: GroupOffice;
  /** The recruiter roles for the group office. */
  roles?: Maybe<Array<Maybe<RecruiterRoleEnum>>>;
};

/** Define the mission to refuse. */
export type RecruiterRefuseMissionInput = {
  /** The mail content. */
  content: Scalars['String'];
  /** The mission id to refuse. */
  missionUuid: Scalars['Uuid'];
};

/** The refuse mission mutation payload. */
export type RecruiterRefuseMissionPayload = {
  __typename?: 'RecruiterRefuseMissionPayload';
  /** The mission object. */
  mission: Mission;
  /** The recruiter whose refuse mission. */
  user: Recruiter;
};

/** Define the mission to report. */
export type RecruiterReportMissionInput = {
  /** The mail content. */
  content: Scalars['String'];
  /** The mission id to report. */
  missionUuid: Scalars['Uuid'];
};

/** The report mission mutation payload. */
export type RecruiterReportMissionsPayload = {
  __typename?: 'RecruiterReportMissionsPayload';
  /** The mission object. */
  mission: Mission;
  /** The recruiter whose report mission. */
  user: Recruiter;
};

/** The recruiter role enum. */
export enum RecruiterRoleEnum {
  /** Accountant role */
  Accountant = 'ACCOUNTANT',
  /** Administrator role */
  Administrator = 'ADMINISTRATOR',
  /** Human resource role */
  HumanResource = 'HUMAN_RESOURCE'
}

export type ReplacementType = {
  __typename?: 'ReplacementType';
  /** The replacement type id. */
  id: Scalars['ID'];
  /** Is replacement active. */
  isActive: Scalars['Boolean'];
  /** The replacement type name. */
  name: Scalars['String'];
  /** The replacement display order. */
  order: Scalars['Int'];
  /** The replacement type. */
  type?: Maybe<ReplacementTypeEnum>;
};

/** The replacement type. */
export enum ReplacementTypeEnum {
  /** CDD replacement types. */
  Cdd = 'CDD',
  /** Short replacement types. */
  Session = 'SESSION'
}

/** Define the mission to report. */
export type ReportMissionInput = {
  /** The break time duration in minutes if reason is BAD_TIME. */
  break?: InputMaybe<Scalars['Int']>;
  /** The mission report comment if reason is NOT_DONE or OTHER. */
  comment?: InputMaybe<Scalars['String']>;
  /** The working time duration in minutes if reason is BAD_TIME. */
  duration?: InputMaybe<Scalars['Int']>;
  /** The end hour time if reason is BAD_TIME. */
  endTime?: InputMaybe<Scalars['Time']>;
  /** The mission uuid to report. */
  missionUuid: Scalars['Uuid'];
  /** The mission report reason. */
  reason: MissionReportReasonEnum;
  /** The start hour time if reason is BAD_TIME. */
  startTime?: InputMaybe<Scalars['Time']>;
};

/** The rhythm type. */
export enum RhythmEnum {
  /** Day rhythm. */
  Day = 'DAY',
  /** Night rhythm. */
  Night = 'NIGHT'
}

/** The salary object. */
export type Salary = {
  __typename?: 'Salary';
  /** The salary amount. */
  amount?: Maybe<Amount>;
  /** The salary type. */
  type: SalaryTypeEnum;
};

/** Salary types. */
export enum SalaryTypeEnum {
  /** The gross annual salary. */
  GrossAnnual = 'GROSS_ANNUAL',
  /** The gross daily salary. */
  GrossDaily = 'GROSS_DAILY',
  /** The gross monthly salary. */
  GrossMonthly = 'GROSS_MONTHLY',
  /** The retrocession salary. */
  GrossRetrocession = 'GROSS_RETROCESSION',
  /** The net annual salary. */
  NetAnnual = 'NET_ANNUAL',
  /** The net daily salary. */
  NetDaily = 'NET_DAILY',
  /** The net monthly salary. */
  NetMonthly = 'NET_MONTHLY',
  /** The net retrocession salary. */
  NetRetrocession = 'NET_RETROCESSION',
  /** The profile salary. */
  Profile = 'PROFILE',
  /** The no sector salary. */
  SectorNone = 'SECTOR_NONE',
  /** The sector one salary. */
  SectorOne = 'SECTOR_ONE',
  /** The sector one or sector two salary. */
  SectorOneOrTwo = 'SECTOR_ONE_OR_TWO',
  /** The sector three salary. */
  SectorThree = 'SECTOR_THREE',
  /** The sector two salary. */
  SectorTwo = 'SECTOR_TWO'
}

/** DEPRECATED - Advertisement user applications */
export type SearchCriteria = {
  __typename?: 'SearchCriteria';
  /** The establishment types criteria. */
  establishmentTypes?: Maybe<Array<AvailabilityEstablishmentTypeEnum>>;
  /** The location range criteria. */
  locationRanges?: Maybe<Array<CandidateLocation>>;
  /** The services criteria. */
  services?: Maybe<Array<Service>>;
  /** The specialties criteria. */
  specialties?: Maybe<Array<Specialty>>;
};

export type SendNewAnnouncementInput = {
  /** The attachment */
  attachment?: InputMaybe<Scalars['UploadType']>;
  /** The mail content. */
  content: Scalars['String'];
  /** The group office id. */
  groupOfficeUuid: Scalars['Uuid'];
};

/** The new announcement mutation payload. */
export type SendNewAnnouncementPayload = {
  __typename?: 'SendNewAnnouncementPayload';
  /** The groupOffice. */
  groupOffice: GroupOffice;
  /** True if new announcement email sent. */
  success: Scalars['Boolean'];
  /** The recruiter. */
  user: Recruiter;
};

/** Service. */
export type Service = {
  __typename?: 'Service';
  /** The service id. */
  id: Scalars['ID'];
  /** Is the service active ? */
  isActive: Scalars['Boolean'];
  /** The service name. */
  name: Scalars['String'];
};

/** A connection to a list of items. */
export type ServiceConnection = {
  __typename?: 'ServiceConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<ServiceEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The amount of services. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type ServiceEdge = {
  __typename?: 'ServiceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Service>;
};

/** The signing status. */
export enum SigningStatus {
  /** Not signed status. */
  NotSigned = 'NOT_SIGNED',
  /** Signed status. */
  Signed = 'SIGNED'
}

export type Skill = NodeInterface & {
  __typename?: 'Skill';
  /** The skill id. */
  id: Scalars['ID'];
  /** The skill name. */
  name: Scalars['String'];
};

/** A connection to a list of items. */
export type SkillConnection = {
  __typename?: 'SkillConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<SkillEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The amount of skills. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type SkillEdge = {
  __typename?: 'SkillEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Skill>;
};

export type Specialty = NodeInterface & {
  __typename?: 'Specialty';
  /** The node id. A base 64 encode of 'Specialty:$id'. */
  globalId: Scalars['ID'];
  /** The specialty id. */
  id: Scalars['ID'];
  /** Defined if the specialty is active. */
  isActive: Scalars['Boolean'];
  /** Defined if the specialty is active for register. */
  isActiveRegister: Scalars['Boolean'];
  /** The specialty name. */
  name: Scalars['String'];
  /** The rank to be ordered. */
  order: Scalars['Int'];
  /** The specialty slug used in url. */
  slug: Scalars['String'];
};

/** A connection to a list of items. */
export type SpecialtyConnection = {
  __typename?: 'SpecialtyConnection';
  /** Information to aid in pagination. */
  edges?: Maybe<Array<Maybe<SpecialtyEdge>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The amount of specialties. */
  totalCount?: Maybe<Scalars['Int']>;
};

/** An edge in a connection. */
export type SpecialtyEdge = {
  __typename?: 'SpecialtyEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Specialty>;
};

export type SpecificService = NodeInterface & {
  __typename?: 'SpecificService';
  /** Is the specific service enabled ? */
  enabled: Scalars['Boolean'];
  /** The specific service id. */
  id: Scalars['ID'];
  /** The specific service name. */
  name: Scalars['String'];
};

/** A Spreadsheet file stored in Google Cloud. */
export type SpreadSheetFile = {
  __typename?: 'SpreadSheetFile';
  /** Public spreadsheet''s url. */
  url?: Maybe<Scalars['String']>;
};

/** Dto''s attributes to export. */
export enum SpreadsheetExportableFields {
  Cancelled = 'CANCELLED',
  ContractDates = 'CONTRACT_DATES',
  ContractNumber = 'CONTRACT_NUMBER',
  Dates = 'DATES',
  FullName = 'FULL_NAME',
  Hours = 'HOURS',
  InvoiceNumber = 'INVOICE_NUMBER',
  NbrNeeds = 'NBR_NEEDS',
  PaymentDate = 'PAYMENT_DATE',
  PendingDate = 'PENDING_DATE',
  Reported = 'REPORTED',
  Rhythm = 'RHYTHM',
  Salary = 'SALARY',
  Sector = 'SECTOR',
  Service = 'SERVICE',
  SignedAt = 'SIGNED_AT',
  Speciality = 'SPECIALITY',
  SpecificService = 'SPECIFIC_SERVICE',
  Status = 'STATUS',
  Validated = 'VALIDATED'
}

/** Candidate suspension information */
export type Suspension = {
  __typename?: 'Suspension';
  /** Period of dates for which the user is suspended */
  dates: DateTimeRange;
  /** The reason for which the candidate is suspended */
  reason?: Maybe<SuspensionReasonEnum>;
};

/** Suspensions reason */
export enum SuspensionReasonEnum {
  /** Bad cancellation rate, 7 day suspension */
  BadCancellationRatio = 'BAD_CANCELLATION_RATIO',
  /** Indefinite suspension */
  Definitive = 'DEFINITIVE',
  /** Serious cancellation rate, 14 day suspension */
  SeriousCancellationRatio = 'SERIOUS_CANCELLATION_RATIO'
}

/** Range between the minimum value and maximum value need to obtain a tag */
export type TagRange = {
  __typename?: 'TagRange';
  /** Max */
  max?: Maybe<Scalars['Int']>;
  /** Min */
  min?: Maybe<Scalars['Int']>;
};

/** Set the update email parameters. */
export type UpdateEmailInput = {
  /** The new email. */
  email: Scalars['String'];
  /** The new email confirmation. */
  emailConfirmation: Scalars['String'];
};

/** The update email mutation payload. */
export type UpdateEmailPayload = {
  __typename?: 'UpdateEmailPayload';
  /** The email. */
  email: Scalars['String'];
  /** The user whose link email. */
  user: User;
};

/** Set the update password parameters. */
export type UpdatePasswordInput = {
  /** The new candidate password. */
  newPassword: Scalars['String'];
  /** The old candidate password. */
  oldPassword: Scalars['String'];
  /** The confirmation of new candidate password. */
  passwordConfirmation: Scalars['String'];
};

/** The update password mutation payload. */
export type UpdatePasswordPayload = {
  __typename?: 'UpdatePasswordPayload';
  /** The user whose link password. */
  user: User;
};

/** A user entity. */
export type User = NodeInterface & {
  __typename?: 'User';
  /** The user candidate agent */
  administrator?: Maybe<Administrator>;
  /** The paginated user contract. */
  contracts?: Maybe<ContractConnection>;
  /** The user create date. */
  createAt: Scalars['DateTime'];
  /** Set when cv has been updated. */
  cvUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** Set when the user has delete his account. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** Is the user disabled */
  disabled: Scalars['Boolean'];
  /** The user documents. */
  documents: Array<Maybe<UserDocument>>;
  /** Set when user has status. */
  files: UserFiles;
  /** The node id. A base 64 encode of 'User:$id'. */
  globalId: Scalars['ID'];
  /** One obfuscated IBAN */
  iban?: Maybe<Scalars['String']>;
  /** The user id. */
  id: Scalars['ID'];
  /** Set when image has been updated. */
  imageUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** The paginated user invoice. */
  invoices?: Maybe<InvoiceConnection>;
  /** Does the user handle CDD missions */
  isCDD: Scalars['Boolean'];
  /** The last visit date. */
  lastVisit?: Maybe<Scalars['DateTime']>;
  /** The paginated user mission. */
  missions?: Maybe<MissionConnection>;
  /**
   * The mission filters list.
   * @deprecated Use matchingGroupOffices instead
   */
  missionsFilters?: Maybe<MissionFilter>;
  /** NIC front & back */
  nic?: Maybe<Array<Maybe<File>>>;
  /** The user planning days for the given period. */
  planningDays: Array<Maybe<PlanningDay>>;
  /** The user candidate information. */
  proInformation: ProInformation;
  /** The user profile object. */
  profile: UserProfile;
  /** The referral code. */
  referralCode?: Maybe<Scalars['String']>;
  /** The user salary expected. */
  salary?: Maybe<Scalars['Int']>;
  /** The user search criteria */
  searchCriterias: SearchCriteria;
  /** The user skills. */
  skills?: Maybe<Array<Skill>>;
  /** The user specialties. */
  specialties: Array<Specialty>;
  /** The user statistics */
  statistics?: Maybe<Array<Maybe<UserStatisticPeriod>>>;
  /** Set when user has status. */
  status?: Maybe<UserStatusEnum>;
  /** The candidate newsletter subscription. */
  subscription: Scalars['Boolean'];
  /** The user uuid. */
  uuid: Scalars['Uuid'];
  /** The user email validation date. */
  validatedAt?: Maybe<Scalars['DateTime']>;
};


/** A user entity. */
export type UserContractsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  candidateSigningStatus?: InputMaybe<SigningStatus>;
  createdAt?: InputMaybe<DateRangeInput>;
  dates?: InputMaybe<DateRangeInput>;
  first?: InputMaybe<Scalars['Int']>;
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<ContractOrderFieldInput>>;
  packageStatus?: InputMaybe<Array<PackageStatus>>;
  recruiterSigningStatus?: InputMaybe<SigningStatus>;
  replacementType?: InputMaybe<ReplacementTypeEnum>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
};


/** A user entity. */
export type UserInvoicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<DateRangeInput>;
  dates?: InputMaybe<DateRangeInput>;
  first?: InputMaybe<Scalars['Int']>;
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  last?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<Array<InvoiceOrderFieldInput>>;
  paymentStatus?: InputMaybe<Array<InputMaybe<PaymentStatus>>>;
  replacementType?: InputMaybe<ReplacementTypeEnum>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
};


/** A user entity. */
export type UserMissionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  cancellationInitiator?: InputMaybe<Array<InputMaybe<MissionInitiatorEnum>>>;
  candidateSigningStatus?: InputMaybe<SigningStatus>;
  contractDetailCandidateValidationStatus?: InputMaybe<Array<InputMaybe<ValidationStatusEnum>>>;
  contractDetailInvoiceStatus?: InputMaybe<Array<InputMaybe<ContractDetailInvoiceStatusEnum>>>;
  contractDetailRecruiterValidationStatus?: InputMaybe<Array<InputMaybe<ValidationStatusEnum>>>;
  dates?: InputMaybe<DateRangeInput>;
  first?: InputMaybe<Scalars['Int']>;
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  last?: InputMaybe<Scalars['Int']>;
  macroStatus?: InputMaybe<Array<MissionMacroStatusEnum>>;
  orderBy?: InputMaybe<Array<MissionOrderFieldInput>>;
  recruiterSigningStatus?: InputMaybe<SigningStatus>;
  recruiterValidationStatus?: InputMaybe<Array<InputMaybe<ValidationStatusEnum>>>;
  replacementType?: InputMaybe<ReplacementTypeEnum>;
  servicesId?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
  states?: InputMaybe<Array<InputMaybe<MissionStateEnum>>>;
  status?: InputMaybe<Array<MissionStatusEnum>>;
};


/** A user entity. */
export type UserMissionsFiltersArgs = {
  status?: InputMaybe<Array<MissionStatusEnum>>;
};


/** A user entity. */
export type UserPlanningDaysArgs = {
  dates: DateRangeInput;
  limit?: InputMaybe<Scalars['Int']>;
};


/** A user entity. */
export type UserStatisticsArgs = {
  dates: DateRangeInput;
  groupBy: UserStatisticGroupByEnum;
};

/** Salary types. */
export enum UserCanExerciseEnum {
  /** No, in study or PAE in progress. */
  No = 'NO',
  /** Valid replacement license. */
  Pending = 'PENDING',
  /** Order number registered or in progress. */
  Yes = 'YES'
}

/** A user document entity. */
export type UserDocument = {
  __typename?: 'UserDocument';
  /** Set when the user document is uploaded. */
  createdAt: Scalars['DateTime'];
  /** Set when the user document is deleted. */
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The type of document. */
  document: Document;
  /** The user document id. */
  id: Scalars['ID'];
  /** The user document file mime type. */
  mimeType: Scalars['String'];
  /** The user document file name. */
  name: Scalars['String'];
  /** The user owns the document. */
  user: Candidate;
  /** The user document uuid. */
  uuid: Scalars['Uuid'];
};

/** The user files. */
export type UserFiles = {
  __typename?: 'UserFiles';
  /** The cv file name. */
  cv?: Maybe<File>;
  /** The image file. */
  image?: Maybe<ImageFile>;
};

/** The user profile. */
export type UserProfile = {
  __typename?: 'UserProfile';
  /** The user birth day date. */
  birthDay?: Maybe<Scalars['Date']>;
  /** The user description. */
  description?: Maybe<Scalars['String']>;
  /** The user email. Used for login. */
  email: Scalars['String'];
  /** The user first name. */
  firstName?: Maybe<Scalars['String']>;
  /** The user gender. */
  gender: GenderEnum;
  /** The user last name. */
  lastName: Scalars['String'];
  /** The user location object. */
  location?: Maybe<Location>;
  /** Set when the user has updated his name. */
  nameUpdatedAt?: Maybe<Scalars['DateTime']>;
  /** The user password expire date. */
  passwordExpiredAt?: Maybe<Scalars['Date']>;
  /** The user phone number and code. */
  phone: Phone;
  /** Does the user have to validate the cgu ? */
  userNeedCguValidation: Scalars['Boolean'];
};

/** Set the user profile. */
export type UserProfileInput = {
  /** DEPRECATED (Will be removed) - The user birth date. */
  birthDay?: InputMaybe<Scalars['Date']>;
  /** DEPRECATED (bad typo) The user can exercice ou not */
  canExercice?: InputMaybe<UserCanExerciseEnum>;
  /** The user can exercice ou not */
  canExercise?: InputMaybe<UserCanExerciseEnum>;
  /** DEPRECATED (Will be removed) - The user description. */
  description?: InputMaybe<Scalars['String']>;
  /** The user first name. */
  firstName: Scalars['String'];
  /** The user gender. */
  gender: GenderEnum;
  /** An iban to validate. */
  iban?: InputMaybe<Scalars['String']>;
  /** The user is a liberal */
  isLiberal?: InputMaybe<Scalars['Boolean']>;
  /** The user last name. */
  lastName: Scalars['String'];
  /** Location of user */
  localisation?: InputMaybe<LocalisationInput>;
  /** The user phone number and code. */
  phone: PhoneInput;
  /** A valide rpps number. */
  rppsNumber?: InputMaybe<Scalars['String']>;
  /** A valide siret number. */
  siret?: InputMaybe<Scalars['String']>;
  /** Specialities */
  specialities?: InputMaybe<Array<Scalars['ID']>>;
};

/** User statistic group by type. */
export enum UserStatisticGroupByEnum {
  /** Group user statistics by month. */
  Month = 'MONTH',
  /** Group user statistics by year. */
  Year = 'YEAR'
}

/** The user statistic entry. */
export type UserStatisticPeriod = {
  __typename?: 'UserStatisticPeriod';
  /** The period's dates number. */
  nbDates: Scalars['Int'];
  /** The analysis period. */
  period: Scalars['Date'];
  /** The period's total net salaries. */
  totalNetSalaries: Scalars['Float'];
  /** The period's total salaries. */
  totalSalaries: Scalars['Float'];
};

/** List of user status. */
export enum UserStatusEnum {
  /** User is in the public network. */
  CandidateAccess = 'CANDIDATE_ACCESS',
  /** User is in the public network, but not in active research. */
  CandidateDiscrete = 'CANDIDATE_DISCRETE',
  /** User is in the public network, but not visible by recruiters. */
  CandidateInvisible = 'CANDIDATE_INVISIBLE',
  /** User is in the talent network. */
  CandidateTalent = 'CANDIDATE_TALENT'
}

/** User tags */
export enum UserTagEnum {
  /** Feedback addict */
  CommunityDriven = 'COMMUNITY_DRIVEN',
  /** Product driven user */
  Contributor = 'CONTRIBUTOR',
  /** Experienced user level 1 */
  Experienced_1 = 'EXPERIENCED_1',
  /** Experienced user level 2 */
  Experienced_2 = 'EXPERIENCED_2',
  /** Experienced user level 3 */
  Experienced_3 = 'EXPERIENCED_3',
  /** Experienced user level 4 */
  Experienced_4 = 'EXPERIENCED_4',
  /** Experienced user level 5 */
  Experienced_5 = 'EXPERIENCED_5',
  /** User with Advantage */
  Privileged = 'PRIVILEGED',
  /** Fast contract signer */
  Reactive = 'REACTIVE',
  /** At least 5 other tags */
  SuperCaregiver = 'SUPER_CAREGIVER',
  /** Often connected */
  Technophile = 'TECHNOPHILE',
  /** Mobile user */
  Traveler = 'TRAVELER',
  /** Multi service user */
  Versatile = 'VERSATILE'
}

/** The validation data object */
export type Validation = {
  __typename?: 'Validation';
  /** The validation by admin boolean */
  validationByAdmin?: Maybe<Scalars['Boolean']>;
  /** The validation date */
  validationDate?: Maybe<Scalars['DateTime']>;
  /** The validation initiator */
  validationInitiator?: Maybe<Initiator>;
  /** The validation status */
  validationStatus?: Maybe<ValidationStatusEnum>;
};

/** The validation status. */
export enum ValidationStatusEnum {
  /** Object is invalidated. */
  Invalid = 'INVALID',
  /** Object is validated. */
  Valid = 'VALID'
}

/** The working time object. */
export type WorkingTime = {
  __typename?: 'WorkingTime';
  /** The break time duration in minutes. */
  break: Scalars['Int'];
  /** The working time duration in minutes. */
  duration: Scalars['Int'];
  /** The start hour time. */
  time: Scalars['Time'];
};

/** The recruiter mutation object. */
export type RecruiterMutation = {
  __typename?: 'recruiterMutation';
  /** Send annoucement cancellation to Medelse. */
  cancelAnnouncement: CancelAnnouncementPayload;
  /** Delete recruiter password. */
  deleteRecruiterAccount: Recruiter;
  /** create zip and return download link. */
  downloadInvoices: DownloadPayload;
  /** Recruiter cancel request missions. */
  recruiterCancelRequestMissions: RecruiterCancelRequestMissionPayload;
  /** Confirm mission. */
  recruiterConfirmMission: RecruiterConfirmMissionPayload;
  /** Refuse mission. */
  recruiterRefuseMission: RecruiterRefuseMissionPayload;
  /** DEPRECATED - Report mission. */
  recruiterReportMission: RecruiterReportMissionsPayload;
  removeImage: ImageRemovedPayload;
  /** Report mission. */
  reportMission: RecruiterReportMissionsPayload;
  /** Send new announcement to Medelse. */
  sendNewAnnouncement: SendNewAnnouncementPayload;
  /** Update recruiter email. */
  updateRecruiterEmail: UpdateEmailPayload;
  /** Update recruiter password. */
  updateRecruiterPassword: UpdatePasswordPayload;
  /** Update recruiter profile. */
  updateRecruiterProfile: Recruiter;
  uploadImage: ImagePayload;
};


/** The recruiter mutation object. */
export type RecruiterMutationCancelAnnouncementArgs = {
  input: CancelAnnouncementInput;
};


/** The recruiter mutation object. */
export type RecruiterMutationDownloadInvoicesArgs = {
  createdAt?: InputMaybe<DateRangeInput>;
  dates?: InputMaybe<DateRangeInput>;
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  orderBy?: InputMaybe<Array<InvoiceOrderFieldInput>>;
  paymentStatus?: InputMaybe<Array<InputMaybe<PaymentStatus>>>;
};


/** The recruiter mutation object. */
export type RecruiterMutationRecruiterCancelRequestMissionsArgs = {
  input: RecruiterCancelRequestMissionInput;
};


/** The recruiter mutation object. */
export type RecruiterMutationRecruiterConfirmMissionArgs = {
  input: RecruiterConfirmMissionInput;
};


/** The recruiter mutation object. */
export type RecruiterMutationRecruiterRefuseMissionArgs = {
  input: RecruiterRefuseMissionInput;
};


/** The recruiter mutation object. */
export type RecruiterMutationRecruiterReportMissionArgs = {
  input: RecruiterReportMissionInput;
};


/** The recruiter mutation object. */
export type RecruiterMutationRemoveImageArgs = {
  file: Scalars['String'];
};


/** The recruiter mutation object. */
export type RecruiterMutationReportMissionArgs = {
  input: ReportMissionInput;
};


/** The recruiter mutation object. */
export type RecruiterMutationSendNewAnnouncementArgs = {
  input: SendNewAnnouncementInput;
};


/** The recruiter mutation object. */
export type RecruiterMutationUpdateRecruiterEmailArgs = {
  input: UpdateEmailInput;
};


/** The recruiter mutation object. */
export type RecruiterMutationUpdateRecruiterPasswordArgs = {
  input: UpdatePasswordInput;
};


/** The recruiter mutation object. */
export type RecruiterMutationUpdateRecruiterProfileArgs = {
  input: UserProfileInput;
};


/** The recruiter mutation object. */
export type RecruiterMutationUploadImageArgs = {
  file?: InputMaybe<Scalars['UploadType']>;
};

/** The mission's queries for recruiter. */
export type RecruiterQuery = {
  __typename?: 'recruiterQuery';
  /** A paginated list of candidate missions stats. */
  CandidateMissionsStats?: Maybe<Array<CandidateMissionsStats>>;
  /** A paginated list of cities. */
  Cities?: Maybe<CityConnection>;
  /** The city getting by id or google place id. */
  City?: Maybe<City>;
  /** The contract getting by uuid. */
  Contract?: Maybe<Contract>;
  /** A paginated list of countries. */
  Countries?: Maybe<CountryConnection>;
  /** The country getting by id. */
  Country?: Maybe<Country>;
  /** The invoice getting by uuid. */
  Invoice?: Maybe<Invoice>;
  /** The mission getting by uuid. */
  Mission?: Maybe<Mission>;
  /** A paginated list of services. */
  Services?: Maybe<ServiceConnection>;
  /** The skill getting by id. */
  Skill: Skill;
  /** A paginated list of skills. */
  Skills: SkillConnection;
  /** A paginated list of specialties. */
  Specialties?: Maybe<SpecialtyConnection>;
  /** A specialty getting by id. */
  Specialty?: Maybe<Specialty>;
  /** Get the group office upflow dashboard URL if exists */
  getGroupOfficeUpflowURL?: Maybe<Scalars['String']>;
  me?: Maybe<Recruiter>;
};


/** The mission's queries for recruiter. */
export type RecruiterQueryCandidateMissionsStatsArgs = {
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>>>;
  limit?: InputMaybe<Scalars['Int']>;
};


/** The mission's queries for recruiter. */
export type RecruiterQueryCitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


/** The mission's queries for recruiter. */
export type RecruiterQueryCityArgs = {
  googleId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
};


/** The mission's queries for recruiter. */
export type RecruiterQueryContractArgs = {
  uuid: Scalars['Uuid'];
};


/** The mission's queries for recruiter. */
export type RecruiterQueryCountriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


/** The mission's queries for recruiter. */
export type RecruiterQueryCountryArgs = {
  id: Scalars['ID'];
};


/** The mission's queries for recruiter. */
export type RecruiterQueryInvoiceArgs = {
  uuid: Scalars['Uuid'];
};


/** The mission's queries for recruiter. */
export type RecruiterQueryMissionArgs = {
  uuid: Scalars['Uuid'];
};


/** The mission's queries for recruiter. */
export type RecruiterQueryServicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};


/** The mission's queries for recruiter. */
export type RecruiterQuerySkillArgs = {
  id: Scalars['ID'];
};


/** The mission's queries for recruiter. */
export type RecruiterQuerySkillsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
};


/** The mission's queries for recruiter. */
export type RecruiterQuerySpecialtiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  locale?: InputMaybe<LocaleEnum>;
  search?: InputMaybe<Scalars['String']>;
};


/** The mission's queries for recruiter. */
export type RecruiterQuerySpecialtyArgs = {
  id: Scalars['ID'];
};


/** The mission's queries for recruiter. */
export type RecruiterQueryGetGroupOfficeUpflowUrlArgs = {
  groupOfficeUuid: Scalars['Uuid'];
};

export type UpcomingMissionsQueryVariables = Exact<{
  groupOfficesUuid?: InputMaybe<Array<Scalars['Uuid']> | Scalars['Uuid']>;
  dates: DateRangeInput;
}>;


export type UpcomingMissionsQuery = { __typename?: 'recruiterQuery', me?: { __typename?: 'Recruiter', uuid: any, missions?: { __typename?: 'MissionConnection', edges?: Array<{ __typename?: 'MissionEdge', node?: { __typename?: 'Mission', uuid: any, salary: number, sector?: string | null, dates: { __typename?: 'DateTimeRange', startDate?: any | null, endDate?: any | null }, groupOffice: { __typename?: 'GroupOffice', uuid: any, name: string }, candidate: { __typename?: 'Candidate', profile: { __typename?: 'UserProfile', gender: GenderEnum, firstName?: string | null, lastName: string, email: string, phone: { __typename?: 'Phone', code?: number | null, number?: string | null } }, specialties: Array<{ __typename?: 'Specialty', name: string }> }, specialty: { __typename?: 'Specialty', name: string }, service: { __typename?: 'Service', name: string }, specificServices: Array<{ __typename?: 'SpecificService', name: string } | null> } | null } | null> | null } | null } | null };

export type UserGroupOfficesQueryVariables = Exact<{ [key: string]: never; }>;


export type UserGroupOfficesQuery = { __typename?: 'recruiterQuery', me?: { __typename?: 'Recruiter', uuid: any, recruiterGroupOffices?: Array<{ __typename?: 'RecruiterGroupOffice', groupOffice: { __typename?: 'GroupOffice', uuid: any, name: string, isFeatureConfirmationEnabled: boolean } } | null> | null } | null };

export type UserNameQueryVariables = Exact<{ [key: string]: never; }>;


export type UserNameQuery = { __typename?: 'recruiterQuery', me?: { __typename?: 'Recruiter', profile: { __typename?: 'UserProfile', firstName?: string | null, lastName: string } } | null };

export type GetAdministratorQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAdministratorQuery = { __typename?: 'recruiterQuery', me?: { __typename?: 'Recruiter', uuid: any, administrator?: { __typename?: 'Administrator', lastName?: string | null, firstName?: string | null, phoneNumber?: string | null, email: string, agendaLink?: string | null, jobTitle?: AdministratorJobTitleEnum | null, imageFile?: { __typename?: 'ImageFile', square: { __typename?: 'ImageSquare', square273: string } } | null } | null } | null };

export type ContractDataFragmentFragment = { __typename?: 'Contract', uuid: any, contractNumber: string, packageStatus?: PackageStatus | null, recruiterEmail?: string | null, recruiterSignedAt?: any | null, documentDownloadLink?: string | null, candidate?: { __typename?: 'Candidate', profile: { __typename?: 'UserProfile', firstName?: string | null, lastName: string, gender: GenderEnum } } | null, specialty?: { __typename?: 'Specialty', id: string, name: string } | null, contractDetails?: Array<{ __typename?: 'ContractDetail', uuid: any, dateTimes: { __typename?: 'DateTimeRange', startDate?: any | null, endDate?: any | null } } | null> | null };

export type ContractsAlertQueryVariables = Exact<{
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>> | InputMaybe<Scalars['Uuid']>>;
}>;


export type ContractsAlertQuery = { __typename?: 'recruiterQuery', me?: { __typename?: 'Recruiter', uuid: any, contracts?: { __typename?: 'ContractConnection', totalCount?: number | null } | null } | null };

export type ContractsSignedQueryVariables = Exact<{
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>> | InputMaybe<Scalars['Uuid']>>;
  dates?: InputMaybe<DateRangeInput>;
}>;


export type ContractsSignedQuery = { __typename?: 'recruiterQuery', me?: { __typename?: 'Recruiter', uuid: any, contracts?: { __typename?: 'ContractConnection', edges?: Array<{ __typename?: 'ContractEdge', node?: { __typename?: 'Contract', uuid: any, contractNumber: string, packageStatus?: PackageStatus | null, recruiterEmail?: string | null, recruiterSignedAt?: any | null, documentDownloadLink?: string | null, candidate?: { __typename?: 'Candidate', profile: { __typename?: 'UserProfile', firstName?: string | null, lastName: string, gender: GenderEnum } } | null, specialty?: { __typename?: 'Specialty', id: string, name: string } | null, contractDetails?: Array<{ __typename?: 'ContractDetail', uuid: any, dateTimes: { __typename?: 'DateTimeRange', startDate?: any | null, endDate?: any | null } } | null> | null } | null } | null> | null } | null } | null };

export type ContractsToSignQueryVariables = Exact<{
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>> | InputMaybe<Scalars['Uuid']>>;
  dates?: InputMaybe<DateRangeInput>;
}>;


export type ContractsToSignQuery = { __typename?: 'recruiterQuery', me?: { __typename?: 'Recruiter', uuid: any, contracts?: { __typename?: 'ContractConnection', edges?: Array<{ __typename?: 'ContractEdge', node?: { __typename?: 'Contract', uuid: any, contractNumber: string, packageStatus?: PackageStatus | null, recruiterEmail?: string | null, recruiterSignedAt?: any | null, documentDownloadLink?: string | null, candidate?: { __typename?: 'Candidate', profile: { __typename?: 'UserProfile', firstName?: string | null, lastName: string, gender: GenderEnum } } | null, specialty?: { __typename?: 'Specialty', id: string, name: string } | null, contractDetails?: Array<{ __typename?: 'ContractDetail', uuid: any, dateTimes: { __typename?: 'DateTimeRange', startDate?: any | null, endDate?: any | null } } | null> | null } | null } | null> | null } | null } | null };

export type ContractsSignedExportQueryVariables = Exact<{
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>> | InputMaybe<Scalars['Uuid']>>;
  dates?: InputMaybe<DateRangeInput>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
}>;


export type ContractsSignedExportQuery = { __typename?: 'recruiterQuery', me?: { __typename?: 'Recruiter', uuid: any, contracts?: { __typename?: 'ContractConnection', spreadSheet?: { __typename?: 'SpreadSheetFile', url?: string | null } | null } | null } | null };

export type ContractsToSignExportQueryVariables = Exact<{
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>> | InputMaybe<Scalars['Uuid']>>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
  dates?: InputMaybe<DateRangeInput>;
}>;


export type ContractsToSignExportQuery = { __typename?: 'recruiterQuery', me?: { __typename?: 'Recruiter', uuid: any, contracts?: { __typename?: 'ContractConnection', spreadSheet?: { __typename?: 'SpreadSheetFile', url?: string | null } | null } | null } | null };

export type GetContractSigningLinkQueryVariables = Exact<{
  contractUuid: Scalars['Uuid'];
}>;


export type GetContractSigningLinkQuery = { __typename?: 'recruiterQuery', Contract?: { __typename?: 'Contract', uuid: any, recruiterSigningLink?: string | null } | null };

export type ActionBlockQueryVariables = Exact<{
  groupOfficesUuid?: InputMaybe<Array<Scalars['Uuid']> | Scalars['Uuid']>;
}>;


export type ActionBlockQuery = { __typename?: 'recruiterQuery', me?: { __typename?: 'Recruiter', uuid: any, contracts?: { __typename?: 'ContractConnection', totalCount?: number | null } | null, missions?: { __typename?: 'MissionConnection', totalCount?: number | null } | null } | null };

export type CardAnnouncementsQueryVariables = Exact<{
  groupOfficesUuid?: InputMaybe<Array<Scalars['Uuid']> | Scalars['Uuid']>;
}>;


export type CardAnnouncementsQuery = { __typename?: 'recruiterQuery', me?: { __typename?: 'Recruiter', uuid: any, Announcements?: { __typename?: 'AnnouncementShortConnection', totalCount?: number | null } | null } | null };

export type ContractualizedMissionsQueryVariables = Exact<{
  groupOfficesUuid?: InputMaybe<Array<Scalars['Uuid']> | Scalars['Uuid']>;
  dates?: InputMaybe<DateRangeInput>;
}>;


export type ContractualizedMissionsQuery = { __typename?: 'recruiterQuery', me?: { __typename?: 'Recruiter', uuid: any, missions?: { __typename?: 'MissionConnection', totalCount?: number | null } | null } | null };

export type LateInvoicesQueryVariables = Exact<{
  groupOfficesUuid?: InputMaybe<Array<Scalars['Uuid']> | Scalars['Uuid']>;
}>;


export type LateInvoicesQuery = { __typename?: 'recruiterQuery', me?: { __typename?: 'Recruiter', uuid: any, invoices?: { __typename?: 'InvoiceConnection', totalCount?: number | null } | null } | null };

export type MissionsCountInNextInvoiceQueryVariables = Exact<{
  groupOfficesUuid?: InputMaybe<Array<Scalars['Uuid']> | Scalars['Uuid']>;
}>;


export type MissionsCountInNextInvoiceQuery = { __typename?: 'recruiterQuery', me?: { __typename?: 'Recruiter', uuid: any, missions?: { __typename?: 'MissionConnection', totalCount?: number | null } | null } | null };

export type TopTenUsersQueryVariables = Exact<{
  groupOfficesUuid?: InputMaybe<Array<Scalars['Uuid']> | Scalars['Uuid']>;
}>;


export type TopTenUsersQuery = { __typename?: 'recruiterQuery', CandidateMissionsStats?: Array<{ __typename?: 'CandidateMissionsStats', completedMissionNb: number, candidate: { __typename?: 'Candidate', uuid: any, profile: { __typename?: 'UserProfile', gender: GenderEnum, firstName?: string | null, lastName: string }, specialties: Array<{ __typename?: 'Specialty', name: string }> } }> | null };

export type GetGroupOfficeUpflowUrlQueryVariables = Exact<{
  groupOfficeUuid: Scalars['Uuid'];
}>;


export type GetGroupOfficeUpflowUrlQuery = { __typename?: 'recruiterQuery', getGroupOfficeUpflowURL?: string | null };

export type InvoiceBaseDataFragmentFragment = { __typename?: 'Invoice', uuid: any, id: string, documentDownloadLink?: string | null, totalAmount?: number | null, payment?: { __typename?: 'Payment', status?: PaymentStatus | null, dueDate?: any | null, endDate?: any | null } | null, candidate?: { __typename?: 'Candidate', profile: { __typename?: 'UserProfile', firstName?: string | null, lastName: string, gender: GenderEnum } } | null, contractDetails?: Array<{ __typename?: 'ContractDetail', dateTimes: { __typename?: 'DateTimeRange', startDate?: any | null }, specialty: { __typename?: 'Specialty', name: string } } | null> | null };

export type DownloadInvoicesMutationVariables = Exact<{
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>> | InputMaybe<Scalars['Uuid']>>;
  dates?: InputMaybe<DateRangeInput>;
  paymentStatus?: InputMaybe<Array<InputMaybe<PaymentStatus>> | InputMaybe<PaymentStatus>>;
}>;


export type DownloadInvoicesMutation = { __typename?: 'recruiterMutation', downloadInvoices: { __typename?: 'DownloadPayload', link: string } };

export type InvoicesPaidExportQueryVariables = Exact<{
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>> | InputMaybe<Scalars['Uuid']>>;
  dates?: InputMaybe<DateRangeInput>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
}>;


export type InvoicesPaidExportQuery = { __typename?: 'recruiterQuery', me?: { __typename?: 'Recruiter', uuid: any, invoices?: { __typename?: 'InvoiceConnection', spreadSheet?: { __typename?: 'SpreadSheetFile', url?: string | null } | null } | null } | null };

export type InvoicesToPayExportQueryVariables = Exact<{
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>> | InputMaybe<Scalars['Uuid']>>;
  dates?: InputMaybe<DateRangeInput>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
}>;


export type InvoicesToPayExportQuery = { __typename?: 'recruiterQuery', me?: { __typename?: 'Recruiter', uuid: any, invoices?: { __typename?: 'InvoiceConnection', spreadSheet?: { __typename?: 'SpreadSheetFile', url?: string | null } | null } | null } | null };

export type InvoicesAlertQueryVariables = Exact<{
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>> | InputMaybe<Scalars['Uuid']>>;
}>;


export type InvoicesAlertQuery = { __typename?: 'recruiterQuery', me?: { __typename?: 'Recruiter', uuid: any, invoices?: { __typename?: 'InvoiceConnection', totalCount?: number | null } | null } | null };

export type InvoicesPaidQueryVariables = Exact<{
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>> | InputMaybe<Scalars['Uuid']>>;
  dates?: InputMaybe<DateRangeInput>;
}>;


export type InvoicesPaidQuery = { __typename?: 'recruiterQuery', me?: { __typename?: 'Recruiter', uuid: any, invoices?: { __typename?: 'InvoiceConnection', edges?: Array<{ __typename?: 'InvoiceEdge', node?: { __typename?: 'Invoice', uuid: any, id: string, documentDownloadLink?: string | null, totalAmount?: number | null, payment?: { __typename?: 'Payment', status?: PaymentStatus | null, dueDate?: any | null, endDate?: any | null } | null, candidate?: { __typename?: 'Candidate', profile: { __typename?: 'UserProfile', firstName?: string | null, lastName: string, gender: GenderEnum } } | null, contractDetails?: Array<{ __typename?: 'ContractDetail', dateTimes: { __typename?: 'DateTimeRange', startDate?: any | null }, specialty: { __typename?: 'Specialty', name: string } } | null> | null } | null } | null> | null } | null } | null };

export type InvoicesToPayQueryVariables = Exact<{
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>> | InputMaybe<Scalars['Uuid']>>;
  dates?: InputMaybe<DateRangeInput>;
}>;


export type InvoicesToPayQuery = { __typename?: 'recruiterQuery', me?: { __typename?: 'Recruiter', uuid: any, invoices?: { __typename?: 'InvoiceConnection', edges?: Array<{ __typename?: 'InvoiceEdge', node?: { __typename?: 'Invoice', uuid: any, id: string, documentDownloadLink?: string | null, totalAmount?: number | null, payment?: { __typename?: 'Payment', status?: PaymentStatus | null, dueDate?: any | null, endDate?: any | null } | null, candidate?: { __typename?: 'Candidate', profile: { __typename?: 'UserProfile', firstName?: string | null, lastName: string, gender: GenderEnum } } | null, contractDetails?: Array<{ __typename?: 'ContractDetail', dateTimes: { __typename?: 'DateTimeRange', startDate?: any | null }, specialty: { __typename?: 'Specialty', name: string } } | null> | null } | null } | null> | null } | null } | null };

export type CancelMissionRequestMutationVariables = Exact<{
  input: RecruiterCancelRequestMissionInput;
}>;


export type CancelMissionRequestMutation = { __typename?: 'recruiterMutation', recruiterCancelRequestMissions: { __typename?: 'RecruiterCancelRequestMissionPayload', success: boolean } };

export type CancelledMissionsQueryVariables = Exact<{
  groupOfficesUuid?: InputMaybe<Array<Scalars['Uuid']> | Scalars['Uuid']>;
  dates?: InputMaybe<DateRangeInput>;
}>;


export type CancelledMissionsQuery = { __typename?: 'recruiterQuery', me?: { __typename?: 'Recruiter', uuid: any, missions?: { __typename?: 'MissionConnection', edges?: Array<{ __typename?: 'MissionEdge', node?: { __typename?: 'Mission', uuid: any, macroStatus: MissionMacroStatusEnum, salary: number, sector?: string | null, dates: { __typename?: 'DateTimeRange', startDate?: any | null, endDate?: any | null }, candidate: { __typename?: 'Candidate', profile: { __typename?: 'UserProfile', gender: GenderEnum, firstName?: string | null, lastName: string } }, specialty: { __typename?: 'Specialty', name: string }, service: { __typename?: 'Service', name: string }, specificServices: Array<{ __typename?: 'SpecificService', name: string } | null>, groupOffice: { __typename?: 'GroupOffice', uuid: any, name: string } } | null } | null> | null } | null } | null };

export type ConfirmMissionMutationVariables = Exact<{
  input: RecruiterConfirmMissionInput;
}>;


export type ConfirmMissionMutation = { __typename?: 'recruiterMutation', recruiterConfirmMission: { __typename?: 'RecruiterConfirmMissionPayload', mission: { __typename?: 'Mission', uuid: any, recruiterValidation?: { __typename?: 'Validation', validationStatus?: ValidationStatusEnum | null, validationDate?: any | null } | null } } };

export type CancelledMissionsExportQueryVariables = Exact<{
  groupOfficesUuid?: InputMaybe<Array<Scalars['Uuid']> | Scalars['Uuid']>;
  dates?: InputMaybe<DateRangeInput>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
}>;


export type CancelledMissionsExportQuery = { __typename?: 'recruiterQuery', me?: { __typename?: 'Recruiter', uuid: any, missions?: { __typename?: 'MissionConnection', spreadSheet?: { __typename?: 'SpreadSheetFile', url?: string | null } | null } | null } | null };

export type FinishedMissionsExportQueryVariables = Exact<{
  groupOfficesUuid?: InputMaybe<Array<Scalars['Uuid']> | Scalars['Uuid']>;
  dates?: InputMaybe<DateRangeInput>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
}>;


export type FinishedMissionsExportQuery = { __typename?: 'recruiterQuery', me?: { __typename?: 'Recruiter', uuid: any, missions?: { __typename?: 'MissionConnection', spreadSheet?: { __typename?: 'SpreadSheetFile', url?: string | null } | null } | null } | null };

export type MissionsToConfirmExportQueryVariables = Exact<{
  groupOfficesUuid?: InputMaybe<Array<Scalars['Uuid']> | Scalars['Uuid']>;
  dates?: InputMaybe<DateRangeInput>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
}>;


export type MissionsToConfirmExportQuery = { __typename?: 'recruiterQuery', me?: { __typename?: 'Recruiter', uuid: any, missions?: { __typename?: 'MissionConnection', spreadSheet?: { __typename?: 'SpreadSheetFile', url?: string | null } | null } | null } | null };

export type OnlineAnnouncementsExportQueryVariables = Exact<{
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>> | InputMaybe<Scalars['Uuid']>>;
  dates?: InputMaybe<DateRangeInput>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
}>;


export type OnlineAnnouncementsExportQuery = { __typename?: 'recruiterQuery', me?: { __typename?: 'Recruiter', uuid: any, Announcements?: { __typename?: 'AnnouncementShortConnection', spreadSheet?: { __typename?: 'SpreadSheetFile', url?: string | null } | null } | null } | null };

export type UpcomingMissionsExportQueryVariables = Exact<{
  groupOfficesUuid?: InputMaybe<Array<Scalars['Uuid']> | Scalars['Uuid']>;
  dates?: InputMaybe<DateRangeInput>;
  spreadSheet?: InputMaybe<ExportToSpreadsheetInput>;
}>;


export type UpcomingMissionsExportQuery = { __typename?: 'recruiterQuery', me?: { __typename?: 'Recruiter', uuid: any, missions?: { __typename?: 'MissionConnection', spreadSheet?: { __typename?: 'SpreadSheetFile', url?: string | null } | null } | null } | null };

export type FinishedMissionsQueryVariables = Exact<{
  groupOfficesUuid?: InputMaybe<Array<Scalars['Uuid']> | Scalars['Uuid']>;
  dates?: InputMaybe<DateRangeInput>;
}>;


export type FinishedMissionsQuery = { __typename?: 'recruiterQuery', me?: { __typename?: 'Recruiter', uuid: any, missions?: { __typename?: 'MissionConnection', edges?: Array<{ __typename?: 'MissionEdge', node?: { __typename?: 'Mission', uuid: any, breakDuration: number, salary: number, sector?: string | null, dates: { __typename?: 'DateTimeRange', startDate?: any | null, endDate?: any | null }, candidate: { __typename?: 'Candidate', profile: { __typename?: 'UserProfile', gender: GenderEnum, firstName?: string | null, lastName: string } }, specialty: { __typename?: 'Specialty', name: string }, service: { __typename?: 'Service', name: string }, specificServices: Array<{ __typename?: 'SpecificService', name: string } | null>, groupOffice: { __typename?: 'GroupOffice', uuid: any, name: string }, contractDetail?: { __typename?: 'ContractDetail', recruiterValidationStatus?: ValidationStatusEnum | null, recruiterValidationDate?: any | null } | null } | null } | null> | null } | null } | null };

export type MissionsToConfirmQueryVariables = Exact<{
  groupOfficesUuid?: InputMaybe<Array<Scalars['Uuid']> | Scalars['Uuid']>;
  dates?: InputMaybe<DateRangeInput>;
}>;


export type MissionsToConfirmQuery = { __typename?: 'recruiterQuery', me?: { __typename?: 'Recruiter', uuid: any, missions?: { __typename?: 'MissionConnection', edges?: Array<{ __typename?: 'MissionEdge', node?: { __typename?: 'Mission', uuid: any, breakDuration: number, salary: number, sector?: string | null, dates: { __typename?: 'DateTimeRange', startDate?: any | null, endDate?: any | null }, candidate: { __typename?: 'Candidate', profile: { __typename?: 'UserProfile', gender: GenderEnum, firstName?: string | null, lastName: string } }, specialty: { __typename?: 'Specialty', name: string }, service: { __typename?: 'Service', name: string }, specificServices: Array<{ __typename?: 'SpecificService', name: string } | null>, groupOffice: { __typename?: 'GroupOffice', uuid: any, name: string }, recruiterValidation?: { __typename?: 'Validation', validationStatus?: ValidationStatusEnum | null, validationDate?: any | null } | null } | null } | null> | null } | null } | null };

export type MissionsToConfirmCountQueryVariables = Exact<{
  groupOfficesUuid?: InputMaybe<Array<Scalars['Uuid']> | Scalars['Uuid']>;
}>;


export type MissionsToConfirmCountQuery = { __typename?: 'recruiterQuery', me?: { __typename?: 'Recruiter', uuid: any, missions?: { __typename?: 'MissionConnection', totalCount?: number | null } | null } | null };

export type OnlineAnnouncementsQueryVariables = Exact<{
  groupOfficesUuid?: InputMaybe<Array<InputMaybe<Scalars['Uuid']>> | InputMaybe<Scalars['Uuid']>>;
  dates?: InputMaybe<DateRangeInput>;
}>;


export type OnlineAnnouncementsQuery = { __typename?: 'recruiterQuery', me?: { __typename?: 'Recruiter', uuid: any, Announcements?: { __typename?: 'AnnouncementShortConnection', edges?: Array<{ __typename?: 'AnnouncementShortEdge', node?: { __typename?: 'AnnouncementShort', uuid: any, salary: number, sector?: string | null, nbrNeeds?: number | null, dates: { __typename?: 'DateTimeRange', startDate?: any | null, endDate?: any | null }, specialty: { __typename?: 'Specialty', name: string }, service?: { __typename?: 'Service', name: string } | null, specificServices: Array<{ __typename?: 'SpecificService', name: string } | null>, groupOffice: { __typename?: 'GroupOffice', uuid: any, name: string } } | null } | null> | null } | null } | null };

export type RefuseMissionMutationVariables = Exact<{
  input: RecruiterRefuseMissionInput;
}>;


export type RefuseMissionMutation = { __typename?: 'recruiterMutation', recruiterRefuseMission: { __typename?: 'RecruiterRefuseMissionPayload', mission: { __typename?: 'Mission', uuid: any, recruiterValidation?: { __typename?: 'Validation', validationStatus?: ValidationStatusEnum | null, validationDate?: any | null } | null } } };

export type ReportMissionMutationVariables = Exact<{
  input: ReportMissionInput;
}>;


export type ReportMissionMutation = { __typename?: 'recruiterMutation', reportMission: { __typename?: 'RecruiterReportMissionsPayload', mission: { __typename?: 'Mission', uuid: any, contractDetail?: { __typename?: 'ContractDetail', recruiterValidationStatus?: ValidationStatusEnum | null, recruiterValidationDate?: any | null } | null } } };

export type DeleteRecruiterAccountMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteRecruiterAccountMutation = { __typename?: 'recruiterMutation', deleteRecruiterAccount: { __typename?: 'Recruiter', uuid: any } };

export type GetParametersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetParametersQuery = { __typename?: 'recruiterQuery', me?: { __typename?: 'Recruiter', uuid: any, profile: { __typename?: 'UserProfile', gender: GenderEnum, firstName?: string | null, lastName: string, email: string, phone: { __typename?: 'Phone', number?: string | null, code?: number | null } } } | null };

export type UpdateRecruiterEmailMutationVariables = Exact<{
  input: UpdateEmailInput;
}>;


export type UpdateRecruiterEmailMutation = { __typename?: 'recruiterMutation', updateRecruiterEmail: { __typename?: 'UpdateEmailPayload', user: { __typename?: 'User', uuid: any } } };

export type UpdateRecruiterPasswordMutationVariables = Exact<{
  input: UpdatePasswordInput;
}>;


export type UpdateRecruiterPasswordMutation = { __typename?: 'recruiterMutation', updateRecruiterPassword: { __typename?: 'UpdatePasswordPayload', user: { __typename?: 'User', uuid: any } } };

export type UpdateRecruiterProfileMutationVariables = Exact<{
  input: UserProfileInput;
}>;


export type UpdateRecruiterProfileMutation = { __typename?: 'recruiterMutation', updateRecruiterProfile: { __typename?: 'Recruiter', uuid: any, profile: { __typename?: 'UserProfile', gender: GenderEnum, firstName?: string | null, lastName: string, phone: { __typename?: 'Phone', code?: number | null, number?: string | null } } } };

export type CancelAnnouncementMutationVariables = Exact<{
  input: CancelAnnouncementInput;
}>;


export type CancelAnnouncementMutation = { __typename?: 'recruiterMutation', cancelAnnouncement: { __typename?: 'CancelAnnouncementPayload', groupOffice: { __typename?: 'GroupOffice', uuid: any } } };

export type CreateAnnouncementMutationVariables = Exact<{
  input: SendNewAnnouncementInput;
}>;


export type CreateAnnouncementMutation = { __typename?: 'recruiterMutation', sendNewAnnouncement: { __typename?: 'SendNewAnnouncementPayload', success: boolean } };

export const ContractDataFragmentFragmentDoc = gql`
    fragment ContractDataFragment on Contract {
  uuid
  contractNumber
  candidate {
    profile {
      firstName
      lastName
      gender
    }
  }
  specialty {
    id
    name
  }
  contractDetails {
    uuid
    dateTimes {
      startDate
      endDate
    }
  }
  packageStatus
  recruiterEmail
  recruiterSignedAt
  documentDownloadLink
}
    `;
export const InvoiceBaseDataFragmentFragmentDoc = gql`
    fragment InvoiceBaseDataFragment on Invoice {
  uuid
  id
  payment {
    status
    dueDate
    endDate
  }
  documentDownloadLink
  candidate {
    profile {
      firstName
      lastName
      gender
    }
  }
  contractDetails {
    dateTimes {
      startDate
    }
    specialty {
      name
    }
  }
  totalAmount
}
    `;
export const UpcomingMissionsDocument = gql`
    query upcomingMissions($groupOfficesUuid: [Uuid!], $dates: DateRangeInput!) {
  me {
    uuid
    missions(
      macroStatus: [CONTRACTUALIZED_PENDING, CONTRACTUALIZED_SIGNED_BY_CANDIDATE, CONTRACTUALIZED_SIGNED_BY_RECRUITER, CONTRACTUALIZED_FULLY_SIGNED_UPCOMING, CONTRACTUALIZED_FULLY_SIGNED_IN_PROGRESS]
      replacementType: SESSION
      groupOfficesUuid: $groupOfficesUuid
      dates: $dates
    ) {
      edges {
        node {
          uuid
          dates {
            startDate
            endDate
          }
          groupOffice {
            uuid
            name
          }
          candidate {
            profile {
              gender
              firstName
              lastName
              email
              phone {
                code
                number
              }
            }
            specialties {
              name
            }
          }
          specialty {
            name
          }
          service {
            name
          }
          specificServices {
            name
          }
          salary
          sector
        }
      }
    }
  }
}
    `;

/**
 * __useUpcomingMissionsQuery__
 *
 * To run a query within a React component, call `useUpcomingMissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingMissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingMissionsQuery({
 *   variables: {
 *      groupOfficesUuid: // value for 'groupOfficesUuid'
 *      dates: // value for 'dates'
 *   },
 * });
 */
export function useUpcomingMissionsQuery(baseOptions: Apollo.QueryHookOptions<UpcomingMissionsQuery, UpcomingMissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpcomingMissionsQuery, UpcomingMissionsQueryVariables>(UpcomingMissionsDocument, options);
      }
export function useUpcomingMissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpcomingMissionsQuery, UpcomingMissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpcomingMissionsQuery, UpcomingMissionsQueryVariables>(UpcomingMissionsDocument, options);
        }
export type UpcomingMissionsQueryHookResult = ReturnType<typeof useUpcomingMissionsQuery>;
export type UpcomingMissionsLazyQueryHookResult = ReturnType<typeof useUpcomingMissionsLazyQuery>;
export type UpcomingMissionsQueryResult = Apollo.QueryResult<UpcomingMissionsQuery, UpcomingMissionsQueryVariables>;
export const UserGroupOfficesDocument = gql`
    query userGroupOffices {
  me {
    uuid
    recruiterGroupOffices {
      groupOffice {
        uuid
        name
        isFeatureConfirmationEnabled
      }
    }
  }
}
    `;

/**
 * __useUserGroupOfficesQuery__
 *
 * To run a query within a React component, call `useUserGroupOfficesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGroupOfficesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGroupOfficesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserGroupOfficesQuery(baseOptions?: Apollo.QueryHookOptions<UserGroupOfficesQuery, UserGroupOfficesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserGroupOfficesQuery, UserGroupOfficesQueryVariables>(UserGroupOfficesDocument, options);
      }
export function useUserGroupOfficesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserGroupOfficesQuery, UserGroupOfficesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserGroupOfficesQuery, UserGroupOfficesQueryVariables>(UserGroupOfficesDocument, options);
        }
export type UserGroupOfficesQueryHookResult = ReturnType<typeof useUserGroupOfficesQuery>;
export type UserGroupOfficesLazyQueryHookResult = ReturnType<typeof useUserGroupOfficesLazyQuery>;
export type UserGroupOfficesQueryResult = Apollo.QueryResult<UserGroupOfficesQuery, UserGroupOfficesQueryVariables>;
export const UserNameDocument = gql`
    query userName {
  me {
    profile {
      firstName
      lastName
    }
  }
}
    `;

/**
 * __useUserNameQuery__
 *
 * To run a query within a React component, call `useUserNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNameQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserNameQuery(baseOptions?: Apollo.QueryHookOptions<UserNameQuery, UserNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserNameQuery, UserNameQueryVariables>(UserNameDocument, options);
      }
export function useUserNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserNameQuery, UserNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserNameQuery, UserNameQueryVariables>(UserNameDocument, options);
        }
export type UserNameQueryHookResult = ReturnType<typeof useUserNameQuery>;
export type UserNameLazyQueryHookResult = ReturnType<typeof useUserNameLazyQuery>;
export type UserNameQueryResult = Apollo.QueryResult<UserNameQuery, UserNameQueryVariables>;
export const GetAdministratorDocument = gql`
    query getAdministrator {
  me {
    uuid
    administrator {
      lastName
      firstName
      phoneNumber
      email
      agendaLink
      jobTitle
      imageFile {
        square {
          square273
        }
      }
    }
  }
}
    `;

/**
 * __useGetAdministratorQuery__
 *
 * To run a query within a React component, call `useGetAdministratorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdministratorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdministratorQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAdministratorQuery(baseOptions?: Apollo.QueryHookOptions<GetAdministratorQuery, GetAdministratorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAdministratorQuery, GetAdministratorQueryVariables>(GetAdministratorDocument, options);
      }
export function useGetAdministratorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAdministratorQuery, GetAdministratorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAdministratorQuery, GetAdministratorQueryVariables>(GetAdministratorDocument, options);
        }
export type GetAdministratorQueryHookResult = ReturnType<typeof useGetAdministratorQuery>;
export type GetAdministratorLazyQueryHookResult = ReturnType<typeof useGetAdministratorLazyQuery>;
export type GetAdministratorQueryResult = Apollo.QueryResult<GetAdministratorQuery, GetAdministratorQueryVariables>;
export const ContractsAlertDocument = gql`
    query contractsAlert($groupOfficesUuid: [Uuid]) {
  me {
    uuid
    contracts(
      packageStatus: PENDING_SIGNATURE
      recruiterSigningStatus: NOT_SIGNED
      groupOfficesUuid: $groupOfficesUuid
      replacementType: SESSION
    ) {
      totalCount
    }
  }
}
    `;

/**
 * __useContractsAlertQuery__
 *
 * To run a query within a React component, call `useContractsAlertQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractsAlertQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractsAlertQuery({
 *   variables: {
 *      groupOfficesUuid: // value for 'groupOfficesUuid'
 *   },
 * });
 */
export function useContractsAlertQuery(baseOptions?: Apollo.QueryHookOptions<ContractsAlertQuery, ContractsAlertQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractsAlertQuery, ContractsAlertQueryVariables>(ContractsAlertDocument, options);
      }
export function useContractsAlertLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractsAlertQuery, ContractsAlertQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractsAlertQuery, ContractsAlertQueryVariables>(ContractsAlertDocument, options);
        }
export type ContractsAlertQueryHookResult = ReturnType<typeof useContractsAlertQuery>;
export type ContractsAlertLazyQueryHookResult = ReturnType<typeof useContractsAlertLazyQuery>;
export type ContractsAlertQueryResult = Apollo.QueryResult<ContractsAlertQuery, ContractsAlertQueryVariables>;
export const ContractsSignedDocument = gql`
    query contractsSigned($groupOfficesUuid: [Uuid], $dates: DateRangeInput) {
  me {
    uuid
    contracts(
      recruiterSigningStatus: SIGNED
      groupOfficesUuid: $groupOfficesUuid
      dates: $dates
      replacementType: SESSION
    ) {
      edges {
        node {
          ...ContractDataFragment
        }
      }
    }
  }
}
    ${ContractDataFragmentFragmentDoc}`;

/**
 * __useContractsSignedQuery__
 *
 * To run a query within a React component, call `useContractsSignedQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractsSignedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractsSignedQuery({
 *   variables: {
 *      groupOfficesUuid: // value for 'groupOfficesUuid'
 *      dates: // value for 'dates'
 *   },
 * });
 */
export function useContractsSignedQuery(baseOptions?: Apollo.QueryHookOptions<ContractsSignedQuery, ContractsSignedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractsSignedQuery, ContractsSignedQueryVariables>(ContractsSignedDocument, options);
      }
export function useContractsSignedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractsSignedQuery, ContractsSignedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractsSignedQuery, ContractsSignedQueryVariables>(ContractsSignedDocument, options);
        }
export type ContractsSignedQueryHookResult = ReturnType<typeof useContractsSignedQuery>;
export type ContractsSignedLazyQueryHookResult = ReturnType<typeof useContractsSignedLazyQuery>;
export type ContractsSignedQueryResult = Apollo.QueryResult<ContractsSignedQuery, ContractsSignedQueryVariables>;
export const ContractsToSignDocument = gql`
    query contractsToSign($groupOfficesUuid: [Uuid], $dates: DateRangeInput) {
  me {
    uuid
    contracts(
      packageStatus: PENDING_SIGNATURE
      recruiterSigningStatus: NOT_SIGNED
      groupOfficesUuid: $groupOfficesUuid
      dates: $dates
      replacementType: SESSION
    ) {
      edges {
        node {
          ...ContractDataFragment
        }
      }
    }
  }
}
    ${ContractDataFragmentFragmentDoc}`;

/**
 * __useContractsToSignQuery__
 *
 * To run a query within a React component, call `useContractsToSignQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractsToSignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractsToSignQuery({
 *   variables: {
 *      groupOfficesUuid: // value for 'groupOfficesUuid'
 *      dates: // value for 'dates'
 *   },
 * });
 */
export function useContractsToSignQuery(baseOptions?: Apollo.QueryHookOptions<ContractsToSignQuery, ContractsToSignQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractsToSignQuery, ContractsToSignQueryVariables>(ContractsToSignDocument, options);
      }
export function useContractsToSignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractsToSignQuery, ContractsToSignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractsToSignQuery, ContractsToSignQueryVariables>(ContractsToSignDocument, options);
        }
export type ContractsToSignQueryHookResult = ReturnType<typeof useContractsToSignQuery>;
export type ContractsToSignLazyQueryHookResult = ReturnType<typeof useContractsToSignLazyQuery>;
export type ContractsToSignQueryResult = Apollo.QueryResult<ContractsToSignQuery, ContractsToSignQueryVariables>;
export const ContractsSignedExportDocument = gql`
    query contractsSignedExport($groupOfficesUuid: [Uuid], $dates: DateRangeInput, $spreadSheet: ExportToSpreadsheetInput) {
  me {
    uuid
    contracts(
      recruiterSigningStatus: SIGNED
      groupOfficesUuid: $groupOfficesUuid
      spreadSheet: $spreadSheet
      dates: $dates
      replacementType: SESSION
    ) {
      spreadSheet(
        recruiterSigningStatus: SIGNED
        groupOfficesUuid: $groupOfficesUuid
        spreadSheet: $spreadSheet
        dates: $dates
        replacementType: SESSION
      ) {
        url
      }
    }
  }
}
    `;

/**
 * __useContractsSignedExportQuery__
 *
 * To run a query within a React component, call `useContractsSignedExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractsSignedExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractsSignedExportQuery({
 *   variables: {
 *      groupOfficesUuid: // value for 'groupOfficesUuid'
 *      dates: // value for 'dates'
 *      spreadSheet: // value for 'spreadSheet'
 *   },
 * });
 */
export function useContractsSignedExportQuery(baseOptions?: Apollo.QueryHookOptions<ContractsSignedExportQuery, ContractsSignedExportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractsSignedExportQuery, ContractsSignedExportQueryVariables>(ContractsSignedExportDocument, options);
      }
export function useContractsSignedExportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractsSignedExportQuery, ContractsSignedExportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractsSignedExportQuery, ContractsSignedExportQueryVariables>(ContractsSignedExportDocument, options);
        }
export type ContractsSignedExportQueryHookResult = ReturnType<typeof useContractsSignedExportQuery>;
export type ContractsSignedExportLazyQueryHookResult = ReturnType<typeof useContractsSignedExportLazyQuery>;
export type ContractsSignedExportQueryResult = Apollo.QueryResult<ContractsSignedExportQuery, ContractsSignedExportQueryVariables>;
export const ContractsToSignExportDocument = gql`
    query contractsToSignExport($groupOfficesUuid: [Uuid], $spreadSheet: ExportToSpreadsheetInput, $dates: DateRangeInput) {
  me {
    uuid
    contracts(
      packageStatus: PENDING_SIGNATURE
      recruiterSigningStatus: NOT_SIGNED
      groupOfficesUuid: $groupOfficesUuid
      spreadSheet: $spreadSheet
      dates: $dates
      replacementType: SESSION
    ) {
      spreadSheet(
        packageStatus: PENDING_SIGNATURE
        recruiterSigningStatus: NOT_SIGNED
        groupOfficesUuid: $groupOfficesUuid
        spreadSheet: $spreadSheet
        dates: $dates
        replacementType: SESSION
      ) {
        url
      }
    }
  }
}
    `;

/**
 * __useContractsToSignExportQuery__
 *
 * To run a query within a React component, call `useContractsToSignExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractsToSignExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractsToSignExportQuery({
 *   variables: {
 *      groupOfficesUuid: // value for 'groupOfficesUuid'
 *      spreadSheet: // value for 'spreadSheet'
 *      dates: // value for 'dates'
 *   },
 * });
 */
export function useContractsToSignExportQuery(baseOptions?: Apollo.QueryHookOptions<ContractsToSignExportQuery, ContractsToSignExportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractsToSignExportQuery, ContractsToSignExportQueryVariables>(ContractsToSignExportDocument, options);
      }
export function useContractsToSignExportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractsToSignExportQuery, ContractsToSignExportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractsToSignExportQuery, ContractsToSignExportQueryVariables>(ContractsToSignExportDocument, options);
        }
export type ContractsToSignExportQueryHookResult = ReturnType<typeof useContractsToSignExportQuery>;
export type ContractsToSignExportLazyQueryHookResult = ReturnType<typeof useContractsToSignExportLazyQuery>;
export type ContractsToSignExportQueryResult = Apollo.QueryResult<ContractsToSignExportQuery, ContractsToSignExportQueryVariables>;
export const GetContractSigningLinkDocument = gql`
    query getContractSigningLink($contractUuid: Uuid!) {
  Contract(uuid: $contractUuid) {
    uuid
    recruiterSigningLink
  }
}
    `;

/**
 * __useGetContractSigningLinkQuery__
 *
 * To run a query within a React component, call `useGetContractSigningLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContractSigningLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContractSigningLinkQuery({
 *   variables: {
 *      contractUuid: // value for 'contractUuid'
 *   },
 * });
 */
export function useGetContractSigningLinkQuery(baseOptions: Apollo.QueryHookOptions<GetContractSigningLinkQuery, GetContractSigningLinkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContractSigningLinkQuery, GetContractSigningLinkQueryVariables>(GetContractSigningLinkDocument, options);
      }
export function useGetContractSigningLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContractSigningLinkQuery, GetContractSigningLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContractSigningLinkQuery, GetContractSigningLinkQueryVariables>(GetContractSigningLinkDocument, options);
        }
export type GetContractSigningLinkQueryHookResult = ReturnType<typeof useGetContractSigningLinkQuery>;
export type GetContractSigningLinkLazyQueryHookResult = ReturnType<typeof useGetContractSigningLinkLazyQuery>;
export type GetContractSigningLinkQueryResult = Apollo.QueryResult<GetContractSigningLinkQuery, GetContractSigningLinkQueryVariables>;
export const ActionBlockDocument = gql`
    query actionBlock($groupOfficesUuid: [Uuid!]) {
  me {
    uuid
    contracts(
      packageStatus: PENDING_SIGNATURE
      recruiterSigningStatus: NOT_SIGNED
      groupOfficesUuid: $groupOfficesUuid
      replacementType: SESSION
    ) {
      totalCount
    }
    missions(
      macroStatus: [WAITING_FOR_CONTRACT]
      recruiterValidationStatus: null
      groupOfficesUuid: $groupOfficesUuid
      replacementType: SESSION
    ) {
      totalCount
    }
  }
}
    `;

/**
 * __useActionBlockQuery__
 *
 * To run a query within a React component, call `useActionBlockQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionBlockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionBlockQuery({
 *   variables: {
 *      groupOfficesUuid: // value for 'groupOfficesUuid'
 *   },
 * });
 */
export function useActionBlockQuery(baseOptions?: Apollo.QueryHookOptions<ActionBlockQuery, ActionBlockQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActionBlockQuery, ActionBlockQueryVariables>(ActionBlockDocument, options);
      }
export function useActionBlockLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActionBlockQuery, ActionBlockQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActionBlockQuery, ActionBlockQueryVariables>(ActionBlockDocument, options);
        }
export type ActionBlockQueryHookResult = ReturnType<typeof useActionBlockQuery>;
export type ActionBlockLazyQueryHookResult = ReturnType<typeof useActionBlockLazyQuery>;
export type ActionBlockQueryResult = Apollo.QueryResult<ActionBlockQuery, ActionBlockQueryVariables>;
export const CardAnnouncementsDocument = gql`
    query cardAnnouncements($groupOfficesUuid: [Uuid!]) {
  me {
    uuid
    Announcements(
      status: PUBLISHED
      groupOfficesUuid: $groupOfficesUuid
      replacementType: SESSION
    ) {
      totalCount
    }
  }
}
    `;

/**
 * __useCardAnnouncementsQuery__
 *
 * To run a query within a React component, call `useCardAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCardAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCardAnnouncementsQuery({
 *   variables: {
 *      groupOfficesUuid: // value for 'groupOfficesUuid'
 *   },
 * });
 */
export function useCardAnnouncementsQuery(baseOptions?: Apollo.QueryHookOptions<CardAnnouncementsQuery, CardAnnouncementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CardAnnouncementsQuery, CardAnnouncementsQueryVariables>(CardAnnouncementsDocument, options);
      }
export function useCardAnnouncementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CardAnnouncementsQuery, CardAnnouncementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CardAnnouncementsQuery, CardAnnouncementsQueryVariables>(CardAnnouncementsDocument, options);
        }
export type CardAnnouncementsQueryHookResult = ReturnType<typeof useCardAnnouncementsQuery>;
export type CardAnnouncementsLazyQueryHookResult = ReturnType<typeof useCardAnnouncementsLazyQuery>;
export type CardAnnouncementsQueryResult = Apollo.QueryResult<CardAnnouncementsQuery, CardAnnouncementsQueryVariables>;
export const ContractualizedMissionsDocument = gql`
    query contractualizedMissions($groupOfficesUuid: [Uuid!], $dates: DateRangeInput) {
  me {
    uuid
    missions(
      macroStatus: [CONTRACTUALIZED_PENDING, CONTRACTUALIZED_SIGNED_BY_CANDIDATE, CONTRACTUALIZED_SIGNED_BY_RECRUITER, CONTRACTUALIZED_FULLY_SIGNED_UPCOMING, CONTRACTUALIZED_FULLY_SIGNED_IN_PROGRESS, VALIDATION_INVALIDATED_BY_BOTH, VALIDATION_INVALIDATED_BY_CANDIDATE, VALIDATION_INVALIDATED_BY_RECRUITER, VALIDATION_VALIDATED_BY_CANDIDATE_INVALIDATED_BY_RECRUITER, VALIDATION_INVALIDATED_BY_CANDIDATE_VALIDATED_BY_RECRUITER, VALIDATION_VALIDATED_BY_RECRUITER, VALIDATION_VALIDATED_BY_CANDIDATE, VALIDATION_PENDING, INVOICEABLE, NOT_INVOICEABLE, INVOICED]
      groupOfficesUuid: $groupOfficesUuid
      dates: $dates
      replacementType: SESSION
    ) {
      totalCount
    }
  }
}
    `;

/**
 * __useContractualizedMissionsQuery__
 *
 * To run a query within a React component, call `useContractualizedMissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractualizedMissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractualizedMissionsQuery({
 *   variables: {
 *      groupOfficesUuid: // value for 'groupOfficesUuid'
 *      dates: // value for 'dates'
 *   },
 * });
 */
export function useContractualizedMissionsQuery(baseOptions?: Apollo.QueryHookOptions<ContractualizedMissionsQuery, ContractualizedMissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ContractualizedMissionsQuery, ContractualizedMissionsQueryVariables>(ContractualizedMissionsDocument, options);
      }
export function useContractualizedMissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ContractualizedMissionsQuery, ContractualizedMissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ContractualizedMissionsQuery, ContractualizedMissionsQueryVariables>(ContractualizedMissionsDocument, options);
        }
export type ContractualizedMissionsQueryHookResult = ReturnType<typeof useContractualizedMissionsQuery>;
export type ContractualizedMissionsLazyQueryHookResult = ReturnType<typeof useContractualizedMissionsLazyQuery>;
export type ContractualizedMissionsQueryResult = Apollo.QueryResult<ContractualizedMissionsQuery, ContractualizedMissionsQueryVariables>;
export const LateInvoicesDocument = gql`
    query lateInvoices($groupOfficesUuid: [Uuid!]) {
  me {
    uuid
    invoices(
      paymentStatus: LATE
      groupOfficesUuid: $groupOfficesUuid
      replacementType: SESSION
    ) {
      totalCount
    }
  }
}
    `;

/**
 * __useLateInvoicesQuery__
 *
 * To run a query within a React component, call `useLateInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useLateInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLateInvoicesQuery({
 *   variables: {
 *      groupOfficesUuid: // value for 'groupOfficesUuid'
 *   },
 * });
 */
export function useLateInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<LateInvoicesQuery, LateInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LateInvoicesQuery, LateInvoicesQueryVariables>(LateInvoicesDocument, options);
      }
export function useLateInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LateInvoicesQuery, LateInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LateInvoicesQuery, LateInvoicesQueryVariables>(LateInvoicesDocument, options);
        }
export type LateInvoicesQueryHookResult = ReturnType<typeof useLateInvoicesQuery>;
export type LateInvoicesLazyQueryHookResult = ReturnType<typeof useLateInvoicesLazyQuery>;
export type LateInvoicesQueryResult = Apollo.QueryResult<LateInvoicesQuery, LateInvoicesQueryVariables>;
export const MissionsCountInNextInvoiceDocument = gql`
    query missionsCountInNextInvoice($groupOfficesUuid: [Uuid!]) {
  me {
    uuid
    missions(
      groupOfficesUuid: $groupOfficesUuid
      macroStatus: [CONTRACTUALIZED_PENDING, CONTRACTUALIZED_SIGNED_BY_CANDIDATE, CONTRACTUALIZED_SIGNED_BY_RECRUITER, CONTRACTUALIZED_FULLY_SIGNED_UPCOMING, CONTRACTUALIZED_FULLY_SIGNED_IN_PROGRESS, VALIDATION_INVALIDATED_BY_BOTH, VALIDATION_INVALIDATED_BY_CANDIDATE, VALIDATION_INVALIDATED_BY_RECRUITER, VALIDATION_VALIDATED_BY_CANDIDATE_INVALIDATED_BY_RECRUITER, VALIDATION_INVALIDATED_BY_CANDIDATE_VALIDATED_BY_RECRUITER, VALIDATION_VALIDATED_BY_RECRUITER, VALIDATION_VALIDATED_BY_CANDIDATE, VALIDATION_PENDING, INVOICEABLE]
      contractDetailInvoiceStatus: INVOICEABLE
      contractDetailCandidateValidationStatus: VALID
      contractDetailRecruiterValidationStatus: [VALID, null]
      replacementType: SESSION
    ) {
      totalCount
    }
  }
}
    `;

/**
 * __useMissionsCountInNextInvoiceQuery__
 *
 * To run a query within a React component, call `useMissionsCountInNextInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useMissionsCountInNextInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMissionsCountInNextInvoiceQuery({
 *   variables: {
 *      groupOfficesUuid: // value for 'groupOfficesUuid'
 *   },
 * });
 */
export function useMissionsCountInNextInvoiceQuery(baseOptions?: Apollo.QueryHookOptions<MissionsCountInNextInvoiceQuery, MissionsCountInNextInvoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MissionsCountInNextInvoiceQuery, MissionsCountInNextInvoiceQueryVariables>(MissionsCountInNextInvoiceDocument, options);
      }
export function useMissionsCountInNextInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MissionsCountInNextInvoiceQuery, MissionsCountInNextInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MissionsCountInNextInvoiceQuery, MissionsCountInNextInvoiceQueryVariables>(MissionsCountInNextInvoiceDocument, options);
        }
export type MissionsCountInNextInvoiceQueryHookResult = ReturnType<typeof useMissionsCountInNextInvoiceQuery>;
export type MissionsCountInNextInvoiceLazyQueryHookResult = ReturnType<typeof useMissionsCountInNextInvoiceLazyQuery>;
export type MissionsCountInNextInvoiceQueryResult = Apollo.QueryResult<MissionsCountInNextInvoiceQuery, MissionsCountInNextInvoiceQueryVariables>;
export const TopTenUsersDocument = gql`
    query topTenUsers($groupOfficesUuid: [Uuid!]) {
  CandidateMissionsStats(groupOfficesUuid: $groupOfficesUuid, limit: 10) {
    candidate {
      uuid
      profile {
        gender
        firstName
        lastName
      }
      specialties {
        name
      }
    }
    completedMissionNb
  }
}
    `;

/**
 * __useTopTenUsersQuery__
 *
 * To run a query within a React component, call `useTopTenUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopTenUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopTenUsersQuery({
 *   variables: {
 *      groupOfficesUuid: // value for 'groupOfficesUuid'
 *   },
 * });
 */
export function useTopTenUsersQuery(baseOptions?: Apollo.QueryHookOptions<TopTenUsersQuery, TopTenUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopTenUsersQuery, TopTenUsersQueryVariables>(TopTenUsersDocument, options);
      }
export function useTopTenUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopTenUsersQuery, TopTenUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopTenUsersQuery, TopTenUsersQueryVariables>(TopTenUsersDocument, options);
        }
export type TopTenUsersQueryHookResult = ReturnType<typeof useTopTenUsersQuery>;
export type TopTenUsersLazyQueryHookResult = ReturnType<typeof useTopTenUsersLazyQuery>;
export type TopTenUsersQueryResult = Apollo.QueryResult<TopTenUsersQuery, TopTenUsersQueryVariables>;
export const GetGroupOfficeUpflowUrlDocument = gql`
    query getGroupOfficeUpflowURL($groupOfficeUuid: Uuid!) {
  getGroupOfficeUpflowURL(groupOfficeUuid: $groupOfficeUuid)
}
    `;

/**
 * __useGetGroupOfficeUpflowUrlQuery__
 *
 * To run a query within a React component, call `useGetGroupOfficeUpflowUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupOfficeUpflowUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupOfficeUpflowUrlQuery({
 *   variables: {
 *      groupOfficeUuid: // value for 'groupOfficeUuid'
 *   },
 * });
 */
export function useGetGroupOfficeUpflowUrlQuery(baseOptions: Apollo.QueryHookOptions<GetGroupOfficeUpflowUrlQuery, GetGroupOfficeUpflowUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGroupOfficeUpflowUrlQuery, GetGroupOfficeUpflowUrlQueryVariables>(GetGroupOfficeUpflowUrlDocument, options);
      }
export function useGetGroupOfficeUpflowUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGroupOfficeUpflowUrlQuery, GetGroupOfficeUpflowUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGroupOfficeUpflowUrlQuery, GetGroupOfficeUpflowUrlQueryVariables>(GetGroupOfficeUpflowUrlDocument, options);
        }
export type GetGroupOfficeUpflowUrlQueryHookResult = ReturnType<typeof useGetGroupOfficeUpflowUrlQuery>;
export type GetGroupOfficeUpflowUrlLazyQueryHookResult = ReturnType<typeof useGetGroupOfficeUpflowUrlLazyQuery>;
export type GetGroupOfficeUpflowUrlQueryResult = Apollo.QueryResult<GetGroupOfficeUpflowUrlQuery, GetGroupOfficeUpflowUrlQueryVariables>;
export const DownloadInvoicesDocument = gql`
    mutation downloadInvoices($groupOfficesUuid: [Uuid], $dates: DateRangeInput, $paymentStatus: [PaymentStatus]) {
  downloadInvoices(
    groupOfficesUuid: $groupOfficesUuid
    dates: $dates
    paymentStatus: $paymentStatus
  ) {
    link
  }
}
    `;
export type DownloadInvoicesMutationFn = Apollo.MutationFunction<DownloadInvoicesMutation, DownloadInvoicesMutationVariables>;

/**
 * __useDownloadInvoicesMutation__
 *
 * To run a mutation, you first call `useDownloadInvoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadInvoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadInvoicesMutation, { data, loading, error }] = useDownloadInvoicesMutation({
 *   variables: {
 *      groupOfficesUuid: // value for 'groupOfficesUuid'
 *      dates: // value for 'dates'
 *      paymentStatus: // value for 'paymentStatus'
 *   },
 * });
 */
export function useDownloadInvoicesMutation(baseOptions?: Apollo.MutationHookOptions<DownloadInvoicesMutation, DownloadInvoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadInvoicesMutation, DownloadInvoicesMutationVariables>(DownloadInvoicesDocument, options);
      }
export type DownloadInvoicesMutationHookResult = ReturnType<typeof useDownloadInvoicesMutation>;
export type DownloadInvoicesMutationResult = Apollo.MutationResult<DownloadInvoicesMutation>;
export type DownloadInvoicesMutationOptions = Apollo.BaseMutationOptions<DownloadInvoicesMutation, DownloadInvoicesMutationVariables>;
export const InvoicesPaidExportDocument = gql`
    query invoicesPaidExport($groupOfficesUuid: [Uuid], $dates: DateRangeInput, $spreadSheet: ExportToSpreadsheetInput) {
  me {
    uuid
    invoices(
      paymentStatus: [PAID]
      groupOfficesUuid: $groupOfficesUuid
      spreadSheet: $spreadSheet
      dates: $dates
      replacementType: SESSION
    ) {
      spreadSheet(
        paymentStatus: [PAID]
        groupOfficesUuid: $groupOfficesUuid
        spreadSheet: $spreadSheet
        dates: $dates
      ) {
        url
      }
    }
  }
}
    `;

/**
 * __useInvoicesPaidExportQuery__
 *
 * To run a query within a React component, call `useInvoicesPaidExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesPaidExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesPaidExportQuery({
 *   variables: {
 *      groupOfficesUuid: // value for 'groupOfficesUuid'
 *      dates: // value for 'dates'
 *      spreadSheet: // value for 'spreadSheet'
 *   },
 * });
 */
export function useInvoicesPaidExportQuery(baseOptions?: Apollo.QueryHookOptions<InvoicesPaidExportQuery, InvoicesPaidExportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoicesPaidExportQuery, InvoicesPaidExportQueryVariables>(InvoicesPaidExportDocument, options);
      }
export function useInvoicesPaidExportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicesPaidExportQuery, InvoicesPaidExportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoicesPaidExportQuery, InvoicesPaidExportQueryVariables>(InvoicesPaidExportDocument, options);
        }
export type InvoicesPaidExportQueryHookResult = ReturnType<typeof useInvoicesPaidExportQuery>;
export type InvoicesPaidExportLazyQueryHookResult = ReturnType<typeof useInvoicesPaidExportLazyQuery>;
export type InvoicesPaidExportQueryResult = Apollo.QueryResult<InvoicesPaidExportQuery, InvoicesPaidExportQueryVariables>;
export const InvoicesToPayExportDocument = gql`
    query invoicesToPayExport($groupOfficesUuid: [Uuid], $dates: DateRangeInput, $spreadSheet: ExportToSpreadsheetInput) {
  me {
    uuid
    invoices(
      paymentStatus: [LATE, PENDING, PROCESSING]
      groupOfficesUuid: $groupOfficesUuid
      spreadSheet: $spreadSheet
      dates: $dates
      replacementType: SESSION
    ) {
      spreadSheet(
        paymentStatus: [LATE, PENDING, PROCESSING]
        groupOfficesUuid: $groupOfficesUuid
        spreadSheet: $spreadSheet
        dates: $dates
        replacementType: SESSION
      ) {
        url
      }
    }
  }
}
    `;

/**
 * __useInvoicesToPayExportQuery__
 *
 * To run a query within a React component, call `useInvoicesToPayExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesToPayExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesToPayExportQuery({
 *   variables: {
 *      groupOfficesUuid: // value for 'groupOfficesUuid'
 *      dates: // value for 'dates'
 *      spreadSheet: // value for 'spreadSheet'
 *   },
 * });
 */
export function useInvoicesToPayExportQuery(baseOptions?: Apollo.QueryHookOptions<InvoicesToPayExportQuery, InvoicesToPayExportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoicesToPayExportQuery, InvoicesToPayExportQueryVariables>(InvoicesToPayExportDocument, options);
      }
export function useInvoicesToPayExportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicesToPayExportQuery, InvoicesToPayExportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoicesToPayExportQuery, InvoicesToPayExportQueryVariables>(InvoicesToPayExportDocument, options);
        }
export type InvoicesToPayExportQueryHookResult = ReturnType<typeof useInvoicesToPayExportQuery>;
export type InvoicesToPayExportLazyQueryHookResult = ReturnType<typeof useInvoicesToPayExportLazyQuery>;
export type InvoicesToPayExportQueryResult = Apollo.QueryResult<InvoicesToPayExportQuery, InvoicesToPayExportQueryVariables>;
export const InvoicesAlertDocument = gql`
    query invoicesAlert($groupOfficesUuid: [Uuid]) {
  me {
    uuid
    invoices(
      paymentStatus: [PENDING, LATE, PROCESSING]
      groupOfficesUuid: $groupOfficesUuid
      replacementType: SESSION
    ) {
      totalCount
    }
  }
}
    `;

/**
 * __useInvoicesAlertQuery__
 *
 * To run a query within a React component, call `useInvoicesAlertQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesAlertQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesAlertQuery({
 *   variables: {
 *      groupOfficesUuid: // value for 'groupOfficesUuid'
 *   },
 * });
 */
export function useInvoicesAlertQuery(baseOptions?: Apollo.QueryHookOptions<InvoicesAlertQuery, InvoicesAlertQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoicesAlertQuery, InvoicesAlertQueryVariables>(InvoicesAlertDocument, options);
      }
export function useInvoicesAlertLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicesAlertQuery, InvoicesAlertQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoicesAlertQuery, InvoicesAlertQueryVariables>(InvoicesAlertDocument, options);
        }
export type InvoicesAlertQueryHookResult = ReturnType<typeof useInvoicesAlertQuery>;
export type InvoicesAlertLazyQueryHookResult = ReturnType<typeof useInvoicesAlertLazyQuery>;
export type InvoicesAlertQueryResult = Apollo.QueryResult<InvoicesAlertQuery, InvoicesAlertQueryVariables>;
export const InvoicesPaidDocument = gql`
    query invoicesPaid($groupOfficesUuid: [Uuid], $dates: DateRangeInput) {
  me {
    uuid
    invoices(
      paymentStatus: [PAID]
      dates: $dates
      groupOfficesUuid: $groupOfficesUuid
      replacementType: SESSION
    ) {
      edges {
        node {
          ...InvoiceBaseDataFragment
        }
      }
    }
  }
}
    ${InvoiceBaseDataFragmentFragmentDoc}`;

/**
 * __useInvoicesPaidQuery__
 *
 * To run a query within a React component, call `useInvoicesPaidQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesPaidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesPaidQuery({
 *   variables: {
 *      groupOfficesUuid: // value for 'groupOfficesUuid'
 *      dates: // value for 'dates'
 *   },
 * });
 */
export function useInvoicesPaidQuery(baseOptions?: Apollo.QueryHookOptions<InvoicesPaidQuery, InvoicesPaidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoicesPaidQuery, InvoicesPaidQueryVariables>(InvoicesPaidDocument, options);
      }
export function useInvoicesPaidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicesPaidQuery, InvoicesPaidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoicesPaidQuery, InvoicesPaidQueryVariables>(InvoicesPaidDocument, options);
        }
export type InvoicesPaidQueryHookResult = ReturnType<typeof useInvoicesPaidQuery>;
export type InvoicesPaidLazyQueryHookResult = ReturnType<typeof useInvoicesPaidLazyQuery>;
export type InvoicesPaidQueryResult = Apollo.QueryResult<InvoicesPaidQuery, InvoicesPaidQueryVariables>;
export const InvoicesToPayDocument = gql`
    query invoicesToPay($groupOfficesUuid: [Uuid], $dates: DateRangeInput) {
  me {
    uuid
    invoices(
      paymentStatus: [LATE, PENDING, PROCESSING, BLOCKED, CANCELED]
      dates: $dates
      groupOfficesUuid: $groupOfficesUuid
      replacementType: SESSION
    ) {
      edges {
        node {
          ...InvoiceBaseDataFragment
        }
      }
    }
  }
}
    ${InvoiceBaseDataFragmentFragmentDoc}`;

/**
 * __useInvoicesToPayQuery__
 *
 * To run a query within a React component, call `useInvoicesToPayQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesToPayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesToPayQuery({
 *   variables: {
 *      groupOfficesUuid: // value for 'groupOfficesUuid'
 *      dates: // value for 'dates'
 *   },
 * });
 */
export function useInvoicesToPayQuery(baseOptions?: Apollo.QueryHookOptions<InvoicesToPayQuery, InvoicesToPayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvoicesToPayQuery, InvoicesToPayQueryVariables>(InvoicesToPayDocument, options);
      }
export function useInvoicesToPayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicesToPayQuery, InvoicesToPayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvoicesToPayQuery, InvoicesToPayQueryVariables>(InvoicesToPayDocument, options);
        }
export type InvoicesToPayQueryHookResult = ReturnType<typeof useInvoicesToPayQuery>;
export type InvoicesToPayLazyQueryHookResult = ReturnType<typeof useInvoicesToPayLazyQuery>;
export type InvoicesToPayQueryResult = Apollo.QueryResult<InvoicesToPayQuery, InvoicesToPayQueryVariables>;
export const CancelMissionRequestDocument = gql`
    mutation cancelMissionRequest($input: RecruiterCancelRequestMissionInput!) {
  recruiterCancelRequestMissions(input: $input) {
    success
  }
}
    `;
export type CancelMissionRequestMutationFn = Apollo.MutationFunction<CancelMissionRequestMutation, CancelMissionRequestMutationVariables>;

/**
 * __useCancelMissionRequestMutation__
 *
 * To run a mutation, you first call `useCancelMissionRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelMissionRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelMissionRequestMutation, { data, loading, error }] = useCancelMissionRequestMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelMissionRequestMutation(baseOptions?: Apollo.MutationHookOptions<CancelMissionRequestMutation, CancelMissionRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelMissionRequestMutation, CancelMissionRequestMutationVariables>(CancelMissionRequestDocument, options);
      }
export type CancelMissionRequestMutationHookResult = ReturnType<typeof useCancelMissionRequestMutation>;
export type CancelMissionRequestMutationResult = Apollo.MutationResult<CancelMissionRequestMutation>;
export type CancelMissionRequestMutationOptions = Apollo.BaseMutationOptions<CancelMissionRequestMutation, CancelMissionRequestMutationVariables>;
export const CancelledMissionsDocument = gql`
    query cancelledMissions($groupOfficesUuid: [Uuid!], $dates: DateRangeInput) {
  me {
    uuid
    missions(
      groupOfficesUuid: $groupOfficesUuid
      dates: $dates
      macroStatus: [CANCELLED_BY_CANDIDATE, CANCELLED_BY_RECRUITER]
      replacementType: SESSION
    ) {
      edges {
        node {
          uuid
          macroStatus
          dates {
            startDate
            endDate
          }
          candidate {
            profile {
              gender
              firstName
              lastName
            }
          }
          specialty {
            name
          }
          service {
            name
          }
          specificServices {
            name
          }
          groupOffice {
            uuid
            name
          }
          salary
          sector
        }
      }
    }
  }
}
    `;

/**
 * __useCancelledMissionsQuery__
 *
 * To run a query within a React component, call `useCancelledMissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCancelledMissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCancelledMissionsQuery({
 *   variables: {
 *      groupOfficesUuid: // value for 'groupOfficesUuid'
 *      dates: // value for 'dates'
 *   },
 * });
 */
export function useCancelledMissionsQuery(baseOptions?: Apollo.QueryHookOptions<CancelledMissionsQuery, CancelledMissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CancelledMissionsQuery, CancelledMissionsQueryVariables>(CancelledMissionsDocument, options);
      }
export function useCancelledMissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CancelledMissionsQuery, CancelledMissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CancelledMissionsQuery, CancelledMissionsQueryVariables>(CancelledMissionsDocument, options);
        }
export type CancelledMissionsQueryHookResult = ReturnType<typeof useCancelledMissionsQuery>;
export type CancelledMissionsLazyQueryHookResult = ReturnType<typeof useCancelledMissionsLazyQuery>;
export type CancelledMissionsQueryResult = Apollo.QueryResult<CancelledMissionsQuery, CancelledMissionsQueryVariables>;
export const ConfirmMissionDocument = gql`
    mutation confirmMission($input: RecruiterConfirmMissionInput!) {
  recruiterConfirmMission(input: $input) {
    mission {
      uuid
      recruiterValidation {
        validationStatus
        validationDate
      }
    }
  }
}
    `;
export type ConfirmMissionMutationFn = Apollo.MutationFunction<ConfirmMissionMutation, ConfirmMissionMutationVariables>;

/**
 * __useConfirmMissionMutation__
 *
 * To run a mutation, you first call `useConfirmMissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmMissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmMissionMutation, { data, loading, error }] = useConfirmMissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConfirmMissionMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmMissionMutation, ConfirmMissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmMissionMutation, ConfirmMissionMutationVariables>(ConfirmMissionDocument, options);
      }
export type ConfirmMissionMutationHookResult = ReturnType<typeof useConfirmMissionMutation>;
export type ConfirmMissionMutationResult = Apollo.MutationResult<ConfirmMissionMutation>;
export type ConfirmMissionMutationOptions = Apollo.BaseMutationOptions<ConfirmMissionMutation, ConfirmMissionMutationVariables>;
export const CancelledMissionsExportDocument = gql`
    query cancelledMissionsExport($groupOfficesUuid: [Uuid!], $dates: DateRangeInput, $spreadSheet: ExportToSpreadsheetInput) {
  me {
    uuid
    missions(
      groupOfficesUuid: $groupOfficesUuid
      dates: $dates
      macroStatus: [CANCELLED_BY_CANDIDATE, CANCELLED_BY_RECRUITER]
      spreadSheet: $spreadSheet
      replacementType: SESSION
    ) {
      spreadSheet(
        groupOfficesUuid: $groupOfficesUuid
        macroStatus: [CANCELLED_BY_CANDIDATE, CANCELLED_BY_RECRUITER]
        dates: $dates
        spreadSheet: $spreadSheet
        replacementType: SESSION
      ) {
        url
      }
    }
  }
}
    `;

/**
 * __useCancelledMissionsExportQuery__
 *
 * To run a query within a React component, call `useCancelledMissionsExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useCancelledMissionsExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCancelledMissionsExportQuery({
 *   variables: {
 *      groupOfficesUuid: // value for 'groupOfficesUuid'
 *      dates: // value for 'dates'
 *      spreadSheet: // value for 'spreadSheet'
 *   },
 * });
 */
export function useCancelledMissionsExportQuery(baseOptions?: Apollo.QueryHookOptions<CancelledMissionsExportQuery, CancelledMissionsExportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CancelledMissionsExportQuery, CancelledMissionsExportQueryVariables>(CancelledMissionsExportDocument, options);
      }
export function useCancelledMissionsExportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CancelledMissionsExportQuery, CancelledMissionsExportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CancelledMissionsExportQuery, CancelledMissionsExportQueryVariables>(CancelledMissionsExportDocument, options);
        }
export type CancelledMissionsExportQueryHookResult = ReturnType<typeof useCancelledMissionsExportQuery>;
export type CancelledMissionsExportLazyQueryHookResult = ReturnType<typeof useCancelledMissionsExportLazyQuery>;
export type CancelledMissionsExportQueryResult = Apollo.QueryResult<CancelledMissionsExportQuery, CancelledMissionsExportQueryVariables>;
export const FinishedMissionsExportDocument = gql`
    query finishedMissionsExport($groupOfficesUuid: [Uuid!], $dates: DateRangeInput, $spreadSheet: ExportToSpreadsheetInput) {
  me {
    uuid
    missions(
      groupOfficesUuid: $groupOfficesUuid
      dates: $dates
      macroStatus: [VALIDATION_INVALIDATED_BY_BOTH, VALIDATION_INVALIDATED_BY_CANDIDATE, VALIDATION_INVALIDATED_BY_RECRUITER, VALIDATION_VALIDATED_BY_CANDIDATE_INVALIDATED_BY_RECRUITER, VALIDATION_INVALIDATED_BY_CANDIDATE_VALIDATED_BY_RECRUITER, VALIDATION_VALIDATED_BY_RECRUITER, VALIDATION_VALIDATED_BY_CANDIDATE, VALIDATION_PENDING, INVOICEABLE, INVOICED, NOT_INVOICEABLE]
      spreadSheet: $spreadSheet
      replacementType: SESSION
    ) {
      spreadSheet(
        groupOfficesUuid: $groupOfficesUuid
        macroStatus: [VALIDATION_INVALIDATED_BY_BOTH, VALIDATION_INVALIDATED_BY_CANDIDATE, VALIDATION_INVALIDATED_BY_RECRUITER, VALIDATION_VALIDATED_BY_CANDIDATE_INVALIDATED_BY_RECRUITER, VALIDATION_INVALIDATED_BY_CANDIDATE_VALIDATED_BY_RECRUITER, VALIDATION_VALIDATED_BY_RECRUITER, VALIDATION_VALIDATED_BY_CANDIDATE, VALIDATION_PENDING, INVOICEABLE, INVOICED, NOT_INVOICEABLE]
        dates: $dates
        spreadSheet: $spreadSheet
        replacementType: SESSION
      ) {
        url
      }
    }
  }
}
    `;

/**
 * __useFinishedMissionsExportQuery__
 *
 * To run a query within a React component, call `useFinishedMissionsExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinishedMissionsExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinishedMissionsExportQuery({
 *   variables: {
 *      groupOfficesUuid: // value for 'groupOfficesUuid'
 *      dates: // value for 'dates'
 *      spreadSheet: // value for 'spreadSheet'
 *   },
 * });
 */
export function useFinishedMissionsExportQuery(baseOptions?: Apollo.QueryHookOptions<FinishedMissionsExportQuery, FinishedMissionsExportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FinishedMissionsExportQuery, FinishedMissionsExportQueryVariables>(FinishedMissionsExportDocument, options);
      }
export function useFinishedMissionsExportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FinishedMissionsExportQuery, FinishedMissionsExportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FinishedMissionsExportQuery, FinishedMissionsExportQueryVariables>(FinishedMissionsExportDocument, options);
        }
export type FinishedMissionsExportQueryHookResult = ReturnType<typeof useFinishedMissionsExportQuery>;
export type FinishedMissionsExportLazyQueryHookResult = ReturnType<typeof useFinishedMissionsExportLazyQuery>;
export type FinishedMissionsExportQueryResult = Apollo.QueryResult<FinishedMissionsExportQuery, FinishedMissionsExportQueryVariables>;
export const MissionsToConfirmExportDocument = gql`
    query missionsToConfirmExport($groupOfficesUuid: [Uuid!], $dates: DateRangeInput, $spreadSheet: ExportToSpreadsheetInput) {
  me {
    uuid
    missions(
      groupOfficesUuid: $groupOfficesUuid
      macroStatus: [WAITING_FOR_CONTRACT]
      spreadSheet: $spreadSheet
      dates: $dates
      replacementType: SESSION
    ) {
      spreadSheet(
        groupOfficesUuid: $groupOfficesUuid
        macroStatus: [WAITING_FOR_CONTRACT]
        spreadSheet: $spreadSheet
        dates: $dates
        replacementType: SESSION
      ) {
        url
      }
    }
  }
}
    `;

/**
 * __useMissionsToConfirmExportQuery__
 *
 * To run a query within a React component, call `useMissionsToConfirmExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useMissionsToConfirmExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMissionsToConfirmExportQuery({
 *   variables: {
 *      groupOfficesUuid: // value for 'groupOfficesUuid'
 *      dates: // value for 'dates'
 *      spreadSheet: // value for 'spreadSheet'
 *   },
 * });
 */
export function useMissionsToConfirmExportQuery(baseOptions?: Apollo.QueryHookOptions<MissionsToConfirmExportQuery, MissionsToConfirmExportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MissionsToConfirmExportQuery, MissionsToConfirmExportQueryVariables>(MissionsToConfirmExportDocument, options);
      }
export function useMissionsToConfirmExportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MissionsToConfirmExportQuery, MissionsToConfirmExportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MissionsToConfirmExportQuery, MissionsToConfirmExportQueryVariables>(MissionsToConfirmExportDocument, options);
        }
export type MissionsToConfirmExportQueryHookResult = ReturnType<typeof useMissionsToConfirmExportQuery>;
export type MissionsToConfirmExportLazyQueryHookResult = ReturnType<typeof useMissionsToConfirmExportLazyQuery>;
export type MissionsToConfirmExportQueryResult = Apollo.QueryResult<MissionsToConfirmExportQuery, MissionsToConfirmExportQueryVariables>;
export const OnlineAnnouncementsExportDocument = gql`
    query onlineAnnouncementsExport($groupOfficesUuid: [Uuid], $dates: DateRangeInput, $spreadSheet: ExportToSpreadsheetInput) {
  me {
    uuid
    Announcements(
      status: [PUBLISHED]
      groupOfficesUuid: $groupOfficesUuid
      spreadSheet: $spreadSheet
      dates: $dates
      replacementType: SESSION
    ) {
      spreadSheet(
        status: [PUBLISHED]
        groupOfficesUuid: $groupOfficesUuid
        spreadSheet: $spreadSheet
        dates: $dates
        replacementType: SESSION
      ) {
        url
      }
    }
  }
}
    `;

/**
 * __useOnlineAnnouncementsExportQuery__
 *
 * To run a query within a React component, call `useOnlineAnnouncementsExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnlineAnnouncementsExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnlineAnnouncementsExportQuery({
 *   variables: {
 *      groupOfficesUuid: // value for 'groupOfficesUuid'
 *      dates: // value for 'dates'
 *      spreadSheet: // value for 'spreadSheet'
 *   },
 * });
 */
export function useOnlineAnnouncementsExportQuery(baseOptions?: Apollo.QueryHookOptions<OnlineAnnouncementsExportQuery, OnlineAnnouncementsExportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnlineAnnouncementsExportQuery, OnlineAnnouncementsExportQueryVariables>(OnlineAnnouncementsExportDocument, options);
      }
export function useOnlineAnnouncementsExportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnlineAnnouncementsExportQuery, OnlineAnnouncementsExportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnlineAnnouncementsExportQuery, OnlineAnnouncementsExportQueryVariables>(OnlineAnnouncementsExportDocument, options);
        }
export type OnlineAnnouncementsExportQueryHookResult = ReturnType<typeof useOnlineAnnouncementsExportQuery>;
export type OnlineAnnouncementsExportLazyQueryHookResult = ReturnType<typeof useOnlineAnnouncementsExportLazyQuery>;
export type OnlineAnnouncementsExportQueryResult = Apollo.QueryResult<OnlineAnnouncementsExportQuery, OnlineAnnouncementsExportQueryVariables>;
export const UpcomingMissionsExportDocument = gql`
    query upcomingMissionsExport($groupOfficesUuid: [Uuid!], $dates: DateRangeInput, $spreadSheet: ExportToSpreadsheetInput) {
  me {
    uuid
    missions(
      macroStatus: [CONTRACTUALIZED_PENDING, CONTRACTUALIZED_SIGNED_BY_CANDIDATE, CONTRACTUALIZED_SIGNED_BY_RECRUITER, CONTRACTUALIZED_FULLY_SIGNED_UPCOMING, CONTRACTUALIZED_FULLY_SIGNED_IN_PROGRESS]
      groupOfficesUuid: $groupOfficesUuid
      dates: $dates
      spreadSheet: $spreadSheet
      replacementType: SESSION
    ) {
      spreadSheet(
        groupOfficesUuid: $groupOfficesUuid
        macroStatus: [CONTRACTUALIZED_PENDING, CONTRACTUALIZED_SIGNED_BY_CANDIDATE, CONTRACTUALIZED_SIGNED_BY_RECRUITER, CONTRACTUALIZED_FULLY_SIGNED_UPCOMING, CONTRACTUALIZED_FULLY_SIGNED_IN_PROGRESS]
        spreadSheet: $spreadSheet
        dates: $dates
        replacementType: SESSION
      ) {
        url
      }
    }
  }
}
    `;

/**
 * __useUpcomingMissionsExportQuery__
 *
 * To run a query within a React component, call `useUpcomingMissionsExportQuery` and pass it any options that fit your needs.
 * When your component renders, `useUpcomingMissionsExportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUpcomingMissionsExportQuery({
 *   variables: {
 *      groupOfficesUuid: // value for 'groupOfficesUuid'
 *      dates: // value for 'dates'
 *      spreadSheet: // value for 'spreadSheet'
 *   },
 * });
 */
export function useUpcomingMissionsExportQuery(baseOptions?: Apollo.QueryHookOptions<UpcomingMissionsExportQuery, UpcomingMissionsExportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UpcomingMissionsExportQuery, UpcomingMissionsExportQueryVariables>(UpcomingMissionsExportDocument, options);
      }
export function useUpcomingMissionsExportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UpcomingMissionsExportQuery, UpcomingMissionsExportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UpcomingMissionsExportQuery, UpcomingMissionsExportQueryVariables>(UpcomingMissionsExportDocument, options);
        }
export type UpcomingMissionsExportQueryHookResult = ReturnType<typeof useUpcomingMissionsExportQuery>;
export type UpcomingMissionsExportLazyQueryHookResult = ReturnType<typeof useUpcomingMissionsExportLazyQuery>;
export type UpcomingMissionsExportQueryResult = Apollo.QueryResult<UpcomingMissionsExportQuery, UpcomingMissionsExportQueryVariables>;
export const FinishedMissionsDocument = gql`
    query finishedMissions($groupOfficesUuid: [Uuid!], $dates: DateRangeInput) {
  me {
    uuid
    missions(
      groupOfficesUuid: $groupOfficesUuid
      dates: $dates
      macroStatus: [VALIDATION_INVALIDATED_BY_BOTH, VALIDATION_INVALIDATED_BY_CANDIDATE, VALIDATION_INVALIDATED_BY_RECRUITER, VALIDATION_VALIDATED_BY_CANDIDATE_INVALIDATED_BY_RECRUITER, VALIDATION_INVALIDATED_BY_CANDIDATE_VALIDATED_BY_RECRUITER, VALIDATION_VALIDATED_BY_RECRUITER, VALIDATION_VALIDATED_BY_CANDIDATE, VALIDATION_PENDING, INVOICEABLE, INVOICED, NOT_INVOICEABLE]
      replacementType: SESSION
    ) {
      edges {
        node {
          uuid
          breakDuration
          dates {
            startDate
            endDate
          }
          candidate {
            profile {
              gender
              firstName
              lastName
            }
          }
          specialty {
            name
          }
          service {
            name
          }
          specificServices {
            name
          }
          groupOffice {
            uuid
            name
          }
          salary
          sector
          contractDetail {
            recruiterValidationStatus
            recruiterValidationDate
          }
        }
      }
    }
  }
}
    `;

/**
 * __useFinishedMissionsQuery__
 *
 * To run a query within a React component, call `useFinishedMissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFinishedMissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFinishedMissionsQuery({
 *   variables: {
 *      groupOfficesUuid: // value for 'groupOfficesUuid'
 *      dates: // value for 'dates'
 *   },
 * });
 */
export function useFinishedMissionsQuery(baseOptions?: Apollo.QueryHookOptions<FinishedMissionsQuery, FinishedMissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FinishedMissionsQuery, FinishedMissionsQueryVariables>(FinishedMissionsDocument, options);
      }
export function useFinishedMissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FinishedMissionsQuery, FinishedMissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FinishedMissionsQuery, FinishedMissionsQueryVariables>(FinishedMissionsDocument, options);
        }
export type FinishedMissionsQueryHookResult = ReturnType<typeof useFinishedMissionsQuery>;
export type FinishedMissionsLazyQueryHookResult = ReturnType<typeof useFinishedMissionsLazyQuery>;
export type FinishedMissionsQueryResult = Apollo.QueryResult<FinishedMissionsQuery, FinishedMissionsQueryVariables>;
export const MissionsToConfirmDocument = gql`
    query missionsToConfirm($groupOfficesUuid: [Uuid!], $dates: DateRangeInput) {
  me {
    uuid
    missions(
      groupOfficesUuid: $groupOfficesUuid
      dates: $dates
      macroStatus: [WAITING_FOR_CONTRACT]
      replacementType: SESSION
    ) {
      edges {
        node {
          uuid
          breakDuration
          dates {
            startDate
            endDate
          }
          candidate {
            profile {
              gender
              firstName
              lastName
            }
          }
          specialty {
            name
          }
          service {
            name
          }
          specificServices {
            name
          }
          groupOffice {
            uuid
            name
          }
          salary
          sector
          recruiterValidation {
            validationStatus
            validationDate
          }
        }
      }
    }
  }
}
    `;

/**
 * __useMissionsToConfirmQuery__
 *
 * To run a query within a React component, call `useMissionsToConfirmQuery` and pass it any options that fit your needs.
 * When your component renders, `useMissionsToConfirmQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMissionsToConfirmQuery({
 *   variables: {
 *      groupOfficesUuid: // value for 'groupOfficesUuid'
 *      dates: // value for 'dates'
 *   },
 * });
 */
export function useMissionsToConfirmQuery(baseOptions?: Apollo.QueryHookOptions<MissionsToConfirmQuery, MissionsToConfirmQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MissionsToConfirmQuery, MissionsToConfirmQueryVariables>(MissionsToConfirmDocument, options);
      }
export function useMissionsToConfirmLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MissionsToConfirmQuery, MissionsToConfirmQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MissionsToConfirmQuery, MissionsToConfirmQueryVariables>(MissionsToConfirmDocument, options);
        }
export type MissionsToConfirmQueryHookResult = ReturnType<typeof useMissionsToConfirmQuery>;
export type MissionsToConfirmLazyQueryHookResult = ReturnType<typeof useMissionsToConfirmLazyQuery>;
export type MissionsToConfirmQueryResult = Apollo.QueryResult<MissionsToConfirmQuery, MissionsToConfirmQueryVariables>;
export const MissionsToConfirmCountDocument = gql`
    query missionsToConfirmCount($groupOfficesUuid: [Uuid!]) {
  me {
    uuid
    missions(
      groupOfficesUuid: $groupOfficesUuid
      macroStatus: [WAITING_FOR_CONTRACT]
      replacementType: SESSION
    ) {
      totalCount
    }
  }
}
    `;

/**
 * __useMissionsToConfirmCountQuery__
 *
 * To run a query within a React component, call `useMissionsToConfirmCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMissionsToConfirmCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMissionsToConfirmCountQuery({
 *   variables: {
 *      groupOfficesUuid: // value for 'groupOfficesUuid'
 *   },
 * });
 */
export function useMissionsToConfirmCountQuery(baseOptions?: Apollo.QueryHookOptions<MissionsToConfirmCountQuery, MissionsToConfirmCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MissionsToConfirmCountQuery, MissionsToConfirmCountQueryVariables>(MissionsToConfirmCountDocument, options);
      }
export function useMissionsToConfirmCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MissionsToConfirmCountQuery, MissionsToConfirmCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MissionsToConfirmCountQuery, MissionsToConfirmCountQueryVariables>(MissionsToConfirmCountDocument, options);
        }
export type MissionsToConfirmCountQueryHookResult = ReturnType<typeof useMissionsToConfirmCountQuery>;
export type MissionsToConfirmCountLazyQueryHookResult = ReturnType<typeof useMissionsToConfirmCountLazyQuery>;
export type MissionsToConfirmCountQueryResult = Apollo.QueryResult<MissionsToConfirmCountQuery, MissionsToConfirmCountQueryVariables>;
export const OnlineAnnouncementsDocument = gql`
    query onlineAnnouncements($groupOfficesUuid: [Uuid], $dates: DateRangeInput) {
  me {
    uuid
    Announcements(
      status: [PUBLISHED]
      groupOfficesUuid: $groupOfficesUuid
      dates: $dates
      replacementType: SESSION
    ) {
      edges {
        node {
          uuid
          dates {
            startDate
            endDate
          }
          specialty {
            name
          }
          service {
            name
          }
          specificServices {
            name
          }
          groupOffice {
            uuid
            name
          }
          salary
          sector
          nbrNeeds
        }
      }
    }
  }
}
    `;

/**
 * __useOnlineAnnouncementsQuery__
 *
 * To run a query within a React component, call `useOnlineAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOnlineAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnlineAnnouncementsQuery({
 *   variables: {
 *      groupOfficesUuid: // value for 'groupOfficesUuid'
 *      dates: // value for 'dates'
 *   },
 * });
 */
export function useOnlineAnnouncementsQuery(baseOptions?: Apollo.QueryHookOptions<OnlineAnnouncementsQuery, OnlineAnnouncementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OnlineAnnouncementsQuery, OnlineAnnouncementsQueryVariables>(OnlineAnnouncementsDocument, options);
      }
export function useOnlineAnnouncementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OnlineAnnouncementsQuery, OnlineAnnouncementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OnlineAnnouncementsQuery, OnlineAnnouncementsQueryVariables>(OnlineAnnouncementsDocument, options);
        }
export type OnlineAnnouncementsQueryHookResult = ReturnType<typeof useOnlineAnnouncementsQuery>;
export type OnlineAnnouncementsLazyQueryHookResult = ReturnType<typeof useOnlineAnnouncementsLazyQuery>;
export type OnlineAnnouncementsQueryResult = Apollo.QueryResult<OnlineAnnouncementsQuery, OnlineAnnouncementsQueryVariables>;
export const RefuseMissionDocument = gql`
    mutation refuseMission($input: RecruiterRefuseMissionInput!) {
  recruiterRefuseMission(input: $input) {
    mission {
      uuid
      recruiterValidation {
        validationStatus
        validationDate
      }
    }
  }
}
    `;
export type RefuseMissionMutationFn = Apollo.MutationFunction<RefuseMissionMutation, RefuseMissionMutationVariables>;

/**
 * __useRefuseMissionMutation__
 *
 * To run a mutation, you first call `useRefuseMissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefuseMissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refuseMissionMutation, { data, loading, error }] = useRefuseMissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRefuseMissionMutation(baseOptions?: Apollo.MutationHookOptions<RefuseMissionMutation, RefuseMissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefuseMissionMutation, RefuseMissionMutationVariables>(RefuseMissionDocument, options);
      }
export type RefuseMissionMutationHookResult = ReturnType<typeof useRefuseMissionMutation>;
export type RefuseMissionMutationResult = Apollo.MutationResult<RefuseMissionMutation>;
export type RefuseMissionMutationOptions = Apollo.BaseMutationOptions<RefuseMissionMutation, RefuseMissionMutationVariables>;
export const ReportMissionDocument = gql`
    mutation reportMission($input: ReportMissionInput!) {
  reportMission(input: $input) {
    mission {
      uuid
      contractDetail {
        recruiterValidationStatus
        recruiterValidationDate
      }
    }
  }
}
    `;
export type ReportMissionMutationFn = Apollo.MutationFunction<ReportMissionMutation, ReportMissionMutationVariables>;

/**
 * __useReportMissionMutation__
 *
 * To run a mutation, you first call `useReportMissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportMissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportMissionMutation, { data, loading, error }] = useReportMissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportMissionMutation(baseOptions?: Apollo.MutationHookOptions<ReportMissionMutation, ReportMissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportMissionMutation, ReportMissionMutationVariables>(ReportMissionDocument, options);
      }
export type ReportMissionMutationHookResult = ReturnType<typeof useReportMissionMutation>;
export type ReportMissionMutationResult = Apollo.MutationResult<ReportMissionMutation>;
export type ReportMissionMutationOptions = Apollo.BaseMutationOptions<ReportMissionMutation, ReportMissionMutationVariables>;
export const DeleteRecruiterAccountDocument = gql`
    mutation deleteRecruiterAccount {
  deleteRecruiterAccount {
    uuid
  }
}
    `;
export type DeleteRecruiterAccountMutationFn = Apollo.MutationFunction<DeleteRecruiterAccountMutation, DeleteRecruiterAccountMutationVariables>;

/**
 * __useDeleteRecruiterAccountMutation__
 *
 * To run a mutation, you first call `useDeleteRecruiterAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRecruiterAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRecruiterAccountMutation, { data, loading, error }] = useDeleteRecruiterAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteRecruiterAccountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRecruiterAccountMutation, DeleteRecruiterAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRecruiterAccountMutation, DeleteRecruiterAccountMutationVariables>(DeleteRecruiterAccountDocument, options);
      }
export type DeleteRecruiterAccountMutationHookResult = ReturnType<typeof useDeleteRecruiterAccountMutation>;
export type DeleteRecruiterAccountMutationResult = Apollo.MutationResult<DeleteRecruiterAccountMutation>;
export type DeleteRecruiterAccountMutationOptions = Apollo.BaseMutationOptions<DeleteRecruiterAccountMutation, DeleteRecruiterAccountMutationVariables>;
export const GetParametersDocument = gql`
    query getParameters {
  me {
    uuid
    profile {
      gender
      firstName
      lastName
      phone {
        number
        code
      }
      email
    }
  }
}
    `;

/**
 * __useGetParametersQuery__
 *
 * To run a query within a React component, call `useGetParametersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParametersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParametersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetParametersQuery(baseOptions?: Apollo.QueryHookOptions<GetParametersQuery, GetParametersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetParametersQuery, GetParametersQueryVariables>(GetParametersDocument, options);
      }
export function useGetParametersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetParametersQuery, GetParametersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetParametersQuery, GetParametersQueryVariables>(GetParametersDocument, options);
        }
export type GetParametersQueryHookResult = ReturnType<typeof useGetParametersQuery>;
export type GetParametersLazyQueryHookResult = ReturnType<typeof useGetParametersLazyQuery>;
export type GetParametersQueryResult = Apollo.QueryResult<GetParametersQuery, GetParametersQueryVariables>;
export const UpdateRecruiterEmailDocument = gql`
    mutation updateRecruiterEmail($input: UpdateEmailInput!) {
  updateRecruiterEmail(input: $input) {
    user {
      uuid
    }
  }
}
    `;
export type UpdateRecruiterEmailMutationFn = Apollo.MutationFunction<UpdateRecruiterEmailMutation, UpdateRecruiterEmailMutationVariables>;

/**
 * __useUpdateRecruiterEmailMutation__
 *
 * To run a mutation, you first call `useUpdateRecruiterEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecruiterEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecruiterEmailMutation, { data, loading, error }] = useUpdateRecruiterEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRecruiterEmailMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRecruiterEmailMutation, UpdateRecruiterEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRecruiterEmailMutation, UpdateRecruiterEmailMutationVariables>(UpdateRecruiterEmailDocument, options);
      }
export type UpdateRecruiterEmailMutationHookResult = ReturnType<typeof useUpdateRecruiterEmailMutation>;
export type UpdateRecruiterEmailMutationResult = Apollo.MutationResult<UpdateRecruiterEmailMutation>;
export type UpdateRecruiterEmailMutationOptions = Apollo.BaseMutationOptions<UpdateRecruiterEmailMutation, UpdateRecruiterEmailMutationVariables>;
export const UpdateRecruiterPasswordDocument = gql`
    mutation updateRecruiterPassword($input: UpdatePasswordInput!) {
  updateRecruiterPassword(input: $input) {
    user {
      uuid
    }
  }
}
    `;
export type UpdateRecruiterPasswordMutationFn = Apollo.MutationFunction<UpdateRecruiterPasswordMutation, UpdateRecruiterPasswordMutationVariables>;

/**
 * __useUpdateRecruiterPasswordMutation__
 *
 * To run a mutation, you first call `useUpdateRecruiterPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecruiterPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecruiterPasswordMutation, { data, loading, error }] = useUpdateRecruiterPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRecruiterPasswordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRecruiterPasswordMutation, UpdateRecruiterPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRecruiterPasswordMutation, UpdateRecruiterPasswordMutationVariables>(UpdateRecruiterPasswordDocument, options);
      }
export type UpdateRecruiterPasswordMutationHookResult = ReturnType<typeof useUpdateRecruiterPasswordMutation>;
export type UpdateRecruiterPasswordMutationResult = Apollo.MutationResult<UpdateRecruiterPasswordMutation>;
export type UpdateRecruiterPasswordMutationOptions = Apollo.BaseMutationOptions<UpdateRecruiterPasswordMutation, UpdateRecruiterPasswordMutationVariables>;
export const UpdateRecruiterProfileDocument = gql`
    mutation updateRecruiterProfile($input: UserProfileInput!) {
  updateRecruiterProfile(input: $input) {
    uuid
    profile {
      gender
      firstName
      lastName
      phone {
        code
        number
      }
    }
  }
}
    `;
export type UpdateRecruiterProfileMutationFn = Apollo.MutationFunction<UpdateRecruiterProfileMutation, UpdateRecruiterProfileMutationVariables>;

/**
 * __useUpdateRecruiterProfileMutation__
 *
 * To run a mutation, you first call `useUpdateRecruiterProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecruiterProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecruiterProfileMutation, { data, loading, error }] = useUpdateRecruiterProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRecruiterProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRecruiterProfileMutation, UpdateRecruiterProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRecruiterProfileMutation, UpdateRecruiterProfileMutationVariables>(UpdateRecruiterProfileDocument, options);
      }
export type UpdateRecruiterProfileMutationHookResult = ReturnType<typeof useUpdateRecruiterProfileMutation>;
export type UpdateRecruiterProfileMutationResult = Apollo.MutationResult<UpdateRecruiterProfileMutation>;
export type UpdateRecruiterProfileMutationOptions = Apollo.BaseMutationOptions<UpdateRecruiterProfileMutation, UpdateRecruiterProfileMutationVariables>;
export const CancelAnnouncementDocument = gql`
    mutation cancelAnnouncement($input: CancelAnnouncementInput!) {
  cancelAnnouncement(input: $input) {
    groupOffice {
      uuid
    }
  }
}
    `;
export type CancelAnnouncementMutationFn = Apollo.MutationFunction<CancelAnnouncementMutation, CancelAnnouncementMutationVariables>;

/**
 * __useCancelAnnouncementMutation__
 *
 * To run a mutation, you first call `useCancelAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelAnnouncementMutation, { data, loading, error }] = useCancelAnnouncementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelAnnouncementMutation(baseOptions?: Apollo.MutationHookOptions<CancelAnnouncementMutation, CancelAnnouncementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelAnnouncementMutation, CancelAnnouncementMutationVariables>(CancelAnnouncementDocument, options);
      }
export type CancelAnnouncementMutationHookResult = ReturnType<typeof useCancelAnnouncementMutation>;
export type CancelAnnouncementMutationResult = Apollo.MutationResult<CancelAnnouncementMutation>;
export type CancelAnnouncementMutationOptions = Apollo.BaseMutationOptions<CancelAnnouncementMutation, CancelAnnouncementMutationVariables>;
export const CreateAnnouncementDocument = gql`
    mutation createAnnouncement($input: SendNewAnnouncementInput!) {
  sendNewAnnouncement(input: $input) {
    success
  }
}
    `;
export type CreateAnnouncementMutationFn = Apollo.MutationFunction<CreateAnnouncementMutation, CreateAnnouncementMutationVariables>;

/**
 * __useCreateAnnouncementMutation__
 *
 * To run a mutation, you first call `useCreateAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAnnouncementMutation, { data, loading, error }] = useCreateAnnouncementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAnnouncementMutation(baseOptions?: Apollo.MutationHookOptions<CreateAnnouncementMutation, CreateAnnouncementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAnnouncementMutation, CreateAnnouncementMutationVariables>(CreateAnnouncementDocument, options);
      }
export type CreateAnnouncementMutationHookResult = ReturnType<typeof useCreateAnnouncementMutation>;
export type CreateAnnouncementMutationResult = Apollo.MutationResult<CreateAnnouncementMutation>;
export type CreateAnnouncementMutationOptions = Apollo.BaseMutationOptions<CreateAnnouncementMutation, CreateAnnouncementMutationVariables>;