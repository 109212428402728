import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from './hooks/useAuth';

type Props = { children: JSX.Element };

const RequireAnonymous = ({ children }: Props) => {
  const auth = useAuth();
  const location = useLocation();

  const token = auth.getToken();

  if (token) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  document.body.dataset.logged = 'false';

  return children;
};

export default RequireAnonymous;
