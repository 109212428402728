import React from 'react';
import { useTranslation } from 'react-i18next';

import GroupOfficeSelect from '../../app/components/GroupOfficeSelect';
import PageHeader from '../../app/components/PageHeader';
import Tabs from '../../app/components/Tabs';
import InvoicesAlert from '../../features/InvoicesPage/InvoicesAlert';
import InvoicesToPayTable from '../../features/InvoicesPage/InvoicesToPayTable';
import InvoicesPaidTable from '../../features/InvoicesPage/InvoicesPaidTable';
import RangeDatePicker from '../../app/components/RangeDatePicker';
import { DateRangeInput } from '../../app/generated/graphql';
import { useGroupOffice } from '../../app/data/hooks';
import useTracking from '../../app/hooks/useTracking';

const InvoicesPage = () => {
  const { t } = useTranslation();
  const { logEvent } = useTracking();
  const { getGroupOfficeQueryFilter } = useGroupOffice();

  const [filterDates, setFilterDates] = React.useState<DateRangeInput | null>(null);
  const selectedGroupOffices = getGroupOfficeQueryFilter();

  const onFilterDatesChange = (value: DateRangeInput | null) => {
    logEvent('m.invoice.header.select_date.update');
    setFilterDates(value);
  };

  return (
    <div>
      <PageHeader
        title={t('invoicesPage.title')}
        description={t('invoicesPage.hint')}
        leftSlot={<GroupOfficeSelect eventNamePrefix="m.invoice.header" />}
        rightSlot={(
          <>
            <div className="mb-4">
              <InvoicesAlert groupOfficesUuid={selectedGroupOffices} />
            </div>
            <RangeDatePicker onChange={onFilterDatesChange} />
          </>
        )}
      />
      <Tabs
        items={[
          {
            title: t('invoicesPage.tabToPay'),
            content: (
              <InvoicesToPayTable
                groupOfficesUuid={selectedGroupOffices}
                dates={filterDates}
                eventNamePrefix="m.invoice.to_pay"
              />
            ),
            eventName: 'm.invoice.switch.to_pay.click',
          },
          {
            title: t('invoicesPage.tabPayed'),
            content: (
              <InvoicesPaidTable
                dates={filterDates}
                groupOfficesUuid={selectedGroupOffices}
                eventNamePrefix="m.invoice.paid"
              />
            ),
            eventName: 'm.invoice.switch.paid.click',
          },
        ]}
      />
    </div>
  );
};

export default InvoicesPage;
