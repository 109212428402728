import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

import CDDMetabase from '../../features/CDD/CDDMetabase';

const CDDMetabasePage = () => {
  const { groupOfficeUuid } = useParams();

  if (groupOfficeUuid) {
    return (
      <CDDMetabase groupOfficeUuid={groupOfficeUuid} />
    );
  }

  return <Navigate to="/401" replace />;
};

export default CDDMetabasePage;
