import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import PageHeader from '../../app/components/PageHeader';
import GroupOfficeSelect from '../../app/components/GroupOfficeSelect';
import Dashboard from '../../features/Dashboard';
import ActionBlock from '../../features/Dashboard/ActionBloc';
import { useGroupOffice } from '../../app/data/hooks';
import UserNameContext from '../../auth/UserNameContext';
import useAuth from '../../auth/hooks/useAuth';

const DashboardPage = () => {
  const { getAccessLevel } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { getGroupOfficeQueryFilter, showConfirmationFeature } = useGroupOffice();
  const userNameCtx = React.useContext(UserNameContext);
  const { lastName, firstName } = userNameCtx!.profile;
  const userName = `${firstName} ${lastName}`;

  const selectedGroupOffices = getGroupOfficeQueryFilter();

  if (getAccessLevel() !== 3) {
    return <Navigate to="/missions" state={null} replace />;
  }

  return (
    <div>
      <PageHeader
        title={`${t('dashboardPage.greetings')} ${userName}`}
        description={t('dashboardPage.hint')}
        leftSlot={<GroupOfficeSelect eventNamePrefix="m.dash.header" />}
        rightSlot={(
          <ActionBlock
            groupOfficesUuid={selectedGroupOffices}
            onContractButtonClicked={() => navigate('/contracts')}
            onMissionButtonClicked={() => navigate('/missions')}
            showConfirmation={showConfirmationFeature}
          />
        )}
      />
      <Dashboard groupOfficesUuid={selectedGroupOffices} onNavigate={(to) => navigate(to)} />
    </div>
  );
};

export default DashboardPage;
