import React from 'react';
import BootstrapTab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

import * as tokens from '../../DesignSystem/tokens';

export type TabsItem = {
  title: string,
  content: React.ReactNode
  eventName: string
};
type Props = { items: Array<TabsItem> };

const Tabs = ({ items }: Props) => {
  if (items.length === 0) {
    throw new Error('Tabs>items cannot be an empty array');
  }

  return (
    <BootstrapTab.Container defaultActiveKey={items[0].title}>
      <div
        className="nav-container d-inline-block overflow-hidden"
        style={{
          backgroundColor: tokens.colorSilverLight,
          marginBottom: tokens.spaceMedium,
          boxShadow: tokens.shadowMedium,
          borderRadius: tokens.roundedMedium,
          padding: 6,
        }}
      >
        <Nav variant="pills">
          {items.map((item) => (
            <Nav.Item key={item.title}>
              <Nav.Link
                eventKey={item.title}
                data-tracking-event={item.eventName}
              >
                {item.title}
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </div>
      <BootstrapTab.Content>
        {items.map((item) => (
          <BootstrapTab.Pane eventKey={item.title} key={item.title}>
            {item.content}
          </BootstrapTab.Pane>
        ))}
      </BootstrapTab.Content>
    </BootstrapTab.Container>
  );
};

export default Tabs;
