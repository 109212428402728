/* eslint-disable react/function-component-definition */
import React from 'react';

import './NotFound.css';

function NotFound() {
  return (
    <div className="content">
      <p>
        404
      </p>
    </div>
  );
}

export default NotFound;
