import { format as datefnsFormat } from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz';
import { ColumnDef } from '@tanstack/react-table';

import { PaymentStatus, Phone, SpreadsheetExportableFields } from '../generated/graphql';
import { type Menu } from '../../navigation/menus';

const formatNumber = (num: number, decimals: number = 2): string => (Math.round(num * 100) / 100)
  .toFixed(decimals)
  .toString();

const formatFloat = (num: number): string => formatNumber(num);

const formatDateTime = (JSONDateTime: string, format: string = 'dd/MM/yyyy') => {
  const tz = 'Europe/Paris';
  const utc = zonedTimeToUtc(JSONDateTime, tz);

  return datefnsFormat(utcToZonedTime(utc, tz), format);
};

const formatDate = (JSONDateOnly: string, format: string = 'dd/MM/yyyy') => {
  const JSONDateTime = `${JSONDateOnly} 09:00:00`;

  return formatDateTime(JSONDateTime, format);
};

const formatDateToJSON = (date: Date) => datefnsFormat(date, 'yyyy-MM-dd');

const getPaymentStatusLabel = (paymentStatus: PaymentStatus | null | undefined): string => {
  if (!paymentStatus) { return '-'; }

  if (paymentStatus === PaymentStatus.Paid) {
    return 'invoices.paymentStatusDone';
  }
  return 'invoices.paymentStatusPending';
};

const openLinkInNewWindow = (url: string) => window.open(url, '_blank', 'noopener,noreferrer');

const openStreamLink = (url: string | undefined, token: string) => openLinkInNewWindow(`${url}?bearer=${token}`);

// For browsers that do not support navigator clipboard API
const fallbackCopyToClipboard = (string: string) => {
  const textArea = document.createElement('textarea');
  textArea.value = string;

  textArea.style.top = '0';
  textArea.style.left = '0';
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand('copy');
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }

  document.body.removeChild(textArea);
};

const copyToClipboard = (string: string) => {
  if (!navigator.clipboard) {
    fallbackCopyToClipboard(string);
    return;
  }
  navigator.clipboard.writeText(string);
};

const formatPhoneNumber = (phone: Phone) => {
  const replace = (phoneNumber: string) => phoneNumber.replace(/\D*(\d{1})\D*(\d{2})\D*(\d{2})\D*(\d{2})\D*(\d{2})\D*/, '$1 $2 $3 $4 $5');
  let formattedPhoneNumber;
  if (phone.number) {
    if (phone.number.length === 10) {
      formattedPhoneNumber = replace(phone.number.slice(1));
      return `+${phone.code} ${formattedPhoneNumber}`;
    }
    if (phone.number.length === 9) {
      formattedPhoneNumber = replace(phone.number);
      return `+${phone.code} ${formattedPhoneNumber}`;
    }
  }
  return `+33 ${phone.number}`;
};

const getLastItemFromArray = <T>(array: Array<T>): T | null => {
  if (array.length) {
    return array[array.length - 1];
  }

  return null;
};

const filterTableColumnsByRole = <T> (rows: ColumnDef<T>[], role: number)
  : ColumnDef<T>[] => {
  switch (role) {
    case 1:
      return rows.filter((row) => row.id !== 'amount' && row.id !== 'salary');
      break;
    case 2:
      return rows.filter((row) => row.id !== 'amount' && row.id !== 'salary');
      break;
    default:
      return rows;
      break;
  }
};

const filterNavItemsByRole = (items: Menu, role: number)
: Menu => {
  switch (role) {
    case 1:
      return items.filter((item) => item.to === '/missions' || item.to === '/settings');
      break;
    case 2:
      return items.filter((item) => item.to === '/missions' || item.to === '/settings');
      break;
    default:
      return items;
      break;
  }
};

const filterExportOptionsByRole = (options: SpreadsheetExportableFields[], role: number) => {
  switch (role) {
    case 1:
      return options.filter((item) => item !== SpreadsheetExportableFields.Salary);
      break;
    case 2:
      return options.filter((item) => item !== SpreadsheetExportableFields.Salary);
      break;
    default:
      return options;
      break;
  }
};

export default {
  formatFloat,
  formatDate,
  formatDateTime,
  formatDateToJSON,
  getPaymentStatusLabel,
  openLinkInNewWindow,
  openStreamLink,
  fallbackCopyToClipboard,
  copyToClipboard,
  formatPhoneNumber,
  getLastItemFromArray,
  filterTableColumnsByRole,
  filterNavItemsByRole,
  filterExportOptionsByRole,
};
