import React from 'react';
import { Toast as BSToast } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faExclamation,
  faXmark,
  type IconDefinition,
} from '@fortawesome/free-solid-svg-icons';

import * as tokens from '../../DesignSystem/tokens';

type Props = {
  message: string
  variant: 'success' | 'warning' | 'danger'
  show?: boolean
  onClose?: VoidFunction | undefined
};

const Toast = ({
  message,
  onClose,
  show,
  variant,
}: Props) => {
  let icon: IconDefinition;
  let colorLight: string;
  let colorDark: string;

  switch (variant) {
    case 'success':
      icon = faCheck;
      colorLight = tokens.colorGreenLight;
      colorDark = tokens.colorGreenDark;
      break;
    case 'warning':
      icon = faExclamation;
      colorLight = tokens.colorOrangeLight;
      colorDark = tokens.colorOrangeDark;
      break;
    case 'danger':
      icon = faXmark;
      colorLight = tokens.colorRedLight;
      colorDark = tokens.colorRedDark;
      break;
    default:
      icon = faXmark;
      colorLight = tokens.colorRedLight;
      colorDark = tokens.colorRedDark;
      break;
  }

  const toastStyles: React.CSSProperties = {
    borderRadius: tokens.roundedMedium,
    backgroundColor: colorLight,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    font: tokens.fontBold,
    boxShadow: tokens.shadowMedium,
    width: '100%',
  };

  const toastHeaderStyles: React.CSSProperties = {
    flex: 1,
    color: colorDark,
    backgroundColor: 'transparent',
  };

  const toastIconStyles: React.CSSProperties = {
    width: 35,
    height: 35,
    backgroundColor: tokens.colorWhite,
    border: `4px solid ${colorDark}`,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 10,
  };

  return (
    <BSToast
      onClose={onClose}
      show={show}
      style={toastStyles}
    >
      <BSToast.Header style={toastHeaderStyles} closeButton={onClose !== undefined}>
        <div style={toastIconStyles}>
          <FontAwesomeIcon icon={icon} role="img" fontSize={20} />
        </div>
        <strong className="me-auto">{message}</strong>
      </BSToast.Header>
    </BSToast>
  );
};

Toast.defaultProps = {
  show: true,
  onClose: undefined,
};

export default Toast;
