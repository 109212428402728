import React from 'react';
import * as tokens from '../../DesignSystem/tokens';

export type ButtonVariant = 'primary' | 'primaryDark' | 'secondary' | 'negative' | 'positive' | 'warning';

type Props = {
  disabled?: boolean
  title: string
  onClick: VoidFunction
  variant?: ButtonVariant | undefined
  trackingEvent?: string | undefined
  minWidth?: number
};

const Button = ({
  disabled,
  onClick,
  title,
  trackingEvent,
  variant,
  minWidth,
}: Props) => {
  let backgroundColor: string = tokens.colorWhite;
  let color: string = tokens.colorGreyBlue;
  let borderColor: string = tokens.colorGreyBlue;

  const buttonStyles: React.CSSProperties = {
    minWidth,
    borderRadius: tokens.roundedMedium,
    borderWidth: 1,
    textAlign: 'center',
    paddingTop: 6,
    paddingBottom: 6,
    paddingLeft: tokens.spaceExtraSmall,
    paddingRight: tokens.spaceExtraSmall,
    font: tokens.fontBase,
  };

  if (disabled === false) {
    switch (variant) {
      case 'primary':
      case undefined:
        backgroundColor = tokens.colorBlueOcean;
        borderColor = tokens.colorBlueOcean;
        color = tokens.colorWhite;
        break;
      case 'primaryDark':
        backgroundColor = tokens.colorBlueNight;
        borderColor = tokens.colorBlueNight;
        color = tokens.colorWhite;
        break;
      case 'secondary':
        backgroundColor = tokens.colorWhite;
        borderColor = tokens.colorBlueNight;
        color = tokens.colorBlueNight;
        break;
      case 'negative':
        backgroundColor = tokens.colorRedDark;
        borderColor = tokens.colorRedDark;
        color = tokens.colorWhite;
        break;
      case 'positive':
        backgroundColor = tokens.colorGreenDark;
        borderColor = tokens.colorGreenDark;
        color = tokens.colorWhite;
        break;
      case 'warning':
        backgroundColor = tokens.colorOrangeLight;
        borderColor = tokens.colorOrangeLight;
        color = tokens.colorBlueNight;
        break;
      default:
        backgroundColor = tokens.colorBlueOcean;
        borderColor = tokens.colorBlueOcean;
        break;
    }
  }

  return (
    <button
      disabled={disabled}
      type="button"
      onClick={onClick}
      style={{
        ...buttonStyles,
        borderColor,
        backgroundColor,
        color,
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...trackingEvent ? { 'data-tracking-event': trackingEvent } : {}}
    >
      {title}
    </button>
  );
};

Button.defaultProps = {
  disabled: false,
  variant: undefined,
  trackingEvent: undefined,
  minWidth: 20,
};

export default Button;
