import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

type Props = {
  testID: string
  valid: boolean
  id: string
  value?: string | undefined
  onChange: (val: string) => void
  required: boolean
};

const PasswordInput = ({
  testID,
  valid,
  id,
  value,
  onChange,
  required,
}: Props) => {
  const [visible, setVisible] = React.useState<boolean>(false);

  return (
    <div className="input-group mb-2">
      <input
        data-testid={testID}
        className={`form-control${!valid ? ' is-invalid' : ''}`}
        id={id}
        name="password"
        type={visible ? 'text' : 'password'}
        value={value}
        onChange={({ target }) => onChange(target.value)}
        required={required}
      />
      <button
        aria-labelledby="Password toggle visibility button"
        className="btn btn-outline-primary"
        type="button"
        data-testid="passwordInput-toggle"
        onClick={() => setVisible(!visible)}
      >
        <FontAwesomeIcon icon={visible ? faEyeSlash : faEye} />
      </button>
    </div>
  );
};

PasswordInput.defaultProps = { value: undefined };

export default PasswordInput;
