import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import QueryIndicator from '../app/components/QueryIndicator';
import useAuth from './hooks/useAuth';

const AutoLogFromRefreshTokenRoute = () => {
  const { refreshToken } = useParams();
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (refreshToken) {
      auth.autologFromRefresh(refreshToken)
        .then(() => navigate('/'))
        .catch(() => navigate('/login'));
    }
  }, [refreshToken, auth, navigate]);

  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <QueryIndicator />
      <span>Chargement …</span>
    </div>
  );
};

export default AutoLogFromRefreshTokenRoute;
