import axios, { AxiosRequestConfig } from 'axios';
import jwtDecode from 'jwt-decode';

import { Credentials, Token, Tokens } from './types';
import { authAPIRoutes } from '../app/constants';
import useTracking from '../app/hooks/useTracking';

const config: AxiosRequestConfig = {
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  timeout: 10000, // docker can be slow at times
};

const AuthService = {
  signInWithCredentials: (credentials: Credentials) => (
    new Promise<Tokens>((resolve, reject) => {
      axios.post(process.env.REACT_APP_API_URL + authAPIRoutes.signIn, credentials, config)
        .then((response) => {
          const { token, refresh_token: refreshToken } = response.data;
          const tokenData: any = jwtDecode(token);

          useTracking().manageTracking(token);

          if (
            tokenData
            && tokenData.roles
            && (tokenData.roles as Array<string>).includes('ROLE_USER_RECRUITER')
          ) {
            resolve({ token, refreshToken });
          } else {
            reject(new Error('auth.unauthorized'));
          }
        })
        .catch((error) => reject(error));
    })
  ),
  recoverPassword: (email: string) => (
    new Promise<void>((resolve, reject) => {
      axios.post(process.env.REACT_APP_API_URL + authAPIRoutes.recoverPassword, { email }, config)
        .then(() => resolve())
        .catch((error) => reject(error));
    })
  ),
  refreshToken: (currentRefreshToken: Token) => (
    new Promise<Tokens>((resolve, reject) => {
      axios.post(
        process.env.REACT_APP_API_URL + authAPIRoutes.refreshToken,
        { refresh_token: currentRefreshToken },
        config,
      )
        .then((response) => {
          const { token, refresh_token: refreshToken } = response.data;
          resolve({ token, refreshToken });
        })
        .catch((error) => reject(error));
    })
  ),
};

export default AuthService;
