import React from 'react';

type Props = {
  source: string
};

const AVATAR_SIZE = 140;

const Avatar = ({ source }: Props) => (
  <img
    src={source}
    alt="avatar"
    className="rounded-circle"
    height={AVATAR_SIZE}
    width={AVATAR_SIZE}
  />
);

export default Avatar;
