import React from 'react';
import { ApolloConsumer } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import LoginForm from './LoginForm';
import LayoutSplit from '../app/components/LayoutSplit/LayoutSplit';
import { EXTERNAL_URL_DEMO } from '../app/constants';

const LinkToAppPro = () => {
  const { t } = useTranslation();

  return (
    <div>
      <span>{t('auth.login.youAreSearchingMission')}</span>
      <a
        className=" ms-2 link link-secondary"
        href={process.env.REACT_APP_PRO_URL}
      >
        {t('auth.login.loginHere')}
      </a>
    </div>
  );
};

const LoginRoute = () => {
  const { t } = useTranslation();

  return (
    <ApolloConsumer>
      {(client) => {
        client.clearStore();

        return (
          <LayoutSplit
            coverImage="https://blog.medelse.com/wp-content/uploads/2020/07/covid-sondage-soignants.png"
            belowCard={<LinkToAppPro />}
            withLogo
            title={t('auth.login.pageTitle')}
          >
            <div id="login-page">
              <div>
                <div className="row d-flex align-items-center">
                  <div className="col">

                    <h2 className="h2">{t('auth.login.title')}</h2>
                  </div>
                  <div className="col text-end text-right">
                    <a
                      className="text-decoration-none"
                      href={EXTERNAL_URL_DEMO}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span className="text-primary">{`${t('auth.login.noAccount')} `}</span>
                      <span className="fw-bolder text-primary text-decoration-underline">
                        {t('auth.login.register')}
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <LoginForm />
            </div>
          </LayoutSplit>
        );
      }}
    </ApolloConsumer>
  );
};

export default LoginRoute;
