import { CSSProperties } from 'react';

/** COLORS */
const shadowColor = 'rgba(0, 0, 0, .25)';
export const colorBlueOcean = '#0E5168';
export const colorBlueDark = '#093C4E';
export const colorBlueNight = '#032830';
export const colorGreenLight = '#CBF6E5';
export const colorGreenDark = '#007E4C';
export const colorOrangeLight = '#FDE6D9';
export const colorOrangeDark = '#FAA07F';
export const colorRedLight = '#FAD7DD';
export const colorRedDark = '#8A2235';

export const colorGreyBlue = '#A7B8C4';
export const colorGreyLight = '#FAFBFC';
export const colorGrey = '#DCDFE6';
export const colorGreyDark = '#BECECC';
export const colorWhite = '#FFFFFF';

export const colorSilverDark = '#BAC0C4';
export const colorSilver = '#EDF0F7';
export const colorSilverLight = '#F2F5FA';

/** SPACING */
export const gutter = 15;
export const spaceExtraSmall = 12;
export const spaceSmall = 20;
export const spaceMedium = 25;
export const spaceLarge = 35;

/** BORDER RADIUS */
export const roundedSmall = 4;
export const roundedMedium = 5;
export const roundedLarge = 16;

/** SHADOWS */
export const shadowMedium = `0px 4px 4px ${shadowColor}`;

/** TYPOGRAPHY */
export const fontBase = '400 16px/24px "Open Sans", sans-serif';
export const fontBold = '700 16px/24px "Open Sans", sans-serif';
export const fontHeading = '400 20px/27px "Open Sans", sans-serif';
export const fontTitle = '700 18px/25px "Open Sans", sans-serif';
export const fontBig = '700 20px/27px "Open Sans", sans-serif';
export const fontGiant = '400 36px/49px "Open Sans", sans-serif';

/** INPUT */
export const textFieldBase = {
  display: 'block',
  width: '100%',
  border: `1px solid ${colorGreyBlue}`,
  borderRadius: roundedMedium,
  padding: '6px 9px',
  color: colorBlueNight,
};

export const textFieldDisabled = {
  ...textFieldBase,
  color: colorGreyBlue,
  backgroundColor: colorSilverLight,
};

export const textFieldLabel: CSSProperties = {
  display: 'block',
  width: '100`%',
  font: fontBase,
  color: colorGreyBlue,
};
