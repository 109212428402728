/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnDef, ColumnSort } from '@tanstack/react-table';
import {
  DateRangeInput,
  Mission,
  MissionMacroStatusEnum,
  useCancelledMissionsExportLazyQuery,
  useCancelledMissionsQuery,
} from '../../../app/generated/graphql';
import QueryIndicator from '../../../app/components/QueryIndicator';
import AlertMessage from '../../../app/components/AlertMessage';
import utils from '../../../app/domain/utils';
import TableCellUser from '../../../app/components/TableCellUser';
import { ARRAY_ITEMS_PER_PAGE } from '../../../app/constants';
import Table from '../../../app/components/Table';
import TableCellSpecialty from '../../../app/components/TableCellSpecialty';
import TableCellService from '../../../app/components/TableCellService';
import { useExportOptions } from '../../../app/hooks/useExportOptions';
import useAuth from '../../../auth/hooks/useAuth';

type Props = {
  groupOfficesUuid: Array<string>,
  dates: DateRangeInput,
  eventNamePrefix: string,
};

const CancelledMissionsTable = ({ dates, groupOfficesUuid, eventNamePrefix }: Props) => {
  const { getAccessLevel } = useAuth();
  const {
    data, error, loading,
  } = useCancelledMissionsQuery({ variables: { groupOfficesUuid, dates } });
  const { t } = useTranslation();

  const { csvOptions, xlsxOptions } = useExportOptions({
    query: useCancelledMissionsExportLazyQuery,
    dates,
    groupOfficesUuid,
    name: t('missionsPage.exportCancelledTitle'),
    eventName: eventNamePrefix,
  });

  if (loading) {
    return <QueryIndicator />;
  }

  if (error) {
    return <AlertMessage message={error.message} />;
  }

  const columns: ColumnDef<Mission>[] = [
    {
      id: 'date',
      header: t('column.date'),
      accessorFn: (row) => row.dates.startDate,
      cell: ({ row }) => utils.formatDate(row.original.dates.startDate),
    },
    {
      id: 'fullname',
      header: t('column.candidate'),
      accessorFn: (row) => `${row.candidate.profile.lastName} ${row.candidate.profile.firstName}`,
      cell: ({ row }) => <TableCellUser profile={row.original.candidate.profile} />,
    },
    {
      id: 'specialty',
      header: t('column.specialties'),
      accessorKey: 'specialty',
      cell: ({ row }) => {
        const { name } = row.original.specialty;

        return <TableCellSpecialty name={name} uuid={row.original.uuid} />;
      },
    },
    {
      id: 'services',
      header: t('column.service'),
      accessorFn: (row) => row.service.name,
      cell: ({ row }) => {
        const { specificServices, service } = row.original;

        return <TableCellService service={service} specificServices={specificServices} />;
      },
    },
    {
      id: 'salary',
      enableGlobalFilter: false,
      header: t('column.salary'),
      accessorFn: (row) => `${utils.formatFloat(row.salary!)} €`,
    },
    {
      id: 'hours',
      header: t('column.hours'),
      cell: ({ row }) => {
        const missionDates = row.original.dates;

        const getHours = (dateTime: string) => utils.formatDateTime(dateTime, 'HH:mm');
        return `${getHours(missionDates.startDate)} - ${getHours(missionDates.endDate)}`;
      },
    },
    {
      id: 'sector',
      header: t('column.sector'),
      accessorKey: 'sector',
    },
    {
      id: 'cancelled_by',
      header: t('column.cancelledBy'),
      cell: ({ row }) => (
        <span>
          {t(`cancelledBy.${row.original.macroStatus === MissionMacroStatusEnum.CancelledByCandidate
            ? 'candidate'
            : 'recruiter'}`)}
        </span>
      ),
    },
  ];

  const missions = data?.me?.missions?.edges?.map((item) => item?.node);

  const defaultColumnSort: ColumnSort = { id: 'date', desc: true };

  return (
    <Table
      eventNamePrefix={eventNamePrefix}
      pageSize={ARRAY_ITEMS_PER_PAGE}
      columns={utils.filterTableColumnsByRole(columns, getAccessLevel())}
      data={(missions as Array<Mission>)}
      defaultColumnSort={defaultColumnSort}
      canBeFiltered
      title={t('missions.cancelledMissions')}
      hint={t('missions.cancelledMissionsHint')}
      exportOptions={[csvOptions, xlsxOptions]}
    />
  );
};

export default CancelledMissionsTable;
