/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { faRightFromBracket, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dropdown } from 'react-bootstrap';

import Pkg from '../../../../package.json';
import logoMenu from '../../../assets/logo_menu.png';
import useAuth from '../../../auth/hooks/useAuth';
import {
  mainNavOnce, mainNavTwo, type Menu,
} from '../../../navigation/menus';
import NavList, { NavListProps } from './NavList/NavList';
import { EXTERNAL_URL_HELP } from '../../constants';
import utils from '../../domain/utils';

type Props = { children: React.ReactChild };

const Separator = () => <div className="border-bottom border-white my-3" />;

const LayoutApp = ({ children }: Props) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isActive = (link: string):boolean => pathname === link;

  const getNav = (menu: Menu): NavListProps['items'] => menu
    .map(({
      label,
      to,
      icon,
      eventName,
    }) => ({
      label: t(label),
      active: isActive(to),
      icon,
      onClick: () => navigate(to),
      eventName,
    }));

  const logoutNav: NavListProps['items'] = [{
    label: t('auth.logout'),
    active: false,
    icon: faRightFromBracket,
    onClick: () => auth.signout(() => navigate('/')),
    eventName: 'm.navigation.menu.logout.click',
  }];

  type HelpToggleProps = { onClick: (e: React.MouseEvent<HTMLButtonElement>) => void };
  const HelpToggle = React.forwardRef(({ onClick }: HelpToggleProps, ref: any) => (
    <button
      type="button"
      ref={ref}
      className="position-relative d-block w-100 text-start p-3"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <div className="d-inline-block text-center me-2">
        <FontAwesomeIcon icon={faQuestionCircle} role="img" />
      </div>
      {t('nav.help')}
    </button>
  ));

  return (
    <div id="layout-app">
      <aside className="d-flex flex-column bg-primary text-white p-4">
        <div className="flex-grow-1">
          <div className="d-flex flex-direction-row align-items-center mb-5">
            <div id="menu-logo" className="text-center">
              <Link to="/">
                <img
                  src={logoMenu}
                  alt="Medelse logo"
                />
              </Link>
            </div>
            <div className="d-block ms-2">
              <div className="h1 m-0 d-block">Medelse</div>
              <div className="fw-lighter fst-italic">
                {`Version ${Pkg.version}`}
              </div>
            </div>
          </div>
          <Separator />
          <NavList items={getNav(utils.filterNavItemsByRole(mainNavOnce, auth.getAccessLevel()))} />
          <Separator />
        </div>
        <nav className="nav-list-component">
          <ul className="nav flex-column">
            <li>
              <Dropdown drop="down">
                <Dropdown.Toggle as={HelpToggle} />
                <Dropdown.Menu>
                  <Dropdown.Item as="button" onClick={() => { window.open(EXTERNAL_URL_HELP, '_blank')!.focus(); }}>{t('nav.FAQ')}</Dropdown.Item>
                  <Dropdown.Item as="button" onClick={() => navigate('/contact')}>{t('nav.contact')}</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </li>
          </ul>
        </nav>
        <NavList items={getNav(utils.filterNavItemsByRole(mainNavTwo, auth.getAccessLevel()))} />
        <Separator />
        <NavList items={logoutNav} />
      </aside>
      <main className="px-5 pt-5 flex-fill d-flex flex-column">
        {children}
      </main>
    </div>
  );
};

export default LayoutApp;
