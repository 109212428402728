/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { differenceInMinutes, format, parseISO } from 'date-fns';

import { MissionReportReasonEnum, ReportMissionMutationVariables, useReportMissionMutation } from '../../../app/generated/graphql';
import { MissionData } from '../MissionsTypes';
import utils from '../../../app/domain/utils';
import AlertMessage from '../../../app/components/AlertMessage';
import CardTitle from '../../../app/components/CardTitle';
import FormActions from '../../../app/components/FormActions';
import QueryIndicator from '../../../app/components/QueryIndicator';
import TextField from '../../../app/components/TextField';

type Props = {
  onClose: VoidFunction
  mission: MissionData
};

const getTimeFromJSONDate = (date: string): string => format(new Date(date), 'HH:mm');

const getReadableMinutesDiff = (minutes: number): string => {
  const hours = Math.floor(minutes / 60).toString().padStart(2, '0');
  const min = (minutes % 60).toString().padStart(2, '0');

  return `${hours}:${min}`;
};

const timeToJSONDate = (jsonDate: string, time?: string): string => {
  if (!time) {
    return jsonDate;
  }

  const [date] = jsonDate.split(' ');

  return `${date} ${time}:00`;
};

type RadioInputProps = {
  reason: MissionReportReasonEnum
  currentReason: MissionReportReasonEnum | undefined
  onChange: (arg: MissionReportReasonEnum) => void
};

const RadioInput = ({ reason, currentReason, onChange }: RadioInputProps) => {
  const { t } = useTranslation();
  const inputId = `reason-${reason}`;

  return (
    <div className="form-check" key={reason}>
      <label htmlFor={inputId} className="fw-semibold">
        {t(`reportMissionForm.reason.${reason}`)}
        <input
          className="form-check-input"
          id={inputId}
          name="reason"
          type="radio"
          checked={currentReason === reason}
          onChange={() => onChange(reason)}
        />
      </label>
    </div>
  );
};

type TimeInputProps = {
  disabled?: boolean
  label: string
  id: string
  onChange: (args: ChangeEvent<HTMLInputElement>) => void
  value: string | undefined
};

const TimeInput = ({
  disabled,
  label,
  id,
  onChange,
  value,
}: TimeInputProps) => (
  <div className="row">
    <div className="col">
      <label className="col-form-label" htmlFor={id}>{label}</label>
    </div>
    <div className="col">
      <input
        id={id}
        type="time"
        onChange={onChange}
        disabled={disabled}
        className="form-control"
        value={value}
      />
    </div>
  </div>
);
TimeInput.defaultProps = { disabled: false };

const ReportMissionForm = ({ mission, onClose }: Props) => {
  const [currentReason, setCurrentReason] = React.useState<MissionReportReasonEnum | undefined>();
  const [timeStart, setTimeStart] = React.useState<string | undefined>();
  const [timeEnd, setTimeEnd] = React.useState<string | undefined>();
  const [breakDuration, setBreakDuration] = React.useState<number | undefined>();
  const [comment, setComment] = React.useState<string | undefined>();
  const [forValid, setFormValid] = React.useState<boolean>(true);
  const { t } = useTranslation();
  const [reportMission, { loading, error }] = useReportMissionMutation();

  const { profile } = mission.candidate;
  const gender = t(`gender.${profile.gender}`);

  let workingTime: number = differenceInMinutes(
    parseISO(`2000-01-01 ${timeEnd}`),
    parseISO(`2000-01-01 ${timeStart}`),
  );

  // Handle night shift
  if (workingTime < 0) {
    workingTime = differenceInMinutes(
      parseISO(`2000-01-02 ${timeEnd}`),
      parseISO(`2000-01-01 ${timeStart}`),
    );
  }

  const duration = workingTime + (breakDuration || 0);

  React.useEffect(() => {
    setTimeStart(getTimeFromJSONDate(mission.dates.startDate));
    setTimeEnd(getTimeFromJSONDate(mission.dates.endDate));
    setBreakDuration(mission.breakDuration);
  }, [mission]);

  const getIsFormValid = (): boolean => {
    let isValid = true;

    const hasValue = (
      val: string | undefined,
    ): boolean => val !== undefined && val.trim().length > 0;

    switch (currentReason) {
      case MissionReportReasonEnum.Other:
      case MissionReportReasonEnum.NotDone:
        isValid = hasValue(comment);
        break;
      case MissionReportReasonEnum.BadTime:
        isValid = (
          breakDuration !== undefined
          && breakDuration !== null
          && !Number.isNaN(breakDuration)
          && hasValue(timeStart)
          && hasValue(timeEnd)
        );
        break;
      default:
        isValid = false;
        break;
    }

    return isValid;
  };

  const handleOnSubmit = () => {
    setFormValid(true);
    if (getIsFormValid()) {
      if (currentReason && currentReason !== MissionReportReasonEnum.BadTime) {
        const variables: ReportMissionMutationVariables = {
          input: {
            missionUuid: mission.uuid,
            reason: currentReason,
            comment: comment || undefined,
          },
        };
        reportMission({
          variables,
          onCompleted() { onClose(); },
          onError() {},
        });
      }

      if (currentReason && currentReason === MissionReportReasonEnum.BadTime) {
        const variables: ReportMissionMutationVariables = {
          input: {
            missionUuid: mission.uuid,
            reason: currentReason,
            break: breakDuration,
            duration,
            startTime: timeToJSONDate(mission.dates.startDate, timeStart),
            endTime: timeToJSONDate(mission.dates.endDate, timeEnd),
          },
        };
        reportMission({
          variables,
          onCompleted() { onClose(); },
          onError() {},
        });
      }
    } else {
      setFormValid(false);
    }
  };

  const title = `${t('reportMissionForm.title')} ${utils.formatDateTime(mission.dates.startDate, 'dd/MM/yyyy')} - ${gender} ${profile.firstName} ${profile.lastName}`;

  return (
    <div>
      <CardTitle>{title}</CardTitle>
      <AlertMessage variant="warning" message={t('reportMissionForm.alert')} />
      {loading ? (
        <QueryIndicator />
      ) : (
        <>
          <form>
            {error && <AlertMessage message={error.message} />}
            {!forValid ? <AlertMessage message={t('errors.requiredField')} /> : null}
            <RadioInput
              currentReason={currentReason}
              reason={MissionReportReasonEnum.BadTime}
              onChange={setCurrentReason}
            />
            {currentReason === MissionReportReasonEnum.BadTime ? (
              <div className="px-4">
                <div className="row pb-2">
                  <div className="col-6">
                    <TimeInput
                      id="start-time"
                      label={t('reportMissionForm.startTime')}
                      onChange={(e) => setTimeStart(e.target.value)}
                      value={timeStart}
                    />
                  </div>
                  <div className="col-6">
                    <TimeInput
                      id="end-time"
                      label={t('reportMissionForm.endTime')}
                      onChange={(e) => setTimeEnd(e.target.value)}
                      value={timeEnd}
                    />
                  </div>
                </div>
                <div className="row pb-2">
                  <div className="col-6">
                    <div className="row">
                      <div className="col">
                        <label className="col-form-label" htmlFor="duration">
                          {t('reportMissionForm.break')}
                        </label>
                      </div>
                      <div className="col">
                        <input
                          id="break"
                          type="number"
                          className="form-control"
                          onChange={(e) => setBreakDuration(Number.parseInt(e.target.value, 10))}
                          value={breakDuration}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row">
                      <div className="col">
                        <label className="col-form-label" htmlFor="duration">
                          {t('reportMissionForm.duration')}
                        </label>
                      </div>
                      <div className="col">
                        <input
                          id="duration"
                          type="text"
                          className="form-control"
                          onChange={() => {}}
                          value={getReadableMinutesDiff(duration)}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            <RadioInput
              currentReason={currentReason}
              reason={MissionReportReasonEnum.NotDone}
              onChange={setCurrentReason}
            />
            {currentReason && currentReason === MissionReportReasonEnum.NotDone ? (
              <TextField
                id="reason"
                onChange={setComment}
                multiline
                lines={5}
                placeholder={t('reportMissionForm.notDone')}
                value={comment}
              />
            ) : null}
            <RadioInput
              currentReason={currentReason}
              reason={MissionReportReasonEnum.Other}
              onChange={setCurrentReason}
            />
            {currentReason && currentReason === MissionReportReasonEnum.Other ? (
              <TextField
                id="reason"
                onChange={setComment}
                multiline
                lines={5}
                placeholder={t('reportMissionForm.other')}
                value={comment}
              />
            ) : null}
          </form>
          <FormActions
            onCancel={onClose}
            onConfirm={handleOnSubmit}
            submitLabel={t('actions.report')}
            eventNamePrefix="m.mission.button.warn"
          />
        </>
      )}
    </div>
  );
};

export default ReportMissionForm;
