import React, {
} from 'react';
import { InputGroup } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';

import { availableCountryCodes } from '../../constants';
import * as tokens from '../../DesignSystem/tokens';
import { Phone } from '../../generated/graphql';
import '../../../sass/phoneNumberInput.scss';

export type PhoneObject = Required<Pick<Phone, 'code' | 'number'>>;

type PhoneProps = {
  phoneValue: PhoneObject;
  onChange: ({ code, number }: PhoneObject) => void
  label: string;
};

const PhoneNumberInput = ({
  phoneValue, onChange, label,
}: PhoneProps) => {
  const selectedCode = phoneValue.code || 33;
  const phoneNumber = phoneValue.number || '';
  const country = availableCountryCodes.find((item) => item.code === selectedCode);
  const currentSelectedCountry = country || ({ flag: 'flag', code: 33 });

  const handleSelectedCountryCode = (code: number) => {
    onChange({ code, number: phoneNumber });
  };

  const handleTypedNumber = (typedNumber: string) => {
    onChange({ code: currentSelectedCountry.code, number: typedNumber });
  };

  return (
    <label htmlFor="phone-code-dropdown" style={tokens.textFieldLabel}>
      {label}
      <InputGroup style={{ display: 'flex', flexWrap: 'nowrap' }}>
        <DropdownButton
          title={`${currentSelectedCountry.flag} +${currentSelectedCountry.code}`}
          id="phone-code-dropdown"
          data-testid="defaultCodeOption"
        >
          {availableCountryCodes.map((item) => (
            <Dropdown.Item
              key={item.code}
              onClick={() => handleSelectedCountryCode(item.code)}
              value={item.code}
            >
              {`${item.flag} +${item.code}`}

            </Dropdown.Item>
          ))}
        </DropdownButton>
        <Form.Control
          placeholder="0612345678"
          defaultValue={phoneNumber}
          data-testid="number-input-control"
          onChange={(evt) => handleTypedNumber(evt.target.value)}
          aria-label="Country Code Selector"
        />
      </InputGroup>
    </label>
  );
};

export default PhoneNumberInput;
