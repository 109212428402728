import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCardAnnouncementsQuery } from '../../../app/generated/graphql';
import AlertMessage from '../../../app/components/AlertMessage';
import QueryIndicator from '../../../app/components/QueryIndicator';

type Props = { groupOfficesUuid: Array<string> };
const CardAnnouncements = ({ groupOfficesUuid }: Props) => {
  const { data, error, loading } = useCardAnnouncementsQuery({ variables: { groupOfficesUuid } });
  const { t } = useTranslation();

  if (loading) {
    return <QueryIndicator />;
  }

  if (error) {
    return <AlertMessage message={error.message} />;
  }

  let translationKey = 'dashboard.announcementsCountSingular';

  if (
    data
    && data.me
    && data.me.Announcements
    && data.me.Announcements.totalCount
    && data.me.Announcements.totalCount > 1) {
    translationKey = 'dashboard.announcementsCountPlural';
  }

  return (
    <>
      <div className="h2 mb-3">
        {data?.me?.Announcements?.totalCount}
      </div>
      <h3>{t(translationKey)}</h3>
    </>
  );
};

export default CardAnnouncements;
