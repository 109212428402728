import jwtDecode from 'jwt-decode';

type Event = { event: string };
type TableOrderData = {
  table_order_field: string
  table_order_order: string
};
type TableFilterData = {
  table_filter_value: string
};
type TableExportEventData = {
  table_export_format: 'xlsx' | 'csv' | 'zip'
};
type TableOrderEvent = Event & TableOrderData;
type TableFilterEvent = Event & TableFilterData;
export type TableExportEvent = Event & TableExportEventData;

const pushEvent = (event: Event | TableOrderEvent | TableFilterEvent | TableExportEvent) => {
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push(event);
};

const logEvent = (event: string): void => {
  pushEvent({ event });
};

const logTableOrderChange = (event: string, data: TableOrderData) => {
  pushEvent({ event: `${event}.table_o`, ...data });
};

const logTableFilterChange = (event: string, data: TableFilterData) => {
  pushEvent({ event: `${event}.table_f`, ...data });
};

const logTableFilterExport = (event: string, data: TableExportEventData) => {
  pushEvent({ event: `${event}.table_e`, ...data });
};

const manageTracking = (token: string | null) => {
  if (token) {
    const tokenData: any = jwtDecode(token);

    document.body.dataset.logged = 'true';

    if (tokenData.username) {
      if ((tokenData.username as string).endsWith('@medelse.com')) {
        document.body.dataset.admin = 'true';
      } else {
        document.body.dataset.admin = 'false';
        document.body.dataset.username = tokenData.username;
        logEvent('m.enable_tracking');
      }
    }
  }
};

const disableTracking = () => {
  document.body.dataset.admin = 'false';
  document.body.dataset.username = '';
};

export default () => ({
  logEvent,
  logTableOrderChange,
  logTableFilterChange,
  logTableFilterExport,
  manageTracking,
  disableTracking,
});
