import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import Button from '../Button';
import utils from '../../domain/utils';

type Props = {
  calendarLink?: string
  email?: string
  phone?: string
};

const ICON_BOX_WIDTH = 40;

const ContactMethods = ({
  calendarLink, email, phone,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className="px-5">
      {email ? (
        <div className="mb-3 d-flex flex-direction-row">
          <div style={{ width: ICON_BOX_WIDTH }} className="me-3">
            <FontAwesomeIcon icon={faEnvelope} role="img" fontSize={25} />
          </div>
          <div className="fw-bolder me-1">{`${t('contact.email')}: `}</div>
          <a className="text-dark" href={`mailto:${email}`}>{email}</a>
        </div>
      ) : null}
      {phone ? (
        <div className="mb-3 d-flex flex-direction-row">
          <div style={{ width: ICON_BOX_WIDTH }} className="me-3">
            <FontAwesomeIcon icon={faPhone} role="img" fontSize={25} />
          </div>
          <div className="fw-bolder me-1">{`${t('contact.phone')}: `}</div>
          {phone}
        </div>
      ) : null}
      {calendarLink ? (
        <div className="text-center">
          <Button
            title={t('contact.calendarLink')}
            onClick={() => { utils.openLinkInNewWindow(calendarLink); }}
          />
        </div>
      ) : null}
    </div>
  );
};

ContactMethods.defaultProps = {
  calendarLink: undefined,
  email: undefined,
  phone: undefined,
};

export default ContactMethods;
