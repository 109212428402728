/* eslint-disable react/prop-types */
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Contract,
  DateRangeInput,
  useContractsToSignExportLazyQuery,
  useContractsToSignQuery,
} from '../../../app/generated/graphql';
import QueryIndicator from '../../../app/components/QueryIndicator';
import ContractsTable from '../ContractsTable';
import { useExportOptions } from '../../../app/hooks/useExportOptions';

type Props = {
  groupOfficesUuid: Array<string>
  dates: DateRangeInput | null
  eventNamePrefix: string
};
const ContractsToSignTable = ({ groupOfficesUuid, dates, eventNamePrefix }: Props) => {
  const { t } = useTranslation();
  const {
    data,
    error,
    loading,
  } = useContractsToSignQuery({ variables: { groupOfficesUuid, dates } });

  const { csvOptions, xlsxOptions } = useExportOptions({
    query: useContractsToSignExportLazyQuery,
    dates,
    groupOfficesUuid,
    name: t('contractsPage.exportToSignTitle'),
    eventName: eventNamePrefix,
  });

  if (loading) {
    return <QueryIndicator />;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  const contracts = data?.me?.contracts?.edges?.map((i) => (i!.node));

  return (
    <ContractsTable
      eventNamePrefix={eventNamePrefix}
      contracts={(contracts as Array<Contract>)}
      title={t('contracts.toSign')}
      hint={t('contracts.toSignHint')}
      exportOptions={[csvOptions, xlsxOptions]}
    />
  );
};

export default ContractsToSignTable;
