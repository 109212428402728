import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../app/components/Button';
import Modal from '../../../app/components/Modal';
import CreateAnnouncementForm from './CreateAnnouncementForm';

const OPEN_TRACK_KEY = 'm.announcement.button.create.open';

const CreateAnnouncement = () => {
  const { t } = useTranslation();

  const [modalVisible, setModalVisible] = React.useState<boolean>(false);

  const closeModal = () => setModalVisible(false);
  return (
    <>
      <Button
        title={t('createAnnouncement.openForm')}
        onClick={() => setModalVisible(true)}
        trackingEvent={OPEN_TRACK_KEY}
      />
      <Modal
        visible={modalVisible}
        onClose={closeModal}
      >
        <CreateAnnouncementForm
          onCancel={closeModal}
          onMutationSuccess={() => closeModal()}
        />
      </Modal>
    </>
  );
};

export default CreateAnnouncement;
