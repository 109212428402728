import React from 'react';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  HttpLink,
  from,
  ServerError,
  Observable,
  ApolloLink,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';

import useAuth from '../../auth/hooks/useAuth';

type Props = {
  children: JSX.Element,
};

const promiseToObservable = (promise: Promise<any>) => new Observable((subscriber: any) => {
  promise.then(
    (value) => {
      if (subscriber.closed) return;
      subscriber.next(value);
      subscriber.complete();
    },
    (err) => subscriber.error(err),
  );
});

const GraphQLProvider = ({ children }: Props) => {
  const { getToken, refreshToken } = useAuth();
  const httpLink = createUploadLink({ uri: `${process.env.REACT_APP_API_URL}/graphql/recruiter` });
  const authLink = setContext(
    () => ({ headers: { authorization: `Bearer ${getToken()}` } }),
  );
  // eslint-disable-next-line consistent-return
  const errorLink = onError(({ forward, networkError, operation }) => {
    if (
      networkError
      && (networkError as ServerError).response
      && (networkError as ServerError).response.status
      && (networkError as ServerError).response.status === 401
    ) {
      const retryNumber = operation.getContext()?.retryNumber || 0;

      const canRefresh = localStorage.getItem('medelse-remember-me');

      if (canRefresh && canRefresh === '1') {
        operation.setContext((prev: any) => (
          {
            ...prev,
            retryNumber: retryNumber + 1,
          }
        ));
        if (retryNumber < 4 && canRefresh) {
          return promiseToObservable(refreshToken(() => {})).flatMap(() => forward(operation));
        }
      }
    }
  });

  const debugLink = new ApolloLink((operation, forward) => {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.log('graphQL', operation.operationName, operation.variables);
    }

    return forward(operation);
  });

  const uuidBasedPolicy = { keyFields: ['uuid'] };

  const client = new ApolloClient({
    connectToDevTools: true,
    link: from([
      debugLink,
      errorLink,
      authLink,
      httpLink as unknown as HttpLink,
    ]),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all',
      },
    },
    cache: new InMemoryCache({
      typePolicies: {
        Recruiter: {
          ...uuidBasedPolicy,
          merge: true,
        },
        MissionConnection: { merge: true },
        ContractConnection: { merge: true },
        InvoiceConnection: { merge: true },
        Mission: uuidBasedPolicy,
        Announcement: uuidBasedPolicy,
        Contracts: uuidBasedPolicy,
        GroupOffice: {
          ...uuidBasedPolicy,
          merge: true,
        },
      },
    }),
  });

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  );
};

export default GraphQLProvider;
