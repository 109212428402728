/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { ColumnDef } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import { CandidateMissionsStats, useTopTenUsersQuery } from '../../../app/generated/graphql';
import AlertMessage from '../../../app/components/AlertMessage';
import QueryIndicator from '../../../app/components/QueryIndicator';
import Table from '../../../app/components/Table';
import TableCellUser from '../../../app/components/TableCellUser';
import TableCellSpecialty from '../../../app/components/TableCellSpecialty';

type Props = { groupOfficesUuid: Array<string> };

const TopTenUsers = ({ groupOfficesUuid }: Props) => {
  const { t } = useTranslation();
  const { data, error, loading } = useTopTenUsersQuery({ variables: { groupOfficesUuid } });

  if (loading) {
    return <QueryIndicator />;
  }

  if (error) {
    return <AlertMessage message={error.message} />;
  }

  const columns: ColumnDef<CandidateMissionsStats>[] = [
    {
      id: 'cancdidate_name',
      enableSorting: false,
      header: () => t('column.candidate'),
      accessorFn: (row) => `${row.candidate.profile.lastName} ${row.candidate.profile.firstName}`,
      cell: ({ row }) => <TableCellUser profile={row.original.candidate.profile} />,
    },
    {
      id: 'specialties',
      enableSorting: false,
      header: () => t('column.specialties'),
      accessorFn: (row) => row.candidate.specialties
        .map((spe) => t(`database_fields.${spe.name}`)),
      cell: ({ row }) => (
        <>
          {row.original.candidate.specialties.map((spe) => (
            <TableCellSpecialty key={spe.name} name={spe.name} uuid={spe.name} />
          ))}
        </>
      ),
    },
    {
      id: 'completedMissionNb',
      enableSorting: false,
      header: () => t('column.numberOfMissions'),
      accessorKey: 'completedMissionNb',
    },
  ];

  return (
    <Table
      title={t('dashboard.topTen.title')}
      hint={t('dashboard.topTen.hint')}
      columns={columns}
      data={(data!.CandidateMissionsStats as Array<CandidateMissionsStats>)}
    />
  );
};

export default TopTenUsers;
