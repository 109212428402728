import React from 'react';
import { useTranslation } from 'react-i18next';

import GroupOfficeSelect from '../../app/components/GroupOfficeSelect';
import PageHeader from '../../app/components/PageHeader';
import UpflowSummary from '../../features/FeesInvoices/UpflowSummary';
import { useGroupOffice } from '../../app/data/hooks';

const InvoicesPage = () => {
  const { t } = useTranslation();
  const { allGroupOffices, selectedGroupOfficeUuid } = useGroupOffice();
  const [groupOfficeUuid, setGroupOfficeUuid] = React.useState<string | undefined>(undefined);

  React.useEffect(() => {
    if (allGroupOffices.length === 1) {
      setGroupOfficeUuid(allGroupOffices[0].value);
    }
  }, [allGroupOffices]);

  return (
    <div>
      <PageHeader
        title={t('feesInvoicesPage.title')}
        description={t('feesInvoicesPage.hint')}
        leftSlot={<GroupOfficeSelect eventNamePrefix="m.fees-invoice.header" />}
      />
      <UpflowSummary groupOfficeUuid={selectedGroupOfficeUuid || groupOfficeUuid} />
    </div>
  );
};

export default InvoicesPage;
