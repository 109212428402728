import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { GroupOfficeOption } from '../domain/models';
import { ActionTypes } from './redux/actions';
import { AppDispatch, RootState } from './redux/store';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

type UseGroupOffice = () => ({
  allGroupOffices: GroupOfficeOption[]
  selectedGroupOfficeUuid: string
  selectGroupOffice: (groupOfficeUuid: string) => void
  getGroupOfficeQueryFilter: () => string[]
  showConfirmationFeature: boolean
});

export const useGroupOffice: UseGroupOffice = () => {
  const dispatch = useAppDispatch();
  const allGroupOffices = useAppSelector((state) => state.groupOffices.allGroupOffices);
  const selectedGroupOfficeUuid = useAppSelector((state) => state.groupOffices.selectedGroupOffice);
  const selectGroupOffice = (groupOfficeUuid: string) => dispatch({
    type: ActionTypes.SELECT_GROUP_OFFICE,
    groupOfficeUuid,
  });
  const getGroupOfficeQueryFilter = () => {
    if (selectedGroupOfficeUuid.length > 0) {
      return [selectedGroupOfficeUuid];
    }

    return allGroupOffices.map((go) => go.value);
  };

  const shouldDisplayConfirmationTable = () => {
    const currentGO = allGroupOffices.find((go) => go.value === selectedGroupOfficeUuid);

    if (currentGO) {
      return currentGO.feature_confirmation_enabled === true;
    }

    return allGroupOffices.find((go) => go.feature_confirmation_enabled) !== undefined;
  };

  return ({
    allGroupOffices,
    selectedGroupOfficeUuid,
    selectGroupOffice,
    getGroupOfficeQueryFilter,
    showConfirmationFeature: shouldDisplayConfirmationTable(),
  });
};
