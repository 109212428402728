import React from 'react';
import { useTranslation } from 'react-i18next';

import { Service, SpecificService } from '../../generated/graphql';
import TextWithTooltip from '../TextWithTooltip';

type Props = {
  service?: Pick<Service, 'name'> | null
  specificServices?: Array<Pick<SpecificService, 'name'> | null> | null
};
const TableCellService = ({ service, specificServices }: Props) => {
  const { t } = useTranslation();
  if (service && service) {
    const translatedService = t(`database_fields.${service.name}`);
    if (specificServices && specificServices.length) {
      const formattedSpecificServives = specificServices
        .filter((item) => item !== null && item.name != null)
        .map((item) => item!.name);
      return (
        <div className="d-flex justify-content-center">
          <TextWithTooltip
            tooltipId={`table-service-cell-${Math.random()}`}
            text={translatedService}
            tooltipContent={<div>{formattedSpecificServives.join(', ')}</div>}
          />
        </div>
      );
    }
    return <span>{translatedService}</span>;
  }

  return <span>-</span>;
};

TableCellService.defaultProps = {
  service: undefined,
  specificServices: undefined,
};

export default TableCellService;
