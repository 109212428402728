import React from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../Button';
import { ButtonVariant } from '../Button/Button';

type Props = {
  eventNamePrefix: string
  onCancel: VoidFunction
  onConfirm: VoidFunction
  variant?: ButtonVariant | undefined
  submitLabel?: string | undefined,
};

const FormActions = ({
  eventNamePrefix,
  onCancel,
  onConfirm,
  variant,
  submitLabel,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex flex-row justify-content-between mt-medium">
      <Button
        trackingEvent={`${eventNamePrefix}.cancel`}
        onClick={onCancel}
        title={t('actions.cancel')}
        variant="secondary"
      />
      <Button
        trackingEvent={`${eventNamePrefix}.confirm`}
        onClick={onConfirm}
        title={submitLabel || t('actions.confirm')}
        variant={variant || 'primary'}
      />
    </div>
  );
};

FormActions.defaultProps = {
  variant: undefined,
  submitLabel: undefined,
};

export default FormActions;
