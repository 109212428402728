import React from 'react';

import { MissionsToConfirmCountQueryResult, useMissionsToConfirmCountQuery } from '../../../app/generated/graphql';
import AlertMessage from '../../../app/components/AlertMessage';
import QueryIndicator from '../../../app/components/QueryIndicator';
import Toast from '../../../app/components/Toast';

type Props = { groupOfficesUuid: string[] };
const MissionsToConfirmCountAlert = ({ groupOfficesUuid }: Props) => {
  const {
    data,
    loading,
    error,
  } = useMissionsToConfirmCountQuery({ variables: { groupOfficesUuid } });

  if (loading) {
    return <QueryIndicator />;
  }

  if (error) {
    return <AlertMessage message={error.message} />;
  }

  const count = (data as MissionsToConfirmCountQueryResult['data'])!.me!.missions!.totalCount;

  if (count! > 5) {
    return <Toast variant="danger" message={`Attention - ${count} Missions à confirmer`} />;
  }
  if (count! > 0) {
    return <Toast variant="warning" message={`Attention - ${count} Missions à confirmer`} />;
  }

  return <Toast variant="success" message="Super - aucune action à confirmer" />;
};

export default MissionsToConfirmCountAlert;
