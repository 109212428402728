import React, { ReactNode } from 'react';
import jwtDecode from 'jwt-decode';
import useTracking from '../app/hooks/useTracking';
import AuthContext from './AuthContext';
import AuthService from './AuthService';
import { Credentials, Tokens } from './types';

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { disableTracking, manageTracking } = useTracking();
  const getToken = () => {
    const storedTokens = localStorage.getItem('medelse-business-auth');

    if (storedTokens) {
      return (JSON.parse(storedTokens) as Tokens)!.token;
    }

    return null;
  };

  const getAccessLevel = (): number => {
    const token = getToken();
    const { roles } = jwtDecode(token!) as any;
    const currentLevel = roles.filter((role: string) => role.includes('LEVEL'))[0];
    if (currentLevel === 'RECRUITER_LEVEL_1') {
      return 1;
    }
    if (currentLevel === 'RECRUITER_LEVEL_2') {
      return 2;
    }
    return 3;
  };

  const getRefreshToken = () => {
    const storedTokens = localStorage.getItem('medelse-business-auth');

    if (storedTokens) {
      return (JSON.parse(storedTokens) as Tokens)!.refreshToken;
    }

    return null;
  };

  const saveToken = (tokens: Tokens) => localStorage.setItem('medelse-business-auth', JSON.stringify(tokens));

  const signin = (
    credentials: Credentials,
    rememberMe: boolean,
  ) => new Promise<void>((resolve, reject) => {
    AuthService
      .signInWithCredentials(credentials)
      .then((authTokens) => {
        saveToken(authTokens);
        if (rememberMe) {
          localStorage.setItem('medelse-remember-me', '1');
        } else {
          localStorage.removeItem('medelse-remember-me');
        }
        resolve();
      })
      .catch((err) => reject(err));
  });

  const signout = (callback: VoidFunction) => {
    disableTracking();
    localStorage.removeItem('medelse-business-auth');
    localStorage.removeItem('medelse-remember-me');
    callback();
  };

  const refreshToken = () => new Promise<void>((resolve, reject) => {
    AuthService.refreshToken(getRefreshToken())
      .then((tokens) => {
        saveToken(tokens);
        resolve();
      })
      .catch((err) => reject(err));
  });

  const autologFromRefresh = (rToken: string) => new Promise<void>((resolve, reject) => {
    AuthService.refreshToken(rToken)
      .then((tokens) => {
        saveToken(tokens);
        localStorage.setItem('medelse-remember-me', '1');
        resolve();
      })
      .catch((e) => {
        reject(e);
      });
  });

  // We want to rerender the tree when the value changes
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    autologFromRefresh,
    getToken,
    getAccessLevel,
    signin,
    signout,
    refreshToken,
  };

  manageTracking(getToken());

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
