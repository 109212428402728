import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  AnnouncementShort,
  Service,
  SpecificService,
  useCancelAnnouncementMutation,
} from '../../../app/generated/graphql';
import FormActions from '../../../app/components/FormActions';
import AlertMessage from '../../../app/components/AlertMessage';
import TextField from '../../../app/components/TextField';
import QueryIndicator from '../../../app/components/QueryIndicator';
import utils from '../../../app/domain/utils';

export type AnnouncementToCancel = Pick<AnnouncementShort, 'uuid' | 'dates' | 'salary' | 'nbrNeeds' | 'sector'> & {
  specialty: Pick<AnnouncementShort['specialty'], 'name'>
  groupOffice: Pick<AnnouncementShort['groupOffice'], 'name'>
  service: Pick<Service, 'name'>
  specificServices: Array<Pick<SpecificService, 'name'>>
};

type Props = {
  announcement: AnnouncementToCancel | null
  onCancel: () => void
};

const CancelAnnouncement = ({
  announcement,
  onCancel,
}: Props) => {
  const { t } = useTranslation();
  const [content, setContent] = React.useState<string>(
    t('cancelAnnouncement.content', {
      officeName: announcement?.groupOffice.name,
      date: announcement ? utils.formatDate(announcement.dates.startDate) : '',
    }),
  );
  const [cancel, { loading, error }] = useCancelAnnouncementMutation();

  const handleSubmit = () => {
    if (announcement) {
      cancel({
        variables: {
          input: {
            announcementUuid: announcement.uuid,
            content,
          },
        },
        onCompleted() { onCancel(); },
        onError() {},
      });
    }
  };

  if (!announcement) {
    return null;
  }

  return (
    <div>
      <form>
        {error && <AlertMessage message={error.message} />}
        <TextField
          id="cancel-announcement-input"
          label={t('general.message')}
          onChange={setContent}
          value={content}
          multiline
        />
      </form>
      {loading ? (
        <QueryIndicator />
      ) : (
        <FormActions
          onCancel={onCancel}
          onConfirm={handleSubmit}
          eventNamePrefix="m.announcement.button.cancel"
          variant="warning"
        />
      )}
    </div>
  );
};

export default CancelAnnouncement;
