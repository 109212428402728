import React from 'react';
import { useTranslation } from 'react-i18next';
import AlertMessage from '../../../app/components/AlertMessage';

import CardTitle from '../../../app/components/CardTitle';
import FormActions from '../../../app/components/FormActions';
import QueryIndicator from '../../../app/components/QueryIndicator';
import Select from '../../../app/components/Select';
import TextField from '../../../app/components/TextField';
import { useGroupOffice } from '../../../app/data/hooks';
import { useCreateAnnouncementMutation } from '../../../app/generated/graphql';

import * as tokens from '../../../app/DesignSystem/tokens';

type Props = {
  onCancel: VoidFunction
  onMutationSuccess: VoidFunction
};

const CreateAnnouncementForm = ({ onMutationSuccess, onCancel }: Props) => {
  const { t } = useTranslation();
  const {
    allGroupOffices,
  } = useGroupOffice();

  const [groupOfficeUuid, setGroupOfficeUuid] = React.useState<string | undefined>(undefined);
  const [content, setContent] = React.useState<string>(t('createAnnouncement.content', { officeName: '' }));
  const [attachment, setAttachment] = React.useState<File | null>(null);
  const [createAnnouncement, { loading, error }] = useCreateAnnouncementMutation();

  React.useEffect(() => {
    if (allGroupOffices.length === 1) {
      setGroupOfficeUuid(allGroupOffices[0].value);
      setContent(t('createAnnouncement.content', { officeName: allGroupOffices[0].label }));
    }
  }, [allGroupOffices, t]);

  const handleGroupOfficeSelect = (goUuid: string) => {
    setGroupOfficeUuid(goUuid);
    setContent(t('createAnnouncement.content', { officeName: allGroupOffices.find(((go) => go.value === goUuid))?.label }));
  };

  const handleOnSubmit = () => {
    createAnnouncement({
      variables: { input: { content, groupOfficeUuid, attachment } },
      onCompleted() { onMutationSuccess(); },
      onError() {},
    });
  };

  return (
    <div>
      <CardTitle>{t('createAnnouncement.title')}</CardTitle>
      <AlertMessage variant="info" message={t('createAnnouncement.description')} />
      {loading ? (
        <QueryIndicator />
      ) : (
        <>
          <form>
            {error && <AlertMessage message={error.message} />}
            <div className="pb-3">
              <Select
                title={t('createAnnouncement.chooseAnOffice')}
                variant="white"
                onChange={handleGroupOfficeSelect}
                value={groupOfficeUuid}
                options={allGroupOffices}
              />
            </div>
            <TextField
              id="create-announcement-input"
              label={t('general.message')}
              onChange={setContent}
              value={content}
              multiline
            />
            <div className="mt-2">
              <label htmlFor="needs_attachment" style={tokens.textFieldLabel}>
                {t('createAnnouncement.attachment')}
                <input
                  className="form-control mt-2"
                  id="needs_attachment"
                  type="file"
                  onChange={({ target: { files } }) => {
                    if (files) {
                      setAttachment(files[0]);
                    } else {
                      setAttachment(null);
                    }
                  }}
                />
              </label>
            </div>
          </form>
          <FormActions
            onCancel={onCancel}
            onConfirm={handleOnSubmit}
            eventNamePrefix="m.announcement.button.create"
            variant="warning"
          />
        </>
      )}
    </div>
  );
};

export default CreateAnnouncementForm;
