import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AlertMessage from '../app/components/AlertMessage';
import useTracking from '../app/hooks/useTracking';
import AuthService from './AuthService';

type Props = { onCancel: VoidFunction };

const RecoverPassword = ({ onCancel }: Props) => {
  const { t } = useTranslation();
  const { logEvent } = useTracking();
  const [email, setEmail] = useState<string>('');
  const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isEmailFieldValid, setIsEmailFieldValid] = useState<boolean>(true);

  const validateEmail = (emailToValidate: string) => String(emailToValidate)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

  const emailIsValid = email.trim().length > 0 && validateEmail(email);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    logEvent('m.password.button.confirm.click');
    event.preventDefault();

    if (emailIsValid) {
      setIsEmailFieldValid(true);
      AuthService.recoverPassword(email)
        .then(() => {
          setEmail('');
          setIsSuccess(true);
        })
        .catch((err) => {
          logEvent('');
          setErrorMessage(err.message);
        });
    } else {
      setIsEmailFieldValid(false);
    }
  };

  return (
    <div className="mt-3">
      {isSuccess && !errorMessage && (
        <div className="alert alert-success" role="alert">
          {t('auth.recoverPassword.successMessage')}
        </div>
      )}
      {errorMessage && (
        <AlertMessage message={errorMessage} />
      )}
      <form onSubmit={handleSubmit}>
        <div className="input-group has-validation">
          <label htmlFor="emailField" className="form-label w-100">
            {t('auth.recoverPassword.emailInputLabel')}
            <input
              className={`form-control${!isEmailFieldValid ? ' is-invalid' : ''}`}
              id="emailField"
              name="email"
              type="email"
              value={email}
              onChange={({ target }) => setEmail(target.value)}
              required
            />
            {!isEmailFieldValid && <div className="invalid-feedback">{t('errors.requiredField')}</div>}
          </label>
        </div>
        <div className="text-end">
          <button
            type="button"
            className="btn btn-grey me-2"
            onClick={onCancel}
          >
            {t('app.global.cancel')}
          </button>
          <button
            type="submit"
            className="btn btn-primary"
          >
            {t('auth.recoverPassword.submitButton')}
          </button>
        </div>
      </form>
    </div>
  );
};

export default RecoverPassword;
