import React from 'react';
import { useTranslation } from 'react-i18next';

import { useGetGroupOfficeUpflowUrlQuery } from '../../../app/generated/graphql';
import QueryIndicator from '../../../app/components/QueryIndicator';
import Card from '../../../app/components/Card';
import EmptyDataComponent from '../../../app/components/EmptyDataComponent';

type Props = { groupOfficeUuid: string | undefined };

const UpflowSummary = ({ groupOfficeUuid }: Props) => {
  const { t } = useTranslation();
  const {
    loading,
    error,
    data,
  } = useGetGroupOfficeUpflowUrlQuery({ variables: { groupOfficeUuid } });

  if (!groupOfficeUuid) {
    return (
      <div className="alert alert-ternary" role="alert">{t('upflowSummary.placeholder')}</div>
    );
  }

  if (loading) {
    return <QueryIndicator />;
  }

  if (error) {
    return (
      <Card>
        <EmptyDataComponent message={t('upflowSummary.errorBlocMessage')} />
      </Card>
    );
  }

  return (
    <div>
      {data && data.getGroupOfficeUpflowURL ? (
        <Card>
          <div className="text-center py-5">
            <a
              className="btn btn-primary btn-lg"
              href={data.getGroupOfficeUpflowURL}
              title="Dashboard Upflow"
              target="_blank"
              rel="noreferrer"
              role="button"
            >
              {t('upflowSummary.openSummaryInNewTab')}
            </a>
          </div>
        </Card>
      ) : null}
    </div>
  );
};

export default UpflowSummary;
