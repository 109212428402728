import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Contract,
  DateRangeInput,
  PackageStatus,
  useContractsSignedExportLazyQuery,
  useContractsSignedQuery,
} from '../../../app/generated/graphql';
import QueryIndicator from '../../../app/components/QueryIndicator';
import ContractsTable from '../ContractsTable';
import { STYLE_ARRAY_RED_BACKGROUND } from '../../../app/constants';
import { useExportOptions } from '../../../app/hooks/useExportOptions';

type Props = {
  groupOfficesUuid: Array<string>
  dates: DateRangeInput | null
  eventNamePrefix: string
};
const ContractsSignedTable = ({ groupOfficesUuid, dates, eventNamePrefix }: Props) => {
  const { t } = useTranslation();
  const {
    data,
    error,
    loading,
  } = useContractsSignedQuery({ variables: { groupOfficesUuid, dates } });

  const { csvOptions, xlsxOptions } = useExportOptions({
    query: useContractsSignedExportLazyQuery,
    dates,
    groupOfficesUuid,
    name: t('contractsPage.exportSignedTitle'),
    eventName: eventNamePrefix,
  });

  if (loading) {
    return <QueryIndicator />;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  const contracts = data?.me?.contracts?.edges?.map((i) => (i!.node));

  return (
    <ContractsTable
      eventNamePrefix={eventNamePrefix}
      contracts={(contracts as Array<Contract>)}
      setRowStyles={(row) => {
        const contract = (row.original as Contract);
        if (contract.packageStatus !== PackageStatus.FullySigned) {
          return STYLE_ARRAY_RED_BACKGROUND;
        }

        return ({});
      }}
      title={t('contracts.signed')}
      hint={t('contracts.signedHint')}
      exportOptions={[csvOptions, xlsxOptions]}
    />
  );
};

export default ContractsSignedTable;
