import React from 'react';

type Props = {
  message: string
  variant?: 'danger' | 'warning' | 'success' | 'info'
};

const AlertMessage = ({ message: errorMessage, variant }: Props) => {
  let finalMessage: string;
  try {
    const { message } = JSON.parse(errorMessage);
    // eslint-disable-next-line no-unneeded-ternary
    finalMessage = message ? message : errorMessage;
  } catch (error) {
    finalMessage = errorMessage;
  }
  return (
    <div className={`alert alert-${variant || 'danger'}`} role="alert">{finalMessage}</div>
  );
};
AlertMessage.defaultProps = { variant: undefined };

export default AlertMessage;
