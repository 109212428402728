import React from 'react';
import { addYears, format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { DateRangeInput } from '../../app/generated/graphql';
import { spaceMedium } from '../../app/DesignSystem/tokens';
import GroupOfficeSelect from '../../app/components/GroupOfficeSelect';
import PageHeader from '../../app/components/PageHeader';
import Tabs from '../../app/components/Tabs';
import MissionsToConfirmCountAlert from '../../features/MissionsPage/MissionsToConfirmCountAlert';
import OnlineAnnouncementsTable from '../../features/MissionsPage/OnlineAnnouncementsTable';
import Card from '../../app/components/Card';
import MissionsToConfirmTable from '../../features/MissionsPage/MissionsToConfirmTable';
import UpcomingMissions from '../../app/components/UpcomingMissions';
import FinishedMissionsTable from '../../features/MissionsPage/FinishedMissionsTable';
import RangeDatePicker from '../../app/components/RangeDatePicker';
import useTracking from '../../app/hooks/useTracking';
import { useGroupOffice } from '../../app/data/hooks';
import CreateAnnouncement from '../../features/announcement/CreateAnnouncement';
import CancelledMissionsTable from '../../features/MissionsPage/CancelledMissionsTable';
import useAuth from '../../auth/hooks/useAuth';

const MissionsPage = () => {
  const { t } = useTranslation();
  const { logEvent } = useTracking();
  const { getAccessLevel } = useAuth();
  const { getGroupOfficeQueryFilter, showConfirmationFeature } = useGroupOffice();

  const today = new Date();
  const rangeNowToOneYear: DateRangeInput = {
    startDate: format(today, 'yyyy-MM-dd'),
    endDate: format(addYears(today, 1), 'yyyy-MM-dd'),
  };

  const rangeOneYearToNow: DateRangeInput = {
    startDate: format(addYears(today, -1), 'yyyy-MM-dd'),
    endDate: format(today, 'yyyy-MM-dd'),
  };

  const [filterDates, setFilterDates] = React.useState<DateRangeInput | null>(null);
  const selectedGroupOffices = getGroupOfficeQueryFilter();

  const handleFilterDateChange = (value: DateRangeInput | null) => {
    logEvent('m.mission.header.select_date.update');
    setFilterDates(value);
  };

  return (
    <div>
      <PageHeader
        title={t('missionsPage.title')}
        description={t('missionsPage.hint')}
        leftSlot={<GroupOfficeSelect eventNamePrefix="m.mission.header" />}
        rightSlot={(
          <>
            <div className="mb-4">
              {showConfirmationFeature ? (
                <MissionsToConfirmCountAlert groupOfficesUuid={selectedGroupOffices} />
              ) : null}
            </div>
            <RangeDatePicker onChange={handleFilterDateChange} />
          </>
        )}
      />
      <div className="text-end">
        <CreateAnnouncement />
      </div>
      <Tabs
        items={[
          {
            title: t('missionsPage.tabUpcoming'),
            content: (
              <div>
                {showConfirmationFeature && getAccessLevel() !== 1 ? (
                  <div style={{ marginBottom: spaceMedium }}>
                    <Card>
                      <MissionsToConfirmTable
                        groupOfficesUuid={selectedGroupOffices}
                        dates={filterDates}
                        eventNamePrefix="m.mission.missions_to_confirm"
                      />
                    </Card>
                  </div>
                ) : null}
                <div style={{ marginBottom: spaceMedium }}>
                  <Card>
                    <UpcomingMissions
                      eventNamePrefix="m.mission.upcoming_missions"
                      groupOfficesUuid={selectedGroupOffices}
                      dates={filterDates || rangeNowToOneYear}
                      title={t('missions.upcomingMissions')}
                      hint={t('missions.upcomingMissionsHint')}
                    />
                  </Card>
                </div>
                {getAccessLevel() !== 1 && (
                <Card>
                  <OnlineAnnouncementsTable
                    groupOfficesUuid={selectedGroupOffices}
                    dates={filterDates}
                    eventNamePrefix="m.mission.online_announcements"
                  />
                </Card>
                )}
              </div>
            ),
            eventName: 'm.mission.switch.future.click',
          },
          {
            title: t('missionsPage.tabArchived'),
            content: (
              <div>
                <div style={{ marginBottom: spaceMedium }}>
                  <Card>
                    <FinishedMissionsTable
                      eventNamePrefix="m.mission.finished_missions"
                      groupOfficesUuid={selectedGroupOffices}
                      dates={filterDates || rangeOneYearToNow}
                    />
                  </Card>
                </div>
                <Card>
                  <CancelledMissionsTable
                    eventNamePrefix="m.mission.cancelled_missions"
                    groupOfficesUuid={selectedGroupOffices}
                    dates={filterDates || rangeOneYearToNow}
                  />
                </Card>
              </div>
            ),
            eventName: 'm.mission.switch.old.click',
          },
        ]}
      />
    </div>
  );
};

export default MissionsPage;
