import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

type Props = { message?: string | undefined };

const EmptyDataComponent = ({ message }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex flex-column justify-content-center align-items-center text-ternary py-4">
      <FontAwesomeIcon icon={faMagnifyingGlass} role="img" fontSize={62} />
      <div className="d-inline-block mt-4">
        {message || t('emptyResult')}
      </div>
    </div>
  );
};

EmptyDataComponent.defaultProps = {
  message: undefined,
};

export default EmptyDataComponent;
