/* eslint-disable react/jsx-props-no-spreading */
import React, {
  forwardRef,
  LegacyRef,
  useState,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faChevronUp,
  faChevronDown,
  faClose,
} from '@fortawesome/free-solid-svg-icons';
import fr from 'date-fns/locale/fr';
import DatePicker from 'react-datepicker';
import 'react-datepicker/src/stylesheets/datepicker.scss';

import utils from '../../domain/utils';
import './RangeDatePicker.scss';
import { DateRangeInput } from '../../generated/graphql';

const INITIAL_VALUE: DateRangeInput = {
  startDate: null, endDate: null,
};

export type RangeDatePickerProps = {
  onChange: (dates: DateRangeInput | null) => void;
};

type CustomInputProps = {
  value?: string;
  onClear: () => void;
  onClick?: () => void;
  isOpen: boolean
};

const Input = forwardRef(({
  value, onClick, isOpen, onClear,
}: CustomInputProps, ref: LegacyRef<HTMLDivElement>) => {
  const hasValue = value!.length >= 14;
  return (
    <div className="date-picker-global-container">
      <div
        ref={ref}
        data-testid="datePicker-show-button"
        role="button"
        className="customDateSelect"
        tabIndex={-1}
        onClick={onClick}
        onKeyDown={onClick}
      >
        <div className="customDateSelect--icon-calendar"><FontAwesomeIcon style={{ color: '#093C4E' }} icon={faCalendarAlt} /></div>
        {
            (hasValue)
              ? <div className="customDateSelect--date-container-end"><span data-testid="endDate-text">{value}</span></div>
              : <span className="customDateSelect--date-container-select-text" data-testid="datepicker-placeholder-text">Sélectionner les dates</span>
            }
        {
        isOpen
          ? <div className="customDateSelect--icon-activity"><FontAwesomeIcon icon={faChevronUp} /></div>
          : <div className="customDateSelect--icon-activity"><FontAwesomeIcon icon={faChevronDown} /></div>
        }
      </div>
      {hasValue && (
        <button
          aria-labelledby="Clear date button"
          className="clear-date-range"
          data-testid="clear-date"
          type="button"
          onClick={onClear}
        >
          <FontAwesomeIcon icon={faClose} />
        </button>
      )}
    </div>
  );
});

Input.defaultProps = {
  value: undefined,
  onClick: () => {},
};

const RangeDatePicker = ({ onChange }: RangeDatePickerProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [displayedDateRange, setDisplayedDateRange] = useState<DateRangeInput>(INITIAL_VALUE);

  const clearPicker = () => {
    setDisplayedDateRange(INITIAL_VALUE);
    onChange(null);
  };

  return (
    <div className="datePicker-container">
      <DatePicker
        onChange={(el) => {
          setDisplayedDateRange({
            startDate: el[0],
            endDate: el[1],
          });
        }}
        startDate={displayedDateRange.startDate}
        endDate={displayedDateRange.endDate}
        locale={fr}
        formatWeekDay={(day) => day.charAt(0).toLocaleUpperCase('fr') + day.slice(1, 3)}
        dateFormat="dd/MM/yyyy"
        calendarStartDay={1}
        selectsRange
        shouldCloseOnSelect
        onCalendarOpen={() => {
          setIsOpen(true);
        }}
        onCalendarClose={() => {
          setIsOpen(false);
          if (displayedDateRange.startDate === null || displayedDateRange.endDate === null) {
            onChange(null);
          } else {
            onChange({
              startDate: utils.formatDateToJSON(displayedDateRange.startDate),
              endDate: utils.formatDateToJSON(displayedDateRange.endDate),
            });
          }
        }}
        customInput={<Input isOpen={isOpen} onClear={clearPicker} />}
      />
    </div>
  );
};

export default RangeDatePicker;
