import React from 'react';
import axios from 'axios';

import QueryIndicator from '../../app/components/QueryIndicator';
import { authAPIRoutes } from '../../app/constants';

type CDDMetabaseUsecase = (groupOfficeUuid: string) => ({
  loading: boolean
  url: string | undefined
  error: string | undefined
});

type Props = { groupOfficeUuid: string };

export const METABASE_OPTIONS = '#theme=transparent&bordered=false&titled=false';

const useCDDMetabase: CDDMetabaseUsecase = (groupOfficeUuid: string) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [url, setUrl] = React.useState<string | undefined>();
  const [error, setError] = React.useState<string | undefined>();

  React.useMemo(() => {
    const apiUrl = process.env.REACT_APP_API_URL + authAPIRoutes.CDDMetabase.replace('{uuid}', groupOfficeUuid);

    axios.get(apiUrl)
      .then((response) => {
        const { data } = response;
        setUrl(data.url);
        setError(undefined);
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [groupOfficeUuid]);

  return { loading, url, error };
};

const CDDMetabase = ({ groupOfficeUuid }: Props) => {
  const { loading, url, error } = useCDDMetabase(groupOfficeUuid);

  if (loading) {
    return <QueryIndicator />;
  }

  return (
    <div>
      {error ? (
        <div className="container py-4">
          <div className="alert alert-danger">{error}</div>
        </div>
      ) : null}
      {url ? (
        <iframe
          title="Dashboard CDD"
          src={url + METABASE_OPTIONS}
          style={{ width: '100%', minHeight: '100vh' }}
        />
      ) : null}
    </div>
  );
};

export default CDDMetabase;
