import React from 'react';
import { Modal as BSModal } from 'react-bootstrap';

type Props = {
  visible: boolean
  children: React.ReactNode
  onClose: VoidFunction
};

const Modal = ({
  children,
  onClose,
  visible,
}: Props) => (
  <BSModal
    show={visible}
    onHide={onClose}
    centered
    size="lg"
  >
    <BSModal.Body>
      {children}
    </BSModal.Body>
  </BSModal>
);

export default Modal;
