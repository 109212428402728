import React from 'react';

import utils from '../../domain/utils';
import TextWithTooltip from '../TextWithTooltip';

type Props = { dates: Array<string>, rowUuid: string };

const TableCellDatesPopover = ({ dates, rowUuid }: Props) => {
  const formattedDates = dates.map((d) => utils.formatDate(d));

  if (formattedDates.length > 2) {
    const lastItem = utils.getLastItemFromArray(formattedDates);
    const displayedDates: Array<string> = [
      formattedDates[0],
      lastItem!,
    ];

    return (
      <div className="d-flex justify-content-center">
        <TextWithTooltip
          text={displayedDates.join('; ')}
          tooltipVariant="more"
          tooltipType="popover"
          tooltipContent={(
            <>
              {formattedDates.map((date) => <div key={date}>{date}</div>)}
            </>
          )}
          tooltipId={`${rowUuid}-dates-popover`}
        />
      </div>
    );
  }

  return <span>{formattedDates.join('; ')}</span>;
};

export default TableCellDatesPopover;
