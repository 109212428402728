import React from 'react';
import { useTranslation } from 'react-i18next';

import LayoutSplit from '../../../app/components/LayoutSplit/LayoutSplit';
import GeneratePasswordForm from '../GeneratePasswordForm/GeneratePasswordForm';

const GeneratePasswordRoute = () => {
  const { t } = useTranslation();

  return (
    <LayoutSplit coverImage="https://blog.medelse.com/wp-content/uploads/2020/07/covid-sondage-soignants.png" withLogo>
      <>
        <div className="d-flex justify-content-between align-items-center">
          <h2 className="h2">{t('generatePassword.title')}</h2>
        </div>
        <GeneratePasswordForm />
      </>
    </LayoutSplit>
  );
};

export default GeneratePasswordRoute;
