import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import './sass/main.scss';
import Router from './navigation/Router';
import reportWebVitals from './reportWebVitals';

import GraphQLProvider from './app/providers/GraphqlProvider';
import AuthProvider from './auth/AuthProvider';
import TranslationsProvider from './i18n/TranslationsProvider';
import initializeGTM from './GoogleTagManager';
import store from './app/data/redux/store';

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <TranslationsProvider>
        <AuthProvider>
          <GraphQLProvider>
            <Router />
          </GraphQLProvider>
        </AuthProvider>
      </TranslationsProvider>
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
initializeGTM();
