/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnDef, ColumnSort } from '@tanstack/react-table';

import {
  DateRangeInput,
  Mission,
  useFinishedMissionsExportLazyQuery,
  useFinishedMissionsQuery,
  ValidationStatusEnum,
} from '../../../app/generated/graphql';
import AlertMessage from '../../../app/components/AlertMessage';
import { ARRAY_ITEMS_PER_PAGE } from '../../../app/constants';
import QueryIndicator from '../../../app/components/QueryIndicator';
import Table from '../../../app/components/Table';
import utils from '../../../app/domain/utils';
import TableCellService from '../../../app/components/TableCellService';
import Button from '../../../app/components/Button';
import TableCellIcon from '../../../app/components/TableCellIcon';
import TableCellUser from '../../../app/components/TableCellUser';
import TableCellSpecialty from '../../../app/components/TableCellSpecialty';
import Modal from '../../../app/components/Modal';
import ReportMissionForm from '../ReportMissionForm';
import { MissionData } from '../MissionsTypes';
import { useExportOptions } from '../../../app/hooks/useExportOptions';
import useAuth from '../../../auth/hooks/useAuth';

type Props = {
  groupOfficesUuid: Array<string>,
  dates: DateRangeInput
  eventNamePrefix: string
};
const FinishedMissionsTable = ({ dates, groupOfficesUuid, eventNamePrefix }: Props) => {
  const { getAccessLevel } = useAuth();
  const {
    data,
    error,
    loading,
  } = useFinishedMissionsQuery({ variables: { groupOfficesUuid, dates } });
  const { t } = useTranslation();
  const [missionToReport, setMissionToReport] = React.useState<MissionData | null>(null);

  const REPORT = t('column.report');
  const REPORTED_AT = t('missions.reportedAt');

  const { csvOptions, xlsxOptions } = useExportOptions({
    query: useFinishedMissionsExportLazyQuery,
    dates,
    groupOfficesUuid,
    name: t('missionsPage.exportArchivedTitle'),
    eventName: eventNamePrefix,
    role: getAccessLevel(),
  });

  if (loading) {
    return <QueryIndicator />;
  }

  if (error) {
    return <AlertMessage message={error.message} />;
  }

  const columns: ColumnDef<Mission>[] = [
    {
      id: 'date',
      header: t('column.date'),
      accessorFn: (row) => row.dates.startDate,
      cell: ({ row }) => utils.formatDate(row.original.dates.startDate),
    },
    {
      id: 'fullname',
      header: t('column.candidate'),
      accessorFn: (row) => `${row.candidate.profile.lastName} ${row.candidate.profile.firstName}`,
      cell: ({ row }) => <TableCellUser profile={row.original.candidate.profile} />,
    },
    {
      id: 'specialty',
      header: t('column.specialties'),
      accessorKey: 'specialty',
      cell: ({ row }) => {
        const { name } = row.original.specialty;

        return <TableCellSpecialty name={name} uuid={row.original.uuid} />;
      },
    },
    {
      id: 'services',
      header: t('column.service'),
      accessorFn: (row) => row.service.name,
      cell: ({ row }) => {
        const { specificServices, service } = row.original;

        return <TableCellService service={service} specificServices={specificServices} />;
      },
    },
    {
      id: 'sector',
      header: t('column.sector'),
      accessorKey: 'sector',
    },
    {
      id: 'salary',
      enableGlobalFilter: false,
      header: t('column.salary'),
      accessorFn: (row) => `${utils.formatFloat(row.salary!)} €`,
    },
    {
      id: 'hours',
      header: t('column.hours'),
      cell: ({ row }) => {
        const missionDates = row.original.dates;

        const getHours = (dateTime: string) => utils.formatDateTime(dateTime, 'HH:mm');
        return `${getHours(missionDates.startDate)} - ${getHours(missionDates.endDate)}`;
      },
    },
    {
      id: 'report',
      header: t('column.report'),
      cell: ({ row }) => {
        if (row.original.contractDetail === null) { return null; }

        const { recruiterValidationDate, recruiterValidationStatus } = row.original.contractDetail!;

        if (recruiterValidationStatus === ValidationStatusEnum.Invalid) {
          return (
            <TableCellIcon
              variant="warning"
              text={`${REPORTED_AT} ${utils.formatDateTime(recruiterValidationDate, 'dd/MM/yyyy')}`}
            />
          );
        }

        return (
          <Button
            trackingEvent="m.mission.button.warn.click"
            variant="warning"
            onClick={() => setMissionToReport(row.original)}
            title={REPORT}
          />
        );
      },
    },
  ];

  const missions = data?.me?.missions?.edges?.map((item) => item?.node);

  const defaultColumnSort: ColumnSort = { id: 'date', desc: true };

  return (
    <>
      <Table
        eventNamePrefix={eventNamePrefix}
        pageSize={ARRAY_ITEMS_PER_PAGE}
        columns={utils.filterTableColumnsByRole(columns, getAccessLevel())}
        data={(missions as Array<Mission>)}
        defaultColumnSort={defaultColumnSort}
        canBeFiltered
        title={t('missions.finishedMissions')}
        hint={t('missions.finishedMissionsHint')}
        exportOptions={[csvOptions, xlsxOptions]}
      />
      <Modal
        visible={missionToReport !== null}
        onClose={() => setMissionToReport(null)}
      >
        {missionToReport && (
          <ReportMissionForm
            mission={missionToReport}
            onClose={() => setMissionToReport(null)}
          />
        )}
      </Modal>
    </>
  );
};

export default FinishedMissionsTable;
