import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

export type NavListProps = {
  items: {
    active: boolean
    label: string,
    icon: IconDefinition
    onClick: () => void
    eventName: string
  }[]
};

const NavList = ({ items }: NavListProps) => (
  <nav className="nav-list-component">
    <ul className="nav flex-column">
      {items.map((navItem) => {
        const buttonClasses: string[] = [
          'position-relative',
          'd-block',
          'w-100',
          'text-start',
          'p-3',
        ];

        if (navItem.active) {
          buttonClasses.push('active', 'fw-bolder');
        }

        return (
          <li key={`nav-${navItem.label}`}>
            <button
              data-tracking-event={navItem.eventName}
              className={buttonClasses.join(' ')}
              type="button"
              onClick={navItem.onClick}
              disabled={navItem.active}
            >
              <div className="d-inline-block text-center me-2">
                <FontAwesomeIcon icon={navItem.icon} role="img" />
              </div>
              {navItem.label}
            </button>
          </li>
        );
      })}
    </ul>
  </nav>
);

export default NavList;
