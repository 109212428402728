/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import { ColumnDef, ColumnSort } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';

import {
  DateRangeInput,
  Invoice,
  PaymentStatus,
  useDownloadInvoicesMutation,
  useInvoicesToPayExportLazyQuery,
  useInvoicesToPayQuery,
} from '../../../app/generated/graphql';
import { ARRAY_ITEMS_PER_PAGE, STYLE_ARRAY_RED_BACKGROUND } from '../../../app/constants';
import { Action } from '../../../app/components/ActionDropdown/ActionDropdown';
import utils from '../../../app/domain/utils';
import Table from '../../../app/components/Table';
import QueryIndicator from '../../../app/components/QueryIndicator';
import ActionDropdown from '../../../app/components/ActionDropdown';
import Card from '../../../app/components/Card';
import TableCellUser from '../../../app/components/TableCellUser';
import useAuth from '../../../auth/hooks/useAuth';
import TableCellSpecialty from '../../../app/components/TableCellSpecialty';
import useTracking from '../../../app/hooks/useTracking';
import TableCellDatesPopover from '../../../app/components/TableCellDatesPopover';
import { useExportOptions } from '../../../app/hooks/useExportOptions';
import Modal from '../../../app/components/Modal';

type Props = {
  groupOfficesUuid:Array<string>
  dates: DateRangeInput | null
  eventNamePrefix: string
};
const InvoicesToPayTable = ({ groupOfficesUuid, dates: invoiceDates, eventNamePrefix }: Props) => {
  const { t } = useTranslation();
  const { logEvent } = useTracking();
  const { getToken, getAccessLevel } = useAuth();
  const token = getToken();
  const {
    data,
    error,
    loading,
  } = useInvoicesToPayQuery({ variables: { groupOfficesUuid, dates: invoiceDates } });

  const { csvOptions, xlsxOptions } = useExportOptions({
    query: useInvoicesToPayExportLazyQuery,
    dates: invoiceDates,
    groupOfficesUuid,
    name: t('invoicesPage.exportToPayTitle'),
    eventName: eventNamePrefix,
  });

  const { loading: zipLoading, zipOptions } = useExportOptions({
    query: useDownloadInvoicesMutation,
    dates: invoiceDates,
    groupOfficesUuid,
    name: t('invoicesPage.exportToPayTitle'),
    eventName: eventNamePrefix,
  });

  if (loading) {
    return <QueryIndicator />;
  }

  if (error) {
    return <div>{error.message}</div>;
  }

  const invoices = data?.me?.invoices?.edges?.map((i) => (i!.node));
  const columns: ColumnDef<Invoice>[] = [
    {
      id: 'invoice_number',
      accessorKey: 'id',
      header: () => t('column.invoiceNumber'),
    },
    {
      id: 'candidate_name',
      header: () => t('column.candidate'),
      accessorFn: (row) => `${row.candidate?.profile.lastName} ${row.candidate?.profile.firstName}`,
      cell: ({ row }) => <TableCellUser profile={row.original.candidate?.profile} />,
    },
    {
      id: 'specialty',
      header: () => t('column.specialties'),
      accessorFn: (row) => row.contractDetails![0]!.specialty.name,
      cell: ({ row }) => {
        const { name } = row.original.contractDetails![0]!.specialty;

        return <TableCellSpecialty name={name} uuid={row.original.uuid} />;
      },
    },
    {
      id: 'amount',
      enableGlobalFilter: false,
      header: () => t('column.salary'),
      accessorFn: (row) => `${utils.formatFloat(row.totalAmount!)} €`,
    },
    {
      id: 'paymentLimit',
      enableGlobalFilter: false,
      header: () => t('column.paymentLimit'),
      accessorFn: (row) => row.payment?.dueDate,
      cell: ({ row }) => utils.formatDate(row.original.payment?.dueDate),
    },
    {
      id: 'status',
      header: () => t('column.status'),
      enableGlobalFilter: false,
      accessorFn: (row) => t(utils.getPaymentStatusLabel(row.payment?.status)),
    },
    {
      id: 'dates',
      header: () => t('column.contractDates'),
      enableGlobalFilter: false,
      accessorFn: (row) => row.contractDetails!.map(
        (cd) => cd?.dateTimes.startDate,
      ),
      cell: (props) => {
        const dates = props.getValue<Array<string>>();

        return (
          <TableCellDatesPopover
            dates={dates}
            rowUuid={props.row.original.uuid}
          />
        );
      },
    },
    {
      id: 'actions',
      header: () => t('column.actions'),
      cell: ({ row }) => {
        const actions: Array<Action> = [{
          label: 'Télécharger',
          action: () => {
            logEvent('m.invoice.button.to_pay.download');
            if (row.original.documentDownloadLink && token) {
              utils.openStreamLink(row.original.documentDownloadLink, token);
            }
          },
        }];
        return <ActionDropdown actions={actions} id={`table-action-${row.original.uuid}`} />;
      },
    },
  ];

  const defaultColumnSort: ColumnSort = { id: 'invoice_number', desc: true };

  return (
    <Card>
      <Table
        eventNamePrefix={eventNamePrefix}
        pageSize={ARRAY_ITEMS_PER_PAGE}
        columns={utils.filterTableColumnsByRole(columns, getAccessLevel())}
        data={(invoices as Array<Invoice>)}
        defaultColumnSort={defaultColumnSort}
        canBeFiltered
        setRowStyles={(row) => {
          if (row.original.payment?.status === PaymentStatus.Late) {
            return STYLE_ARRAY_RED_BACKGROUND;
          }

          return {};
        }}
        title="Factures"
        hint="Notes d’honoraires à payer"
        exportOptions={[csvOptions, xlsxOptions, zipOptions]}
      />
      <Modal visible={zipLoading} onClose={() => {}}>
        <h2 className="text-center"><strong>{t('invoicesPage.zipLoading.title')}</strong></h2>
        <QueryIndicator />
        <p className="text-center">{t('invoicesPage.zipLoading.subTitle')}</p>
      </Modal>
    </Card>
  );
};

export default InvoicesToPayTable;
