import React from 'react';
import { NetworkStatus } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

import useAuth from './hooks/useAuth';
import UserGroupOfficesContext from './UserGroupOfficesContext';
import LoaderFullPage from '../app/LoaderFullPage';
import { useUserGroupOfficesQuery } from '../app/generated/graphql';
import store from '../app/data/redux/store';
import { ActionTypes } from '../app/data/redux/actions';

const UserGroupOfficesProvider = ({ children }: { children: React.ReactNode }) => {
  const { signout } = useAuth();
  const navigate = useNavigate();

  const {
    loading,
    data,
    networkStatus,
  } = useUserGroupOfficesQuery(
    {
      notifyOnNetworkStatusChange: true,
      onError() {
        signout(() => navigate('/login', { replace: true }));
      },
      onCompleted(queryData) {
        store.dispatch({
          type: ActionTypes.STORE_GROUP_OFFICES,
          groupOfficeOptions: queryData.me!.recruiterGroupOffices!.map((go) => ({
            label: go!.groupOffice.name,
            value: go!.groupOffice.uuid,
            feature_confirmation_enabled: go!.groupOffice.isFeatureConfirmationEnabled,
          })),
        });
      },
    },
  );

  if (loading || networkStatus === NetworkStatus.refetch) {
    return <LoaderFullPage />;
  }

  if (!data) {
    return null;
  }

  return (
    <UserGroupOfficesContext.Provider value={data.me}>
      {children}
    </UserGroupOfficesContext.Provider>
  );
};

export default UserGroupOfficesProvider;
