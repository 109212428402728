import React, { CSSProperties } from 'react';

import * as tokens from '../../DesignSystem/tokens';

type Props = {
  children: React.ReactNode
  marginBottom?: number,
  h100?: boolean,
};

const cardStyles: CSSProperties = {
  boxShadow: tokens.shadowMedium,
  overflow: 'hidden',
};

const Card = ({ children, h100, marginBottom }: Props) => (
  <div
    className="bg-white p-3 rounded bg-white position-relative"
    style={{
      ...cardStyles,
      height: h100 ? '100%' : undefined,
      marginBottom,
    }}
  >
    {children}
  </div>
);

Card.defaultProps = { marginBottom: 0, h100: false };

export default Card;
